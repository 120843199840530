import { createSlice } from "@reduxjs/toolkit";

const scriptsDetailSlice = createSlice({
  name: "scriptDetail",
  initialState: {},
  reducers: {
    setScriptsDetailData(state, { payload }) {
      state.data = { ...state?.data, ...payload };
    },
  },
});

export const { setScriptsDetailData } = scriptsDetailSlice.actions;
export default scriptsDetailSlice;
