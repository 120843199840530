import { Row } from "antd";
import { Button } from "components/common";
import theme from "utils/themeVars";

const QuizQuestionPagination = ({
  disablePrevious,
  quizDetails,
  onClickPrevious,
  disableNext,
  onClickNext,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  return (
    <Row justify="center">
      <div style={{ display: "flex", gap: "14px" }}>
        <Button
          style={{
            borderColor: theme["blue100"],
            color: theme["blue200"],
            background: theme["light-gray"],
          }}
          size="large"
          onClick={onClickPrevious}
          disabled={disablePrevious}
          icon="MdOutlineChevronLeft"
        />
        <div style={{ display: "flex", gap: "4px" }}>
          {quizDetails?.questionsList?.length
            ? quizDetails?.questionsList.map((e, i) => {
                return (
                  <Button
                    style={{
                      border:
                        i == selectedQuestion
                          ? `2px solid ${theme["blue100"]}`
                          : "",
                      color: i == selectedQuestion ? theme["blue200"] : "",
                      background:
                        i == selectedQuestion ? theme["light-gray"] : "",
                    }}
                    disabled={
                      i != 0 &&
                      !quizDetails?.questionsList[i - 1]?.skipped &&
                      !quizDetails?.questionsList[i - 1]?.answer
                    }
                    onClick={() => setSelectedQuestion(i)}
                    text={i + 1}
                  />
                );
              })
            : null}
        </div>

        <Button
          style={{
            borderColor: theme["blue100"],
            color: theme["blue200"],
            background: theme["light-gray"],
          }}
          size="large"
          disabled={disableNext}
          onClick={onClickNext}
          icon="MdOutlineChevronRight"
        />
      </div>
    </Row>
  );
};

export default QuizQuestionPagination;
