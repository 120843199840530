import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Title, notification } from "components/common";
import { Switch, Select, Checkbox, Row } from "antd";
import styles from "./index.module.less";
import { useUpdateAccountDetailsMutation } from "./api";

const { Option } = Select;

const SelectWithCheckbox = ({ settingsData, setPriviliges }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    settingsData?.activities_to_capture?.length
      ? settingsData?.activities_to_capture
      : []
  );

  const [isAllSelected, setAllSelected] = useState(false);

  const options = [
    { label: "New Page", value: "newPage" },
    { label: "Compared Pages", value: "comparedPages" },
    { label: "Study Session", value: "studySession" },
    { label: "Graphs", value: "graphs" },
  ];

  const handleOptionChange = (selected) => {
    setAllSelected(selected.length === options.length);
    const arr = selected.filter((option) => option !== "selectAll");
    setSelectedOptions(arr);
    setPriviliges(arr);
  };

  useEffect(() => {
    if (isAllSelected) {
      const arr = options.map((option) => option.label);
      handleOptionChange(arr);
    } else if (!isAllSelected && selectedOptions.length === options.length) {
      setSelectedOptions([]);
    }
  }, [isAllSelected]);

  return (
    <Select
      mode="multiple"
      style={{ width: "100%" }}
      value={selectedOptions}
      onChange={handleOptionChange}
    >
      <Option key="selectAll">
        <Checkbox
          style={{ gap: "10px" }}
          checked={selectedOptions.length === options.length || isAllSelected}
          onChange={() => setAllSelected(!isAllSelected)}
        />
        Select All
      </Option>
      {options.map((option) => (
        <Option key={option.label} value={option.label}>
          <Checkbox
            style={{ gap: "10px" }}
            checked={selectedOptions.includes(option.label)}
          />
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

const Settings = ({
  settingsData,
  userCode,
  prepareSettingsData,
  setIsDataUpdated,
}) => {
  const [emailNotification, setEmailNotification] = useState(
    settingsData.is_email_enabled
  );
  const [twoFactorAuth, setTwoFactorAuth] = useState(
    settingsData.is_two_factor_auth_enabled
  );
  const [paymentMode, setPaymentMode] = useState(
    settingsData.is_payment_enabled
  );
  const [userRole, setUserRole] = useState(settingsData?.user_role);
  const [priviliges, setPriviliges] = useState([]);
  const [updateAccountDetails, {}] = useUpdateAccountDetailsMutation();

  const handleUpdate = async () => {
    setIsDataUpdated(false);
    const payload = {
      is_two_factor_auth_enabled: twoFactorAuth,
      activities_to_capture: priviliges,
      is_payment_enabled: paymentMode,
      is_email_enabled: emailNotification,
      is_alert_preference: false,
      is_follow_request: false,
      user_code_v2: userCode,
      user_role: userRole,
    };
    const res = await updateAccountDetails({
      body: payload,
    });

    setIsDataUpdated(true);
    if (res) {
      if (res?.error) {
        notification.error({ message: res?.error });
      } else {
        prepareSettingsData(payload);
        notification.success({ message: "Updated Successfully" });
      }
    }
  };

  return (
    <div>
      <Title style={{ padding: "20px" }} level={1}>
        Settings
      </Title>
      <div className={styles.settings}>
        <div className={styles.settingsLeftSection}>
          <div className={styles.toggleGroup}>
            <label className={styles.heading}>Email Notification:</label>
            <Switch
              style={{ cursor: "pointer" }}
              checked={emailNotification}
              onChange={setEmailNotification}
            />
          </div>
          <div className={styles.toggleGroup}>
            <label className={styles.heading}>Two Factor Auth:</label>
            <Switch
              style={{ cursor: "pointer" }}
              checked={twoFactorAuth}
              onChange={setTwoFactorAuth}
            />
          </div>
          <div className={styles.toggleGroup}>
            <label className={styles.heading}>Payment Mode:</label>
            <Switch
              style={{ cursor: "pointer" }}
              checked={paymentMode}
              onChange={setPaymentMode}
            />
          </div>
          <Row style={{ gap: 8, justifyContent: "left", marginTop: "50px" }}>
            <Button
              size="large"
              type="primary"
              text={"Update"}
              onClick={handleUpdate}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Settings;
