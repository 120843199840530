import { Button, notification } from "components/common";
import styles from "../index.module.less";
import ScriptBasicInfo from "./scriptBasicInfo";
import ScriptExplanationSection from "./scriptExplanationSection";
import ScriptPresentationSection from "./scriptPresentationSection";
import { useNavigate } from "react-router-dom";
import {
  useAddScriptMutation,
  useGetAllQuestionsOfScriptQuery,
  useUpdateScriptMutation,
} from "features/script/api";
import { getScriptPayload } from "./getScriptPayload";
import { useDispatch, useSelector } from "react-redux";
import PreviewModal from "./previewModal";
import { useState, useEffect } from "react";
import { convertQueryObjToString } from "utils";
import { version } from "less";
import {
  setBasicScriptData,
  setQuestionsData,
  setScriptPresentationData,
} from "store/scriptSlice";
import ScriptQuestionSection from "./questions/scriptQuestionSection";

const ScriptRightPanel = ({
  isMobile,
  data,
  editMode,
  initialData,
  editorId,
  version,
  podCastDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.app?.user?.info);
  const scriptData = useSelector((state) => state?.scripts?.data);

  const [addScript, { data: script, isLoading: submitLoading }] =
    useAddScriptMutation();
  const [updateData, { isLoading: updateLoading }] = useUpdateScriptMutation();

  const { data: scriptQuestions } = useGetAllQuestionsOfScriptQuery(
    { scriptId: scriptData?.scriptId },
    { refetchOnMountOrArgChange: true, skip: !scriptData?.scriptId }
  );

  const [previewModal, setPreviewModal] = useState(false);
  const [podCastData, setPodCastData] = useState([]);

  const list = useSelector((state) => state?.scripts?.data?.podCast);

  const handlePodCastData = (data) => {
    if (list?.length > 0 && data) {
      setPodCastData([...list, data]);
    } else if (list?.length === 0 && podCastData?.length) {
      setPodCastData([data]);
    } else {
      setPodCastData((prevData) => [...prevData, data]);
    }
  };

  useEffect(() => {
    if (scriptData?.scriptId) {
      dispatch(setQuestionsData(scriptQuestions));
    }
  }, [scriptQuestions, scriptData]);

  useEffect(() => {
    if (podCastData.length > 0) {
      podCastDetails(podCastData);
    }
  }, [podCastData, podCastDetails]);

  const onSubmitScript = async () => {
    const payload = getScriptPayload(data, user);
    const response = await addScript({
      ...payload,
      question: payload?.questions,
    });
    if (response?.error) {
      notification.error({ message: response?.error?.message });
    } else {
      notification.success({ message: "Script Added Successfully" });
      setPreviewModal(false);
      navigate(`/editedpages`);
    }
  };

  const onEditSave = async (keys, index, updatedData) => {
    let pay = JSON.parse(JSON.stringify(initialData));
    if (!keys && !keys?.length) {
      pay = getScriptPayload(updatedData);
    } else if (!index && !!keys?.length) {
      keys.forEach((key) => {
        pay[key] = getScriptPayload(updatedData)?.[key];
      });
    } else if (!!keys?.length) {
      keys.forEach((key) => {
        pay[key][index] = getScriptPayload(updatedData)?.[key]?.[index];
      });
    }
    pay.podCast = updatedData?.podCast;
    const response = await updateData({ ...pay, editor_template_id: editorId });
    if (response) {
      if (response?.error) {
        notification.error(response?.error);
      } else {
        setPreviewModal(false);
        notification.success({ message: "Script updated successfully" });
        if (!keys && !index) {
          navigate(`/editedpages`);
        } else {
          navigate(
            `/scripts/${updatedData?.name}/edit?${convertQueryObjToString({
              editorId: editorId,
              version: Number(version) + 1,
            })}`
          );
        }
      }
    }
  };

  const handleChange = (updatedData) => {
    dispatch(setBasicScriptData(updatedData));
  };

  const updatePresentationData = (updatedFields, updatedMedia) => {
    dispatch(
      setScriptPresentationData({
        titleDescriptions: updatedFields,
        media: updatedMedia,
      })
    );
  };

  const handleChangeExplanation = (key, value) => {
    dispatch(setBasicScriptData({ [key]: value }));
  };

  return (
    <div className={isMobile ? styles.rightSectionMob : styles.rightSection}>
      <ScriptBasicInfo
        data={data}
        editMode={editMode}
        isMobile={isMobile}
        handleChange={handleChange}
      />
      <ScriptPresentationSection
        editMode={editMode}
        titleDescriptions={data?.presentationSection?.titleDescriptions}
        media={data?.presentationSection?.media}
        onEditSave={onEditSave}
        updateLoading={updateLoading}
        isMobile={isMobile}
        updatePresentationData={updatePresentationData}
      />
      <ScriptExplanationSection
        explanations={data?.explanations}
        schemas={data?.schemas}
        assessments={data?.assessmentAndPlans}
        onEditSave={onEditSave}
        editMode={editMode}
        updateLoading={updateLoading}
        isMobile={isMobile}
        user={user}
        podCast={data?.podCast}
        data={data}
        onPodCast={handlePodCastData}
        handleChange={handleChangeExplanation}
        showPodCast={!!data?.podCast?.length}
      />
      <ScriptQuestionSection
        scriptEditMode={editMode}
        scriptData={scriptData}
        scriptQuestionsApiData={scriptQuestions}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1%",
          margin: "2%",
        }}
      >
        <Button
          size="large"
          text="Cancel"
          onClick={() => navigate(`/script`)}
        />

        <Button
          size="large"
          type="primary"
          text={"Preview"}
          onClick={() => setPreviewModal(true)}
        />
      </div>
      <PreviewModal
        onConfirm={() =>
          editMode ? onEditSave(null, null, scriptData) : onSubmitScript()
        }
        visible={previewModal}
        setIsVisible={setPreviewModal}
        onCancel={() => {
          setPreviewModal(false);
        }}
        loading={submitLoading || updateLoading}
        editMode={editMode}
        previewMode={true}
        destroyOnClose
      />
    </div>
  );
};

export default ScriptRightPanel;
