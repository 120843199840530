import { Modal } from "components/common";
import { useCallback, useEffect, useState } from "react";

const DeleteConfirmationModal = ({
  setIsVisible,
  isVisible,
  onDelete = () => {},
  title,
  selectedType,
}) => {
  return (
    <Modal
      visible={isVisible}
      title={title}
      onOk={() => {
        onDelete();
        setIsVisible(false);
      }}
      onCancel={() => setIsVisible(false)}
      destroyOnClose
    >
      {selectedType == "node"
        ? "Deleting the Node will also delete all it's edges. Are you sure you want to delete? "
        : selectedType == "edge"
        ? "Are you sure you want to delete this edge?"
        : "Are you sure you want to delete?"}
    </Modal>
  );
};

export default DeleteConfirmationModal;
