import { RETURN_URL_STORAGE_KEY } from "constants";
import {
  AUTH_TOKEN_COOKIE_KEY,
  LOGIN_STORAGE_KEY,
  USER_STORAGE_KEY,
} from "constants";
import { LOGIN_PATH } from "constants/app-paths";
import { getCookie, removeCookie } from "utils/cookie";
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "utils/localStorage";
import ReactHtmlParser from "react-html-parser";
import { PopOver } from "components/common";

export const getAppCredentials = () => {
  return {
    auth: {
      token:
        getCookie(AUTH_TOKEN_COOKIE_KEY) || !!getStorageItem(LOGIN_STORAGE_KEY),
    },
    configs: { direction: "ltr", locale: "en" },
    user: { info: getStorageItem(USER_STORAGE_KEY) },
    privileges: null,
    isMobile: window.innerWidth <= 700,
  };
};

export const getPaginationObject = (obj) => {
  return {
    current: obj?.current_page,
    from: obj?.current_page,
    to: obj?.to,
    nextPage: obj?.next_page
      ? obj?.next_page
      : obj?.current_page === obj?.total_pages
      ? null
      : obj?.current_page + 1,
    prevPage: obj?.prev_page
      ? obj?.prev_page
      : obj?.current_page === 1
      ? null
      : obj?.current_page - 1,
    totalCount: obj?.count,
    totalPages: obj?.count,
    pageCount: obj?.per_page || 10,
  };
};

export const removeAppCredentials = (saveUrl) => {
  saveUrl &&
    !window.location.href.includes(new URL(LOGIN_PATH).pathname) &&
    setStorageItem(RETURN_URL_STORAGE_KEY, window.location.href);
  removeCookie(AUTH_TOKEN_COOKIE_KEY);
  removeStorageItem(LOGIN_STORAGE_KEY);
  removeStorageItem(USER_STORAGE_KEY);
};

export const convertRichToPlainText = (richText) => {
  const parser = new DOMParser();
  const htmlDocument = parser.parseFromString(richText, "text/html");
  return htmlDocument.body.textContent;
};

const getContent = (text, template_id) => {
  const store = require("../store");
  const popoverLinks = store?.default?.getState()?.app?.popoverLinks;
  if (popoverLinks?.length) {
    const found = popoverLinks.find(
      (element) => element.Title == text && element?.template_id == template_id
    );

    const foundNested = popoverLinks.find((element) => element.Title == text);

    return found != "undefined" && found ? (
      <div style={{ maxWidth: "300px", maxHeight: "400px", overflow: "auto" }}>
        <h1>{found.Title ? found.Title : ""}</h1>
        <p className="preWrapText">
          {found.Description ? found.Description : ""}
        </p>
        <div>
          <a
            style={{
              cursor: "pointer",
              color: "#0b8783",
              textDecoration: "underline",
              textDecorationStyle: "dotted",
              textAlign: "center",
            }}
            href={found.Link ? found.Link : ""}
            target="_blank"
          >
            {found.Title && found.Link ? found.Link : ""}
            {found.Link && (
              <i
                style={{ paddingLeft: "5px" }}
                className="zmdi zmdi-arrow-right-top"
              ></i>
            )}
          </a>
        </div>
      </div>
    ) : foundNested ? (
      <div style={{ maxWidth: "300px", maxHeight: "400px", overflow: "auto" }}>
        <h1>{foundNested.Title ? foundNested.Title : ""}</h1>
        <p className="preWrapText">
          {foundNested.Description ? foundNested.Description : ""}
        </p>
        <div>
          <a
            style={{
              cursor: "pointer",
              color: "#0b8783",
              textDecoration: "underline",
              textDecorationStyle: "dotted",
              textAlign: "center",
            }}
            href={foundNested.Link ? foundNested.Link : ""}
            target="_blank"
          >
            {foundNested.Title && foundNested.Link ? foundNested.Link : ""}
            {foundNested.Link && (
              <i
                style={{ paddingLeft: "5px" }}
                className="zmdi zmdi-arrow-right-top"
              ></i>
            )}
          </a>
        </div>
      </div>
    ) : (
      <div> No data found </div>
    );
  } else {
    <div>{text}</div>;
  }
};

const checkForTitleExist = (title) => {
  const store = require("../store");
  const popoverLinks = store?.default?.getState()?.app?.popoverLinks;
  const found = popoverLinks?.find((e) => e?.Title == title);
  if (!found) return true;
  return false;
};

export const renderQuillHtml = (html, template_id, style) => {
  return (
    <div className="ql-editor" style={style}>
      {ReactHtmlParser(html, {
        transform: (node) => {
          if (node.name === "a") {
            let renderAnchor = (
              <PopOver
                id={"popover"}
                content={() => getContent(node.children[0].data, template_id)}
              >
                <a
                  style={{
                    cursor: "pointer",
                    color: "#0b8783",
                    textDecoration: "underline",
                    textDecorationStyle: "dotted",
                  }}
                >
                  {node.children[0].data}
                </a>
              </PopOver>
            );
            if (checkForTitleExist(node.children[0].data)) {
              renderAnchor = (
                <a
                  style={{ cursor: "pointer" }}
                  href={node.attribs.href}
                  target="_blank"
                >
                  {node.children[0].data}
                </a>
              );
            }
            return renderAnchor;
          }
        },
      })}
    </div>
  );
};
