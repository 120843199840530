import { List } from "antd";
import theme from "utils/themeVars";
import QuestionItemEdit from "./questionItemEdit";
import QuestionItemEditTitle from "./questionItemEditTitle";

const EditQuestionModalBody = ({
  explanations,
  scriptQuestions,
  handleUpdateQuestions,
  allScripts,
  handleRemoveQuestion,
  handleSingleQuestionUpdate,
  showSingleUpdate = true,
  allTOpics,
}) => {
  const renderItem = (item, index) => {
    return (
      <List.Item style={{ position: "relative", marginBottom: "3%" }}>
        <div
          style={{
            content: '""',
            position: "absolute",
            left: "0",
            top: "4%",
            bottom: "0",
            width: "50%",
            borderLeft: `2px solid ${theme["blue200"]}`,
          }}
        />
        <List.Item.Meta
          title={
            <QuestionItemEditTitle
              question={item}
              index={index}
              handleChangeQuestion={handleUpdateQuestions}
              explanations={explanations}
              allScripts={allScripts}
              handleRemoveQuestion={handleRemoveQuestion}
              allTOpics={allTOpics}
            />
          }
          description={
            <QuestionItemEdit
              question={item}
              questionIndex={index}
              handleUpdateQuestions={handleUpdateQuestions}
              handleSingleQuestionUpdate={handleSingleQuestionUpdate}
              showSingleUpdate={showSingleUpdate}
            />
          }
        />
      </List.Item>
    );
  };
  return <List dataSource={scriptQuestions} renderItem={renderItem} />;
};

export default EditQuestionModalBody;
