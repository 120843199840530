import { useEffect, useState } from "react"

import DateRangePicker from "../dateRangePicker/dateRangePicker"
import { Input } from "antd"
import SelectInput from "../select/select"
import TextInput from "../textInput/textInput"

export default function UnitSelect(props) {
  const {
    label,
    options,
    value,
    placeholder,
    getOptionLabel = option => option.label,
    getOptionValue = option => option.value,
    defaultOption,
    handleChange,
    useInternalState = true,
    componentType,
    disabled,
    loading,
    preventOnChange = true,
    className,
    getValues,
    splitBy = ",",
  } = props

  const [option, setOption] = useState(options[0] || null)

  useEffect(() => {
    defaultOption && setOption(defaultOption)
  }, [defaultOption])

  const disabledDate = current => {
    const minDate = new Date()
    const maxDate = new Date()
    minDate.setDate(minDate.getDate() - 500)
    return current && current > maxDate
  }

  const getConvertedDateValue = dates => {
    return `${dates[0].startOf("day").format("YYYY-MM-DD HH:mm:ss")},${dates[1]
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss")}`
  }

  const getDateValueFromString = value => {
    if (value) {
      return value?.split(splitBy)
    }
    return undefined
  }

  const getComponent = () => {
    switch (componentType) {
      case "date":
        return (
          <DateRangePicker
            onChange={dates => {
              dates && handleChange(option.value, getConvertedDateValue(dates))
            }}
            disabledDate={disabledDate}
            value={getDateValueFromString(value?.[option?.value])}
            noDefault
            inputReadOnly
          />
        )
      default:
        return (
          <TextInput
            value={value?.[option?.value]}
            placeholder={placeholder ? placeholder : `Search By ${option?.label}`}
            useInternalState={useInternalState}
            disabled={disabled}
            loading={loading}
            {...(preventOnChange && {
              ...(useInternalState
                ? {
                    handleBlur(e) {
                      // option.allowInput && option.allowInput(e.target.value) &&
                      handleChange(option.value, e.target.value)
                    },
                  }
                : {
                    handleChange(e) {
                      // option.allowInput && option.allowInput(e.target.value) &&
                      if (getValues) {
                        getValues({ key: option.value, value: e.target.value })
                      } else {
                        handleChange(option.value, e.target.value)
                      }
                    },
                  }),
            })}
            onPressEnter={e => handleChange(option.value, e.target.value, true)}
          />
        )
    }
  }

  return (
    <div className={className}>
      <label>{label}</label>
      <Input.Group style={{ display: "flex" }} compact>
        {getComponent()}
        <SelectInput
          placeholder=""
          value={option}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          disabled={disabled}
          onChange={(value, item) => {
            setOption(item)
            getValues && getValues(prev => ({ ...prev, key: value }))
          }}
          style={{ minWidth: 120 }}
        />
      </Input.Group>
    </div>
  )
}
