import ImageSlider from "components/common/slider/slider";
import TextEditor from "components/common/textEditor/textEditor";
import { useSelector } from "react-redux";

const ExplanationPanel = ({
  explanationKey,
  value,
  onChange,
  media,
  showActions = true,
  isMobile,
  renderExplanationActions,
  onDeleteImage,
  onEditImage,
  isPodCast = false,
  onEditVideo,
}) => {
  const podCast = useSelector((state) => state?.scripts?.data?.podCast);

  return (
    <>
      <div
        style={{
          marginBottom: "1%",
        }}
      >
        {!isPodCast && (
          <TextEditor
            value={value}
            onChange={onChange}
            placeholder={"Add Description"}
            editorHeight={250}
          />
        )}
        {media?.length ? (
          <div style={{ marginTop: "1%" }}>
            <ImageSlider
              data={
                media?.length
                  ? media?.map((e) => ({
                      ...e,
                      url: e?.url
                        ? e?.url
                        : e?.ImageURL
                        ? e?.ImageURL
                        : e?.VideoIframe,
                    }))
                  : []
              }
              itemToShow={media?.length < 4 ? media?.length : 4}
              itemHeight={150}
              showActions={true}
              onDelete={onDeleteImage}
              onEdit={onEditImage}
              onEditVideo={onEditVideo}
            />
          </div>
        ) : null}
      </div>

      {isPodCast && (
        <>
          {podCast?.length ? (
            <div style={{ marginTop: "-5%" }}>
              <ImageSlider
                data={
                  podCast?.length
                    ? podCast?.map((e) => ({
                        ...e,
                        url: e?.ImageURL,
                      }))
                    : []
                }
                itemToShow={podCast?.length < 4 ? podCast?.length : 4}
                itemHeight={100}
                // showActions={false}
                // onDelete={onDeleteImage}
                // onEdit={onEditImage}
              />
            </div>
          ) : null}
        </>
      )}

      {showActions && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1%",
            // paddingTop: "2%",
          }}
        >
          {!isMobile && renderExplanationActions}
        </div>
      )}
    </>
  );
};

export default ExplanationPanel;
