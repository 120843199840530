import Icon from "../icon/icon"
import Tooltip from "../tooltip/tooltip"
import { getTimeDateString } from "utils/date"
const DateFormat = ({ dateClassName = "gray-800", date: d }) => {
  let date = getTimeDateString(d, false, true)
  let time = getTimeDateString(d, true, false, true)
  return (
    date && (
      <div className={dateClassName} style={{ cursor: "pointer" }}>
        <Tooltip title={date + ", " + time}>
          <Icon icon="BiCalendar" size="14px" />
          &nbsp; {date}
        </Tooltip>
      </div>
    )
  )
}

export default DateFormat
