import Algolia from "utils/Algolia"

const algoliaEndpoints = {
  endpoints: build => ({
    getCompanies: build.query({
      async queryFn(args, ...rest) {
        try {
          let { query = "", cityId } = args
          const obj = Algolia.getAgentsQuery(cityId)
          const companies = await Algolia.getAgents().search(query, obj)
          return { data: companies.hits }
        } catch (error) {
          return { error }
        }
      },
    }),

    getLocalities: build.query({
      async queryFn({ query = "", queryObj = {} }) {
        try {
          const resp = await Algolia.getLocationsIndex().search(query, queryObj)
          let data = resp.hits
          return { data }
        } catch (error) {
          return { error }
        }
      },
    }),

    getNewProjects: build.query({
      async queryFn(args) {
        try {
          let { cityId, query = "" } = args

          if (!cityId) {
            return { error: { status: 400, message: "No City Id Provided" } }
          }
          let queryObj = {
            filters: `cityid=${cityId}`,
            facets: ["dev_status"],
            facetFilters: ["dev_status:on"],
            hitsPerPage: 10,
          }
          let newProjects = await Algolia.getNewProjectsIndex().search(query, queryObj)
          return {
            data: newProjects.hits || [],
          }
        } catch (error) {
          return { error }
        }
      },
    }),

    getDevelopers: build.query({
      async queryFn(args) {
        try {
          let { query = "" } = args
          const developersResponse = await Algolia.getDevelopersIndex().search(query)
          return {
            data: developersResponse.hits || [],
          }
        } catch (error) {
          return { error }
        }
      },
    }),

    getDevelopersDetail: build.query({
      async queryFn(developerIds) {
        try {
          let query = Algolia.getDevelopersObject(developerIds)
          let developersObject = await Algolia.getDevelopersIndex().search("", query)
          return { data: developersObject.hits || [] }
        } catch (error) {
          return { error }
        }
      },
    }),

    getProjectsDetail: build.query({
      async queryFn(projectIds) {
        try {
          let query = Algolia.getNewProjectsIndex(projectIds)
          let projectsRes = await Algolia.getNewProjectsIndex().search("", query)
          return { data: projectsRes.hits || [] }
        } catch (error) {
          return { error }
        }
      },
    }),

    getLocationDetail: build.query({
      async queryFn(locationId) {
        try {
          let queryObj = {
            numericFilters: [`location_id = ${locationId}`],
            hitsPerPage: 1,
          }
          const defaultLocation = await Algolia.getLocationsIndex().search("", queryObj)
          let locObj = defaultLocation.hits[0]
          return {
            data: {
              city: { title: { en: locObj.city_title.en }, location_id: locObj.city_id },
              location:
                locObj.level > 3
                  ? {
                      title: { en: locObj.title.en },
                      location_id: locObj.location_id,
                      level: locObj?.level,
                      hierarchy: locObj?.hierarchy,
                    }
                  : null,
              level: locObj.level,
            },
          }
        } catch (error) {
          return { error }
        }
      },
    }),
  }),

  overrideExisting: false,
}

export default algoliaEndpoints
