import { Input } from "antd";
import { Icon } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import theme from "utils/themeVars";

export const TitleDescriptionInput = ({
  key,
  onRemove,
  title,
  description,
  onChangeDescription,
  onChangeTitle,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", right: 0, top: "-0.6vw" }}>
        <Icon
          className="pointer"
          icon="TiDeleteOutline"
          style={{
            color: theme["blue200"],
            fontSize: "1.6rem",
            backgroundColor: "white",
            zIndex: 999,
          }}
          onClick={() => onRemove(key)}
        />
      </div>
      <div
        style={{
          margin: "1%",
          padding: "1%",
          border: `1px solid ${theme["blue100"]}`,
          borderRadius: "10px",
        }}
      >
        <div style={{ marginBottom: "1%" }}>
          <Input
            placeholder="Title"
            value={title}
            onChange={onChangeTitle}
            key={`${key}-title`}
          ></Input>
        </div>

        <TextEditor
          key={`${key}-description`}
          value={description}
          onChange={onChangeDescription}
          placeholder={"Add Description"}
          editorHeight={250}
        />
      </div>
    </div>
  );
};
