const specialtiesPrefixes = [
  { name: "Addiction Medicine", id: "AM" },
  { name: "Anatomy", id: "AT" },
  { name: "Anesthesiology", id: "AN" },
  { name: "Cardiology", id: "CD" },
  { name: "Critical Care", id: "CC" },
  { name: "Dentistry", id: "DN" },
  { name: "Dermatology", id: "DM" },
  { name: "Emergency", id: "EM" },
  { name: "Endocrinology", id: "EN" },
  { name: "Epidemiology", id: "EP" },
  { name: "Ethics", id: "ET" },
  { name: "Adult Ambulatory", id: "AA" },
  { name: "Gastroenterology", id: "GI" },
  { name: "Gynecology", id: "GY" },
  { name: "Hematology", id: "HM" },
  { name: "Immunology", id: "IG" },
  { name: "Infectious Diseases", id: "ID" },
  { name: "Hospital Medicine", id: "HM" },
  { name: "Laboratory", id: "LB" },
  { name: "Nephrology", id: "KD" },
  { name: "Neurology", id: "NR" },
  { name: "Nursing", id: "NU" },
  { name: "Obstetrics", id: "OB" },
  { name: "Oncology", id: "ON" },
  { name: "Osteopathic Medicine", id: "OM" },
  { name: "Palliative Care", id: "PC" },
  { name: "Pathology", id: "HP" },
  { name: "Pediatrics", id: "PD" },
  { name: "Pharmacology", id: "RX" },
  { name: "Physical Medicine & Rehab", id: "PR" },
  { name: "Podiatry", id: "PO" },
  { name: "Preventive Medicine", id: "PM" },
  { name: "Psychiatry", id: "PY" },
  { name: "Pulmonology", id: "PU" },
  { name: "Radiation Oncology", id: "RO" },
  { name: "Radiology - Diagnostic", id: "DR" },
  { name: "Radiology - Interventional", id: "IR" },
  { name: "Rheumatology", id: "RM" },
  { name: "Sleep Medicine", id: "ZZ" },
  { name: "Sports Medicine", id: "SM" },
  { name: "Surgery - ENT", id: "ES" },
  { name: "Surgery - General", id: "GS" },
  { name: "Surgery - Neuro", id: "NS" },
  { name: "Surgery - Ophthalmology", id: "OP" },
  { name: "Surgery - Orthopedic", id: "OS" },
  { name: "Surgery - Plastic", id: "PS" },
  { name: "Surgery - Trauma", id: "TS" },
  { name: "Surgery - Urology", id: "UR" },
  { name: "Surgery - Vascular", id: "VS" },
];

export const getSpecialityWithIDInitials = (specialty) => {
  const found = specialtiesPrefixes?.find((e) => e?.name == specialty);
  return found?.id;
};
export function createPageId(identifiers, specialty) {
  const prefix = getSpecialityWithIDInitials(specialty);
  const matchingValues = identifiers.filter((value) =>
    value.startsWith(prefix)
  );

  if (matchingValues.length === 0) {
    return `${prefix}0001`; // No matching values found
  }

  // Extract the numeric part and find the maximum
  const numericValues = matchingValues
    .map((value) => parseInt(value.substring(prefix.length), 10))
    .filter((num) => !isNaN(num));

  if (numericValues.length === 0) {
    return `${prefix}0001`; // No numeric values found
  }

  const maxNumericValue = Math.max(...numericValues) + 1; // Increment by 1

  // Create the pageId by combining the prefix and the maximum numeric value
  const pageId = `${prefix}${maxNumericValue.toString().padStart(4, "0")}`;

  return pageId;
}
