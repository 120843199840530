import { convertQueryObjToString } from "utils";
// import {
//   allLibrariesTransformer,
// } from "./transformers";

const { default: parentApi } = require("store/parentApi");

const accountApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getAccountDetails: build.query({
      query: (data) => {
        return {
          url: `user/get/profile/details`,
          method: "POST",
          body: data?.body,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      providesTags: ["userDetails"],
    }),

    updateAccountDetails: build.mutation({
      query: (data) => {
        return {
          url: `user/update/profile`,
          method: "POST",
          body: data?.body,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["userDetails"],
    }),
    getAllCountries: build.query({
      query: () => {
        return {
          url: `https://countriesnow.space/api/v0.1/countries`,
        };
      },
      transformResponse: (response) => {
        return response?.data?.length ? response?.data : [];
      },
    }),
    getActivityList: build.query({
      query: () => {
        return {
          url: `user/activity/list`,
          method: "POST",
        };
      },
      transformResponse: (response) => {
        return response?.data?.length ? response?.data : [];
      },
    }),
    updateActivityStatus: build.mutation({
      query: (body) => {
        return {
          url: `user/activity/change/status`,
          method: "POST",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["userDetails"],
    }),
  }),
});

export const {
  useGetAccountDetailsQuery,
  useUpdateAccountDetailsMutation,
  useGetAllCountriesQuery,
  useGetActivityListQuery,
  useUpdateActivityStatusMutation,
} = accountApis;
