import { Form, Input, Row, message } from "antd";
import { Button, Card, Title, notification } from "components/common";
import { useUpdateAccountDetailsMutation } from "./api";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [updateAccountDetails, { isLoading }] =
    useUpdateAccountDetailsMutation();

  const onSubmit = async (values) => {
    if (values?.new_password != values?.confirmNewPassword) {
      notification.error({ message: "Passwords does not match" });
    } else {
      const res = await updateAccountDetails({
        body: {
          new_password: values?.new_password,
          old_password: values?.old_password,
        },
      });
      if (res) {
        if (res?.error) {
          notification.error({ message: res?.error?.message });
        } else {
          notification.success({ message: "Password Updated Successfully!" });
        }
      }
    }
  };

  return (
    <Card>
      <Title style={{ padding: "20px" }}>Change Password</Title>
      <Form
        name="profile"
        initialValues={{
          old_password: null,
          new_password: null,
          confirmNewPassword: null,
        }}
        form={form}
        scrollToFirstError
        onFinish={onSubmit}
        preserve={true}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 10 }}
        shouldUpdate
        disabled={isLoading}
      >
        <Form.Item
          name="old_password"
          initialValue=""
          label="Old Password"
          rules={[{ message: "Please enter password", required: true }]}
        >
          <Input.Password placeholder="Your Old password" />
        </Form.Item>
        <Form.Item
          name="new_password"
          initialValue=""
          label="New Password"
          rules={[{ message: "Please enter password", required: true }]}
        >
          <Input.Password placeholder="Your new password" />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          initialValue=""
          label="Confirm Password"
          rules={[{ message: "Please enter password", required: true }]}
        >
          <Input.Password placeholder="Your new password Confirmation" />
        </Form.Item>
        <Form.Item shouldUpdate className="submit" wrapperCol={{ span: 22 }}>
          <Row style={{ gap: 8, justifyContent: "center" }}>
            <Button
              type="primary"
              text={"Change Password"}
              loading={isLoading}
              onClick={form.submit}
            />
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ChangePassword;
