import { Button, Modal } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import { useEffect, useState } from "react";

const NodeLabelModel = ({ onChange, label, id, setIsVisible, isVisible }) => {
  const [text, setText] = useState(null);

  useEffect(() => {
    setText(label);
  }, [label]);

  const renderFooter = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="default" onClick={() => setIsVisible(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onChange(id, text);
            setIsVisible(false);
          }}
        >
          Update Node Text
        </Button>
      </div>
    );
  };
  return (
    <Modal
    style={{textAlign:"left"}}
      visible={isVisible}
      title={"Add Data to Node"}
      onOk={() => {
        onChange(id, text);
        setIsVisible(false);
      }}
      onCancel={() => setIsVisible(false)}
      okText="Add"
      cancelText="Cancel"
      destroyOnClose
      footer={renderFooter()}
    >
      <TextEditor
      style={{textAlign:"left"}}
        key={id}
        value={text}
        onChange={(e) => setText(e)}
        placeholder={"Add data to node"}
      />
    </Modal>
  );
};

export default NodeLabelModel;
