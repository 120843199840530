import { Card, DataTable, Group, Tag, Title, Tooltip } from "components/common";
import { ActionButtons } from "components/custom";
import { canUserModify } from "features/presentation/utils";
import {
  useGetAllQuizesQuery,
  useGetPageTopicsQuery,
} from "features/script/api";
import { useEffect } from "react";
import { IoMdEye } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import theme from "utils/themeVars";

const quizListingColumns = [
  {
    title: "No.",
    dataIndex: "key",
    className: "tableStartSpace",
    width: 70,
  },
  {
    title: "Script",
    dataIndex: "scriptName",
    width: 300,
  },
  {
    title: "Explanation",
    dataIndex: "explanations",
    width: 300,
  },
  {
    title: "No. of Questions",
    dataIndex: "noOfQuestions",
  },
  {
    title: "Status",
    dataIndex: "quizStatus",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 80,
  },
];

const QuizListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);
  const user = useSelector((state) => state.app.user?.info);
  const navigate = useNavigate();
  const { data: allScripts, isLoading: scriptsLoading } =
    useGetPageTopicsQuery("");

  const { data, isLoading, error, refetch, isFetching } = useGetAllQuizesQuery({
    userId: user?.user_code,
  });

  const getActionsList = (e) => {
    return [
      {
        key: "view_result",
        icon: <IoMdEye />,
        color: canUserModify(user, e?.editor_id)
          ? theme["blue200"]
          : theme["gray500"],
        content: "View Result",
        title: "View Result",
        className: "pointer",
        onClick: () => {
          navigate(`/user/quiz/result?quizId=${e?._id}`);
        },
      },
    ];
  };

  const tableData = () => {
    return data?.length
      ? data.map((e, index) => ({
          key: index + 1,
          explanations: (
            <>
              {e?.explanations?.length > 3 ? (
                <>
                  {e.explanations.map((item, index) => {
                    return index < 3 ? (
                      <Tag key={index} style={{ marginBottom: "0.5vh" }}>
                        {item}
                      </Tag>
                    ) : null;
                  })}
                  <Tooltip
                    placement="top"
                    title={e.explanations.map((item, index) => {
                      return index > 2 ? <div>{item}</div> : null;
                    })}
                  >
                    <Tag key={"more"} style={{ marginBottom: "0.5vh" }}>
                      {`+${e.explanations?.length - 3}`}
                    </Tag>
                  </Tooltip>
                </>
              ) : (
                <>
                  {e.explanations.map((item, index) => (
                    <Tag key={index} style={{ marginBottom: "0.5vh" }}>
                      {item}
                    </Tag>
                  ))}
                </>
              )}
            </>
          ),
          noOfQuestions: e?.questionsList?.length,
          quizStatus: (
            <Tag color="#60A7DB" style={{ borderRadius: "40px" }}>
              {capitalizeFirstLetter(e?.quizStatus)}
            </Tag>
          ),
          scriptName: allScripts?.length
            ? allScripts?.find((it) => it?.editor_template_id == e?.scriptId)
                ?.page_name || "-"
            : "-",
          actions: <ActionButtons list={getActionsList(e)} />,
        }))
      : [];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  // Get the element by its class name
  const element = document.querySelector(".ant-table-header");

  // Check if the element exists before modifying its style
  if (element) {
    // Change its CSS properties
    element.style.overflow = "unset"; // Example of changing the overflow property
  }

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>My Quiz</Title>
      </div>
    );
  };

  const renderQuizListingsWeb = () => {
    return (
      <Group gap="16px" className={"listings-content-adjustments"}>
        <Card>{renderPageHeader()}</Card>
        <DataTable
          pagination={{
            totalCount: data?.pagination?.totalCount,
            pageOptions: [10, 25, 50],
          }}
          paginationOnBottom={true}
          onChangePage={(queryObj) => setSearchParams(queryObj)}
          columns={quizListingColumns}
          data={tableData()}
          loading={isLoading || scriptsLoading}
          error={error}
          onErrorRetry={refetch}
          skeletonLoading={isFetching}
          stickyOffset={0}
          noUrlPush={false}
        />
      </Group>
    );
  };

  return <>{renderQuizListingsWeb()}</>;

  return "Quiz Listing";
};
export default QuizListing;
