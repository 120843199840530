import { Button } from "components/common"
import { Component } from "react"
import Icon from "../../common/icon/icon"
import { Result } from "antd"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    const { hasError, error } = this.state

    if (hasError) {
      return (
        <Result
          icon={<Icon icon="BiSad" size={120} />}
          title="Something went wrong!"
          subTitle={error.message}
          extra={
            <Button
              type="primary"
              onClick={() => {
                window.location.reload()
              }}
            >
              Reload
            </Button>
          }
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
