import { Text } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import { renderQuillHtml } from "configs/utils";

const Notes = ({ editable, description, onChange, isRichText }) => {
  const getRichTextLength = (richText) => {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = richText;
    var plainText = tempDiv.textContent || tempDiv.innerText;
    return plainText.length;
  };

  return (
    <>
      {editable ? (
        <TextEditor
          key={"notes"}
          value={description}
          onChange={onChange}
          placeholder="Enter Notes"
          editorHeight={200}
        />
      ) : (
        <>
          {renderQuillHtml(
            isRichText
              ? getRichTextLength(description)
                ? description
                : "Add Notes..."
              : description?.length
              ? description
              : "Add Notes...",
            undefined,
            { padding: "0% 3% 0% 3%" }
          )}
        </>
      )}

      {editable && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "1%",
            opacity: "0.5",
          }}
        >
          <Text>{`Character Limit: ${
            isRichText
              ? getRichTextLength(description)
              : description?.length || 0
          }/1000`}</Text>
        </div>
      )}
    </>
  );
};

export default Notes;
