import { Button, Card, Modal, Title, notification } from "components/common";
import { PRESENTATION_EDIT_PATH } from "constants/app-paths";
import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { BsQrCode } from "react-icons/bs";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import theme from "utils/themeVars";
import {
  useCompletePresentationMutation,
  useResetPresentationMutation,
  useUpdateAllPollStatusMutation,
} from "../apis";
import styles from "./index.module.less";
import PresentationSlides from "./presentationSlides";
import SlideDetail from "./slideDetail";
import { canUserModify } from "../utils";
import { useSelector } from "react-redux";
import { Space } from "antd";
import { FaExpand } from "react-icons/fa";
import screenfull from "screenfull";

const PresentationDetailPageComponent = ({
  id,
  slides,
  setSlides,
  presentationName,
  isCompleted,
  userId,
  basicInfo,
  refetch,
}) => {
  const sliderRef = useRef(null);
  const modalRef = useRef(null);

  const [mainSlide, setMainSlide] = useState(0);
  const [subSlide, setSubSlide] = useState(0);

  const navigate = useNavigate();
  const [resetPresentation, { isLoading: resetLoading }] =
    useResetPresentationMutation();

  const [completePresentation, { isLoading: completedLoading }] =
    useCompletePresentationMutation();
  const [updateAllPollStatus, { isLoading: updatingAllPollStatus }] =
    useUpdateAllPollStatusMutation();

  const user = useSelector((state) => state.app.user?.info);
  const canUserModifyPresentation = user?._id
    ? canUserModify(user, userId)
    : false;

  const refs = slides.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleFullScreen = () => {
    const rightSectionDiv = document.getElementById("rightSection");
    if (screenfull.isEnabled && rightSectionDiv) {
      screenfull.toggle(rightSectionDiv);
    }
  };

  function handleClick(id) {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  }

  function scrollIfNotInView(id) {
    var element = document.getElementById(`main-slide-${id}`);
    if (!element) return;
    var rect = element.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    var outerDiv = document.getElementById("scrolling-card-preview");
    var outerRect = outerDiv.getBoundingClientRect();
    var outerTop = outerRect.top;
    var outerBottom = outerRect.bottom;

    if (elemTop < outerTop || elemBottom > outerBottom) {
      var offset =
        elemTop < outerTop ? elemTop - outerTop : elemBottom - outerBottom;
      outerDiv.scrollTop += offset;
    }
  }

  useEffect(() => {
    scrollIfNotInView(mainSlide);
  }, [mainSlide]);

  useEffect(() => {
    const handleScroll = () => {
      const card = document.getElementById("scrolling-card");
      if (!card) return;

      const cardScrollTop = card.scrollTop;

      const divElements = document.querySelectorAll(".scrolling-div");
      let activeDivId = null;

      for (const element of divElements) {
        const { top, height } = element.getBoundingClientRect();
        if (top >= 0 && top < height) {
          activeDivId = element.id;
          break;
        }
      }
      if (activeDivId) {
        setMainSlide(activeDivId);
      }
    };

    const card = document.getElementById("scrolling-card");

    if (card) {
      card.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (card) {
        card.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const onChange = (newSlide) => {
    const tempSlides = JSON.parse(JSON.stringify(slides));
    tempSlides[mainSlide].data[subSlide] = {
      ...tempSlides[mainSlide].data[subSlide],
      data: newSlide,
    };
    setSlides(tempSlides);
  };

  const getAllPolls = () => {
    let allPolls = [];
    if (slides?.length) {
      slides.forEach((e) => {
        if (e?.data?.length) {
          const polls = e?.data?.filter((sl) => sl?.type == "poll");
          if (polls?.length) {
            allPolls = [...allPolls, ...polls];
          }
        }
      });
    }
    return allPolls;
  };

  const getAllPollStarted = () => {
    const allPolls = getAllPolls();
    let allStarted = true;
    allPolls?.forEach((e) => {
      if (e?.status != "started") {
        allStarted = false;
      }
    });
    return allStarted;
  };

  const handlePollAction = async () => {
    const isAllPollStarted = getAllPollStarted();
    const res = await updateAllPollStatus({
      presentationId: id,
      status: isAllPollStarted ? "completed" : "started",
    });
    if (res) {
      if (res?.error) {
        notification.error({ message: res?.error });
      } else {
        notification.success({ message: "Updated Successfully" });
      }
    }
  };

  const renderPresentationDetailAndActions = () => {
    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            style={{
              marginBottom: 0,
              color: "black",
              textTransform: "capitalize",
            }}
            level={2}
          >
            {presentationName ?? ""}
          </Title>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 5,
              alignContent: "center",
            }}
          >
            {canUserModifyPresentation && (
              <Button
                style={{
                  backgroundColor: "white",
                  border: `1px solid ${theme["gray500"]}`,
                }}
                onClick={() => {
                  modalRef.current.setVisible(true);
                }}
              >
                <BsQrCode />
              </Button>
            )}
            {canUserModifyPresentation && !isCompleted && (
              <Button
                icon="AiOutlineUnorderedList"
                type="bordered"
                loading={updatingAllPollStatus}
                onClick={handlePollAction}
                style={{
                  borderColor: theme["blue100"],
                  color: theme["blue200"],
                  background: theme["light-gray"],
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
              >
                {getAllPollStarted() ? "End All Polls" : "Start All Polls"}
              </Button>
            )}

            <Button
              type="bordered"
              icon={<FaExpand />}
              onClick={handleFullScreen}
              style={{
                borderColor: theme["blue100"],
                color: theme["blue200"],
                background: theme["light-gray"],
                fontWeight: 700,
                fontSize: "1rem",
              }}
            >
              Go Full Screen
            </Button>

            {canUserModifyPresentation && (
              <Button
                type="bordered"
                icon="MdRefresh"
                loading={resetLoading}
                onClick={() => {
                  resetPresentation(id)
                    .then(() => {
                      notification.success({
                        message: "Presentation has been reset!",
                      });
                    })
                    .catch((e) => {
                      notification.error({
                        message: e?.message,
                      });
                    });
                }}
                style={{
                  borderColor: theme["blue100"],
                  color: theme["blue200"],
                  background: theme["light-gray"],
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
              >
                Reset
              </Button>
            )}
            {canUserModifyPresentation && (
              <Button
                type="bordered"
                icon="FaRegEdit"
                onClick={() => {
                  navigate(PRESENTATION_EDIT_PATH.replace(":id", id));
                }}
                style={{
                  borderColor: theme["blue100"],
                  color: theme["blue200"],
                  background: theme["light-gray"],
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
              >
                Edit
              </Button>
            )}
            {canUserModifyPresentation && (
              <Button
                type="bordered"
                icon="BsFillBookmarkCheckFill"
                onClick={() => {
                  completePresentation(id)
                    .unwrap()
                    .then(() => {
                      notification.success({
                        message: "Presentation has been marked completed!",
                      });
                    })
                    .catch((e) => {
                      notification.error({
                        message: e?.message,
                      });
                    });
                }}
                loading={completedLoading}
                disabled={isCompleted}
                style={{
                  borderColor:
                    isCompleted == true ? theme["gray500"] : theme["blue100"],
                  color:
                    isCompleted == true ? theme["gray600"] : theme["blue200"],
                  background: theme["light-gray"],
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
              >
                {isCompleted == true ? "Completed" : "Mark Completed"}
              </Button>
            )}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div className={styles.rightSectionParent}>
      {renderPresentationDetailAndActions()}
      <div
        className={
          !(canUserModifyPresentation || isCompleted) &&
          slides?.filter(
            (sli) =>
              (sli?.data?.[0].type == "poll" &&
                sli?.data?.[0].status == "started") ||
              sli.data?.[0].status == "completed"
          ).length != 0
            ? styles.pageContainerPublic
            : styles.pageContainer
        }
      >
        {/* This is left section */}
        {(canUserModifyPresentation || isCompleted) && (
          <div
            id="scrolling-card-preview"
            className={styles.leftSection}
            style={{ paddingBlock: 0 }}
          >
            {/* This is Left Section Data  */}
            <Card
              style={{
                height: "98%",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                border: "none",
              }}
              headStyle={{ borderBottom: "none" }}
              bodyStyle={{ overflowY: "scroll" }}
            >
              <PresentationSlides
                slides={slides}
                setSlides={setSlides}
                mainSlide={mainSlide}
                setMainSlide={setMainSlide}
                setSubSlide={setSubSlide}
                isEditable={false}
                handleClick={(item) => handleClick(item)}
                isCompleted={isCompleted}
                canUserModifyPresentation={canUserModifyPresentation}
              />
            </Card>
          </div>
        )}
        <div
          className={
            canUserModifyPresentation || isCompleted
              ? styles.rightSection
              : styles.rightSectionPublic
          }
          id="rightSection"
        >
          {/* <div style={{display:"flex", flexDirection:"column"}}> */}

          <div
            style={{
              height: "100%",
              overflowY: "scroll",
              marginBottom: "20px",
              // maxHeight: "79vh",
            }}
            id={"scrolling-card"}
          >
            {!canUserModifyPresentation &&
            !isCompleted &&
            slides?.filter(
              (sli) =>
                sli?.data?.[0].type == "poll" &&
                (sli?.data?.[0].status == "started" ||
                  sli.data?.[0].status == "completed")
            ).length == 0 ? (
              <Card bodyStyle={{ paddingBlock: 15 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "82vh",
                    minWidth: "100vw",
                  }}
                >
                  <Title
                    style={{
                      marginBottom: 0,
                      color: theme["gray700"],
                      textTransform: "capitalize",
                    }}
                    level={1}
                  >
                    No polls started yet!
                  </Title>
                </div>
              </Card>
            ) : (
              <>
                {slides.map((slide, index) => {
                  return (
                    <SlideDetail
                      ref={refs[slide.id]}
                      subSlide={subSlide ?? 0}
                      slideData={slide?.data}
                      onChange={onChange}
                      slideTitle={slide?.title}
                      setSubSlide={setSubSlide}
                      setSlides={setSlides}
                      index={index}
                      id={id}
                      backgroundColor={slide?.backgroundColor}
                      isCompleted={isCompleted}
                      canUserModifyPresentation={canUserModifyPresentation}
                      refetch={refetch}
                    />
                  );
                })}
              </>
            )}
          </div>
          {/* </div> */}
          <Modal
            ref={modalRef}
            onCancel={() => modalRef.current.setVisible(false)}
            cancelText="Close"
            title={`Scan Presentation QR`}
            subTitle={"Scan QR and get access to this presentation closely!"}
            bodyStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <QRCode
              style={{
                width: "50%",
                height: "50%",
                alignSelf: "center",
              }}
              value={`${window.location.origin}/presentation/details?presentationId=${id}`}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default PresentationDetailPageComponent;
