import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getAPIBaseURL } from "utils/env";

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: getAPIBaseURL(),
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({ url: "/user/login", method: "POST", body }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({ url: "/user/forgot-password", method: "POST", body }),
    }),
    signUp: builder.mutation({
      query: (body) => ({ url: "/user/register", method: "POST", body }),
    }),
    signUpPresentation: builder.mutation({
      query: (body) => ({
        url: "/user/register-presentation",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: "/authentication/logout",
        method: "POST",
        body,
      }),
    }),
    resendEmail: builder.mutation({
      query: (body) => ({
        url: "/user/resend/verify/code",
        method: "POST",
        body,
      }),
    }),
    resendEmailPresentation: builder.mutation({
      query: (body) => ({
        url: "/user/resend/verify/code/presentation",
        method: "POST",
        body,
      }),
    }),
    getSyncElasticSearch: builder.query({
      query: () => ({ url: "/predata/sync-elastic-search", method: "Get" }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useSignUpMutation,
  useResendEmailMutation,
  useSignUpPresentationMutation,
  useResendEmailPresentationMutation,
  useLazyGetSyncElasticSearchQuery,
} = authApi;
