import { useState } from "react";
import { useSelector } from "react-redux";
import AddFileModal from "../addScriptForm/addFileModal";
import AddImageVideoModal from "../addScriptForm/addImageVideoModal";
import ExplanationCardItem from "./explanationCardItem";
import { generateUUID } from "pubnub";

const ScriptExplanationCollapseCards = ({
  explanations,
  handleChange,
  onEditSave,
  activeKeys,
  setActiveKeys,
  editMode = false,
  isPodCast = false,
  podCast = [],
  data,
  onPodCastData,
}) => {
  const [modalsVisibility, setModalsVisibility] = useState(null);
  const [posCastActiveKeys, setPodCastActiveKeys] = useState(["0"]);

  const list = useSelector((state) => state?.scripts?.data?.podCast);

  const handleExpandCollapse = (key) => {
    const keyIndex = activeKeys.findIndex((e) => e == key);
    if (keyIndex == -1) {
      setActiveKeys((prev) => [...prev, key]);
    } else {
      setActiveKeys((prev) => prev.filter((e) => e != key));
    }
  };

  const handleExpandCollapsePodCast = () => {
    const keyIndex = posCastActiveKeys.findIndex((e) => e == "0");
    if (keyIndex == -1) {
      setPodCastActiveKeys((prev) => [...prev, "0"]);
    } else {
      setPodCastActiveKeys((prev) => prev.filter((e) => e != "0"));
    }
  };

  const handleAddExplanationMedia = (media) => {
    media.index = list[0]?.type ? list?.length : 0;
    media.id = generateUUID();
    if (media.type === "licensePodcast") {
      onPodCastData(media);
      setModalsVisibility(null);
      return;
    } else {
      const tempExplanations = JSON.parse(JSON.stringify(explanations));

      if (media?.edit) {
        let tempMedia = tempExplanations?.[media?.explanationIndex]?.media;
        tempMedia = tempMedia?.map((e, ind) => {
          if (ind == media?.editIndex) {
            return { ...e, ...media };
          } else {
            return e;
          }
        });
        tempExplanations[media.explanationIndex].media = tempMedia;
      } else {
        tempExplanations[modalsVisibility?.index]?.media?.push(media);
      }
      handleChange("explanations", tempExplanations);
    }
    setModalsVisibility(null);
  };

  return (
    <>
      <AddFileModal
        isVisible={modalsVisibility?.type == "image"}
        setIsVisible={setModalsVisibility}
        onConfirm={handleAddExplanationMedia}
        data={modalsVisibility}
        destroyOnClose={true}
      />
      <AddImageVideoModal
        isVisible={
          modalsVisibility?.type == "licenseImage" ||
          modalsVisibility?.type == "licenseVideo" ||
          modalsVisibility?.type == "licensePodcast"
        }
        setIsVisible={setModalsVisibility}
        onConfirm={handleAddExplanationMedia}
        data={modalsVisibility}
        type={modalsVisibility?.type}
        destroyOnClose={true}
      />
      {explanations?.length ? (
        explanations?.map((e, i) => (
          <ExplanationCardItem
            itemIndex={i}
            activeKeys={activeKeys}
            handleExpandCollapse={() => handleExpandCollapse(i?.toString())}
            handleChange={handleChange}
            setModalsVisibility={setModalsVisibility}
            editMode={editMode}
            onEditSave={onEditSave}
            explanations={explanations}
            data={data}
          />
        ))
      ) : (
        <></>
      )}

      {isPodCast && (
        <>
          <ExplanationCardItem
            isPodCast={true}
            itemIndex={0}
            activeKeys={posCastActiveKeys}
            handleExpandCollapse={() => {
              handleExpandCollapsePodCast();
            }}
            handleChange={handleChange}
            setModalsVisibility={setModalsVisibility}
            editMode={editMode}
            onEditSave={onEditSave}
            explanations={explanations}
            data={data}
          />
        </>
      )}
    </>
  );
};

export default ScriptExplanationCollapseCards;
