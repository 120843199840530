export const getErrorString = (errorObj) => {
  const errors = errorObj?.errors || errorObj?.error || errorObj?.message;
  if (errors) {
    if (Array.isArray(errors)) {
      const messageArray = errors.map((error) => error.message);
      return messageArray.join(" ");
    }
    if (typeof errors === "string") return errors;
    if (typeof errors === "object")
      return Object.keys(errors)
        .map((key) => errors[key])
        .join(", ");
  }
};

export const convertQueryObjToString = (obj) => {
  if (!obj) return "";
  const keys = Object.keys(obj);
  return keys
    .map((key) =>
      obj[key] === null || obj[key] === undefined ? "" : `${key}=${obj[key]}`
    )
    .filter((e) => !!e)
    .join("&");
};

export const removeEmptyKeysFromObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v));
};

export const capitalizeFirstLetter = (str = "") => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const lowerCaseFirstLetter = (str = "") => {
  return str?.charAt(0).toLowerCase() + str?.slice(1);
};

export const convertToReadableString = (str = "", allCaps) => {
  const splitted = str?.split("_");
  return splitted
    .map((e, i) => (allCaps || i == 0 ? capitalizeFirstLetter(e) : e))
    .join(" ");
};

export const mapQueryStringToFilterObject = (search, cb = () => {}) => {
  const queryObj = {};
  const queryString = search?.split("?")[1];
  if (queryString) {
    const items = queryString.split("&");
    for (const item of items) {
      const key = item?.split("=")[0];
      const value = item?.split("=")[1];
      cb(key, value);
      if (key && value) {
        if (queryObj[key]) {
          queryObj[key] += `,${value}`;
        } else {
          queryObj[key] = value;
        }
      }
    }
  }
  return { queryObj };
};

export const addQueryFilterArray = (
  filters,
  searchKey,
  value,
  splitString,
  cb
) => {
  const prevVal = filters[searchKey];
  const prevValArr = prevVal ? prevVal.toString().split(splitString) : [];
  if (!value) {
    const filtersObj = { ...filters };
    filtersObj[searchKey] = undefined;
    cb(filtersObj);
  } else if (!prevValArr.includes(value.toString())) {
    const filtersObj = { ...filters };
    filtersObj[searchKey] = [...prevValArr, value].join(splitString);
    cb(filtersObj);
  }
};

export const addQueryFilterArrayPreSelected = (
  filters,
  searchKey,
  value,
  splitString,
  cb
) => {
  const obj = { ...filters };
  if (obj[searchKey]) {
    const array = obj[searchKey].split(splitString);
    const index = array.indexOf(value.toString());
    if (array.length > 1) {
      array.splice(index, 1);
      obj[searchKey] = array.join(splitString);
    } else {
      delete obj[searchKey];
    }
  }
  obj.page && delete obj.page;
  cb(obj);
};

export const getUniqueArrayByObjKey = (array, key) => {
  return [
    ...new Map(
      array.map((item) => [
        Array.isArray(key) ? key.map((e) => item[e]).join("-") : item[key],
        item,
      ])
    ).values(),
  ];
};

export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const openMailTo = (email, subject = "", body = "", children) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
  const mailTo = Array.isArray(email) ? email.join(";") : email;
  return window.open(`mailto:${mailTo}${params}`, "_blank");
};

export const getOBJValueByKey = (object, path, defval = null) => {
  if (!path) return defval;
  if (typeof path === "string") path = path?.split(".");
  const res = path.reduce(
    (obj, key) =>
      obj && key.includes("[")
        ? obj[key.substring(1, key.length - 1)]
        : obj && obj[key]
        ? obj[key]
        : defval,
    object
  );
  return res;
};

export const withTryCatch = async (promise) => {
  try {
    const response = await promise();
    return { response };
  } catch (error) {
    return { error: { message: getErrorString(error) } };
  }
};

export const getAgeFromDate = (date) => {
  const dateOfBirth = new Date(date);
  const birthYear = dateOfBirth.getFullYear();
  const currentYear = new Date().getFullYear();
  return currentYear - birthYear;
};

const getCurrency = (val) => {
  switch (val) {
    default:
      return "Rs ";
  }
};

export const formatCompactedNumber = (num, digit) => {
  let cNumber;
  switch (true) {
    case num >= 0 && num < 1000:
      cNumber = num.toFixed(num % 1 ? digit : 0);
      break;
    case num >= 1000 && num < 100000:
      cNumber =
        (num / 1000).toFixed((num / 1000) % 1 ? digit : 0) + " Thousand";
      break;
    case num >= 100000 && num < 10000000:
      cNumber =
        (num / 100000).toFixed((num / 100000) % 1 ? digit : 0) + " Lakh";
      break;
    case num >= 10000000 && num < 1000000000:
      cNumber =
        (num / 10000000).toFixed((num / 10000000) % 1 ? digit : 0) + " Crore";
      break;
    case num >= 1000000000 && num < 100000000000:
      cNumber =
        (num / 1000000000).toFixed((num / 1000000000) % 1 ? digit : 0) +
        " Arab";
      break;
    case num >= 100000000000:
      cNumber =
        (num / 100000000000).toFixed((num / 100000000000) % 1 ? digit : 0) +
        " Kharab";
      break;
  }
  return cNumber;
};

export const numberFormat = (value, format, options) => {
  return new Intl.NumberFormat(format, options).format(value);
};

export const internationalFormatCompactedNumber = (value, digit) => {
  let cNumber = true;
  switch (true) {
    case value >= 0 && value < 1000:
      cNumber = value.toFixed(value % 1 ? digit : 0);
      break;
    case value >= 1000 && value < 1000000:
      cNumber = (value / 1000).toFixed((value / 1000) % 1 ? digit : 0) + " K";
      break;
    case value >= 1000000 && value < 1000000000:
      cNumber =
        (value / 1000000).toFixed((value / 1000000) % 1 ? digit : 0) + " M";
      break;
    case value >= 1000000000 && value < 1000000000000:
      cNumber =
        (value / 1000000000).toFixed((value / 1000000000) % 1 ? digit : 0) +
        " B";
      break;
    case value >= 1000000000000:
      cNumber =
        (value / 1000000000000).toFixed(
          (value / 1000000000000) % 1 ? digit : 0
        ) + " T";
      break;
    default:
      cNumber = value;
  }
  return cNumber;
};

export const formatPriceValue = (
  priceValue,
  currencyOptions,
  fractionDigits,
  settings = {
    locale: "en-PK",
    country_code: "PK",
    currency: "PKR",
    timezone: "Asia/Karachi",
  }
) => {
  if (!isNaN(Number(priceValue))) {
    if (currencyOptions?.notation == "compact")
      return (
        getCurrency(settings.currency) +
        formatCompactedNumber(Number(priceValue), currencyOptions?.dashForNone)
      );
    const fracDigits = !!fractionDigits
      ? Number(priceValue) % 1
        ? fractionDigits
        : 0
      : Number(priceValue) % 1
      ? 2
      : 0;
    let formattedPrice = new Intl.NumberFormat(settings.locale, {
      currency: settings.currency,
      style: "currency",
      currencyDisplay: "symbol",
      ...(fracDigits <= 2 && { minimumFractionDigits: 0 }),
      maximumFractionDigits: fracDigits,
      ...currencyOptions,
    }).format(priceValue);

    return formattedPrice;
  }
  return "";
};

export const joinBytItems = (array, separator) => {
  const filtered = array.filter((e) => !!e);
  return filtered.map((e, i) => {
    if (typeof separator === "string") {
      return i + 1 < filtered.length ? e + separator : e;
    } else {
      return i + 1 < filtered.length ? (
        <>
          {e}
          {separator}
        </>
      ) : (
        e
      );
    }
  });
};

export const getCountryList = (countries, international = false) => {
  if (countries) {
    const list = [...countries];
    const frequentList = [
      ...list.splice(
        list.findIndex((e) => e.locCode === "PK"),
        1
      ),
      ...list.splice(
        list.findIndex((e) => e.locCode === "AE"),
        1
      ),
      ...list.splice(
        list.findIndex((e) => e.locCode === "MA"),
        1
      ),
    ];
    return [
      {
        label: "Frequently",
        options: international
          ? frequentList.concat({
              label: "International",
              value: "international",
            })
          : frequentList,
      },
      { label: "All", options: list },
    ];
  }
  return [];
};

export const numberConvertor = (value, compact, digit) => {
  if (compact === "local") {
    return formatCompactedNumber(Number(value), digit);
  } else if (compact === "intl") {
    return internationalFormatCompactedNumber(Number(value), digit);
  } else {
    return Number(value);
  }
};

export const convertToHMSString = (value) => {
  if (isNaN(Number(value))) {
    return value;
  }
  if (!value) {
    return "-";
  }
  const d = Number(value);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  var hDisplay = h > 0 ? h + (h == 1 ? "h, " : "h, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? "m, " : "m, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
};
