import { createSlice } from "@reduxjs/toolkit";

const quizSlice = createSlice({
  name: "quiz",
  initialState: {},
  reducers: {
    setQuizData(state, { payload }) {
      state.data = { ...state?.data, ...payload };
    },
    setQuizResultData(state, { payload }) {
      state.data = { ...state.data, result: payload };
    },
  },
});

export const { setQuizData, setQuizResultData } = quizSlice.actions;
export default quizSlice;
