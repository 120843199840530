import { useEffect, useState } from "react"

import { DATE_FORMAT } from "constants"
import { DatePicker } from "antd"
import moment from "moment"

export default function SimpleDateFilter({ onSelect, value: dateValue, valueFormat = "YYYY-MM-DD HH:mm:ss", label }) {
  const [value, setValue] = useState()

  useEffect(() => {
    setValue(!!dateValue ? moment(dateValue) : null)
  }, [dateValue])

  return (
    <>
      <label className="d-block">{label}</label>
      <DatePicker
        format={DATE_FORMAT}
        value={value}
        onChange={date => {
          setValue(date)
          onSelect(date, date ? date.format(valueFormat) : "")
        }}
      />
    </>
  )
}
