import { Title } from "components/common";
import { renderQuillHtml } from "configs/utils";
import theme from "utils/themeVars";

const QuestionAnswerDetails = ({
  answer,
  explanation,
  additionalInfo,
  isCorrectSelected,
}) => {
  return (
    <div style={{ backgroundColor: theme["blue50"], borderRadius: "10px" }}>
      <div style={{ padding: "2% 1% 2% 1%", margin: "1%" }}>
        {!isCorrectSelected && (
          <div>
            <Title style={{ color: "#4ca64c" }} level={3}>
              Correct Answer: {answer?.[0]}
            </Title>
          </div>
        )}
        <Title level={3}>Explanation</Title>
        {renderQuillHtml(explanation || "test", undefined, {
          paddingLeft: "0px",
          paddingTop: "0px",
        })}

        {additionalInfo ? (
          <>
            <Title level={3}>Additional Info</Title>
            {renderQuillHtml(additionalInfo, undefined, {
              paddingLeft: "0px",
              paddingTop: "0px",
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionAnswerDetails;
