import { Text, Tooltip } from "components/common"
import { formatCompactedNumber, internationalFormatCompactedNumber, numberFormat } from "./../../../utils/index"

import PropTypes from "prop-types"

const Numbers = props => {
  const currency = "PKR"
  const {
    value,
    compact,
    isCurrency,
    tooltip,
    unit,
    defaultValue,
    digitAfterFraction,
    isStringOnly,
    wrapValue,
    tooltipPlacement,
    ...rest
  } = props
  const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: digitAfterFraction }
  let compactedNumber, wholeNumber
  const number = !isNaN(Number(value)) ? Number(value) : false

  const placeNumberUnit = numberString => {
    if (isCurrency) {
      return currency + " " + numberString
    } else if (unit) {
      return numberString + " " + unit
    } else return numberString
  }

  if (number !== false) {
    wholeNumber = placeNumberUnit(numberFormat(number, "en-US", numberOptions))
    if (compact === "local") {
      compactedNumber = placeNumberUnit(formatCompactedNumber(number, numberOptions.maximumFractionDigits))
    } else if (compact === "intl") {
      compactedNumber = placeNumberUnit(internationalFormatCompactedNumber(number, numberOptions.maximumFractionDigits))
    }
  } else {
    return defaultValue
  }

  const getNumber = () => wrapValue(compactedNumber ? compactedNumber : wholeNumber)
  const getText = () => (isStringOnly ? getNumber() : <Text {...rest}>{getNumber()}</Text>)

  return tooltip && number >= 1000 ? (
    <Tooltip placement={tooltipPlacement} title={wholeNumber}>
      <span>{getText()}</span>
    </Tooltip>
  ) : (
    getText()
  )
}

Numbers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compact: PropTypes.oneOf(["local", "intl"]),
  isCurrency: PropTypes.bool,
  tooltip: PropTypes.bool,
  unit: PropTypes.string,
  defaultValue: PropTypes.string,
  isStringOnly: PropTypes.bool,
  digitAfterFraction: PropTypes.number,
  wrapValue: PropTypes.func,
}

Numbers.defaultProps = {
  isCurrency: false,
  tooltip: false,
  isStringOnly: false,
  defaultValue: "–",
  digitAfterFraction: 2,
  wrapValue: val => val,
  tooltipPlacement: "top",
}

export default Numbers
