import { Avatar as AntdAvatar } from "antd"
import { Avatar } from "components/common"
import React from "react"
import Tooltip from "../tooltip/tooltip"

function AvatarGroup({ source, maxCount = 3, groupSize = "medium", placement = "top" }) {
  return (
    <AntdAvatar.Group maxCount={maxCount} size={groupSize}>
      {source?.map((e, index) => (
        <Tooltip title={e.name} placement={placement}>
          <Avatar src={e?.logo} key={`${e.name}-${index}`} colorKey={e.name?.[0].toLowerCase()}>
            {e.name[0].toUpperCase()}
          </Avatar>
        </Tooltip>
      ))}
    </AntdAvatar.Group>
  )
}

export default AvatarGroup
