import { Collapse } from "antd";
import theme from "utils/themeVars";
import Icon from "../icon/icon";

const CollapseCard = (props) => {
  const { activeKey, header, panel, headerKey, handleExpandCollapse } = props;
  const { Panel } = Collapse;

  return (
    <Collapse
      activeKey={[activeKey]}
      onChange={() => {}}
      expandIconPosition={"end"}
      showArrow={false}
      collapsible="icon"
      expandIcon={({ isActive }) => {
        return (
          <Icon
            icon={isActive ? "FcCollapse" : "FcExpand"}
            style={{
              color: theme["blue200"],
              fontSize: "1.4rem",
              backgroundColor: "white",
            }}
            onClick={handleExpandCollapse}
          />
        );
      }}
    >
      <Panel style={{ overflow: "auto" }} header={header} key={headerKey}>
        {panel}
      </Panel>
    </Collapse>
  );
};
export default CollapseCard;
