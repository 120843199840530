import { Title, Button } from "components/common";
import { TABLE_DELETE_ICON } from "components/svg";
import theme from "utils/themeVars";
import { menuItemIcons } from "constants/menuItems/menuItems-icons";
import { renderQuillHtml } from "configs/utils";

const RenderSlide = ({
  mainSlide,
  slide,
  onClick,
  index,
  opacity,
  isEditable,
  deleteSlide,
  canUserModifyPresentation,
  isCompleted,
}) => {
  return (
    <>
      {isEditable == true ||
      canUserModifyPresentation ||
      isCompleted ||
      (slide?.data?.[0]?.type == "poll" &&
        (slide?.data?.[0]?.status == "started" ||
          slide?.data?.[0]?.status == "completed")) ? (
        <div
          style={{
            opacity: opacity ?? 1,
            border: `1px solid ${
              mainSlide == index ? theme["blue200"] : "#F0F0F0"
            }`,
            borderRadius: "4px",
            padding: "0.5rem 1rem",
            height: window.location.href.includes("add") ? "100px" : "18vh",
            padding: "7px",
            marginBottom: ".5rem",
            cursor: isEditable == true ? "move" : "pointer",
            backgroundColor: slide?.backgroundColor ?? "white",
          }}
          className="pointer"
          onClick={onClick}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Title
              style={{
                color: mainSlide == index ? theme["blue200"] : "#8A8A8A",
                textTransform: "capitalize",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "150px",
              }}
              level={4}
            >
              {slide?.title}
            </Title>
            {isEditable == true ? (
              <Button
                icon={<TABLE_DELETE_ICON />}
                iconProps={{
                  size: "1.8rem",
                  color: "#fc5327",
                  backgroundColor: slide?.backgroundColor ?? "white",
                }}
                style={{
                  backgroundColor: slide?.backgroundColor ?? "white",
                }}
                size="small"
                outlined
                onClick={() => {
                  deleteSlide(slide.id, index);
                }}
              />
            ) : (
              <Button
                icon={
                  slide?.data?.[0]?.type == "poll"
                    ? "AiOutlineUnorderedList"
                    : slide?.data?.[0]?.type == "case"
                    ? "BsPencilSquare"
                    : slide?.data?.[0]?.type == "image"
                    ? "FaImage"
                    : "BsPencilSquare"
                }
                iconProps={{
                  size: "1rem",
                  color: mainSlide == index ? theme["blue200"] : "#9D9D9D",
                  backgroundColor: slide?.backgroundColor ?? "white",
                }}
                style={{
                  backgroundColor: slide?.backgroundColor ?? "white",
                }}
                size="small"
                outlined
                onClick={() => {
                  deleteSlide(id, index);
                }}
              />
            )}
          </div>
          <div
            style={{
              color: mainSlide == index ? theme["blue200"] : "#9D9D9D",
              fontSize: "0.3rem",
              overflow: "clip",
              height: "6vh",
            }}
          >
            {renderQuillHtml(
              typeof slide?.data?.[0]?.data === "string"
                ? slide?.data?.[0]?.data
                : slide?.data?.[0]?.type === "image"
                ? "Image"
                : slide?.data?.[0]?.type === "schema"
                ? "Schema"
                : slide?.data?.[0]?.data?.question
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RenderSlide;
