import { Tooltip } from "antd";
import { Button, Checkbox, TextInput, Title } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import { TABLE_DELETE_ICON } from "components/svg";
import theme from "utils/themeVars";

const QuestionItemEdit = ({
  question,
  questionIndex,
  handleUpdateQuestions,
  handleSingleQuestionUpdate,
  showSingleUpdate = true,
}) => {
  const answerItem = (item, index) => {
    const alphabetIndex = String.fromCharCode(65 + index);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px auto",
          gap: "10px",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Tooltip title="Mark as right option">
          <Checkbox
            key={index}
            onChange={() => {
              let tempQuestion = { ...question };
              tempQuestion.correctAnswer = [item];
              handleUpdateQuestions(tempQuestion, questionIndex);
            }}
            checked={question?.correctAnswer?.find((e) => e == item)}
            style={{ backgroundColor: "white" }}
          ></Checkbox>
        </Tooltip>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: theme["blue50"],
            color: theme["blue200"],
          }}
        >
          {alphabetIndex}
        </div>
        <div style={{ width: "100%", paddingLeft: "10px" }}>
          <TextInput
            key={`answer-${index}`}
            value={item}
            placeholder={"Add Option"}
            onChange={(e) => {
              let tempQuestion = JSON.parse(JSON.stringify(question));
              tempQuestion.options[index] = e?.target?.value;
              handleUpdateQuestions(tempQuestion, questionIndex);
            }}
          />
        </div>

        {!(
          index == question?.options?.length - 1 &&
          question?.options?.length < 5
        ) &&
          question?.options?.length > 2 && (
            <Button
              icon={<TABLE_DELETE_ICON />}
              iconProps={{
                size: "1.8rem",
                color: "#fc5327",
                backgroundColor: "white",
              }}
              style={{
                backgroundColor: "white",
              }}
              outlined
              onClick={() => {
                let tempQuestion = { ...question };
                tempQuestion.options = tempQuestion.options.filter(
                  (e, i) => i != index
                );
                handleUpdateQuestions(tempQuestion, questionIndex);
              }}
            />
          )}

        {index == question?.options?.length - 1 &&
          question?.options?.length < 5 && (
            <Button
              icon="HiPlus"
              style={{
                background: "transparent",
                border: `1px solid ${theme["blue100"]}`,
                color: theme["primary-color"],
              }}
              iconProps={{ color: theme["primary-color"] }}
              onClick={() => {
                let tempQuestion = { ...question };
                tempQuestion.options = [...tempQuestion.options, ""];
                handleUpdateQuestions(tempQuestion, questionIndex);
              }}
            />
          )}
      </div>
    );
  };

  const renderAnswers = () => {
    return question?.options?.length ? (
      <div style={{}}>
        <Title level={4}>Answers:</Title>
        {question?.options?.map((item, index) => answerItem(item, index))}
      </div>
    ) : null;
  };

  const renderAdditional = () => {
    return (
      <div>
        <Title level={3}>Additional Info:</Title>
        <TextEditor
          key={`additional-${questionIndex}`}
          placeholder={"Add additional info"}
          value={question?.additionalInfo}
          onChange={(value) => {
            let tempQuestion = { ...question };
            tempQuestion.additionalInfo = value;
            handleUpdateQuestions(tempQuestion, questionIndex);
          }}
          editorHeight={200}
        />
      </div>
    );
  };
  return (
    <>
      {renderAnswers()} {renderAdditional()}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title style={{ marginTop: "1%" }} level={5}>
          Question Id: {question?.questionId}
        </Title>
        {showSingleUpdate && (
          <div
            style={{ display: "flex", justifyContent: "end", marginTop: "2%" }}
          >
            <Button
              text="Update"
              type="primary"
              onClick={() => {
                handleSingleQuestionUpdate(question, questionIndex);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionItemEdit;
