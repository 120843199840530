import { Typography } from "antd";
import { Button, Card, Text, Title } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import { EDIT_ASSESSMENT } from "components/svg";
import theme from "utils/themeVars";
import Notes from "./notes";
import {
  useAddEditNoteMutation,
  useGetNotesMutation,
} from "features/script/api";
import { useEffect } from "react";
const ScriptNotesSection = ({
  onEdit,
  onChange,
  editorTemplateId,
  setNotes,
  note,
  scrollId,
}) => {
  const [addEditNote, {}] = useAddEditNoteMutation();
  const [getNotes, { data }] = useGetNotesMutation();

  const fetchUserNotes = async () => {
    const res = await getNotes({
      editor_template_id: editorTemplateId,
    });
    if (res) {
      if (res?.error) {
      } else {
        setNotes("user_note", res?.data?.data?.[0]);
      }
    }
  };

  useEffect(() => {
    editorTemplateId && fetchUserNotes();
  }, [editorTemplateId]);

  const handleAddEditNote = async () => {
    const res = await addEditNote({
      editor_template_id: editorTemplateId,
      note: note?.note,
    });
  };

  return (
    <Card bodyStyle={{ padding: "2%" }}>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "1%",
          }}
          key={scrollId}
          id={scrollId}
        >
          <Title level={2}>Notes</Title>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button
              onClick={!note?.editable ? onEdit : handleAddEditNote}
              icon={!note?.editable ? <EDIT_ASSESSMENT /> : null}
              type="bordered"
              style={{
                borderColor: theme["blue100"],
                color: theme["blue200"],
                background: theme["light-gray"],
              }}
            >
              {!note?.editable ? "Edit" : "Save"}
            </Button>
            {note?.editable && (
              <Button
                onClick={() => {
                  setNotes("user_note", {
                    ...note,
                    editable: false,
                    note: data?.data?.[0]?.note,
                  });
                }}
                type="bordered"
                style={{
                  borderColor: theme["blue100"],
                  color: theme["blue200"],
                  background: theme["light-gray"],
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
        <Notes
          editable={note?.editable}
          description={note?.note || ""}
          onChange={onChange}
          isRichText
        />
      </>
    </Card>
  );
};

export default ScriptNotesSection;
