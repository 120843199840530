// Cookie/Storage Keys
export const AUTH_TOKEN_COOKIE_KEY = "illness_access_token";
export const USER_STORAGE_KEY = "userInfo";
export const LOGIN_STORAGE_KEY = "isLoggedIn";
export const RETURN_URL_STORAGE_KEY = "returnUrl";

// Formats
export const DATE_FORMAT = "MMM DD, YYYY";
export const DATE_MONTH_FORMAT = "MMM DD";
export const TIME_FORMAT = "h:mm a";
export const TIME_DATE_FORMAT = "h:mm a, MMM DD, YYYY";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
