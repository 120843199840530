import theme from "utils/themeVars";
import Button from "./button";

const PrimaryButton = (props) => {
  return (
    <Button
      {...props}
      type="bordered"
      style={{
        borderColor: theme["blue100"],
        color: theme["blue200"],
        background: theme["light-gray"],
      }}
      size="small"
    />
  );
};

export default PrimaryButton;
