import React, { useEffect } from "react";
import styles from "../index.module.less";
import ScriptSettingRightPanel from "./scriptSettingRightPanel";
import ScriptSettingLeftPanel from "./scriptSettingLeftPanel";
import { useLazyGetTemplateSettingsDetailsQuery } from "features/templateSettings/api";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTemplateSettingsData } from "store/templateSettingsSlice";
import { TemplateTypes } from "./getTemplateSettingPayload";

const initialData = {
  name: "",
  templateName: "",
  primary_specialty: null,
  secondary_specialty: [],
  topics: [],
  versions: [],
  presentationSection: {
    titleDescriptions: [
      { key: "1", placeholder: "Enter Title and Description" },
      { key: "2", placeholder: "Enter Title and Description" },
      { key: "3", placeholder: "Enter Title and Description" },
    ],
    media: [],
  },
  explanations: [
    {
      title: "",
      explanation: "",
      media: [],
      key: "0",
      description: "",
    },
  ],
};

const ScriptTemplateSettingsForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);
  const dispatch = useDispatch();
  let details = useSelector((state) => state?.templateSetting?.data);

  useEffect(() => {
    dispatch(setTemplateSettingsData(initialData));
  }, []);

  const [getTemplateDetails, { data, isLoading }] =
    useLazyGetTemplateSettingsDetailsQuery();

  const getAllMedia = (e) => {
    let arr = [];
    let temp = [];
    let temp2 = [];
    if (e?.media?.length) {
      arr = e?.media.map((it) => ({
        ...e,
        type: "image",
        imageDescription: it?.imageDescription,
        imageTitle: it?.imageTitle,
        url: it?.url,
        imageUrl: it?.imageUrl,
      }));
    }
    if (e?.LicensedImage?.length) {
      temp = [];
      temp = e?.LicensedImage?.map((it) => ({
        ...it,
        type: "licenseImage",
        ImageURL: it?.ImageURL,
        siteURL: it?.siteURL,
        imageTitle: it?.imageTitle,
      }));
    }
    if (e?.LicensedPodcast?.length) {
      temp = [];
      temp = e?.LicensedPodcast?.map((it) => ({
        ...it,
        type: "licensePodcast",
        PodcastURL: it?.PodcastURL,
        siteURL: it?.siteURL,
        podcastTitle: it?.podcastTitle,
      }));
    }
    if (e?.YTIframe?.length) {
      temp2 = e?.YTIframe?.map((it) => ({
        ...e,
        type: "licenseVideo",
        ImageURL: it?.VideoIframe,
        siteURL: it?.siteURL,
        imageTitle: it?.videoTitle,
      }));
    }
    return [...arr, ...temp, ...temp2];
  };
  const setTemplateSettingsDetails = (data) => {
    const updatedData = {
      pageId: data?.page_id,
      name: data?.name,
      templateId: data?.base_template_id,
      primary_specialty: TemplateTypes?.find((e) => e?.slug == data?.type)
        ?.label,
      presentationSection: {
        titleDescriptions: data?.section_1?.length
          ? data?.section_1.map((e, i) => ({
              key: i?.toString(),
              placeholder: "Add Description",
              title: e?.title,
              description: e?.description,
            }))
          : [
              {
                key: "1",
                placeholder: "Add Description",
                title: "",
                description: "",
              },
            ],
        media: data?.media?.length
          ? data?.media?.map((e) => ({ ...e, type: "image" }))
          : [],
      },
      explanations: data?.section_2?.length
        ? data?.section_2.map((e, i) => ({
            ...e,
            key: i?.toString(),
            title: e?.title,
            description: e?.explanation,
            media: getAllMedia(e),
          }))
        : [],
    };
    dispatch(setTemplateSettingsData(updatedData));
  };

  const fetchTemplateDetails = async () => {
    const response = await getTemplateDetails({
      base_template_id: Number(queryObj?.templateId),
    });
    if (response) {
      if (response?.error) {
      } else {
        setTemplateSettingsDetails(response?.data?.data);
      }
    }
  };

  useEffect(() => {
    if (queryObj?.templateId) {
      fetchTemplateDetails();
    }
  }, [queryObj?.templateId]);

  const getExplanations = (exp) => {
    if (!exp?.length) {
      return [];
    }
    const temp = JSON.parse(JSON.stringify(exp));
    if (temp?.length) {
      temp.unshift({ title: "Presentation" });
    }
    return temp;
  };

  return (
    <div className={styles.pageContainer}>
      <ScriptSettingLeftPanel
        skeletonLoading={isLoading}
        explanations={getExplanations(details?.explanations)}
      />
      <ScriptSettingRightPanel
        data={details}
        templateId={queryObj?.templateId}
      />
    </div>
  );
};

export default ScriptTemplateSettingsForm;
