import { Modal, Image } from "antd";
import { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
window.Buffer = window.Buffer || require("buffer").Buffer;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const ImageSlide = ({ slide }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState(
    slide?.data?.images && slide?.data?.images.length > 0
      ? slide.data.images
      : []
  );

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const calculateWidthPercentage = () => {
    const imageCount = fileList.length;
    switch (imageCount) {
      case 1:
        return "100%";
      case 2:
        return "50%";
      case 3:
        return "33.33%";
      case 4:
        return "25%";
      default:
        return "25%";
    }
  };

  const rows = [];
  const widthPercentage = calculateWidthPercentage();

  let rowIndex = 0;
  for (let i = 0; i < fileList.length; i += 4) {
    const rowImages = fileList.slice(i, i + 4);
    const row = (
      <div
        className="image-row"
        key={i}
        style={{
          display: "flex",
          width: "100%",
          height: `${fileList.length > 4 ? "50%" : "100%"}`,
        }}
      >
        {rowImages.map((image, index) => (
          <div
            key={rowIndex}
            style={{
              width: widthPercentage,
              position: "relative",
              height: `${widthPercentage == "25%" ? "50%" : "100%"}`,
              padding: "10px",
            }}
          >
            <div
              style={{
                widht: "100%",
                height: "100%",
              }}
            >
              <Image
                preview={{
                  mask: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <EyeOutlined style={{ fontSize: 24 }} />{" "}
                      {/* Customize icon size if needed */}
                    </div>
                  ),
                }}
                src={image.url}
                alt={`Image ${index}`}
                width={"95%"}
                height={fileList.length > 4 ? "35.46vh" : "57.56vh"}
              />
            </div>
          </div>
        ))}
      </div>
    );
    rowIndex += 1;
    rows.push(row);
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          // overflow: "scroll",
        }}
      >
        {rows.map((row) => row)}
      </div>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default ImageSlide;
