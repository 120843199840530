import { Form } from "antd";
import {
  ImageUpload,
  Modal,
  Spinner,
  TextInput,
  Title,
  notification,
} from "components/common";
import DraggerView from "components/common/draggerView/draggerView";
import ImageSlider from "components/common/slider/slider";
import TextEditor from "components/common/textEditor/textEditor";
import { renderQuillHtml } from "configs/utils";
import { schemaRules } from "features/shared/utils";
import { useEffect, useState } from "react";
import * as yup from "yup";

const AddFileModal = ({
  isVisible,
  setIsVisible,
  onConfirm,
  data,
  renderFooter = true,
  detailPage = false,
  ...rest
}) => {
  const [form] = Form.useForm();
  const url = Form.useWatch("url", form);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(data?.imageDescription);

  useEffect(() => {
    setDescription(data?.imageDescription);
  }, [data?.imageDescription]);

  const getValidationSchema = () =>
    yup.object().shape({
      title: yup.string().nullable(),
      // .required("Title is required")
      // .min(3, "Title must be atleast 3 characters"),
    });

  const rules = schemaRules(getValidationSchema());

  const getInitialValues = (data) => {
    return {
      imageTitle: data?.imageTitle,
      imageDescription: data?.imageDescription,
      imageURL: data?.imageURL,
      url: data?.url,
    };
  };

  const onSubmit = (values) => {
    if (loading) {
      notification.error({
        message: "Please wait while the file is being uploaded!",
      });
    } else if (!url) {
      notification.error({ message: "Please add a file to continue!" });
    } else {
      form.resetFields();
      onConfirm({ ...data, ...values });
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(getInitialValues(data));
    }
  }, [data]);

  const renderFormView = () => {
    return (
      <Form
        name="basic"
        initialValues={getInitialValues(data)}
        form={form}
        scrollToFirstError
        onFinish={onSubmit}
        preserve={true}
        labelCol={{ span: 6 }}
        shouldUpdate
      >
        <div style={{ display: "flex", gap: "1vh", flexDirection: "column" }}>
          <Form.Item name="imageTitle">
            <TextInput
              name="imageTitle"
              maxLength={100}
              placeholder={`Enter File Title`}
              handleChange={(e) =>
                form.setFieldValue("imageTitle", e?.target?.value)
              }
            />
          </Form.Item>
          <Form.Item name="imageURL">
            <TextInput
              name="imageURL"
              maxLength={100}
              placeholder={`Enter Source Url`}
              handleChange={(e) =>
                form.setFieldValue("imageURL", e?.target?.value)
              }
            />
          </Form.Item>
          <Form.Item noStyle name="imageDescription">
            <TextEditor
              placeholder={"Enter Description"}
              value={description}
              onChange={(value) => {
                setDescription(value);
                form.setFieldValue("imageDescription", value);
              }}
              editorHeight={200}
              key={"imageDescription"}
            />
          </Form.Item>

          <Form.Item noStyle name="url" required>
            <div
              style={{
                display: "flex",
                justifyContent: detailPage ? "center" : "space-between",
              }}
            >
              {!detailPage && (
                <div>
                  <ImageUpload
                    onUpload={(file, uploadedFile) => {
                      form.setFieldValue("url", file?.location);
                    }}
                    setLoading={(value) => {
                      setLoading(value);
                    }}
                  >
                    <DraggerView />
                  </ImageUpload>
                </div>
              )}
              {loading ? (
                <Spinner style={{ paddingTop: "20px" }} />
              ) : (
                url && (
                  <ImageSlider
                    data={[{ type: "image", url: url }]}
                    itemToShow={1}
                    itemHeight={150}
                    itemWidth={150}
                    showActions={false}
                  />
                )
              )}
              <div></div>
            </div>
          </Form.Item>
        </div>
      </Form>
    );
  };

  const renderDetailView = () => {
    return (
      <div>
        <Title level={3} style={{ display: "flex", justifyContent: "center" }}>
          {data?.imageTitle}
        </Title>
        {data?.url && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1%",
            }}
          >
            <ImageSlider
              data={[data]}
              itemToShow={1}
              itemHeight={150}
              itemWidth={150}
              showActions={false}
            />
          </div>
        )}
        {data?.imageURL && (
          <div
            style={{
              paddingTop: "5%",
              display: "flex",
              gap: "5px",
            }}
          >
            <Title level={4}>Source Url: </Title>
            <a href={data?.imageURL}>{data?.imageURL}</a>
          </div>
        )}
        {data?.imageDescription && (
          <>
            <Title level={4}> Description:</Title>
            {renderQuillHtml(data?.imageDescription)}
          </>
        )}
      </div>
    );
  };

  return (
    <Modal
      visible={isVisible}
      title={
        detailPage
          ? null
          : !renderFooter
          ? data?.title || "File"
          : data?.url
          ? "Edit File"
          : "Add File"
      }
      onOk={() => form.submit()}
      width={800}
      onCancel={() => setIsVisible(null)}
      okText={data?.url ? "Edit" : "Add"}
      footer={!renderFooter && null}
      {...rest}
    >
      {!detailPage ? renderFormView() : renderDetailView()}
    </Modal>
  );
};

export default AddFileModal;
