import ScriptBasicInfo from "features/script/components/addScriptForm/scriptBasicInfo";
import styles from "../index.module.less";
import ScriptPresentationSection from "features/script/components/addScriptForm/scriptPresentationSection";
import ScriptExplanationSection from "features/script/components/addScriptForm/scriptExplanationSection";
import { useDispatch } from "react-redux";
import { setTemplateSettingsData } from "store/templateSettingsSlice";
import { Button, notification } from "components/common";
import {
  TemplateTypes,
  getTemplateSettingPayload,
} from "./getTemplateSettingPayload";
import {
  useAddTemplateSettingMutation,
  useUpdateTemplateSettingMutation,
} from "features/templateSettings/api";
import { useNavigate } from "react-router-dom";
import { SCRIPT_TEMPLATE_SETTINGS_PATH } from "constants/app-paths";

const ScriptSettingRightPanel = ({ data = {}, templateId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateSetting, {}] = useUpdateTemplateSettingMutation();
  const [addSetting, {}] = useAddTemplateSettingMutation();

  const handleChangeBasicDetails = (updatedData) => {
    dispatch(setTemplateSettingsData(updatedData));
  };
  const handleChangePresentationDetails = (titleDescriptions, media) => {
    dispatch(
      setTemplateSettingsData({
        presentationSection: {
          ...data?.presentationSection,
          titleDescriptions,
          media,
        },
      })
    );
  };

  const handleChangeExplanation = (key, value) => {
    dispatch(setTemplateSettingsData({ [key]: value }));
  };

  const handleUpdateOrAdd = async () => {
    const details = getTemplateSettingPayload(data, !!templateId);
    const res = await (templateId
      ? updateSetting(details)
      : addSetting(details));
    if (res) {
      if (res?.error) {
        notification.error({ message: res?.error?.message });
      } else {
        notification.success({
          message: templateId
            ? "Template Setting Updated Successfully!"
            : "Template Setting Added Successfully!",
        });
        navigate(SCRIPT_TEMPLATE_SETTINGS_PATH);
      }
    }
  };
  return (
    <div className={styles.rightSection}>
      <ScriptBasicInfo
        data={data}
        editMode={false}
        isMobile={false}
        handleChange={handleChangeBasicDetails}
        showSecondarySpecialty={false}
        showTopics={false}
        showTemplateType={false}
        predefinedTypes={TemplateTypes?.map((e) => e?.label)}
      />
      <ScriptPresentationSection
        editMode={false}
        titleDescriptions={data?.presentationSection?.titleDescriptions}
        media={data?.presentationSection?.media}
        onEditSave={() => {}}
        updateLoading={false}
        isMobile={false}
        updatePresentationData={handleChangePresentationDetails}
      />
      <ScriptExplanationSection
        explanations={data?.explanations}
        showSchemas={false}
        showAssessments={false}
        showPodCast={false}
        onEditSave={() => {}}
        editMode={false}
        updateLoading={false}
        isMobile={false}
        user={{}}
        data={data}
        handleChange={handleChangeExplanation}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
        }}
      >
        <Button
          size="large"
          type="primary"
          text={templateId ? "Update" : "Save"}
          onClick={handleUpdateOrAdd}
        />
      </div>
    </div>
  );
};

export default ScriptSettingRightPanel;
