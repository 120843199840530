import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAPIBaseURL } from "utils/env";

import { AUTH_TOKEN_COOKIE_KEY } from "constants";
import { getCookie } from "utils/cookie";
import algoliaEndpoints from "./algolia";
import { logout } from "./appSlice";
import transformResponse from "./transformResponse";

const authHeader = (auth) => ({
  authorization: auth ? `Bearer ${auth}` : getCookie(AUTH_TOKEN_COOKIE_KEY),
});

const getErrorFromResponse = (response) => {
  console.error("Request Error: ", response);
  return {
    ...response,
    error: {
      status: response.error?.originalStatus || response.error?.status,
      message:
        response?.error?.data?.status_message ||
        response.error?.data?.response_error?.msg ||
        "Network error",
    },
  };
};

const skippedAuthEndpoints = [];

const baseQuery = fetchBaseQuery({
  baseUrl: getAPIBaseURL(),
  prepareHeaders: (headers, { getState, endpoint }) => {
    let obj = {};
    const cookie = getCookie(AUTH_TOKEN_COOKIE_KEY);
    const skipAuth = skippedAuthEndpoints.find((e) => e == endpoint);
    if (!skipAuth) {
      obj = authHeader(cookie);
    }

    Object.keys(obj).forEach((e) => {
      !!obj[e] && headers.set(e, obj[e]);
    });
    return headers;
  },
  withCredentials: true,
  // credentials: inDevelopMode ? undefined : "include",
});

const parentApi = createApi({
  reducerPath: "parentApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      let result = await baseQuery(args, api, extraOptions);

      if (result.error) {
        if (result.error.status === 401 || result.error.status === 403) {
          api.dispatch(logout(false));
          api.dispatch(parentApi.util.resetApiState());
          window.location.href("/login");
        } else {
          return getErrorFromResponse(result);
        }
      }
      return result;
    } catch (e) {
      return getErrorFromResponse(e);
    }
  },
  endpoints: (builder) => ({
    getCurrentUserDetail: builder.query({
      query: () => `/user/details`,
      transformResponse: (response) =>
        transformResponse.userDetailsTransformer(response?.data),
    }),
    getUserPopoverLinks: builder.query({
      query: () => `/user/get/links`,
      transformResponse: (response) => response?.data,
      providesTags: ["allLinks"],
    }),
    getPopoverLinkDetail: builder.query({
      query: (text) => `/user/get/Specific/array_links/${text}`,
      transformResponse: (response) => response?.data,
      providesTags: ["linkDetail"],
    }),
    postLink: builder.mutation({
      query: (data) => {
        return {
          url: `/user/submit/links`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["allLinks", "linkDetail"],
    }),
    updateLink: builder.mutation({
      query: (data) => {
        return {
          url: `/user/update/link/${data?._id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["allLinks", "linkDetail"],
    }),
  }),
});
let algoliaApi = parentApi.injectEndpoints(algoliaEndpoints);

export const {
  useGetCompaniesQuery,
  useLazyGetNewProjectsQuery,
  useGetNewProjectsQuery,
  useGetProjectsDetailQuery,
  useGetDevelopersDetailQuery,
  useLazyGetCompaniesQuery,
  useGetLocalitiesQuery,
  useLazyGetLocalitiesQuery,
  useGetLocationDetailQuery,
  useLazyGetDevelopersQuery,
  useGetDevelopersQuery,
} = algoliaApi;

export default parentApi;
