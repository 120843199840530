import debounce from "lodash.debounce";
import { useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import "../../../styles/quilEditor.css";

const CommonReactQuill = ({
  quillRef,
  placeholder,
  onChange,
  modules,
  formats,
  value,
  className,
  key,
  isToolbarVisible = false,
  setIsToolbarVisible,
  baseHeight = "200px", // Default base height
  ...rest
}) => {
  const debouncedOnChange = useMemo(
    () => debounce((value) => onChange(value), 300),
    [onChange]
  );

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  useEffect(() => {
    document
      .querySelector(".ql-toolbar")
      .addEventListener("mousedown", function (e) {
        e.preventDefault();
      });
  }, []);

  return (
    <div style={{ height: baseHeight }}>
      <ReactQuill
        ref={quillRef}
        key={key}
        theme="snow"
        placeholder={placeholder}
        value={value}
        onChange={(value, delta, source, editor) => {
          if (source == "user") {
            debouncedOnChange(value);
          }
        }}
        formats={formats}
        modules={modules}
        onFocus={() => {
          setIsToolbarVisible(true);
        }}
        onChangeSelection={(event) => {
          if (event && event.length > 0) {
            setIsToolbarVisible(true);
          }
        }}
        onBlur={(event, source, editor) => {
          if (source == "silent") {
            return;
          }
          const linkbar = document.getElementsByClassName(
            "ql-tooltip ql-editing"
          );

          const linkbar1 = document.getElementsByClassName("ql-picker-options");
          if (
            linkbar &&
            linkbar.length > 0 &&
            linkbar[0].classList &&
            !Array.from(linkbar[0].classList).includes("ql-hidden")
          ) {
            return;
          }

          if (
            linkbar1 &&
            linkbar1.length > 0 &&
            [...linkbar1].some((li) => li.ariaHidden == "false")
          ) {
            return;
          }

          setIsToolbarVisible(false);
        }}
        className={`${
          !!isToolbarVisible ? "unhide-quill-toolbar" : "hide-quill-toolbar"
        } ${className}`}
        {...rest}
      />
    </div>
  );
};

export default CommonReactQuill;
