import { Modal, Image, Input } from "antd";
import { useEffect, useState } from "react";
import { ReactS3Client } from "../utils/s3";
import { Spinner, notification, Icon } from "components/common";
window.Buffer = window.Buffer || require("buffer").Buffer;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const ImageSlide = ({ key, slide, onChange }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (slide?.data?.images) {
      setFileList(slide?.data?.images);
    }
  }, [slide?.data?.images]);

  const [loading, setLoading] = useState(false);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const calculateWidthPercentage = () => {
    const imageCount = fileList.length;
    switch (imageCount) {
      case 1:
        return "100%";
      case 2:
        return "50%";
      case 3:
        return "33.33%";
      case 4:
        return "25%";
      default:
        return "25%";
    }
  };

  const rows = [];
  const widthPercentage = calculateWidthPercentage();

  let rowIndex = 0;
  for (let i = 0; i < fileList.length; i += 4) {
    const rowImages = fileList.slice(i, i + 4);
    const row = (
      <div
        className="image-row"
        key={i}
        style={{
          display: "flex",
          width: "100%",
          height: `${fileList.length > 4 ? "50%" : "100%"}`,
        }}
      >
        {rowImages.map((image, index) => (
          <div
            key={rowIndex}
            style={{
              width: widthPercentage,
              position: "relative",
              height: `${widthPercentage == "25%" ? "50%" : "100%"}`,
              padding: "10px",
            }}
          >
            <Icon
              id={`icon-delete-${rowIndex}`}
              key={rowIndex}
              icon="TiDelete"
              danger
              onClick={(e) => {
                setLoading(true);
                const temp = fileList.filter((fil) => fil.url !== image.url);
                setFileList([...temp]);
                onChange({
                  ...slide?.data,
                  images: [...temp],
                });
                setLoading(false);
              }}
              style={{
                position: "absolute",
                fontSize: "2rem",
                color: "red",
                top: 0,
                right: 2,
                zIndex: 200,
                cursor: "pointer",
              }}
            />
            <div
              style={{
                widht: "100%",
                height: "100%",
              }}
            >
              <Image
                src={image.url}
                alt={`Image ${index}`}
                width={"100%"}
                height={fileList.length > 4 ? "31.46vh" : "57.56vh"}
              />
            </div>
          </div>
        ))}
      </div>
    );
    rowIndex += 1;
    rows.push(row);
  }

  const imageUpload = (file) => {
    return new Promise((resolve, reject) => {
      ReactS3Client.uploadFile(file)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleChange = async (newFileList) => {
    try {
      setLoading(true);
      const file = await imageUpload(newFileList[0]);
      const t = { url: file.location };
      setFileList([...fileList, t]);
      onChange({ ...slide?.data, images: [...fileList, t] });
      setLoading(false);
    } catch (err) {
      notification.error({
        message: "Something went wrong uploading file",
      });
      setLoading(false);
    }
  };

  return (
    <div key={key}>
      {loading ? (
        <Spinner />
      ) : fileList.length >= 8 ? null : (
        <Input
          placeholder="Basic usage"
          type="file"
          onChange={(e) => {
            handleChange(e.target.files);
          }}
        />
      )}

      <div
        key={key + "inner image"}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          // overflow: "scroll",
        }}
      >
        {rows.map((row) => row)}
      </div>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default ImageSlide;
