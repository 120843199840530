// import * as Sentry from "@sentry/react"

import App from "./App"
// import { BrowserTracing } from "@sentry/tracing"
import { Provider } from "react-redux"
import React from "react"
import ReactDOM from "react-dom/client"
// import ReactGA from "react-ga4"
// import { inDevelopMode } from "utils/env"
import reportWebVitals from "./reportWebVitals"
import store from "store"

// !inDevelopMode &&
//   Sentry.init({
//     dsn: "https://b4e7e5719d5f42d7bc2f936fd1855a81@o994084.ingest.sentry.io/4504474239500288",
//     integrations: [new BrowserTracing()],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   })

const root = ReactDOM.createRoot(document.getElementById("root"))

// !inDevelopMode &&
//   ReactGA.initialize([
//     {
//       trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID,
//       gaOptions: { debug_mode: true },
//       gtagOptions: { debug_mode: true },
//     },
//   ])

root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
