export const getScriptPayload = (data, user) => {
  const section2 = data?.explanations?.map((e) => {
    const { title = "", explanation = "", media = [] } = e;
    const images = media?.length
      ? media?.filter((e) => e?.type == "image" || e?.type == "Image")
      : [];
    const licenseImage = media?.length
      ? media?.filter((e) => e?.type == "licenseImage")
      : [];
    const licenseVideo = media?.length
      ? media?.filter((e) => e?.type == "licenseVideo")
      : [];
    const licensePodcast = media?.length
      ? media?.filter((e) => e?.type == "licensePodcast")
      : [];
    const pay = {
      title,
      explanation,
      media: images?.length
        ? images?.map((it) => {
            return {
              imageDescription: it?.imageDescription,
              imageTitle: it?.imageTitle,
              type: it?.type,
              url: it?.url,
              // ImageURL: it?.imageURL,
            };
          })
        : [],
      LicensedImage: licenseImage?.length
        ? licenseImage?.map((it) => {
            return {
              ImageURL: it?.ImageURL,
              siteURL: it?.siteURL,
              imageTitle: it?.imageTitle,
            };
          })
        : [],
      YTIframe: licenseVideo?.length
        ? licenseVideo?.map((it) => {
            return {
              VideoIframe: it?.ImageURL,
              siteURL: it?.siteURL,
              videoTitle: it?.imageTitle,
            };
          })
        : [],
      LicensedPodcast: licensePodcast?.length
        ? licensePodcast?.map((it) => {
            return {
              PodcastURL: it?.ImageURL,
              siteURL: it?.siteURL,
              podcastTitle: it?.imageTitle,
            };
          })
        : [],
    };
    return pay;
  });
  return {
    name: data?.name,
    image_slider_position: 2,
    podCast: data?.podCast,
    text_slider_position: 1,
    type: data?.templateType,
    page_name: data?.name,
    primarySpeciality: data?.primary_specialty,
    speciality: data?.secondary_specialty,
    topics: data?.topics,
    schemas: data?.schemas?.length
      ? data?.schemas?.map((e) => ({
          title: e?.title,
          json: JSON.stringify(e?.data),
        }))
      : [],
    assessment_and_plans: data?.assessmentAndPlans,
    section_1: data?.presentationSection?.titleDescriptions?.map((e) => ({
      title: e?.title,
      description: e?.description,
    })),
    media: data?.presentationSection?.media.map((it) => {
      return {
        imageURL: it.url,
        type: it.type,
        url: it.url,
        imageDescription: it?.description,
      };
    }),
    section_2: section2,
    page_id: data?.pageId,
    questions: data?.questions?.length ? data?.questions : [],
  };
};
