import { Spinner, notification } from "components/common";
import { PRESENTATION_PATH } from "constants/app-paths";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddPresentationMutation,
  useGetPresentationQuery,
  useUpdatePresentationMutation,
} from "../apis";
import { validateSubmitData } from "../transformers";
import { getUniqueId } from "../utils";
import styles from "./index.module.less";
import PresentationPage from "./presentationPage";

const Presentation = () => {
  const params = useParams();
  const { data: presentation, isFetching } = useGetPresentationQuery(
    params.id,
    { skip: !params?.id, refetchOnMountOrArgChange: true }
  );
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.app.user?.info);
  const [updatePresentation, updateResponse] = useUpdatePresentationMutation();
  const [addPresentation, addResponse] = useAddPresentationMutation();
  const [action, setAction] = useState(null);
  const navigate = useNavigate();
  const [basicInfo, setBasicInfo] = useState({
    name: "",
    topics: [],
    specialities: [],
  });

  const [slides, setSlides] = useState(
    presentation?.data
      ? presentation.data?.slides
      : [
          {
            id: getUniqueId([]),
            title: "",
            backgroundColor: "white",
            data: [
              {
                type: "slide",
                id: getUniqueId([]),
                placeholder: "Start adding content to your slide...",
                data: "<p></p>",
              },
            ],
          },
        ]
  );

  useEffect(() => {
    if (presentation && presentation.data?.slides) {
      setSlides(presentation.data.slides);
      setAction("edit");
      setBasicInfo({
        name: presentation.data.name ?? "",
        topics: presentation.data.topics ?? [],
        specialities: presentation.data.speciality ?? [],
      });
    } else {
      setSlides([
        {
          id: getUniqueId([]),
          title: "",
          backgroundColor: "white",
          data: [
            {
              type: "slide",
              id: getUniqueId([]),
              placeholder: "Start adding content to your slide...",
              data: "<p></p>",
            },
          ],
        },
      ]);
      setAction("add");
    }
  }, [presentation]);

  return (
    <>
      <div className={styles.pageContainer}>
        {isFetching || isLoading ? (
          <div className={styles.pageContainer}>
            <Spinner />
          </div>
        ) : (
          <PresentationPage
            slides={slides}
            basicInfo={basicInfo}
            setBasicInfo={setBasicInfo}
            setSlides={setSlides}
            onSubmitClick={() => {
              const presentationData = {
                slides: slides,
                name: basicInfo.name,
                editor_id: user.user_code,
                topics: basicInfo.topics,
                speciality: basicInfo.specialities,
              };

              if (validateSubmitData(presentationData) !== true) {
                notification.error({
                  message: validateSubmitData(presentationData),
                });
                return;
              }

              try {
                setIsLoading(true);
                if (action) {
                  if (action == "edit") {
                    updatePresentation({
                      id: params.id,
                      data: presentationData,
                    })
                      .unwrap()
                      .then(() => {
                        notification.success({
                          message: "Presentation updated!",
                        });
                        setIsLoading(false);
                        navigate(PRESENTATION_PATH);
                      })
                      .catch((e) => {
                        notification.error({
                          message: e?.message,
                        });
                        setIsLoading(false);
                      });
                  } else if (action == "add") {
                    addPresentation({ data: presentationData })
                      .unwrap()
                      .then(() => {
                        notification.success({
                          message: "Presentation submitted!",
                        });
                        setIsLoading(false);
                        navigate(PRESENTATION_PATH);
                      })
                      .catch((e) => {
                        notification.error({
                          message: e?.message,
                        });
                        setIsLoading(false);
                      });
                  }
                }
              } catch (err) {
                notification.error({
                  message: err,
                });
                setIsLoading(false);
              }
            }}
          />
        )}
      </div>
    </>
  );
};
export default Presentation;
