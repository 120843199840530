import { Pagination, Row, Table } from "antd";
import { useEffect, useState } from "react";

import cx from "clsx";
import { Group } from "components/common";
import { useTableData } from "hooks/useTableData";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { ComponentState } from "..";
import styles from "./dataTable.module.less";

const DataTable = (props) => {
  const {
    columns: col,
    data: rows = [],
    pagination,
    showPagination,
    onChangePage,
    noUrlPush,
    paginationOnTop = false,
    paginationOnBottom = false,
    loading,
    skeletonLoading,
    renderTopRight,
    onErrorRetry = () => {},
    error,
    rowSelection,
    expandable,
    Components,
    shouldTransformData,
    tableStyle,
    expandedRowKeys,
    tableLayout,
    stickyOffset,
    onRow = () => {},
    loadingComponentStyle,
    noTableBg = false,
    ...rest
  } = props;
  const [data, columns] = useTableData(
    rows,
    col,
    Components,
    shouldTransformData
  );

  const defaultPageSize = pagination?.pageOptions?.[0] || 20;
  const [page, setPage] = useState(1);
  const [pageSizeSave, setPageSize] = useState(defaultPageSize);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);
  let marginTopValue = "";
  if (rows.length === 25) {
    marginTopValue = "4vh";
  } else if (rows.length === 50) {
    marginTopValue = "19vh";
  }

  useEffect(() => {
    setPage(queryObj.page ? parseInt(queryObj.page) : 1);
    setPageSize(queryObj.per_page ? queryObj.per_page : defaultPageSize);
  }, [queryObj.page, queryObj.per_page]);

  const onHandleChange = (page, pageSize) => {
    if (noUrlPush) {
      setPage(page);
      setPageSize(pageSize);
      onChangePage(queryObj);
    } else {
      setSearchParams({
        ...queryObj,
        page: parseInt(page),
        per_page: pageSize,
      });
    }
  };

  const renderPagination = () => {
    return !!pagination?.totalCount ? (
      <Row justify="end" style={{ padding: "10px 16px 16px" }}>
        <Pagination
          onChange={onHandleChange}
          pageSizeOptions={pagination?.pageOptions}
          pageSize={pageSizeSave}
          defaultCurrent={page}
          current={page}
          total={pagination?.totalCount}
          showSizeChanger={!!pagination?.pageOptions}
        />
      </Row>
    ) : null;
  };

  const tablePagination = () => {
    if (
      !pagination &&
      showPagination &&
      data.length &&
      data.length > defaultPageSize
    ) {
      return true;
    }
    return false;
  };

  return (
    <Group gap="8px" template="minmax(0, auto)" className={props.mainClass}>
      {(renderTopRight || paginationOnTop) && (
        <Row
          justify={renderTopRight ? "space-between" : "end"}
          style={{ columnGap: 24 }}
          align="bottom"
        >
          {renderTopRight && renderTopRight()}
          {paginationOnTop && !error ? renderPagination() : null}
        </Row>
      )}
      <div>
        {!!error ? (
          <ComponentState
            onClick={onErrorRetry}
            btnLoading={loading}
            description={error?.message}
            status={error.status}
          />
        ) : (
          <Table
            className={cx(
              styles.tableHeader,
              props.tableClass,
              noTableBg && styles.noBg
            )}
            pagination={tablePagination()}
            dataSource={data || []}
            columns={
              (columns &&
                columns.map((item) => ({
                  ...item,
                  render: (component) => (
                    <div
                      style={{ minWidth: (item.title?.length || 0) + 2 + "ch" }}
                    >
                      {component}
                    </div>
                  ),
                }))) ||
              []
            }
            loading={skeletonLoading || loading}
            locale={{
              emptyText: skeletonLoading ? (
                "Loading..."
              ) : (
                <ComponentState
                  style={loadingComponentStyle}
                  type="empty"
                  title=""
                  description={loading ? "Loading..." : "No records found"}
                />
              ),
            }}
            rowSelection={rowSelection}
            expandable={expandable}
            style={{ ...tableStyle }}
            sticky={{ offsetHeader: stickyOffset }}
            expandedRowKeys={expandedRowKeys}
            tableLayout={tableLayout}
            onRow={onRow}
            rowClassName={props.rowClassName && props.rowClassName}
            {...rest}
          />
        )}
      </div>

      {paginationOnBottom && !error ? renderPagination() : null}
    </Group>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onChangePage: PropTypes.func,
  noUrlPush: PropTypes.bool,
  paginationOnTop: PropTypes.bool,
  paginationOnBottom: PropTypes.bool,
  tabs: PropTypes.bool,
  loading: PropTypes.bool,
  skeletonLoading: PropTypes.bool,
};

DataTable.defaultProps = {
  paginationOnTop: false,
  paginationOnBottom: true,
  tabs: true,
  mainClass: "",
  stickyOffset: 64,
};

export default DataTable;
