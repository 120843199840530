import { Layout } from "antd";
import { Button, Card, Group, Text, TextInput, Title } from "components/common";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { regex } from "constants/regex";

const PublicPresentation = () => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const user = useSelector((state) => state?.app?.user?.info);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);

  const validateEmail = () => {
    const valid = regex.emailRegex.test(email);
    if (!valid) {
      setError("Invalid Email");
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    if (queryObj?.presentationId && user?._id) {
      navigate(`/presentation/${queryObj?.presentationId}`);
    }
  }, [user, queryObj]);

  const goToPresentation = () => {
    validateEmail();
    if (!error && !!email) {
      localStorage.setItem("voteEmail", email);
      navigate(`/presentation/${queryObj?.presentationId}`);
    }
  };

  return (
    <Layout.Content className={styles.authLayout}>
      <Group
        gap="32px"
        style={{
          placeContent: isMobile ? "unset" : "center",
          minHeight: "100vh",
        }}
      >
        <Card
          style={{
            width: !isMobile
              ? false === "sign-up"
                ? "55vw"
                : "35vw"
              : "unset",
          }}
        >
          <Group
            style={{ padding: "40px" }}
            template="initial"
            justify="space-around"
            align=""
          >
            <Title level={2} align="center">
              Enter Your Email To Continue!
            </Title>
            <TextInput
              onChange={(e) => {
                setEmail(e?.target.value);
                // validateEmail();
              }}
              onBlur={validateEmail}
              placeholder="Enter Your Email"
              value={email}
              errorMsg={error}
            ></TextInput>
            <Button type="primary" onClick={goToPresentation}>
              View Presentation
            </Button>
            <Button
              type="link"
              onClick={() => {
                navigate(
                  `/login?redirectedPath=/presentation/${queryObj?.presentationId}`
                );
              }}
            >
              Login
            </Button>
          </Group>
        </Card>
      </Group>
    </Layout.Content>
  );
};

export default PublicPresentation;
