import { useCallback, useEffect, useMemo, useState } from "react"

import { DATE_FORMAT } from "constants"
import { DatePicker } from "antd"
import moment from "moment"

const { RangePicker } = DatePicker

const DateRangePicker = props => {
  const { value, onChange, noDefault, noOfDaysBefore = 0, limitRange = false, ...rest } = props
  const [date, setDate] = useState(
    !noDefault ? [moment().subtract(7 + noOfDaysBefore, "days"), moment().subtract(noOfDaysBefore, "days")] : undefined
  )

  useEffect(() => {
    setDate(value?.[0] && value?.[1] ? [moment(value[0]), moment(value[1])] : undefined)
  }, [value])

  const onChangeDate = (dates, dateStrings) => {
    setDate(dates)
  }

  const handleChangeDate = useCallback(() => {
    onChange(date)
  }, [date])

  const ranges = useMemo(() => {
    return {
      //   ...(!!noOfDaysBefore ? {} : { Today: [moment(), moment()] }),
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(7 + noOfDaysBefore, "days"), moment()],
      "Last 30 Days": [moment().subtract(30 + noOfDaysBefore, "days"), moment()],
      "Last 90 Days": [moment().subtract(90 + noOfDaysBefore, "days"), moment()],
      "Last 6 Months": [moment().subtract(6, "months"), moment()],
      "Last Year": [moment().subtract(12, "months"), moment()],
    }
  }, [])

  return (
    <div>
      <RangePicker
        allowClear={false}
        ranges={!limitRange && ranges}
        value={date}
        onChange={onChangeDate}
        format={DATE_FORMAT}
        onOpenChange={isOpened => {
          !isOpened && handleChangeDate()
        }}
        {...rest}
      />
    </div>
  )
}
export default DateRangePicker
