import S3 from 'react-aws-s3';

export const config = {
    bucketName :"illnessrx-prod",
    dirName :"",
    region:"us-east-2",
    accessKeyId:"AKIAXISJZ44RWI2RIXFS",
    secretAccessKey:"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"
}

export const ReactS3Client = new S3(config);
