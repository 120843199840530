import { Card } from "antd";
import { Button, notification, Title } from "components/common";
import {
  useEndQuizMutation,
  useGetQuizDetailsQuery,
} from "features/script/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setQuizData } from "store/quizSlice";
import theme from "utils/themeVars";
import QuestionItem from "./questionItem";
import QuizQuestionPagination from "./quizQuestionPagination";

const TakeQuiz = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);
  const quizDetails = useSelector((state) => state?.quiz?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const { quizId } = queryObj;
  const { data } = useGetQuizDetailsQuery({ quizId }, { skip: !quizId });

  const [handleEndQuiz, _] = useEndQuizMutation();

  const onQuizEnd = async () => {
    const res = await handleEndQuiz({
      quizId: quizId,
      questionList: quizDetails?.questionsList,
    });
    if (res) {
      if (res?.error) {
      } else {
        navigate(`/user/quiz/result?quizId=${quizId}`);
      }
    }
  };

  useEffect(() => {
    if (quizDetails?.questionsList?.length) {
      let allAnsweredOrSkipped = true;
      quizDetails?.questionsList?.forEach((question) => {
        if (!question.answer && !question?.skipped) {
          allAnsweredOrSkipped = false;
        }
      });
      if (allAnsweredOrSkipped) {
        notification.success({ message: "All answered" });
        onQuizEnd();
      }
    }
  }, [quizDetails]);

  useEffect(() => {
    if (data?.length) {
      dispatch(setQuizData(data?.[0]));
    }
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: theme["light-gray"],
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card
          style={{
            flex: 1,
            marginBottom: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ margin: "1%" }}>
            <Title level={1}>Quiz</Title>
            <QuizQuestionPagination
              disablePrevious={selectedQuestion == 0}
              quizDetails={quizDetails}
              onClickPrevious={() => {
                setSelectedQuestion((prev) => prev - 1);
              }}
              disableNext={
                selectedQuestion >= quizDetails?.questionsList?.length - 1 ||
                (!quizDetails?.questionsList?.[selectedQuestion]?.skipped &&
                  !quizDetails?.questionsList?.[selectedQuestion]?.answer)
              }
              onClickNext={() => setSelectedQuestion((prev) => prev + 1)}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
            />
            <div>
              <QuestionItem
                question={quizDetails?.questionsList?.[selectedQuestion]}
                setSelectedQuestion={setSelectedQuestion}
                selectedQuestion={selectedQuestion}
                quizDetails={quizDetails}
                scriptId={quizDetails?.scriptId}
              />
            </div>
          </div>
        </Card>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px",
          backgroundColor: theme["light-gray"],
        }}
      >
        <Button
          size="large"
          type="primary"
          text={"End Quiz"}
          onClick={onQuizEnd}
        />
      </div>
    </div>
  );
};

export default TakeQuiz;
