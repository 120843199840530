import ImageUpload from "../imageUpload/imageUpload";
import cx from "clsx";
import styles from "./index.module.less";
import React from "react";
import Lottie from "react-lottie";
import animationData from "animation/uploadFileAnimation";
import Text from "../text/text";
import { IconText } from "components/custom";
import theme from "utils/themeVars";

const DraggerView = () => {
  return (
    <div
      gap="24px"
      className="p-16"
      style={{
        "align-items": "center",
        display: "flex",
        maxWidth: "300px",
      }}
    >
      <div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          style={{ stroke: theme["primary-color"] }}
          height={56}
          width={56}
        />
      </div>
      <div>
        {/* <Row className="text-left" style={{ gap: 8 }}> */}
        <Text color={theme["primary-color"]} block>
          <span className="semiBold">
            {"Click or drag file to this area to upload"}
          </span>
        </Text>

        <IconText
          icon="BsInfo"
          title={"Must be in JPG, PNG or PDF file format up till 5 MB."}
          iconProps={{
            style: { color: "#707070" },
            hasBackground: true,
            size: "1.5em",
            iconContainerStyle: {
              marginTop: 4,
              padding: 0,
              "--icon-bg-color": "#E6E6E6",
            },
          }}
          style={{
            alignItems: "flex-start",
            "--typography-color": "#9D9D9D",
            fontSize: 12,
            gap: 8,
          }}
        />
      </div>
    </div>
  );
};
export default DraggerView;
