import React from "react";
import styles from "./index.module.less";
import { Button, Card } from "components/common";
import theme from "utils/themeVars";

const CircularArrow = ({ ref, subSlide, setSubSlide, totalSlides ,applyCustomClass }) => {
  return (
    <div className={`${styles.arrowSlider} ${applyCustomClass ? styles.adjustCrowser : ''}`}>
      <Button
        type="button-bordered"
        shape="circle"
        className="pi-8 pb-0"
        style={{ position: "absolute", left: "-15px", background:"white", color: theme["gray500"]}}
        iconProps={{ size: "1.4rem" }}
        icon="AiOutlineLeft"
        outlined
        disabled={subSlide == 0}
        onClick={() => setSubSlide((prev) => prev - 1)}
      />
      <div className={styles.contentSlider}>
        {subSlide + 1} / {totalSlides}
      </div>
      <Button
        type="button-bordered"
        shape="circle"
        className="pi-8 pb-0"
        style={{ position: "absolute", right: "-15px", background:"white", color:theme["gray500"] }}
        iconProps={{ size: "1.4rem" }}
        icon="AiOutlineRight"
        outlined
        disabled={subSlide + 1 == totalSlides}
        onClick={() => setSubSlide((prev) => prev + 1)}
      />
    </div>
  );
};

export default CircularArrow;
