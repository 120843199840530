import { Card, DataTable, Group, Tag, Title } from "components/common";
import {
  useGetActivityListQuery,
  useUpdateActivityStatusMutation,
} from "./api";
import theme from "utils/themeVars";
import { ActionButtons } from "components/custom";

const UserActivity = () => {
  const columns = [
    {
      title: "No.",
      dataIndex: "key",
      className: "tableStartSpace",
      width: 100,
    },
    {
      title: "text",
      dataIndex: "text",
      width: 350,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    { title: "Actions", dataIndex: "actions", width: 100 },
  ];

  const {
    data: activity,
    isLoading: loading,
    error,
    refetch,
    isFetching,
  } = useGetActivityListQuery();
  const [changeActivityStatus, _] = useUpdateActivityStatusMutation();

  const getActionsList = (e) => {
    return [
      {
        key: "close_activity",
        icon: "IoMdClose",
        color: theme["error-color"],
        content: "Close Activity",
        title: "Close Activity",
        className: "pointer",
        onClick: () => {
          changeActivityStatus({
            activity_id: e?.activity_id,
            status: "Closed",
          });
        },
      },
    ];
  };

  const tableData = () => {
    return activity?.length
      ? activity?.map((e, index) => ({
          key: index + 1,
          ...e,
          created_at: (
            <div style={{ display: "flex", gap: "8px" }}>
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }).format(new Date(e?.created_at))}
            </div>
          ),
          status: (
            <Tag
              color={
                e.status == "Created"
                  ? theme["primary-color"]
                  : e?.status == "updated"
                  ? theme["primary-light"]
                  : theme["error-color"]
              }
              style={{ borderRadius: "40px" }}
            >
              {e.status}
            </Tag>
          ),
          actions: <ActionButtons list={getActionsList(e)} />,
        }))
      : [];
  };
  return (
    <Group gap="16px" className={"listings-content-adjustments"}>
      <Card>
        <Title>Activity</Title>
      </Card>
      <DataTable
        pagination={{
          totalCount: activity?.pagination?.totalCount,
          pageOptions: [10, 25, 50],
        }}
        paginationOnBottom={true}
        onChangePage={(queryObj) => {}}
        columns={columns}
        data={tableData()}
        loading={loading}
        error={error}
        stickyOffset={0}
        onErrorRetry={refetch}
        skeletonLoading={isFetching}
      />
    </Group>
  );
};

export default UserActivity;
