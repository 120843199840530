import { Icon, Modal, Title } from "components/common";
import { useState } from "react";
import theme from "utils/themeVars";
import PresentationDetails from "./presentationDetails";
import { useRef, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SlidePreview from "./slidePreview";
import update from "immutability-helper";
import { Space } from "antd";
import RenderSlide from "./renderSlide";

const PresentationSlides = ({
  slides,
  setSlides,
  mainSlide,
  setMainSlide,
  setSubSlide,
  handleClick,
  basicInfo,
  setBasicInfo,
  isCompleted,
  canUserModifyPresentation,
  isEditable = true,
}) => {
  const modalRef = useRef();
  const deleteRef = useRef();
  const [selectedSlideforDelete, setSelectedSlideorDelete] = useState({
    id: null,
    index: null,
  });

  const deleteSlide = () => {
    const { id, index } = selectedSlideforDelete;
    setSubSlide(0);
    const tempSlides = JSON.parse(JSON.stringify(slides));
    let newSlides = tempSlides.filter((sli) => sli.id !== id);
    setSlides(newSlides);
  };

  const moveSlide = useCallback((dragIndex, hoverIndex) => {
    setSlides((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderSlide = useCallback(
    (slide, index) => {
      if (isEditable == true) {
        return (
          <SlidePreview
            key={slide.id}
            id={slide.id}
            slide={slide}
            index={index}
            mainSlide={mainSlide}
            onClick={() => {
              setMainSlide(index);
              setSubSlide(0);
            }}
            moveSlide={moveSlide}
            deleteSlide={(id, index) => {
              setSelectedSlideorDelete({ id: id, index: index });
              deleteRef.current.setVisible(true);
            }}
          />
        );
      } else {
        return (
          <RenderSlide
            handlerId={null}
            mainSlide={mainSlide}
            slide={slide}
            onClick={() => {
              setMainSlide(index);
              setSubSlide(0);
              handleClick(index);
            }}
            index={index}
            isEditable={false}
            isCompleted={isCompleted}
            canUserModifyPresentation={canUserModifyPresentation}
          />
        );
      }
    },
    [slides, mainSlide]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          flexGrow: 1,
          // overflowY: "scroll",
        }}
      >
        {isEditable == true ? (
          <DndProvider backend={HTML5Backend}>
            {slides?.length
              ? slides.map((e, index) => (
                  <div id={`main-slide-${index}`}>{renderSlide(e, index)}</div>
                ))
              : ""}
          </DndProvider>
        ) : (
          <>
            {slides?.length
              ? slides.map((e, index) => (
                  <div id={`main-slide-${index}`}>{renderSlide(e, index)} </div>
                ))
              : ""}
          </>
        )}
      </div>
      <Modal
        ref={deleteRef}
        onOk={() => {
          deleteSlide();

          deleteRef.current.setVisible(false);
        }}
        onCancel={() => deleteRef.current.setVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
        title={`Delete Slide`}
      >
        Are you sure you want to delete this slide?
      </Modal>
    </div>
  );
};

export default PresentationSlides;
