import { v4 as uuidv4 } from "uuid";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getUniqueId = (ids) => {
  let id = uuidv4();
  while (ids.includes(id)) {
    id = uuidv4();
  }
  return id;
};

export const pollMainSlide = {
  title: "",
  backgroundColor: "white",
  data: [
    {
      type: "poll",
      data: {
        status: "created",
        question: "",
        placeholder: "Enter question...",
        options: [
          {
            placeholder: "Add your option here",
            description: null,
            isAnswer: false,
          },
          {
            placeholder: "Add your option here",
            description: null,
            isAnswer: false,
          },
        ],
      },
    },
  ],
};

export const imageMainSlide = {
  backgroundColor: "white",
  title: "",
  data: [
    {
      title: "",
      type: "image",
      data: {
        images: [],
      },
    },
  ],
};

export const schemaMainSlide = {
  title: "",
  backgroundColor: "white",
  data: [
    {
      title: "",
      type: "schema",
      data: {
        nodes: [],
        edges: [],
      },
    },
  ],
};

export const simpleMainSlide = {
  backgroundColor: "white",
  title: "",
  data: [
    {
      title: "",
      type: "slide",
      placeholder: "Start adding content to your slide...",
      data: "<p></p>",
    },
  ],
};

export const pollSubSlide = {
  type: "poll",
  data: {
    status: "created",
    question: "",
    placeholder: "Start adding content to your slide...",
    options: [
      {
        placeholder: "Add your option here",
        description: null,
        isAnswer: false,
      },
      {
        placeholder: "Add your option here",
        description: null,
        isAnswer: false,
      },
    ],
  },
};

export const caseSubSlide = {
  type: "slide",
  data: `<h2><strong>Title</strong></h2><h3>Add Description</h3>`,
};

export const caseSlide = {
  title: "",
  type: "slide",
  backgroundColor: "white",
  data: [
    `<h2><strong>Hpi</strong></h2><h3>Add Description</h3><p><br></p><h2><strong>Vitals</strong></h2><h3>Add Description</h3><p><br></p><h2><strong>Exams</strong></h2><h3>Add Description</h3><p><br></p><h2><strong>Labs</strong></h2><h3>Add Description</h3><p><br></p>`,
  ],
};

export const imageSubSlide = {
  type: "image",
  data: "",
};

export const schemaSubSlide = {
  type: "schema",
  data: {
    nodes: [],
    edges: [],
  },
};

export const imageSlide = {
  title: "",
  type: "image",
  data: [""],
};

export const schemaSlide = {
  type: "schema",
  data: ``,
};

export const simpleSlide = {
  backgroundColor: "white",
  title: "",
  type: "slide",
  data: ``,
  placeholder: "Simple Slide",
};

export const canUserModify = (user, user_id) => {
  return user?.user_role == "ADMIN" || user?.user_code == user_id;
};

export const isUserLoggedIn = () => {
  const accessToken = cookies.get("illness_access_token");
  return accessToken != undefined && accessToken != null && accessToken != "";
};
