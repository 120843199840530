import { convertQueryObjToString } from "utils";
import {
  allLibrariesTransformer,
  scriptById,
  getLastThreeTemplates,
} from "./transformers";

const { default: parentApi } = require("store/parentApi");

const libraryApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getAllScripts: build.query({
      query: (data) => {
        return {
          url: `/scripts/search?${convertQueryObjToString(data?.filters)}`,
          method: "POST",
          body: data?.body,
        };
      },
      transformResponse: (response) => {
        return allLibrariesTransformer(response?.data);
      },
      providesTags: ["allScripts"],
    }),
    getScriptTypesList: build.query({
      query: (filters) => {
        return {
          url: `/scripts/templates?${convertQueryObjToString(filters)}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return response?.data?.length ? response?.data : [];
      },
    }),
    getScriptTypeDetails: build.query({
      query: (body) => {
        return {
          url: `/scripts/template/detail`,
          method: "POST",
          body: body,
        };
      },
    }),
    getScriptTopics: build.query({
      query: (filters) => {
        return {
          url: `/user/get/PageTopics?${convertQueryObjToString(filters)}`,
          method: "GET",
        };
      },
    }),
    getSearchListingScriptTopics: build.query({
      query: (params) => {
        return {
          url: `/scripts/pageTopics?${convertQueryObjToString(params)}`,
          method: "GET",
        };
      },
    }),
    getScriptsSpecialties: build.query({
      query: (filters) => {
        return {
          url: `/predata/specialities?${convertQueryObjToString(filters)}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return response?.data?.length
          ? response.data.map((e) => ({
              ...e,
              id: e?._id,
              title: e?.topic,
              value: e?._id,
            }))
          : [];
      },
    }),
    getScriptDetail: build.query({
      query: (body) => {
        return {
          url: `scripts/detail`,
          method: "POST",
          body: body,
        };
      },
      providesTags: ["scriptDetail"],
      transformResponse: (response) => {
        return scriptById(response?.data);
      },
    }),
    getTemplateHistory: build.query({
      query: (body) => {
        return {
          url: `scripts/history`,
          method: "POST",
          body: body,
        };
      },
      transformResponse: (response) => {
        return getLastThreeTemplates(response?.data);
      },
    }),
    addScript: build.mutation({
      query: (body) => {
        return {
          url: `/scripts/add`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["allScripts"],
    }),
    getAllLibraries: build.query({
      query: (filters) => {
        return {
          url: `/scripts?${convertQueryObjToString(filters)}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return allLibrariesTransformer(response?.data);
      },
      providesTags: ["allLibraries"],
    }),
    getLibraryDetails: build.query({
      query: (id) => {
        return {
          url: `/template/${id}`,
          method: "GET",
        };
      },
      transformResponse: (response) => response?.data,
      providesTags: ["libraryDetails"],
    }),
    getPlatforms: build.query({
      query: () => {
        return {
          url: `/predata/platforms`,
          method: "GET",
        };
      },
    }),
    getTopics: build.query({
      query: (common) => {
        return {
          url: `/predata/topics?common=${common}`,
          method: "GET",
        };
      },
    }),
    getSpecialities: build.query({
      query: () => {
        return {
          url: `/predata/specialities`,
          method: "GET",
        };
      },
    }),
    deleteScript: build.mutation({
      query: (selectedRow) => {
        return {
          url: `/scripts/delete/${selectedRow}`,
          method: "DELETE",
        };
      },
      transformResponse: (response) => response?.data.data,
      invalidatesTags: ["allScripts"],
    }),
    getPageIds: build.query({
      query: () => {
        return {
          url: `/scripts/allIDs`,
          method: "POST",
        };
      },
    }),
    getAllEditedPage: build.query({
      query: (data) => {
        return {
          url: `/scripts?${convertQueryObjToString(data?.filters)}`,
          method: "POST",
          body: {
            ...data?.body,
          },
        };
      },
      transformResponse: (response) => {
        return allLibrariesTransformer(response?.data);
      },
      providesTags: ["editedPages"],
    }),
    getAllHiddenPages: build.query({
      query: (data) => {
        return {
          url: `/user/hidden/pages?${convertQueryObjToString(data?.filters)}`,
          method: "POST",
          body: {
            ...data?.body,
          },
        };
      },
      transformResponse: (response) => {
        return allLibrariesTransformer(response?.data);
      },
      providesTags: ["hiddenPages"],
    }),
    restoreTemplate: build.mutation({
      query: (params) => {
        return {
          url: `/scripts/restore/${params.editor_template_id}`,
          body: params,
          method: "POST",
        };
      },
    }),
    changeHiddenStatus: build.mutation({
      query: (params) => {
        return {
          url: `/user/change/hidden/status`,
          body: params,
          method: "POST",
        };
      },
      invalidatesTags: ["hiddenPages", "scriptDetail", "checkIsPageHidden"],
    }),
    getScriptHiddenStatus: build.mutation({
      query: (params) => {
        return {
          url: `/user/get/hidden/script/status`,
          body: params,
          method: "POST",
        };
      },
      providesTags: ["checkIsPageHidden"],
    }),

    updateScript: build.mutation({
      query: (body) => {
        return {
          url: `/scripts/update/${body?.editor_template_id}`,
          body: body,
          method: "PUT",
        };
      },
      invalidatesTags: ["scriptDetail"],
    }),
    getNotes: build.mutation({
      query: (body) => {
        return {
          url: `/user/get/script/notes`,
          body: body,
          method: "POST",
        };
      },
    }),
    addEditNote: build.mutation({
      query: (body) => {
        return {
          url: `/user/add/script/notes/`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["scriptDetail"],
    }),
    getPageTopics: build.query({
      query: (page_name) => {
        return {
          url: `/scripts/getMatchedItems?page_name=${page_name}`,
          method: "GET",
        };
      },
      providesTags: ["allScripts"],
    }),
    markScriptAsFavorite: build.mutation({
      query: (body) => {
        return {
          url: `/user/markFavorite`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["allScripts"],
    }),
    getAllFeedback: build.query({
      query: () => {
        return {
          url: `/user/list/feedback?limit=10&offset=0`,
          method: "POST",
        };
      },
      transformResponse: (response) => {
        return response?.data?.data?.length
          ? { list: response?.data?.data, pagination: {} }
          : [];
      },
    }),
    addFeedback: build.mutation({
      query: (body) => {
        return {
          url: `/add/feedback`,
          body: body,
          method: "POST",
        };
      },
    }),
    addQuestion: build.mutation({
      query: (body) => {
        return {
          url: `/question/questions`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["allQuestions"],
    }),
    updateQuestions: build.mutation({
      query: (body) => {
        return {
          url: `/question/updateQuestionsList`,
          body: body,
          method: "POST",
        };
      },
      // invalidatesTags: ["allQuestions"],
    }),
    getAllQuestionsOfScript: build.query({
      query: (body) => {
        return {
          url: `/question/getQuestionsByScriptId`,
          method: "POST",
          body: body,
        };
      },
      providesTags: ["allQuestions"],
    }),
    startQuiz: build.mutation({
      query: (body) => {
        return {
          url: `/quiz/start`,
          method: "POST",
          body: body,
        };
      },
    }),
    questionAnswer: build.mutation({
      query: (body) => {
        return {
          url: `/question/getAnswer`,
          method: "POST",
          body: body,
        };
      },
    }),
    getQuizDetails: build.query({
      query: (body) => {
        return {
          url: `/quiz/getQuiz/${body?.quizId}`,
        };
      },
      providesTags: ["quizDetails"],
    }),
    getAllQuizes: build.query({
      query: (body) => {
        return {
          url: `/quiz/getAllQuiz/`,
          body: body,
          method: "POST",
        };
      },
      providesTags: ["quizDetails"],
    }),
    endQuiz: build.mutation({
      query: (body) => {
        return {
          url: `/quiz/endQuiz`,
          method: "POST",
          body: body,
        };
      },
    }),
    updateQuestionStats: build.mutation({
      query: (body) => {
        return {
          url: `/questionOptions/updateQuestionOptions`,
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetAllLibrariesQuery,
  useGetScriptTypesListQuery,
  useGetScriptTypeDetailsQuery,
  useGetAllScriptsQuery,
  useGetLibraryDetailsQuery,
  useGetPlatformsQuery,
  useGetTopicsQuery,
  useGetSpecialitiesQuery,
  useGetScriptTopicsQuery,
  useGetScriptsSpecialtiesQuery,
  useGetScriptDetailQuery,
  useGetTemplateHistoryQuery,
  useDeleteScriptMutation,
  useAddScriptMutation,
  useGetPageIdsQuery,
  useGetAllEditedPageQuery,
  useGetAllHiddenPagesQuery,
  useRestoreTemplateMutation,
  useGetSearchListingScriptTopicsQuery,
  useUpdateScriptMutation,
  useAddEditNoteMutation,
  useGetNotesMutation,
  useChangeHiddenStatusMutation,
  useGetScriptHiddenStatusMutation,
  useGetPageTopicsQuery,
  useLazyGetPageTopicsQuery,
  useMarkScriptAsFavoriteMutation,
  useGetAllFeedbackQuery,
  useAddFeedbackMutation,
  useAddQuestionMutation,
  useGetAllQuestionsOfScriptQuery,
  useLazyGetAllQuestionsOfScriptQuery,
  useUpdateQuestionsMutation,
  useStartQuizMutation,
  useQuestionAnswerMutation,
  useGetQuizDetailsQuery,
  useEndQuizMutation,
  useUpdateQuestionStatsMutation,
  useGetAllQuizesQuery,
} = libraryApis;
