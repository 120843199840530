import { useRef, useState, useMemo, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import CommonReactQuill from "./CommonReactQuill";
import PopoverModal from "./PopoverModal";

const TextEditor = ({
  key,
  value,
  onChange,
  placeholder,
  className,
  onBlur = () => {},
  editorHeight = 200,
  inVh = false,
  ...rest
}) => {
  const quillRef = useRef();
  const [text, setText] = useState(null);
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const [toolbarHeight, setToolBarHeight] = useState(false);

  const customLink = () => {
    const quill = quillRef.current.getEditor();
    var range = quill.getSelection();
    if (range && range.length !== 0) {
      var text = quill.getText(range.index, range.length);
      var link = quill.getContents(range.index, range.length);
      setText({ text: text, link: link?.ops?.[0]?.attributes?.link });
      setVisible(true);
    }
  };

  const customToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    [{ font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    [{ direction: "rtl" }],
    ["formula"],
    ["clean"],
    [{ align: [] }],
    [{ background: [] }, { color: [] }],
    [{ script: "sub" }, { script: "super" }],
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: customToolbar,
        handlers: {
          link: customLink,
        },
      },
    }),
    []
  );

  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
    "color",
    "background",
    "script",
    "direction",
    "formula",
  ];

  useEffect(() => {
    let toolbar = document.querySelector(".ql-toolbar")?.scrollHeight || 0;
    setToolBarHeight(isToolbarVisible ? toolbar : 0);
  }, [isToolbarVisible, editorHeight]);

  return (
    <>
      <PopoverModal
        visible={!!text?.text}
        setVisible={setText}
        text={text?.text}
        link={text?.link}
        setText={setText}
        quillRef={quillRef}
      />
      <CommonReactQuill
        quillRef={quillRef}
        placeholder={placeholder}
        onChange={onChange}
        modules={modules}
        formats={formats}
        value={value}
        className={className}
        key={key}
        isToolbarVisible={isToolbarVisible}
        setIsToolbarVisible={setIsToolbarVisible}
        baseHeight={inVh ? `${editorHeight}vh` : `${editorHeight}px`}
        {...rest}
      />
      <div style={{ height: toolbarHeight }}></div>
    </>
  );
};

export default TextEditor;
