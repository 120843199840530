import ReactGA from "react-ga4";
import { inDevelopMode } from "utils/env";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useInitializeApp = () => {
  const user = useSelector((state) => state.app?.user?.info);
  const store = useSelector((state) => state);

  const isWindows = () => {
    return navigator.platform.indexOf("Win") > -1;
  };

  // useEffect(() => {
  //   if (true) {
  //     console.log("===app");
  //     document.body.classList.add("hide-scrollbars");
  //   }
  // }, []);
  useEffect(() => {
    // !inDevelopMode &&
    //   ReactGA.gtag("set", "user_properties", {
    //     user_id: user?.id,
    //     user_email: user?.email,
    //     user_designation: user?.UserDesignation?.name,
    //     user_team: user?.team,
    //     user_region: user?.City?.name,
    //   })
  }, []);
};
