import {
  Button,
  Card,
  DataTable,
  Dropdown,
  Group,
  Icon,
  Modal,
  Tag,
  TextInput,
  Title,
  notification,
} from "components/common";
import { Spinner } from "components/common";
import { ActionButtons, Filters } from "components/custom";
import { EDIT_SCRIPT_PATH, TEMPLATE_DETAIL_PATH } from "constants/app-paths";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter, lowerCaseFirstLetter } from "utils";
import theme from "utils/themeVars";
import { useResetPresentationMutation } from "../../../presentation/apis";
import { useLocation } from "react-router-dom";
import styles from "../index.module.less";
import { Input, Pagination, Row } from "antd";
import PrimaryButton from "components/common/button/primary-button";
import {
  useDeleteScriptMutation,
  useGetAllScriptsQuery,
  useGetPlatformsQuery,
  useGetScriptTypesListQuery,
  useGetSearchListingScriptTopicsQuery,
  useGetSpecialitiesQuery,
  useGetTopicsQuery,
} from "features/script/api";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { canUserModify } from "../../../presentation/utils";
import BackButtonSvg from "svg/BackButton";
import { ScriptFilterLogo } from "components/svg";
import { ScriptLogo } from "components/svg";
import { STUDY_ICON } from "components/svg";

const libraryTableColumns = [
  {
    title: "No.",
    dataIndex: "key",
    className: "tableStartSpace",
    width: 150,
  },
  {
    title: "Page Name",
    dataIndex: "name",
    width: 300,
  },
  {
    title: "Page ID",
    dataIndex: "pageId",
    width: 200,
  },
  { title: "Speciality", dataIndex: "speciality", width: 200 },
  { title: "Actions", dataIndex: "actions", width: 100 },
];

const multipleModes = {
  page_name: false,
  type: true,
  topics: true,
  speciality: true,
  pageTopics: true,
  is_advance_search: false,
  page: false,
  per_page: false,
};

function processObject(inputObj) {
  // Filter out keys with empty or undefined values
  const filteredObj = Object.fromEntries(
    Object.entries(inputObj).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  // Convert comma-separated values to arrays
  const processedObj = Object.fromEntries(
    Object.entries(filteredObj).map(([key, value]) => {
      return [key, multipleModes?.[key] ? value.split(",") : value];
    })
  );

  return processedObj;
}

const MobileScriptListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);
  const [isMobile, setIsMobile] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [showAdvanceFilters, setAdvanceFilters] = useState(false);
  const [filterValue, setFilterValue] = useState(queryObj);
  let { data, isFetching, isLoading, error, refetch } = useGetAllScriptsQuery(
    {
      body: {
        ...processObject(queryObj),
        is_advance_search: !!showAdvanceFilters,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (data?.list?.length) {
      setDataLoading(false);
    }
  }, [data]);

  const { data: options } = useGetScriptTypesListQuery();

  const { data: platforms } = useGetPlatformsQuery();
  const { data: sections } = useGetTopicsQuery(false);
  const { data: pageTopics } = useGetSearchListingScriptTopicsQuery(true);
  const { data: specialties } = useGetSpecialitiesQuery();

  useEffect(() => {
    setIsMobile(window.innerWidth <= 700);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  const [page, setPage] = useState(1);
  const [pageSizeSave, setPageSize] = useState(10);

  useEffect(() => {
    setPage(queryObj.page ? parseInt(queryObj.page) : 1);
    setPageSize(queryObj.per_page ? queryObj.per_page : 10);
  }, [queryObj.page, queryObj.per_page]);

  const modelRef = useRef();
  const [action, setAction] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteScript, deleteScriptResponse] = useDeleteScriptMutation();
  const [resetPresentation, resetPresentationResponse] =
    useResetPresentationMutation();

  const navigate = useNavigate();
  const getActionsList = (e) => [
    {
      key: "edit_presentation",
      icon: "MdOutlineEdit",
      color: canUserModify(user, e?.editor_id)
        ? theme["blue200"]
        : theme["gray500"],
      content: "Edit Template",
      title: "Edit Template",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      className: "pointer",
      onClick: () => {
        navigate(
          `${EDIT_SCRIPT_PATH.replace(":name", e?.page_name)}?editorId=${
            e?.editor_template_id
          }&version=${e?.version}`
        );
      },
    },
    {
      key: "delete_presentation",
      icon: "MdDeleteForever",
      color: canUserModify(user, e?.editor_id)
        ? theme["red"]
        : theme["gray500"],
      content: "Delete Script",
      title: "Delete Script",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      onClick: () => {
        setAction("Delete");
        setSelectedRow(e._id);
        modelRef.current.setVisible(true);
      },
    },
  ];

  const getItems = useCallback(() => {
    return options?.length
      ? options?.map((e) => ({
          label: e?.name,
          key: e?._id,
          onClick: () => {
            navigate(`/scripts/add?template_id=${e?.base_template_id}`);
          },
        }))
      : [];
  }, [options]);

  const user = useSelector((state) => state.app.user?.info);

  const changeAdvanceFilterStatus = (value = false) => {
    setSearchParams({});
    setFilterValue({});
    setAdvanceFilters(value);
  };

  const clearAllFilters = (obj) => {
    if (Object.keys(obj).length) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (value?.length > 0) {
            return true;
          }
        }
      }
      return false;
    }
    return false;
  };

  const advancedFilterList = useMemo(() => {
    return [
      {
        label: "Platforms",
        list: platforms?.data.map((plat) => ({
          label: plat,
          value: lowerCaseFirstLetter(plat),
        })),
        type: "select",
        key: "type",
        mode: "multiple",
        placeholder: "Select platforms",
      },
      {
        label: "Sections",
        list: sections?.data.map((plat) => ({
          label: plat.topic,
          value: plat.topic,
        })),
        type: "select",
        mode: "multiple",
        key: "topics",
        placeholder: "Select sections",
      },
      {
        label: "Specialties",
        mode: "multiple",
        list: specialties?.data.map((plat) => ({
          label: plat.topic,
          value: plat.topic,
        })),
        type: "select",
        key: "speciality",
        placeholder: "Select specialties",
      },
      {
        label: "Topics",
        list: pageTopics?.data.map((plat) => ({
          label: plat.PageTopics,
          value: plat.PageTopics,
        })),
        type: "select",
        mode: "multiple",
        key: "pageTopics",
        placeholder: "Select topics",
      },
    ];
  }, [platforms, sections, specialties, pageTopics]);

  const onHandleChange = (page, pageSize) => {
    setSearchParams({
      ...queryObj,
      page: parseInt(page),
      per_page: pageSize,
    });
  };

  const renderPresentationWeb = () => {
    return (
      <>
        <Card>
          <div
            style={{
              padding: "1%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "93vw",
              }}
            >
              <div>
                <Title level={2}>Script Library</Title>
              </div>
              <div>
                <Dropdown
                  options={getItems()}
                  trigger="click"
                  arrow={false}
                  suffixIcon=""
                  style={{
                    color: theme["blue200"],
                    background: theme["light-gray"],
                    fontWeight: 800,
                    fontSize: "1.2rem",
                    padding: "12px, 12px, 13px, 12px",
                    borderRadius: "4px",
                    borderColor: theme["blue100"],
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "1vh",
                      marginRight: "2vw",
                    }}
                  >
                    <Button
                      text="Add Script"
                      type="secondary"
                      size={"larger"}
                      className="pi-12"
                      style={{
                        height: 48,
                        boxShadow: "none",
                        color: "white",
                        width: "30vw",
                        background: "#1565DC",
                      }}
                      loading={false}
                    >
                      <ScriptLogo />
                      Add Script
                    </Button>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              paddingTop: "4%",
            }}
          >
            {showAdvanceFilters ? (
              <TextInput
                label="Search by Keyword"
                labelProps={{}}
                placeholder="Search by Keyword"
                onChange={(e) =>
                  setFilterValue((prev) => ({
                    ...prev,
                    page_name: e?.target?.value,
                  }))
                }
                value={filterValue?.page_name}
              />
            ) : (
              <TextInput
                labelProps={{}}
                placeholder="Search By Page"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearchParams(filterValue);
                    setDataLoading(true);
                  }
                }}
                onChange={(e) => {
                  setFilterValue((prev) => ({
                    ...prev,
                    page_name: e?.target?.value,
                  }));
                }}
                value={filterValue?.page_name}
              />
            )}
            <div
              style={{
                position: "absolute",
                right: "23vw",
                zIndex: "5",
                top: "12.9vh",
              }}
            >
              <ScriptFilterLogo />
            </div>
            <Button
              text="Search"
              type="secondary"
              size={"larger"}
              className="pi-12"
              style={{
                height: 48,
                boxShadow: "none",
                color: "#1565DC",
                border: "1px solid #1565DC",
                width: "30vw",
              }}
              loading={false}
              onClick={() => {
                setSearchParams(
                  clearAllFilters(filterValue) ? filterValue : {}
                );
              }}
            />
            <Button
              onClick={() => changeAdvanceFilterStatus(true)}
              text="Filters"
              type="secondary"
              size={"larger"}
              className="pi-12"
              style={{
                height: 48,
                boxShadow: "none",
                color: "#1565DC",
                border: "1px solid #1565DC",
                width: "30vw",
              }}
              loading={false}
            />
          </div>
          {showAdvanceFilters && (
            <div style={{ paddingTop: "2%" }}>
              <Filters
                list={advancedFilterList || []}
                loading={false}
                skeletonLoading={false}
                onFiltersApply={(e) => {
                  setSearchParams((prev) => ({
                    ...prev,
                    ...filterValue,
                    ...e,
                  }));
                }}
              />
            </div>
          )}
        </Card>

        {dataLoading ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            <Card style={{ background: "#F5F6F8" }}>
              <span style={{ color: "#8A8A8A", weight: "500", size: "12px" }}>
                Total No Of Scripts :
              </span>{" "}
              <span style={{ color: "#8A8A8A", weight: "700", size: "12px" }}>
                {data?.list?.length}
              </span>
            </Card>

            {data?.list?.map((item) => (
              <>
                <Card
                  onClick={() =>
                    navigate(
                      `${TEMPLATE_DETAIL_PATH.replace(
                        ":name",
                        item?.page_name
                      )}?editorId=${item?.editor_template_id}&version=${
                        item?.version
                      }`
                    )
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Title level={4}>{item?.page_name}</Title>
                    <Dropdown
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      options={[
                        {
                          key: 1,
                          label: (
                            <div
                              style={{ display: "flex", gap: "10px" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                navigate(
                                  `${EDIT_SCRIPT_PATH.replace(
                                    ":name",
                                    item?.page_name
                                  )}?editorId=${
                                    item?.editor_template_id
                                  }&version=${item?.version}`
                                );
                              }}
                            >
                              <Icon
                                icon="FiEdit"
                                size="14px"
                                color={theme["primary-color"]}
                              />
                              <span>Edit</span>
                            </div>
                          ),
                        },
                        // {
                        //   key: 2,
                        //   label: (
                        //     <div style={{ display: "flex", gap: "10px" }}>
                        //       <STUDY_ICON />
                        //       <span>Add To Study Session</span>
                        //     </div>
                        //   ),
                        // },
                        {
                          key: 3,
                          label: (
                            <div
                              style={{ display: "flex", gap: "10px" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setAction("Delete");
                                setSelectedRow(item?._id);
                                modelRef.current.setVisible(true);
                              }}
                            >
                              <Icon
                                icon="RiDeleteBin5Line"
                                size="14px"
                                color="red"
                              />
                              <span>Delete</span>
                            </div>
                          ),
                        },
                      ]}
                      suffixIcon=""
                      trigger={"click"}
                    >
                      <Button
                        size="small"
                        style={{ background: "transparent" }}
                        icon={"BsThreeDotsVertical"}
                      />
                    </Dropdown>
                  </div>
                  <span style={{ color: "#8A8A8A", fontWeight: "400" }}>
                    Page ID:
                  </span>{" "}
                  <span style={{ color: "#707070", fontWeight: "700" }}>
                    {item?.page_id}
                  </span>
                  <br />
                  <span style={{ color: "#8A8A8A", fontWeight: "400" }}>
                    Speciality:
                  </span>{" "}
                  <span style={{ color: "#707070", fontWeight: "700" }}>
                    {item?.speciality?.join(",")}
                  </span>
                </Card>
              </>
            ))}
          </>
        )}
      </>
    );
  };

  const renderAdvanceFilters = () => {
    return (
      <>
        <Card>
          <div
            style={{
              display: "flex",
            }}
          >
            <span
              style={{ marginTop: "0.7vh", marginRight: "1vw" }}
              onClick={() => changeAdvanceFilterStatus(false)}
            >
              <BackButtonSvg />
            </span>
            <Title>Filters</Title>
          </div>
        </Card>
        <Card>
          <TextInput
            labelProps={{}}
            label="By Page"
            placeholder="Search Any Keyword"
            onChange={(e) =>
              setFilterValue((prev) => ({
                ...prev,
                page_name: e?.target?.value,
              }))
            }
            value={filterValue?.page_name}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              paddingTop: "2%",
            }}
          >
            {/* <TextInput
                label="Search by Keyword"
                labelProps={{}}
                placeholder="Search by Keyword"
                onChange={(e) =>
                  setFilterValue((prev) => ({
                    ...prev,
                    page_name: e?.target?.value,
                  }))
                }
                value={filterValue?.page_name}
              /> */}
          </div>

          <div style={{ paddingTop: "2%" }}>
            <Filters
              isScriptMobileView={true}
              list={advancedFilterList || []}
              loading={false}
              skeletonLoading={false}
              onFiltersApply={(e) => {
                setAdvanceFilters(false);
                setDataLoading(true);
                setSearchParams((prev) => ({
                  ...prev,
                  ...filterValue,
                  ...e,
                }));
              }}
            />
          </div>
        </Card>
      </>
    );
  };

  return (
    <>
      {showAdvanceFilters ? renderAdvanceFilters() : renderPresentationWeb()}
      <Modal
        ref={modelRef}
        onOk={() => {
          if (!action) {
            return;
          }
          if (action == "Delete") {
            if (selectedRow) {
              deleteScript(selectedRow)
                .unwrap()
                .then(() => {
                  notification.success({
                    message: "Script has been removed!",
                  });
                })
                .catch((e) => {
                  notification.error({
                    message: e?.message,
                  });
                });
            }
          } else if (action == "Reset") {
            resetPresentation(selectedRow)
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Presentation has been reset!",
                });
              })
              .catch((e) => {
                notification.error({
                  message: e?.message,
                });
              });
          }
          modelRef.current.setVisible(false);
          setAction(null);
          setSelectedRow(null);
          refetch();
        }}
        onCancel={() => modelRef.current.setVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
        title={`${action} Template`}
      >
        Are you sure you want to {action} this template?
      </Modal>
    </>
  );
};

export default MobileScriptListing;
