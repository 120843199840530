export const getTemplateSettingPayload = (data, isUpdate = false) => {
  const section2 = data?.explanations?.map((e) => {
    const { title = "", explanation = "", media = [] } = e;
    const images = media?.length
      ? media?.filter((e) => e?.type == "image" || e?.type == "Image")
      : [];
    const licenseImage = media?.length
      ? media?.filter((e) => e?.type == "licenseImage")
      : [];
    const licenseVideo = media?.length
      ? media?.filter((e) => e?.type == "licenseVideo")
      : [];
    const licensePodcast = media?.length
      ? media?.filter((e) => e?.type == "licensePodcast")
      : [];
    const pay = {
      title,
      explanation,
      media: images?.length
        ? images?.map((it) => {
            return {
              imageDescription: it?.imageDescription,
              imageTitle: it?.imageTitle,
              type: it?.type,
              url: it?.url,
            };
          })
        : [],
      LicensedImage: licenseImage?.length
        ? licenseImage?.map((it) => {
            return {
              ImageURL: it?.ImageURL,
              siteURL: it?.siteURL,
              imageTitle: it?.imageTitle,
            };
          })
        : [],
      YTIframe: licenseVideo?.length
        ? licenseVideo?.map((it) => {
            return {
              VideoIframe: it?.ImageURL,
              siteURL: it?.siteURL,
              videoTitle: it?.imageTitle,
            };
          })
        : [],
      LicensedPodcast: licensePodcast?.length
        ? licensePodcast?.map((it) => {
            return {
              PodcastURL: it?.ImageURL,
              siteURL: it?.siteURL,
              podcastTitle: it?.imageTitle,
            };
          })
        : [],
    };
    return pay;
  });
  return {
    name: data?.name,
    type: TemplateTypes?.find((e) => e?.label == data?.primary_specialty)?.slug,
    ...(isUpdate ? { base_template_id: data?.templateId } : {}),
    section_1: data?.presentationSection?.titleDescriptions?.map((e) => ({
      title: e?.title,
      description: e?.description,
    })),
    media: data?.presentationSection?.media.map((it) => {
      return {
        imageURL: it.url,
        type: it.type,
        url: it.url,
        imageDescription: it?.description,
      };
    }),
    section_2: section2,
  };
};

export const TemplateTypes = [
  { label: "Medicine", slug: "medicine" },
  { label: "Pharmacology", slug: "pharmacology" },
  { label: "Anatomy", slug: "anatomy" },
];
