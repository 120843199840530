import {
  Button,
  Card,
  DataTable,
  Dropdown,
  Group,
  Icon,
  Switch,
  Tag,
  Title,
  notification,
} from "components/common";
import { ActionButtons, Filters } from "components/custom";
import { EDIT_SCRIPT_PATH, TEMPLATE_DETAIL_PATH } from "constants/app-paths";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import theme from "utils/themeVars";

import { Input, Pagination, Row } from "antd";
import PrimaryButton from "components/common/button/primary-button";
import {
  useChangeHiddenStatusMutation,
  useGetAllEditedPageQuery,
  useGetAllHiddenPagesQuery,
  useGetScriptTypesListQuery,
} from "features/script/api";
import { capitalizeFirstLetter } from "utils";
import { canUserModify } from "../../../presentation/utils";
import { ScriptLogo } from "components/svg";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
const HiddenPages = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [sortOrder, setSortOrder] = useState({ key: "", order: "asc" });

  const getSortIcon = (key) => {
    return sortOrder.key === key && sortOrder.order === "asc" ? (
      <CaretUpFilled />
    ) : (
      <CaretDownFilled />
    );
  };

  const handleSort = (key) => {
    let order = "asc";
    if (sortOrder.key === key && sortOrder.order === "asc") {
      order = "dsc";
    }
    setSortOrder({ key, order });

    refetch();
  };
  const hiddenPagesColumns = [
    {
      title: "No.",
      dataIndex: "key",
      className: "tableStartSpace",
      width: 50,
    },
    {
      title: (
        <span onClick={() => handleSort("page_name")}>
          Page Name {getSortIcon("page_name")}
        </span>
      ),
      dataIndex: "name",
      width: 350,
    },
    {
      title: (
        <span onClick={() => handleSort("page_id")}>
          Page ID {getSortIcon("page_id")}
        </span>
      ),
      dataIndex: "pageId",
      width: 60,
    },
    {
      title: "Published At",
      dataIndex: "publishDate",
      width: 90,
    },
    { title: "Hide/Unhide", dataIndex: "actions", width: 60 },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const [changeHiddenStatus, { isLoading: resetLoading }] =
    useChangeHiddenStatusMutation();
  let queryObj = Object.fromEntries([...searchParams]);
  const { isMobile } = useSelector((state) => state?.app);
  const navigate = useNavigate();

  const [filterValue, setFilterValue] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  
  const [page, setPage] = useState(1);
  const [pageSizeSave, setPageSize] = useState(10);

 
  const { data, isFetching, isLoading, error, refetch } =
    useGetAllHiddenPagesQuery(
      {
        filters: {
          page: queryObj?.page || 0,
          per_page: queryObj?.per_page || 10,
        },
        body: queryObj?.page_name
          ? {
              page_name: queryObj.page_name,
              sortBy: sortOrder.key,
              sortOrder: sortOrder.order,
              per_page: pageSizeSave ? pageSizeSave : 10,
              page: page ? page : 1

            }
          : {
              sortBy: sortOrder.key,
              sortOrder: sortOrder.order,
              per_page: pageSizeSave ? pageSizeSave : 10,
              page: page ? page : 1
            },
      },
      { refetchOnMountOrArgChange: true }
    );

  const { data: options, isLoading: optionsLoading } =
    useGetScriptTypesListQuery();


  useEffect(() => {
    setPage(queryObj.page ? parseInt(queryObj.page) : 1);
    setPageSize(queryObj.per_page ? queryObj.per_page : 10);
  }, [queryObj.page, queryObj.per_page]);

  const filterList = useMemo(() => {
    return [
      {
        label: "none",
        type: "input",
        key: "page_name",
        placeholder: "Enter page name",
      },
    ];
  }, []);

  const getItems = useCallback(() => {
    return options?.length
      ? options?.map((e) => ({
          label: e?.name,
          key: e?._id,
          onClick: () => {
            navigate(`/scripts/add?template_id=${e?.base_template_id}`);
          },
        }))
      : [];
  }, [options]);

  const user = useSelector((state) => state.app.user?.info);

  const tableData = () => {
    return data?.list?.length
      ? data.list.map((e, index) => ({
          ...e,
          key: index + 1,
          name: (
            <div
              style={{ color: theme["primary-color"] }}
              className="pointer"
              onClick={() =>
                navigate(
                  `${TEMPLATE_DETAIL_PATH.replace(
                    ":name",
                    e?.page_name
                  )}?editorId=${e?.editor_template_id}&version=${e?.version}`
                )
              }
            >
              {e?.page_name}
            </div>
          ),
          publishDate: (
            <p>
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }).format(new Date(e.updated_at))}
            </p>
          ),
          pageId: <div>{e?.page_id}</div>,

          actions: (
            <>
              <Switch
                onChange={() => {
                  changeHiddenStatus({
                    editor_template_id: e.editor_template_id,
                    is_hidden: false,
                  })
                    .then(() => {
                      notification.success({
                        message: "Page successfully unhidden.",
                      });
                    })
                    .catch((e) => {
                      notification.error({
                        message: e?.message,
                      });
                    });
                }}
                defaultChecked
              />
            </>
          ),
        }))
      : [];
  };

  const onHandleChange = (page, pageSize) => {
    setSearchParams({
      ...queryObj,
      page: parseInt(page),
      per_page: pageSize,
    });
  };

  const renderPresentationWeb = () => {
    return (
      <Group gap="16px" className={"listings-content-adjustments"}>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0.5% 0 1% 0",
            }}
          >
            <Title level={1}>Hidden Scripts</Title>
            <Dropdown
              options={getItems()}
              trigger="click"
              arrow={false}
              suffixIcon=""
              style={{
                color: theme["blue200"],
                background: theme["light-gray"],
                fontWeight: 800,
                fontSize: "1.2rem",
                padding: "12px, 12px, 13px, 12px",
                borderRadius: "4px",
                borderColor: theme["blue100"],
              }}
            >
              <div style={{ marginRight: "0.5vw" }}>
                <PrimaryButton>
                  <ScriptLogo color="1565DC" />
                  Add
                  {/* <Icon size="1.3rem" icon="IoMdArrowDropdown" /> */}
                </PrimaryButton>
              </div>
            </Dropdown>
          </div>
          <Filters
            list={filterList || []}
            loading={false}
            skeletonLoading={false}
            showClearAll={false}
          />
        </Card>

        <DataTable
          pagination={{
            totalCount: data?.pagination?.totalCount,
            pageOptions: [10, 25, 50],
          }}
          paginationOnBottom={true}
          onChangePage={(queryObj) => setSearchParams(queryObj)}
          columns={hiddenPagesColumns}
          data={tableData()}
          loading={isLoading}
          error={error}
          onErrorRetry={refetch}
          skeletonLoading={isFetching}
          stickyOffset={0}
          noUrlPush={false}
        />
      </Group>
    );
  };

  const renderPresentationMob = () => {
    return (
      <>
        <Card style={{ marginBottom: "4%" }}>
          <Title level={2}>Hidden Scripts</Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Input
              label="Search by Name"
              placeholder="Search by Name"
              onChange={(e) => setFilterValue(e?.target?.value)}
              value={filterValue}
            />
            <Button
              onClick={() => {
                setSearchParams({ name: filterValue });
              }}
              icon={"FiSearch"}
              type="primary"
              size={"large"}
              className="pi-12"
              style={{ height: 48, boxShadow: "none" }}
              loading={isFetching}
            />
          </div>
        </Card>
        {data?.list?.length
          ? data?.list.map((item) => (
              <Card
                onClick={() => {
                  navigate(
                    `${TEMPLATE_DETAIL_PATH.replace(
                      ":name",
                      item?.page_name
                    )}?editorId=${item?.editor_template_id}&version=${
                      item?.version
                    }`
                  );
                  // navigate(PRESENTATION_DETAIL_PATH.replace(":id", item._id));
                }}
                style={{ marginBottom: "20px" }}
                className="pointer"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Title level={3}>{capitalizeFirstLetter(item?.name)}</Title>
                  <div>
                    <div style={{ display: "flex" }}>
                      <Tag color="#60A7DB" style={{ borderRadius: "40px" }}>
                        {item.is_completed ? "Completed" : "Not Completed"}
                      </Tag>
                      <Dropdown
                        options={[
                          {
                            key: 1,
                            label: (
                              <div style={{ display: "flex", gap: "10px" }}>
                                <Icon
                                  icon="MdOutlineEdit"
                                  size="14px"
                                  color={theme["primary-color"]}
                                />
                                <span>Edit</span>
                              </div>
                            ),
                            onClick: (e) => {
                              e?.domEvent?.stopPropagation();
                              navigate(
                                `${EDIT_SCRIPT_PATH.replace(
                                  ":name",
                                  item?.page_name
                                )}?editorId=${
                                  item?.editor_template_id
                                }&version=${item?.version}`
                              );
                            },
                          },
                        ]}
                        suffixIcon=""
                        trigger={"click"}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Button
                          size="small"
                          style={{ background: "transparent" }}
                          icon={"BsThreeDotsVertical"}
                        />
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                  className={"gray-800"}
                >
                  PAGE ID: {item?.page_id}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                  className={"gray-800"}
                >
                  <div>
                    <Icon icon="BiCalendar" size="14px" />
                  </div>
                  <div>{`Published at: ${new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(item.updated_at))}`}</div>
                </div>
              </Card>
            ))
          : null}
        <Row justify="end" style={{ padding: "10px 16px 16px" }}>
          <Pagination
            onChange={onHandleChange}
            pageSizeOptions={[10, 25, 50]}
            pageSize={pageSizeSave}
            defaultCurrent={page}
            current={page}
            total={data?.pagination?.totalCount}
            showSizeChanger
          />
        </Row>
      </>
    );
  };

  return <>{isMobile ? renderPresentationMob() : renderPresentationWeb()}</>;
};

export default HiddenPages;
