import { Card, DataTable, Group, Modal, Title } from "components/common";
import { TEMPLATE_DETAIL_PATH } from "constants/app-paths";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import theme from "utils/themeVars";

import { useGetAllFeedbackQuery } from "features/script/api";

const ScriptFeedback = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  // Get the element by its class name
  const element = document.querySelector(".ant-table-header");

  // Check if the element exists before modifying its style
  if (element) {
    // Change its CSS properties
    element.style.overflow = "unset"; // Example of changing the overflow property
  }

  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);

  const [selectedRow, setSelectedRow] = useState(null);

  const { data, isFetching, isLoading, error, refetch } =
    useGetAllFeedbackQuery({}, { refetchOnMountOrArgChange: true });

  const libraryTableColumns = [
    {
      title: "No.",
      dataIndex: "key",
      className: "tableStartSpace",
      width: 70,
    },
    {
      title: "Page Name",
      dataIndex: "name",
    },
    {
      title: "Page ID",
      dataIndex: "pageId",
      width: 100,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 350,
    },
    {
      title: "User Name",
      dataIndex: "username",
    },
    {
      title: "Date",
      dataIndex: "created_date",
    },
  ];

  const navigate = useNavigate();

  const tableData = () => {
    return data?.list?.length
      ? data.list.map((e, index) => ({
          key: index + 1,
          pagename: e?.pagename,
          page_id: e?.page_id,
          name: (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div
                style={{ color: theme["primary-color"] }}
                className="pointer"
                onClick={() =>
                  navigate(
                    `${TEMPLATE_DETAIL_PATH.replace(
                      ":name",
                      e?.pagename
                    )}?editorId=${e?.editor_template_id}&version=${e?.version}`
                  )
                }
              >
                {e?.pagename}
              </div>
            </div>
          ),
          title: e?.title,
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  e?.description?.length > 30
                    ? `${e?.description?.slice(1, 100)}...`
                    : e?.description,
              }}
            ></div>
          ),
          completeDescription: e?.description,
          username: e?.username,
          pageId: <div>{e?.page_id}</div>,
          created_date: (
            <div style={{ display: "flex", gap: "8px" }}>
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }).format(new Date(e?.created_at))}
            </div>
          ),
        }))
      : [];
  };

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>Script Feedback</Title>
      </div>
    );
  };

  const renderPresentationWeb = () => {
    return (
      <Group gap="16px" className={"listings-content-adjustments"}>
        <Card>{renderPageHeader()}</Card>
        <DataTable
          pagination={{
            totalCount: data?.pagination?.totalCount || 10,
            pageOptions: [10, 25, 50],
          }}
          paginationOnBottom={true}
          onChangePage={(queryObj) => setSearchParams(queryObj)}
          columns={libraryTableColumns}
          data={tableData()}
          loading={isLoading}
          error={error}
          onErrorRetry={refetch}
          skeletonLoading={isFetching}
          stickyOffset={0}
          noUrlPush={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setSelectedRow(record);
              },
            };
          }}
          className="pointer"
        />
        <Modal
          visible={selectedRow}
          setVisible={setSelectedRow}
          footer={null}
          onCancel={() => {
            setSelectedRow(null);
          }}
          title={"Feedback Details"}
        >
          <Title level={3}>{selectedRow?.title}</Title>
          <div
            dangerouslySetInnerHTML={{
              __html: selectedRow?.completeDescription,
            }}
          ></div>
        </Modal>
      </Group>
    );
  };

  return <>{renderPresentationWeb()}</>;
};

export default ScriptFeedback;
