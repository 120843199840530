import { Spinner } from "components/common";
import { LOGIN_PATH } from "constants/app-paths";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetPresentationWithIntervalQuery } from "../apis";
import { getUniqueId, isUserLoggedIn } from "../utils";
import styles from "./index.module.less";
import PresentationDetailPageComponent from "./presentationDetailPageComponent";
import MobilePresentationDetailPageComponent from "./mobilePresentationDetailPageComponent";

const PresentationDetails = () => {
  const params = useParams();
  const {
    data: presentation,
    isLoading,
    refetch,
  } = useGetPresentationWithIntervalQuery(params.id, {
    pollingInterval: 15000,
    refetchOnMountOrArgChange: true,
  });

  const location = useLocation();
  // checking is the page is opened on mobile
  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [basicInfo, setBasicInfo] = useState({
    name: "",
    topics: [],
    specialities: [],
  });

  useEffect(() => {
    localStorage.setItem("presentation_url", window.location.pathname);
    setIsMobile(window.innerWidth <= 700);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [slides, setSlides] = useState(
    presentation?.data
      ? presentation?.data?.slides
      : [
          {
            id: getUniqueId([]),
            title: "",
            backgroundColor: "white",
            data: [
              {
                type: "slide",
                id: getUniqueId([]),
                placeholder: "Start adding content to your slide...",
                data: null,
              },
            ],
          },
        ]
  );

  useEffect(() => {
    if (presentation && presentation?.data?.slides) {
      setSlides(presentation?.data?.slides);
      setBasicInfo({
        name: presentation?.data?.name ?? "",
        topics: presentation?.data?.topics ?? [],
        specialities: presentation?.data?.speciality ?? [],
      });
    } else {
      setSlides([
        {
          id: getUniqueId([]),
          title: "",
          backgroundColor: "white",
          data: [
            {
              type: "slide",
              id: getUniqueId([]),
              placeholder: "Start adding content to your slide...",
              data: null,
            },
          ],
        },
      ]);
    }
  }, [presentation]);

  return (
    <div
      className={isMobile ? styles.mobilePageContainer : styles.pageContainer}
    >
      {isLoading ? (
        <div className={styles.pageContainer}>
          <Spinner />
        </div>
      ) : isMobile ? (
        <MobilePresentationDetailPageComponent
          basicInfo={basicInfo}
          presentationName={presentation?.data?.name || ""}
          userId={presentation?.editor_id}
          slides={slides}
          setSlides={setSlides}
          id={params.id}
          isCompleted={presentation?.data?.is_completed}
          refetch={refetch}
        />
      ) : (
        <PresentationDetailPageComponent
          basicInfo={basicInfo}
          presentationName={presentation?.data?.name || ""}
          userId={presentation?.editor_id}
          slides={slides}
          setSlides={setSlides}
          id={params.id}
          isCompleted={presentation?.data?.is_completed}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default PresentationDetails;
