import { Input } from "antd";
import { Card, SelectInput, Title } from "components/common";
import {
  useGetPageIdsQuery,
  useGetScriptsSpecialtiesQuery,
} from "features/script/api";
import {
  createPageId,
  getSpecialityWithIDInitials,
} from "features/script/api/utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBasicScriptData } from "store/scriptSlice";

const ScriptBasicInfo = ({
  data,
  editMode,
  isMobile,
  handleChange,
  showSecondarySpecialty = true,
  showTopics = true,
  showTemplateType = true,
  predefinedTypes = null,
}) => {
  const { data: specialties } = useGetScriptsSpecialtiesQuery();
  const { data: pageIds } = useGetPageIdsQuery();

  useEffect(() => {
    if (!editMode && data?.primary_specialty && pageIds?.length) {
      const pageId = createPageId(pageIds?.[0]?.id, data?.primary_specialty);
      handleChange({ pageId: pageId });
    }
  }, [data?.primary_specialty, pageIds]);

  return (
    <Card style={{ marginBottom: "10px", padding: "1%" }}>
      {showTemplateType && (
        <div style={{ display: "flex", gap: "2%", overflow: "scroll" }}>
          <div style={{ fontSize: "16px", color: "gray" }}>
            {"Template Type:"}
          </div>
          <Title level={3}>{data?.templateName}</Title>
        </div>
      )}
      <div style={{ padding: "1%" }}>
        <Input
          value={data?.name}
          onChange={(e) => handleChange({ name: e?.target?.value })}
          placeholder="Name"
        />
      </div>
      <div
        style={
          isMobile
            ? { padding: "1%" }
            : {
                display: "flex",
                gap: "1%",
                justifyContent: "space-between",
                padding: "1%",
              }
        }
      >
        <SelectInput
          placeholder={predefinedTypes ? "Type" : "Primary Specialty"}
          options={
            predefinedTypes
              ? predefinedTypes
              : specialties?.map((e) => e?.title)
          }
          style={{
            width: isMobile ? "100%" : showSecondarySpecialty ? "23vw" : "74vw",
            paddingBottom: isMobile ? "2%" : "0",
          }}
          value={data?.primary_specialty}
          getOptionValue={(e) => e}
          getOptionLabel={(e) => e}
          onChange={(value, option) => {
            handleChange({
              primary_specialty: value,
            });
          }}
        />
        {showSecondarySpecialty && (
          <SelectInput
            placeholder="Secondary Specialty"
            options={specialties
              ?.filter((it) => it?.title != data?.primary_specialty)
              ?.map((e) => e?.title)}
            style={{
              width: isMobile ? "100%" : "23vw",
              paddingBottom: isMobile ? "2%" : "0",
            }}
            getOptionValue={(e) => e}
            getOptionLabel={(e) => e}
            onChange={(val) => {
              handleChange({
                secondary_specialty: val,
              });
            }}
            value={data?.secondary_specialty}
            allowClear
            showSearch
            mode="multiple"
          />
        )}
        {showTopics && (
          <SelectInput
            placeholder="Topics"
            options={data?.topics?.map((e) => e?.title)}
            style={{
              width: isMobile ? "100%" : "23vw",
              paddingBottom: isMobile ? "2%" : "0",
            }}
            getOptionValue={(e) => e}
            getOptionLabel={(e) => e}
            onChange={(val) => {
              handleChange({
                topics: val,
              });
            }}
            value={data?.topics}
            mode="tags"
          />
        )}
      </div>
    </Card>
  );
};

export default ScriptBasicInfo;
