import { Icon, Skeleton, Text, Tooltip } from "../../common"

import PropTypes from "prop-types"
import { Space } from "antd"

const IconText = props => {
  const { className, children, icon, iconProps, title, gap, textType, loading, loadingProps, message, ...rest } = props

  const renderComponent = () => (
    <Space.Compact
      className={className}
      size={gap}
      style={{ gap: gap, flexDirection: iconProps.placement === "end" ? "row-reverse" : "", ...props.style }}
      {...rest}
    >
      {icon &&
        (loading ? (
          <Skeleton type="avatar" size={loadingProps.avatarSize || "small"} />
        ) : (
          <Icon
            icon={icon}
            size={iconProps.size}
            color={iconProps.color}
            hasBackground={iconProps.hasBackground}
            {...iconProps}
          />
        ))}
      {loading ? (
        <Skeleton type="button" size={loadingProps.rectSize || "small"} />
      ) : (
        <Text type={textType}>{children ? children : title}</Text>
      )}
    </Space.Compact>
  )

  return message ? (
    <Tooltip placement="topLeft" title={message}>
      {renderComponent()}
    </Tooltip>
  ) : (
    renderComponent()
  )
}

IconText.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textColor: PropTypes.string,
  loading: PropTypes.bool,
  loadingProps: PropTypes.object,
  lead: PropTypes.bool,
}

IconText.defaultProps = {
  icon: null,
  iconProps: { size: "1em" },
  loadingProps: { avatarSize: "small", rectSize: "small" },
  title: "title",
  gap: 8,
}

export default IconText
