import {
  Button,
  Divider,
  Group,
  Icon,
  SelectInput,
  SimpleDateFilter,
  Switch,
  Tag,
  TextInput,
  Title,
  TreeSelectInput,
  UnitSelect,
} from "components/common";
import { Col, Collapse, Drawer, Form, Row, Skeleton, Space } from "antd";
import { FilterTag, RegionFilter } from "..";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { addQueryFilterArray } from "utils";
import cx from "clsx";
import styles from "./index.module.less";
import useFilters from "hooks/useFilters";

const Filters = forwardRef(
  (
    {
      list: filterList,
      loading,
      ignoreFilterKeys,
      skeletonLoading,
      isScriptMobileView,
      children,
      onSaveSearch,
      isDrawerView,
      renderTopRight,
      onFilterValueChange,
      onFiltersApply,
      onFilterClear,
      haveDependentRequests,
      searchText,
      onClickMoreFilters,
      saveSearchButtonLabel = "Save Search",
      showSearchButton = true,
      isFetching,
      mapPreviousFilters,
      showClearAll = true,
      isSingleFilter = true,
    },
    ref
  ) => {
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const {
      filterTags,
      filtersObject,
      setFiltersObject,
      setTagsAndFilters,
      isLoading,
    } = useFilters(
      filterList,
      ignoreFilterKeys,
      isFetching,
      haveDependentRequests ? [filterList.length] : [filterList],
      mapPreviousFilters
    );
    /**
     * This function is to check weather any advanced filter is selected
     * or not , if any of the advance filter is selected show clear all else hide it
     * @param {selected Filters information} obj
     * @returns weather any filter is selcted from advance filters or not
     */
    const isFilterSelected = (obj) => {
      if (Object.keys(obj).length) {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            if (value?.length > 0) {
              return true;
            }
          }
        }
        return false;
      }
      return false;
    };

    const INITIAL_FILTERS_TO_SHOW = 4;
    const SPLIT_BY = ",";

    const onSearch = (object, search, appliedParams) => {
      setTagsAndFilters(object, search, appliedParams);
      if (search) {
        onFiltersApply(object);
      }
    };

    useImperativeHandle(ref, () => ({ onSearch, setFiltersObject }), [
      filterList,
    ]);

    const handleFilterReset = () => {
      onSearch({}, true);
      showMoreFilters && isDrawerView && setShowMoreFilters(false);
    };

    const onTagClose = (item) => {
      const obj = { ...filtersObject };
      delete obj[item.searchKey];
      const dependents = item.filterItem?.dependents;
      dependents?.forEach((key) => {
        delete obj[key];
      });
      onFilterValueChange(
        item,
        obj[item.searchKey],
        item.searchKey,
        filtersObject
      );
      onSearch(obj, true);
    };

    const onValueSelect = (item, filterObject, searchKey, getValueKey) => {
      const value = getValueKey(item);
      addQueryFilterArray(filterObject, searchKey, value, SPLIT_BY, (obj) => {
        obj[searchKey] = value;
        onSearch(obj, false);
      });
    };

    const onMultiValueSelect = (
      items,
      filterObject,
      searchKey,
      getValueKey
    ) => {
      const obj = { ...filterObject };
      const values = !!items.length ? items.map((e) => getValueKey(e)) : [];
      obj[searchKey] = values.join(SPLIT_BY);
      onSearch(obj, false);
    };

    const handleChangeTreeSelect = (items, filterObject, searchKey) => {
      const obj = { ...filterObject };
      obj[searchKey] = items.join(SPLIT_BY);
      onSearch(obj);
    };

    const onTextInputChange = (
      event,
      filtersObj,
      searchKey,
      applyFilter = false
    ) => {
      const value = event.target.value;
      let obj = { ...filtersObj };
      obj[searchKey] = value;
      onSearch(obj, applyFilter);
    };

    const onLocationSelect = (level, selectedOption, filterItem) => {
      let obj = { ...filtersObject };
      filterItem.keyList.forEach((item, index) => {
        let key = filterItem.keyList[index].value;
        let value = selectedOption?.value || "";
        if (index === level) {
          obj[key] = value;
          onFilterValueChange(item, selectedOption, key, filtersObject);
        }
      });
      onSearch(obj, false);
    };

    const onUnitSelect = (
      searchKey,
      value,
      keyList,
      applyFilter = false,
      isStacked
    ) => {
      let obj = { ...filtersObject };
      obj[searchKey] = value;
      !isStacked &&
        keyList.forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            obj[key] = "";
          }
        });
      onSearch(obj, applyFilter);
    };

    // const reArrangeList = (item, option, key) => {
    //   const selectedLabels = option.map((i) => i.label);
    //   const selectedItems = item.list.filter((i) => selectedLabels.includes(i.label));

    //   // Remove items from a that are present in b
    //   const remainingItems = item.list.filter((i) => !selectedLabels.includes(i.label));

    //   // Rearrange a so that b items appear at the top
    //   const rearrangedList= [...selectedItems, ...remainingItems];
    //   return rearrangedList;
    // };

    const onChangeMoreFilters = () =>
      setShowMoreFilters((showMoreFilters) => !showMoreFilters);

    const getFilterType = (item) => {
      let {
        type,
        label,
        labelProps,
        placeholder,
        key,
        list,
        keyList,
        defaultValue,
        onValuesConvert,
        getValueKey = (item) => item?.value || item?.id,
        showInSingleTag,
        inputType,
        limit,
        iconColor,
        allowInput,
        level,
        getTagContent,
        nestedOptions,
        content_type,
        isStacked,
        appliedFilter,
        mapFilterObject,
        ...rest
      } = item;
      switch (type) {
        case "select":
          return (
            <SelectInput
              key={key}
              className="selectSearch"
              label={label}
              value={
                rest.mode === "multiple"
                  ? filtersObject[key]
                    ? filtersObject[key].split(SPLIT_BY)
                    : []
                  : filtersObject[key]
              }
              onChange={(_val, option) => {
                const filterObj = mapFilterObject
                  ? mapFilterObject(filtersObject)
                  : filtersObject;
                if (rest.mode === "multiple") {
                  onMultiValueSelect(option, filterObj, key, getValueKey);
                  onFilterValueChange(item, option, key, filtersObject);
                } else {
                  onValueSelect(
                    option,
                    filterObj,
                    key,
                    getValueKey,
                    filtersObject
                  );
                  onFilterValueChange(item, option, key, filtersObject);
                }
              }}
              options={list}
              defaultValue={defaultValue}
              placeholder={placeholder}
              openIcon="MdKeyboardArrowUp"
              labelProps={labelProps}
              {...rest}
            />
          );
        case "treeSelect":
          return (
            <TreeSelectInput
              className="selectSearch"
              label={label}
              value={
                filtersObject[key] ? filtersObject[key].split(SPLIT_BY) : []
              }
              options={list}
              handleOnChange={(it) => {
                handleChangeTreeSelect(it, filtersObject, key);
                onFilterValueChange(
                  item,
                  it.join(SPLIT_BY),
                  key,
                  filtersObject
                );
              }}
              placeholder={placeholder}
              {...rest}
            />
          );
        case "unitSelect":
          return (
            <UnitSelect
              label={label}
              options={keyList}
              defaultOption={keyList.find((e) => filtersObject[e.value])}
              value={filtersObject}
              handleChange={(key, value, applyFilter = false) => {
                onUnitSelect(
                  key,
                  value,
                  keyList.map((e) => e.value).filter((e) => e !== key),
                  applyFilter,
                  item.isStacked
                );
                onFilterValueChange(item, value, key, filtersObject);
              }}
              placeholder={placeholder}
              inputClass="selectSearch"
              componentType={item.componentType}
              splitBy={SPLIT_BY}
            />
          );
        case "region":
          return (
            <RegionFilter
              className={"selectSearch"}
              level={level}
              valueList={keyList.map((e) => filtersObject[e.value] || null)}
              onChange={(level, option) => {
                onLocationSelect(level, option, item);
              }}
              colSpan={isDrawerView ? 24 : 6}
              {...rest}
            />
          );
        case "date":
          return (
            <SimpleDateFilter
              label={label}
              value={filtersObject[key]}
              onSelect={(date, dateString) => {
                const obj = { ...filtersObject, [key]: dateString };
                onSearch(obj);
              }}
              onClear={() => {
                delete filtersObject[key];
                onSearch(filtersObject);
              }}
              {...rest}
            />
          );
        case "input":
          return (
            <TextInput
              value={filtersObject[key] || ""}
              label={label}
              placeholder={placeholder}
              type={inputType}
              limit={limit}
              useInternalState
              handleBlur={(e) => {
                onTextInputChange(e, filtersObject, key);
                onFilterValueChange(item, e.target.value, key, filtersObject);
              }}
              allowInput={allowInput}
              suffixIcon="search"
              labelProps={labelProps}
              skeletonLoading={skeletonLoading}
              onPressEnter={(e) => {
                onTextInputChange(e, filtersObject, key, true);
              }}
            />
          );
        case "toggle":
          return (
            <Switch
              className="selectSearch"
              label={label}
              value={filtersObject[key]}
              size="default"
              onChange={(checked) => {
                let obj = { ...filtersObject };
                obj[key] = checked;
                onFilterValueChange(item, checked, key, filtersObject);
                onSearch(obj);
              }}
              {...rest}
            />
          );
        default:
          return null;
      }
    };

    const searchButton = (
      text = isScriptMobileView ? "Apply Filter" : "Search",
      size = "large",
      icon = "FiSearch"
    ) => {
      return (
        <Button
          onClick={() => {
            onSearch(filtersObject, true);
            showMoreFilters && isDrawerView && setShowMoreFilters(false);
          }}
          icon={icon}
          type="primary"
          size={size}
          text={text}
          className="pi-12"
          style={{
            height: 48,
            boxShadow: "none",
            width: isScriptMobileView ? "48vw" : "unset",
          }}
          loading={loading || isFetching}
        />
      );
    };

    const renderTag = (tag) => (
      <Tag
        closable
        onClose={() => onTagClose(tag)}
        key={`${tag.searchKey}-${tag.tagValue}`}
        color="#fff"
      >
        {tag.title}
      </Tag>
    );

    const renderClearAll = () => (
      <Button
        type="link"
        danger
        variant="skeleton"
        onClick={handleFilterReset}
        disabled={loading}
        text={isScriptMobileView ? "Reset" : "Clear All"}
        style={{
          height: isScriptMobileView ? 48 : 32,
          width: isScriptMobileView ? "48vw" : "unset",
          color: isScriptMobileView ? "#767676" : "red",
          fontWeight: isScriptMobileView ? "700" : "unset",
        }}
      />
    );

    const renderTags = () => {
      const tagList = filterTags?.list
        ? filterTags.list.map((e) => ({
            ...e,
            title: (
              <FilterTag
                label={e.label}
                value={e.tagValue}
                className={e.className}
              />
            ),
          }))
        : [];
      return (
        !!filterTags?.list?.length && (
          <div className="mbe-16">
            <Space size={12} align="start">
              <Icon
                icon="RiFilter2Fill"
                color="#9D9D9D"
                size={18}
                style={{ height: 32 }}
              />
              <div className={styles.appliedTags}>
                {tagList.map(renderTag)}
                <span>
                  {onSaveSearch && (
                    <Button
                      type="primary-light"
                      onClick={() => onSaveSearch(filtersObject)}
                      style={{ height: 32 }}
                    >
                      <Icon icon="RiBookmarkFill" size="14px" />{" "}
                      {saveSearchButtonLabel}
                    </Button>
                  )}
                </span>
              </div>
            </Space>
          </div>
        )
      );
    };

    const renderInitialFilters = () => {
      return (
        <Row
          gutter={8}
          className={
            isScriptMobileView ? styles.scriptFilterMain : styles.filterMain
          }
        >
          {filterList
            ?.filter((item) => !item.hidden)
            ?.map((item, index) =>
              index <= INITIAL_FILTERS_TO_SHOW - 1 ? (
                <Col
                  style={{
                    ...(isSingleFilter && {
                      width: !isScriptMobileView
                        ? `${100 / filterList.length}%`
                        : "unset",
                    }),
                  }}
                  span={isSingleFilter ? 40 : 6}
                  key={item.key}
                >
                  <Form.Item key={item.key}>{getFilterType(item)}</Form.Item>
                </Col>
              ) : null
            )}

          {filterList.length < INITIAL_FILTERS_TO_SHOW && showSearchButton && (
            <Col span={6} style={{ maxWidth: "fit-content" }}>
              <div style={{ marginBlockStart: 23 }}>
                {searchButton(searchText)}
              </div>
            </Col>
          )}
        </Row>
      );
    };

    const renderItemWithHeading = (title, child) => (
      <>
        <Title style={{ paddingTop: 12 }} level={5}>
          {title}
        </Title>
        <Divider className="m-0 mbe-16" />
        {child}
      </>
    );

    const renderFilterItem = (item, index) =>
      item.type === "location" || item.type === "region" ? (
        getFilterType(item)
      ) : (
        <Col span={isDrawerView ? 24 : 6} key={`${item.key}-${index}`}>
          <Form.Item className={item?.containerClassName} key={item.key}>
            {getFilterType(item)}
          </Form.Item>
        </Col>
      );

    const renderRestFilters = () =>
      filterList
        ?.filter((item) => !item.hidden)
        ?.map((item, index) =>
          index > INITIAL_FILTERS_TO_SHOW - 1
            ? item.heading
              ? renderItemWithHeading(
                  item.heading,
                  renderFilterItem(item, index)
                )
              : renderFilterItem(item, index)
            : null
        );

    const renderDrawerView = () => {
      return (
        filterList.length > INITIAL_FILTERS_TO_SHOW && (
          <Row gutter={8}>
            <Drawer
              title={
                <>
                  <Title level={3} className="mbe-0">
                    Filters
                  </Title>
                  <span className="gray-700 fs12">
                    Apply filters to organize data accordingly
                  </span>
                </>
              }
              closable={false}
              placement="right"
              width={450}
              onClose={onChangeMoreFilters}
              open={showMoreFilters}
              className={styles.filterMain}
              extra={<Icon icon="IoMdClose" onClick={onChangeMoreFilters} />}
              footer={
                <Row justify="end">
                  <Space>
                    <Button
                      className="pi-12"
                      style={{ "--btn-bg": "#9d9d9d" }}
                      onClick={handleFilterReset}
                      bordered
                    >
                      Reset Filters
                    </Button>
                    {searchButton("Apply Filter", "default")}
                  </Space>
                </Row>
              }
            >
              {renderRestFilters()}
            </Drawer>
          </Row>
        )
      );
    };

    const renderCollapseView = () => {
      return (
        filterList.length > INITIAL_FILTERS_TO_SHOW && (
          <Collapse
            ghost
            expandIcon={() => (
              <Row justify="center">
                <Button
                  type="link"
                  variant="skeleton"
                  onClick={onChangeMoreFilters}
                >
                  <span className="semiBold">
                    {showMoreFilters ? "Show Less" : "More Filters"}
                  </span>
                  <div
                    className={cx(
                      styles.lotiOuter,
                      showMoreFilters && styles.chevronReverse
                    )}
                  >
                    <div className={styles.chevron}></div>
                    <div className={styles.chevron}></div>
                    <div className={styles.chevron}></div>
                  </div>
                </Button>
              </Row>
            )}
            expandIconPosition="centre"
            collapsible="icon"
            className="expandableOuter mbe-8"
          >
            <Collapse.Panel>
              {showSearchButton && (
                <Row gutter={8} className={styles.filterMain}>
                  {renderRestFilters()}
                  <Col span={4} style={{ marginTop: 25 }}>
                    {/* {searchButton(searchText)} */}
                  </Col>
                </Row>
              )}
            </Collapse.Panel>
          </Collapse>
        )
      );
    };

    const renderFiltersDesktop = () => {
      return (
        <>
          <Group template="1fr auto" gap="8px">
            <div>
              {renderInitialFilters()}
              {isDrawerView ? renderDrawerView() : renderCollapseView()}
            </div>
            <Row
              align="middle"
              justify="space-between"
              wrap={false}
              style={{ marginBlockStart: 23, height: 48, gap: "8px" }}
              className={isScriptMobileView ? styles.adjustSearch : null}
            >
              {isDrawerView && filterList.length > INITIAL_FILTERS_TO_SHOW && (
                <Button
                  type="secondary-light"
                  size="small"
                  onClick={() => {
                    onChangeMoreFilters();
                    onClickMoreFilters();
                  }}
                  className="pi-8 pb-4"
                  style={{ height: "auto", fontWeight: 700 }}
                >
                  More Filters{" "}
                  <Icon
                    icon="MdOutlineDoubleArrow"
                    color="#95c7d5"
                    className="m-0 fs12"
                  />
                </Button>
              )}
              {showClearAll &&
                isFilterSelected(filtersObject) &&
                renderClearAll()}
              {filterList.length >= INITIAL_FILTERS_TO_SHOW &&
                showSearchButton &&
                searchButton(searchText)}
              {renderTopRight()}
            </Row>
          </Group>
        </>
      );
    };

    return loading && !filterList?.length ? (
      <>
        <FiltersSkeleton />
        {children({})}
      </>
    ) : children ? (
      children({ renderFiltersDesktop, renderTags })
    ) : (
      <>
        {renderFiltersDesktop()}
        {/* {renderTags()} */}
      </>
    );
  }
);

const FiltersSkeleton = () => {
  return [1, 2, 3, 4].map((e) => (
    <>
      <Skeleton.Button active size="small" style={{ height: 22 }} />
      <Skeleton.Input active style={{ borderRadius: 6, width: "100%" }} />
    </>
  ));
};

Filters.defaultProps = {
  onFilterValueChange: () => {},
  onFiltersApply: () => {},
  onFilterClear: () => {},
  onClickMoreFilters: () => {},
  renderTopRight: () => null,
  isDrawerView: true,
};

export default Filters;
