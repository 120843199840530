import { notification as antdNotification } from "antd"

const notification = {
  info: obj => {
    const { message, description } = obj
    antdNotification.info({
      message: message,
      description: description,
    })
  },
  error: obj => {
    const { message, description } = obj
    antdNotification.error({
      message: message,
      description: description,
    })
  },

  success: obj => {
    const { message, description } = obj
    antdNotification.success({
      message: message,
      description: description,
    })
  },

  warning: obj => {
    const { message, description } = obj
    antdNotification.warning({
      message: message,
      description: description,
    })
  },
}

export default notification
