const { default: parentApi } = require("store/parentApi");

const scriptSettingsApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getAllScriptsTemplateSettings: build.query({
      query: (data) => {
        return {
          url: `/scripts/template/lists`,
          method: "POST",
        };
      },
      transformResponse: (res) => {
        return res?.data?.data?.length
          ? { list: res?.data?.data, pagination: {} }
          : { list: [], pagination: {} };
      },
      providesTags: ["allTemplateSettings"],
    }),
    getTemplateSettingsDetails: build.query({
      query: (body) => {
        return {
          url: `/scripts/template/detail`,
          method: "POST",
          body: body,
        };
      },
      providesTags: ["templateSettingsDetail"],
    }),
    deleteTemplateSetting: build.mutation({
      query: (body) => {
        return {
          url: `/scripts/template/remove`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["allTemplateSettings"],
    }),
    addTemplateSetting: build.mutation({
      query: (body) => {
        return {
          url: `/scripts/template/add`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["templateSettingsDetail", "allTemplateSettings"],
    }),
    updateTemplateSetting: build.mutation({
      query: (body) => {
        return {
          url: `/scripts/template/edit`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["templateSettingsDetail", "allTemplateSettings"],
    }),
  }),
});

export const {
  useGetAllScriptsTemplateSettingsQuery,
  useLazyGetTemplateSettingsDetailsQuery,
  useDeleteTemplateSettingMutation,
  useAddTemplateSettingMutation,
  useUpdateTemplateSettingMutation,
} = scriptSettingsApis;
