import ReactFlowCommon from "components/common/reactflow/reactflow";
import { useCallback, useEffect } from "react";
import {
  MarkerType,
  updateEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import ActionHeader from "./actionHeader";

const SchemaSlide = ({
  slide,
  onChange,
  nodeTypes,
  edgeTypes,
  onDelete,
  viewOnly,
  isFromPresentation,
}) => {
  const { nodes: initialNodes, edges: initialEdges } = slide?.data;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialNodes, initialEdges]);

  const onConnect = (params) => {
    let tempEdges = JSON.parse(JSON.stringify(edges));
    let alreadyExists = tempEdges?.find(
      (e) => e?.source == params?.source && e?.target == params?.target
    );
    let selfNode = params?.target == params?.source;
    if (!alreadyExists && !selfNode) {
      tempEdges?.push({
        ...params,
        id: `${params?.source}-${params?.target}`,
        markerEnd: { type: MarkerType.ArrowClosed },
        type: "custom",
        data: {
          label: "",
        },
      });
      onChange({ ...slide?.data, edges: tempEdges, nodes: nodes });
    }
  };

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) =>
      setEdges((els) => updateEdge(oldEdge, newConnection, els)),
    []
  );

  const onAddNode = useCallback(
    (type, label, properties = {}) => {
      onChange({
        ...slide?.data,
        nodes: [
          ...nodes,
          {
            id:
              ((Number(nodes[nodes?.length - 1]?.id) || 0) + 1).toString() ||
              "1",
            type: type,
            position: {
              x:
                nodes[nodes?.length - 1]?.position?.x ||
                window.innerWidth / 3.25,
              y:
                nodes[nodes?.length - 1]?.position?.y +
                  nodes[nodes?.length - 1]?.height +
                  100 || window.innerHeight / 5,
            },
            data: { label: label, ...properties },
          },
        ],
        edges: edges,
      });
    },
    [nodes, edges]
  );

  const handleEdgeDoubleClick = (event, edge) => {
    if (edges?.length) {
      let tempEdges = JSON.parse(JSON.stringify(edges));
      tempEdges = tempEdges.map((e) => {
        if (e?.target == edge?.target && e?.source == edge?.source) {
          return { ...e, data: { ...e?.data, showInput: true } };
        } else {
          return e;
        }
      });
      onChange({ ...slide?.data, edges: tempEdges, nodes: nodes });
    }
  };

  const handleNodeDrag = (e, updatedNode) => {
    if (updatedNode) {
      if (nodes?.length) {
        let tempNodes = JSON.parse(JSON.stringify(nodes));
        tempNodes = tempNodes.map((e) => {
          if (e?.id == updatedNode?.id) {
            return updatedNode;
          } else {
            return e;
          }
        });
        onChange({ ...slide.data, nodes: tempNodes, edges: edges });
      }
    }
  };

  const onClearSchema = () => {
    onChange({ ...slide?.data, nodes: [], edges: [] });
  };

  const onChangeColor = (nodeId, value) => {
    if (nodeId) {
      let tempNodes = JSON.parse(JSON.stringify(nodes));
      if (tempNodes?.length) {
        tempNodes = tempNodes.map((e) => {
          if (e?.id == nodeId) {
            return { ...e, data: { ...e?.data, color: value } };
          } else return e;
        });
      }
      onChange({ ...slide?.data, nodes: tempNodes });
    }
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: isFromPresentation ? "72vh" : "100vh",
        }}
      >
        {!viewOnly && (
          <ActionHeader
            onAddNode={onAddNode}
            onClearSchema={onClearSchema}
            nodes={nodes}
            edges={edges}
            onDelete={onDelete}
            onChangeColor={onChangeColor}
          />
        )}
        <ReactFlowCommon
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDrag={handleNodeDrag}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          edgeTypes={edgeTypes}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeDoubleClick={handleEdgeDoubleClick}
          viewOnly={viewOnly}
          zoomOnScroll={!viewOnly}
        />
      </div>
    </>
  );
};

export default SchemaSlide;
