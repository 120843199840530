import ReactFlow, { Background, Controls, MiniMap } from "reactflow";
import theme from "utils/themeVars";
import "reactflow/dist/style.css";
import styles from "./index.module.less";
import { useRef } from "react";

const ReactFlowCommon = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  nodeTypes,
  onConnect,
  viewOnly,
  ...rest
}) => {
  // const flowRef = useRef(null);

  return (
    <ReactFlow
      // ref={flowRef}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      nodesDraggable={!viewOnly}
      elementsSelectable={!viewOnly}
      // onWheel={(event) => {
      //   // if (!scrollReactFlow) return; // Exit if scrolling React Flow is disabled
      //   event.preventDefault(); // Prevent default scroll behavior
      //   const deltaY = event.deltaY;
      //   const container = event.currentTarget;
      //   if (container) {
      //     container.scrollTop += deltaY;
      //   }
      // }}
      style={{
        border: `1px solid ${theme["blue200"]}`,
        borderRadius: "5px",
        padding: "10px",
        // overflow: "hidden",
      }}
      fitView
      {...rest}
    >
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          zIndex: "999",
        }}
      >
        <Controls />
      </div>
      <div class={styles.minimap}>{!viewOnly && <MiniMap />}</div>
      <Background variant="dots" gap={12} size={1} />
    </ReactFlow>
  );
};

export default ReactFlowCommon;
