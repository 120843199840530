import { Button, notification, Title } from "components/common";
import { useStartQuizMutation } from "features/script/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "utils/themeVars";

const QuestionCardView = ({
  scriptData,
  handleAdd,
  handleView,
  handleEdit,
  detailPage,
}) => {
  const navigate = useNavigate();
  const [selectedExplanations, setSelectedExplanations] = useState([]);

  const [handleStartQuiz, _] = useStartQuizMutation();

  const onStartQuiz = async () => {
    const res = await handleStartQuiz({
      scriptId: scriptData?.scriptId,
      isNewQuiz: false,
      explanation: selectedExplanations?.map((e) => e?.title),
    });
    if (res) {
      if (res?.error) {
      } else if (!!res?.data && res?.data?.length) {
        navigate(`/user/quiz/start?quizId=${res?.data}`);
      } else {
        notification.error({
          message: "No Questions Exists for the Selected Explanations",
        });
      }
    }
  };

  return (
    <div
      style={{
        border: `1px solid ${theme["blue100"]}`,
        borderRadius: "10px",
        margin: "2%",
        padding: "2%",
        backgroundColor: theme["light-gray"],
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <div>
          {scriptData?.questions?.length ? (
            <div
              style={{ display: "flex", gap: "20px", alignItems: "baseline" }}
            >
              <Title
                level={3}
              >{`Total Questions: ${scriptData?.questions?.length}`}</Title>
              {!detailPage ? (
                <>
                  <Button
                    style={{
                      borderColor: theme["blue100"],
                      color: theme["blue200"],
                      background: theme["light-gray"],
                      fontWeight: "unset",
                    }}
                    type={"bordered"}
                    icon="FaRegEye"
                    text="View"
                    onClick={handleView}
                  />
                  <Button
                    style={{
                      borderColor: theme["blue100"],
                      color: theme["blue200"],
                      background: theme["light-gray"],
                      fontWeight: "unset",
                    }}
                    type={"bordered"}
                    icon="FaEdit"
                    text="Edit"
                    onClick={handleEdit}
                  />
                </>
              ) : null}
            </div>
          ) : !detailPage ? (
            <>
              <Title level={3}>Want to prepare better? Add questions.</Title>
            </>
          ) : null}
        </div>
        {!detailPage && (
          <Button
            type="primary"
            icon="HiPlus"
            text="Add Question"
            onClick={handleAdd}
          />
        )}
      </div>
      {scriptData?.scriptId && scriptData?.questions?.length && !!detailPage ? (
        <>
          <div style={{ marginTop: "2%" }}>
            <Title level={3}>Choose Explanation Topics</Title>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "space-start",
                flexWrap: "wrap",
              }}
            >
              {scriptData?.explanations
                .filter((e) => !!e.title)
                .map((e, i) => {
                  const selected = selectedExplanations?.find(
                    (it) => it?.title == e?.title
                  );
                  return (
                    <Button
                      key={i}
                      onClick={() => {
                        setSelectedExplanations((prev) => {
                          const find = prev?.findIndex((it) => it == e);

                          if (find == -1) {
                            return [...prev, e];
                          } else {
                            return prev?.filter((it) => it != e);
                          }
                        });
                      }}
                      style={{
                        border: selected
                          ? `2px solid ${theme["blue100"]}`
                          : "1px solid gray",
                        color: selected ? theme["blue200"] : "gray",
                        backgroundColor: selected ? theme["blue50"] : "",
                      }}
                      type={"bordered"}
                      text={e?.title}
                    />
                  );
                })}
            </div>
            <Button
              type="primary"
              text="Start Quiz"
              disabled={!selectedExplanations?.length}
              onClick={onStartQuiz}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default QuestionCardView;
