import { Title, Tooltip } from "components/common";
import CollapseCard from "components/common/collapseCard/collapseCard";
import {
  ADD_ASSESSMENT,
  COPY_ASSESSMENT,
  DELETE_ICON,
  PIN,
} from "components/svg";
import { useState } from "react";
import styles from "../../index.module.less";
import AssessmentAndPlanPanel from "../assessmentAndPlanPanel";

const AssessmentAndPlanAddEditForm = ({
  assessments,
  user,
  handleChange,
  activeKeys,
  setActiveKeys,
  panelKey,
}) => {
  const [selectedAssessment, setSelectedAssessment] = useState(0);

  const handleChangePin = () => {
    let tempAssessments = JSON.parse(JSON.stringify(assessments));
    //Removing this user pin from all other assesments as only one assesment can be pinned
    tempAssessments = tempAssessments.map((ass, i) => {
      if (i != selectedAssessment) {
        return {
          ...ass,
          ...(ass?.pinnedBy?.length
            ? {
                pinnedBy: ass?.pinnedBy?.filter((e) => e == user?.id),
              }
            : {}),
        };
      } else {
        return ass;
      }
    });
    //Updating its pinned for the selected assessment
    tempAssessments = tempAssessments.map((ass, i) => {
      return {
        ...ass,
        ...(i == selectedAssessment
          ? {
              pinnedBy: !!ass?.pinnedBy?.find((e) => e == user?._id)
                ? ass?.pinnedBy?.filter((e) => e != user?._id)
                : [...ass?.pinnedBy, user?._id],
            }
          : {}),
      };
    });
    handleChange("assessmentAndPlans", tempAssessments);
  };

  const handleAddAssessmentAndPlan = () => {
    let tempAssessments = JSON.parse(JSON.stringify(assessments));
    tempAssessments?.push({
      key: tempAssessments?.length?.toString(),
      description: "",
      createdBy: user?._id,
      userName: user?.firstname + " " + user?.lastname,
      pinnedBy: [],
    });
    handleChange("assessmentAndPlans", tempAssessments);
    setSelectedAssessment(assessments?.length);
  };

  function removeTagsAndStyling(inputString) {
    if (!inputString) return null;
    const tempDiv = document.createElement("div");
    const formattedHTML = inputString.replace(/<\/p>/g, "\n");
    tempDiv.innerHTML = formattedHTML;
    const plainText = tempDiv.textContent || tempDiv.innerText;
    const cleanedText = plainText.trim();
    return cleanedText;
  }

  const handleCopyToClipboard = () => {
    let text = removeTagsAndStyling(
      assessments?.[selectedAssessment]?.description
    );
    if (!text) return null;
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    notification.success({ message: "Copied Successfully!" });
  };

  const handleDeleteAP = () => {
    let tempAssessments = JSON.parse(JSON.stringify(assessments));
    handleChange(
      "assessmentAndPlans",
      tempAssessments.filter((e, i) => i != selectedAssessment)
    );
    setSelectedAssessment((prev) =>
      !!tempAssessments?.[prev - 1]
        ? prev - 1
        : tempAssessments?.[prev + 1]
        ? prev + 1
        : 0
    );
  };

  const AssessmentAndPlansHeader = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <Title level={3}>Assessment And Plan ({assessments?.length})</Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "5px",
            }}
          >
            <Tooltip title="Pin/UnPin">
              <div onClick={handleChangePin} className={styles.circle}>
                <PIN
                  clr={
                    assessments?.[selectedAssessment]?.pinnedBy?.find(
                      (e) => e == user?._id
                    )
                      ? "#1565DC"
                      : "#808080"
                  }
                />
              </div>
            </Tooltip>
            <Tooltip title="Add">
              <div
                onClick={handleAddAssessmentAndPlan}
                className={styles.circle}
              >
                <ADD_ASSESSMENT />
              </div>
            </Tooltip>
            <Tooltip title="Copy Text">
              <div onClick={handleCopyToClipboard} className={styles.circle}>
                <COPY_ASSESSMENT />
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div onClick={handleDeleteAP} className={styles.circle}>
                <DELETE_ICON />
              </div>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  const getAssessmentAndPlans = () => {
    return [
      {
        key: panelKey,
        header: AssessmentAndPlansHeader(),
        panel: (
          <AssessmentAndPlanPanel
            index={selectedAssessment}
            data={assessments?.[selectedAssessment]}
            assessments={assessments}
            setSelectedAssessment={setSelectedAssessment}
            handleChange={handleChange}
            keyValue={"assessmentAndPlans"}
          />
        ),
      },
    ];
  };

  const handleExpandCollapse = (key) => {
    const keyIndex = activeKeys.findIndex((e) => e == key);
    if (keyIndex == -1) {
      setActiveKeys((prev) => [...prev, key]);
    } else {
      setActiveKeys((prev) => prev.filter((e) => e != key));
    }
  };
  return (
    <>
      {getAssessmentAndPlans().map((e, i) => (
        <div id={panelKey} style={{ paddingTop: "2%" }}>
          <CollapseCard
            headerKey={i?.toString()}
            panel={e?.panel}
            header={e?.header}
            activeKey={activeKeys?.find((e) => e == i)}
            handleExpandCollapse={() => handleExpandCollapse(i?.toString())}
          />
        </div>
      ))}
    </>
  );
};

export default AssessmentAndPlanAddEditForm;
