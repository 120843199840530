import { Button } from "components/common";
import PrimaryButton from "components/common/button/primary-button";
import TextEditor from "components/common/textEditor/textEditor";
import { renderQuillHtml } from "configs/utils";
import CircularArrow from "features/presentation/components/arrowSlider";
import { useSelector } from "react-redux";

const AssessmentAndPlanPanel = ({
  data,
  index,
  assessments,
  setSelectedAssessment,
  handleChange,
  keyValue,
  detailPage = false,
  previewMode,
  onSubmitAssessment = () => {},
}) => {
  const userInfo = useSelector((state) => state.app.user.info);
  const showEditor = () => !detailPage || data?.editor;
  return (
    <>
      {showEditor() ? (
        <TextEditor
          key={`${index}-panel-assessment`}
          value={data?.description}
          onChange={(e) => {
            if (assessments) {
              const tempAssessments = JSON.parse(JSON.stringify(assessments));
              tempAssessments[index] = {
                ...tempAssessments[index],
                description: e,
                updatedBy: userInfo?._id,
              };
              handleChange(keyValue, tempAssessments);
            }
          }}
          editorHeight={200}
          placeholder={"Add Assessment and Plan"}
        />
      ) : (
        <>
          {renderQuillHtml(data?.description, undefined, {
            maxHeight: "200px",
            overflow: "scroll",
          })}
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent:
            data?.userName && detailPage ? "space-between" : "flex-end",
          marginTop: "2%",
          alignItems: "center",
        }}
      >
        {data?.userName && detailPage && <div>By {data?.userName}</div>}

        <div>
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            {detailPage && !previewMode && data?.editor && (
              <Button
                type="primary"
                icon="BsPlus"
                onClick={() => {
                  onSubmitAssessment(false, "Added Successfully!");
                }}
              ></Button>
            )}
            {assessments?.length > 1 && (
              <CircularArrow
                totalSlides={assessments?.length}
                subSlide={index}
                setSubSlide={setSelectedAssessment}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentAndPlanPanel;
