export const regex = {
  naturalNumbers: /^[1-9][0-9]*$/,
  alphabets: /^[A-Za-z\s]+$/,
  wholeNumbers: /^[0-9]{1,12}$/,
  idNumber: /^[0-9+]{5}-?[0-9+]{7}-?[0-9]{1}$/,
  decimal: /^\d+(\.\d{1,2})?$/,
  numbersAndDecimal: /^\d+(\.\d+)?$/,
  alphaNumeric: (isLimited = false) => {
    return isLimited ? /^[0-9a-zA-Z]{1,5}$/ : /^[0-9a-zA-Z]*$/
  },
  phoneNumber: /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
  number: (isNonZero = false) => {
    return isNonZero ? /^[0-9]{1,12}$/ : /^([1-9]|([1-9]\d{1,11}))$/
  },
  websiteRegex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  emailRegex: /^([a-zA-Z_])([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  cnicRegex: /^[0-9]{5}-[0-9]{7}-[0-9]$/,
  idRegex: /@"^[0-9-]*$"/,
  nameRegex: /^[a-z ,.'-]+$/i,
  locationTitleRegix: /^(?!-)[-a-zA-Z0-9\s()]*[a-zA-Z0-9\s()]$/,
  locationUrduTitleRegix: /^(?![-\s])[\u0600-\u06FF()0-9\s-]*[\u0600-\u06FF0-9\s-]$/,
  latitudeRegex: /^([-+]?)(?:[0-9]|[1-8][0-9]|90)(?:\.[0-9]{1,16})?$/,
  longitudeRegex: /^([-+]?)(?:[0-9]|[1-9][0-9]|1[0-7][0-9]|180)(?:\.[0-9]{1,16})?$/,
}
