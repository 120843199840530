import { useState } from "react";

import { Popover } from "antd";
import { DateRangePickerOne, Skeleton, TextInput } from "components/common";
import { getTimeDateString } from "utils/date";

function DateFilter(props) {
  const {
    placeholder,
    queryStartDate,
    queryEndDate,
    label,
    labelProps,
    loading,
    error,
    skeletonLoading,
    disabled,
    accentColor,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const onClick = () => {
    setIsOpen(true);
  };

  const content = (
    <>
      <DateRangePickerOne
        {...props}
        setIsOpen={setIsOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
        accentColor={accentColor}
      />
    </>
  );

  const styleInput = {
    ...(!!isOpen && { "caret-color": "transparent" }),
  };

  return skeletonLoading ? (
    <Skeleton type="input" />
  ) : (
    <>
      <Popover
        visible={isOpen}
        onVisibleChange={() => {
          setIsOpen(!isOpen);
        }}
        placement="bottomLeft"
        title="Search by Calendar"
        content={content}
        action="click"
        zIndex={1111}
      >
        <TextInput
          label={label}
          labelProps={labelProps}
          suffixIcon="FiCalendar"
          iconColor={accentColor}
          placeholder={placeholder}
          loading={loading}
          skeletonLoading={skeletonLoading}
          onClick={onClick}
          value={
            queryStartDate && queryEndDate
              ? `${getTimeDateString(
                  queryStartDate,
                  false,
                  true
                )} – ${getTimeDateString(queryEndDate, false, true)}`
              : ""
          }
          className="dateFilter"
          style={{
            minWidth: 250,
            caretColor: "transparent",
            "--border-color": accentColor,
          }}
          disabled={disabled}
        />
      </Popover>
    </>
  );
}

export default DateFilter;
