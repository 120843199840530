import { Button, Card, Modal, Title, notification } from "components/common";
import { PRESENTATION_EDIT_PATH } from "constants/app-paths";
import React, { useRef, useState, useEffect, useMemo, useContext } from "react";
import { BsQrCode } from "react-icons/bs";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import theme from "utils/themeVars";
import {
  useCompletePresentationMutation,
  useResetPresentationMutation,
} from "../apis";
import styles from "./index.module.less";
import PresentationSlides from "./presentationSlides";
import SlideDetail from "./slideDetail";
import { canUserModify } from "../utils";
import { useSelector } from "react-redux";
import { Space } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { UnorderedListOutlined } from "@ant-design/icons";

const MobilePresentationDetailPageComponent = ({
  id,
  slides,
  setSlides,
  presentationName,
  isCompleted,
  userId,
  basicInfo,
  refetch,
}) => {
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isUIVisible, setIsUIVisible] = useState(false);
  const handleEllipsisClick = () => {
    setIsUIVisible(!isUIVisible);
  };

  const [mainSlide, setMainSlide] = useState(0);
  const [subSlide, setSubSlide] = useState(0);

  const navigate = useNavigate();
  const [resetPresentation, { isLoading: resetLoading }] =
    useResetPresentationMutation();

  const [completePresentation, { isLoading: completedLoading }] =
    useCompletePresentationMutation();

  const user = useSelector((state) => state.app.user?.info);
  const canUserModifyPresentation = user?._id
    ? canUserModify(user, userId)
    : false;

  const refs = slides.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsUIVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClick(id) {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  }

  function scrollIfNotInView(id) {
    var element = document.getElementById(`main-slide-${id}`);
    if (!element) return;
    var rect = element.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    var outerDiv = document.getElementById("scrolling-card-preview");
    var outerRect = outerDiv.getBoundingClientRect();
    var outerTop = outerRect.top;
    var outerBottom = outerRect.bottom;

    if (elemTop < outerTop || elemBottom > outerBottom) {
      var offset =
        elemTop < outerTop ? elemTop - outerTop : elemBottom - outerBottom;
      outerDiv.scrollTop += offset;
    }
  }

  useEffect(() => {
    scrollIfNotInView(mainSlide);
  }, [mainSlide]);

  useEffect(() => {
    const handleScroll = () => {
      const card = document.getElementById("scrolling-card");
      if (!card) return;

      const cardScrollTop = card.scrollTop;

      const divElements = document.querySelectorAll(".scrolling-div");
      let activeDivId = null;

      for (const element of divElements) {
        const { top, height } = element.getBoundingClientRect();
        if (top >= 0 && top < height) {
          activeDivId = element.id;
          break;
        }
      }
      if (activeDivId) {
        setMainSlide(activeDivId);
      }
    };

    const card = document.getElementById("scrolling-card");

    if (card) {
      card.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (card) {
        card.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const onChange = (newSlide) => {
    const tempSlides = JSON.parse(JSON.stringify(slides));
    tempSlides[mainSlide].data[subSlide] = {
      ...tempSlides[mainSlide].data[subSlide],
      data: newSlide,
    };
    setSlides(tempSlides);
  };

  const renderPresentationDetailAndActions = () => {
    return (
      <Card bodyStyle={{ paddingBlock: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            style={{
              marginBottom: 0,
              color: "black",
              textTransform: "capitalize",
              width: "100%",
            }}
            level={2}
          >
            {presentationName ?? ""}
          </Title>

          <div className={styles.actionButtons}>
            <EllipsisOutlined
              ref={dropdownRef}
              style={{ fontSize: "24px", cursor: "pointer" }}
              onClick={handleEllipsisClick}
            />
          </div>

          {isUIVisible && (
            <div className={styles.actionToggle}>
              {/* {canUserModifyPresentation && (
              <Button
                style={{
                  backgroundColor: "white",
                  border: `1px solid ${theme["gray500"]}`,
                }}
                onClick={() => {
                  modalRef.current.setVisible(true);
                }}
              >
                <BsQrCode />
              </Button>
            )} */}
              {canUserModifyPresentation && (
                <Button
                  type="bordered"
                  icon="BiReset"
                  loading={resetLoading}
                  onClick={() => {
                    resetPresentation(id)
                      .then(() => {
                        notification.success({
                          message: "Presentation has been reset!",
                        });
                      })
                      .catch((e) => {
                        notification.error({
                          message: e?.message,
                        });
                      });
                  }}
                  style={{
                    borderColor: theme["blue100"],
                    color: theme["blue200"],
                    background: theme["light-gray"],
                    fontWeight: 700,
                    fontSize: "1rem",
                  }}
                >
                  Reset
                </Button>
              )}

              {canUserModifyPresentation && (
                <Button
                  type="bordered"
                  icon="BsFillBookmarkCheckFill"
                  onClick={() => {
                    completePresentation(id)
                      .unwrap()
                      .then(() => {
                        notification.success({
                          message: "Presentation has been marked completed!",
                        });
                      })
                      .catch((e) => {
                        notification.error({
                          message: e?.message,
                        });
                      });
                  }}
                  loading={completedLoading}
                  disabled={isCompleted}
                  style={{
                    borderColor:
                      isCompleted == true ? theme["gray500"] : theme["blue100"],
                    color:
                      isCompleted == true ? theme["gray600"] : theme["blue200"],
                    background: theme["light-gray"],
                    fontWeight: 700,
                    fontSize: "1rem",
                  }}
                >
                  {isCompleted == true ? "Completed" : "Mark Completed"}
                </Button>
              )}
            </div>
          )}
        </div>
      </Card>
    );
  };

  return (
    <div className={styles.mobilePageContainer}>
      <div className={styles.mobileRightSection}>
        {renderPresentationDetailAndActions()}
        <div
          style={{
            height: "100%",
            marginTop: 5,
            overflowY: "scroll",
            marginBottom: "20px",
            maxHeight: "99vh",
          }}
          id={"scrolling-card"}
        >
          {!canUserModifyPresentation &&
          !isCompleted &&
          slides?.filter(
            (sli) =>
              sli?.data?.[0].type == "poll" &&
              (sli?.data?.[0].status == "started" ||
                sli.data?.[0].status == "completed")
          ).length == 0 ? (
            <Card bodyStyle={{ paddingBlock: 15 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "30vh",
                }}
              >
                <Title
                  style={{
                    marginBottom: 0,
                    color: theme["gray700"],
                    textTransform: "capitalize",
                  }}
                  level={1}
                >
                  No polls started yet!
                </Title>
              </div>
            </Card>
          ) : (
            <>
              {slides.map((slide, index) => {
                return (
                  <SlideDetail
                    ref={refs[slide.id]}
                    subSlide={subSlide ?? 0}
                    slideData={slide?.data}
                    onChange={onChange}
                    slideTitle={slide?.title}
                    setSubSlide={setSubSlide}
                    setSlides={setSlides}
                    index={index}
                    id={id}
                    backgroundColor={slide?.backgroundColor}
                    isCompleted={isCompleted}
                    canUserModifyPresentation={canUserModifyPresentation}
                    refetch={refetch}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MobilePresentationDetailPageComponent;
