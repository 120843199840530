import { Handle, NodeResizer, Position } from "reactflow";
import NodeLabelModel from "./nodeLabelModal";
import { useEffect, useState } from "react";
import styles from "./styles.css";
import { renderQuillHtml } from "configs/utils";

function DecisionNode({ data, id, onChange, selected, nodeSize = 180 }) {
  const [isVisible, setIsVisible] = useState(false);

  const [size, setSize] = useState(180);

  useEffect(() => {
    setSize(nodeSize);
  }, [nodeSize]);

  const onResize = (event, direction, ref, delta, position) => {
    if (direction && direction.width) {
      setSize(direction.width);
    }
  };

  return (
    <>
      <NodeLabelModel
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onChange={onChange}
        label={data?.label}
        id={id}
      />
      {/* <NodeResizer id={id} minWidth={180} minHeight={180} onResize={onResize} /> */}
      <div
        style={{
          position: "absolute",
          left: "50%",
          zIndex: 999,
          pointerEvents: "all",
        }}
      >
        <Handle
          id={"test"}
          type="target"
          position={Position.Top}
          isConnectable={true}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            borderRadius: "50%", // Makes it a circle
            border: "1px solid #000", // Border color and width
          }}
        />
      </div>
      <div
        style={{ position: "absolute", top: "50%", left: "0%", zIndex: 999 }}
      >
        <Handle
          id={"test2"}
          type="target"
          position={Position.Left}
          isConnectable={true}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            borderRadius: "50%", // Makes it a circle
            border: "1px solid #000", // Border color and width
          }}
        />
      </div>
      <div
        style={{ position: "absolute", top: "50%", right: "0%", zIndex: 999 }}
      >
        <Handle
          id={"test3"}
          type="source"
          position={Position.Right}
          isConnectable={true}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            borderRadius: "50%", // Makes it a circle
            border: "1px solid #000", // Border color and width
          }}
        />
      </div>
      <div
        style={{ position: "absolute", bottom: "0%", left: "50%", zIndex: 999 }}
      >
        <Handle
          id={"test4"}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            borderRadius: "50%", // Makes it a circle
            border: "1px solid #000", // Border color and width
          }}
        />
      </div>
      <div
        style={{
          position: "relative",
          width: `${size || 180}px`,
          height: `${size || 180}px`,
        }}
        className="pointer"
        onDoubleClick={() => setIsVisible(true)}
      >
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <polygon
            points={`${(isNaN(size) ? 180 : size) / 2},0 ${
              isNaN(size) ? 180 : size
            },${(isNaN(size) ? 180 : size) / 2} ${
              (isNaN(size) ? 180 : size) / 2
            },${isNaN(size) ? 180 : size} 0,${isNaN(size) ? 90 : size / 2}`}
            fill={data?.color || "#fff"}
            stroke={selected ? "#1565DC" : "#000"}
            strokeWidth={selected ? "2" : "1"}
          />
        </svg>

        <div
          style={{
            position: "absolute",
            fontWeight: "bold",
            color: selected ? "#1565DC" : "#000",
            overflow: "auto",
            maxWidth: `7vw`,
            maxHeight: `10vh`,
            bottom: "50px",
            left: "37px",
          }}
        >
          {renderQuillHtml(data?.label)}
        </div>
      </div>
    </>
  );
}

export default DecisionNode;
