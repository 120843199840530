import { Upload } from "antd";
import { ReactS3Client } from "features/presentation/utils/s3";
import React, { useState } from "react";
import notification from "../notification/notification";
const filetypes = ["image/jpeg", "image/jpg", "image/png"];

export default function ImageUpload(props) {
  const {
    fileSize = 1,
    setFiles,
    files,
    showOnly,
    allowedFileTypes = filetypes,
    children,
    onUpload,
    setLoading,
    ...rest
  } = props;
  const [file, setFile] = useState({
    previewTitle: "",
    previewImage: "",
    previewOpen: false,
  });

  const imageUpload = (file) => {
    return new Promise((resolve, reject) => {
      ReactS3Client.uploadFile(file)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const customUpload = async (files) => {
    try {
      setLoading(true);
      const file = await imageUpload(files?.file);
      if (file) {
        onUpload(file, files?.file);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notification.error({
        message: "Something went wrong while uploading file",
      });
    }
  };

  return (
    <Upload
      maxCount={8}
      customRequest={customUpload}
      onRemove={() => {
        setFiles(null);
        setFile((prev) => ({ ...prev, containFile: false }));
      }}
      // accept={allowedFileTypes.join(",")}
      showUploadList={false}
      {...rest}
    >
      {React.cloneElement(children, { customUpload })}
    </Upload>
  );
}
