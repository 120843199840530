import theme from "utils/themeVars";
import ImageSlideView from "./imageSlideView";
import QuillEditorView from "./quillEditorView";
import { Card, Title, Button, notification } from "components/common";
import CircularArrow from "./arrowSlider";
import { useEffect, useState } from "react";
import { usePollActionMutation, usePollVoteMutation } from "../apis";
import { Typography, Space, Radio, Tag, Progress } from "antd";
import { useSelector } from "react-redux";
import "../../../styles/slideDetail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import PresentationSchema from "components/custom/schema/presentationSchema";

const { Text } = Typography;

const SlideDetail = ({
  id,
  ref,
  slideData,
  onChange,
  slideTitle,
  index,
  isCompleted,
  canUserModifyPresentation,
  backgroundColor,
  refetch,
}) => {
  const renderCaseTypeSlide = (slide) => {
    return <QuillEditorView explanationDetails={slide.data} />;
  };

  const email = localStorage.getItem("voteEmail");

  const [pollAction, pollactionResponse] = usePollActionMutation();
  const [pollVote, _] = usePollVoteMutation();

  const [subSlide, setSubSlide] = useState(0);

  const [selectedPollIndex, setSelectedPollIndex] = useState(null);

  const user = useSelector((state) => state.app?.user?.info);

  const indexToAlphabet = (index) => {
    if (index >= 0 && index < 26) {
      return String.fromCharCode(65 + index);
    } else {
      // Handle out-of-range indices or other cases as needed
      return null; // or throw an error
    }
  };

  const formatPercentage = (number) => {
    return number.toFixed(0);
  };

  // checking is the page is opened on mobile
  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (pollactionResponse && pollactionResponse.isSuccess) {
      notification.success({
        message: `Poll has been ${
          slideData?.[subSlide].status == "completed"
            ? "Completed"
            : slideData?.[subSlide].status == "started"
            ? "Ended"
            : "Started"
        }`,
      });
    } else if (pollactionResponse && pollactionResponse.isError) {
      notification.error({
        message: "Something went wrong!",
      });
    }
    //  check if the screen size is exactly 360x800
    setIsMobile(window.innerWidth <= 700);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    // add eventListener for window resize
    window.addEventListener("resize", handleResize);
    // initial check on component mount
    handleResize();

    // remove the event listener when the component unmounts

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pollactionResponse]);
  const renderPollTypeSlide = (slide) => {
    const totalVotes = slide.data.options.reduce(
      (partialSum, a) => partialSum + a.votes,
      0
    );
    return (
      <div>
        {slide?.data?.question && (
          <QuillEditorView explanationDetails={slide.data.question} />
        )}
        {/* poll has ended */}
        {slide.status === "completed" ? (
          <>
            {slide.data.options.map((opt, index) => {
              const percentCompleted = formatPercentage(
                totalVotes ? (opt.votes * 100) / totalVotes : 0
              );
              return (
                <>
                  <div
                    className={
                      opt.isAnswer
                        ? isMobile
                          ? "mobile-correct-answer"
                          : "correct-answer"
                        : isMobile
                        ? "mobile-poll-row"
                        : "poll-row"
                    }
                    style={{ padding: "5px" }}
                  >
                    <Tag
                      className={opt.isAnswer ? "correct-option" : "options"}
                    >
                      {indexToAlphabet(index)}
                    </Tag>
                    <Text className="description">{opt.description}</Text>

                    {opt.isAnswer ? (
                      <span className="answer-marker">
                        <FontAwesomeIcon icon={faCheck} color="blue" />
                      </span>
                    ) : (
                      <span className="answer-marker">
                        <FontAwesomeIcon icon={faTimesCircle} color="grey" />
                      </span>
                    )}

                    <Text
                      className={
                        opt.isAnswer
                          ? isMobile
                            ? "mobile-change-votes-color"
                            : "change-votes-color"
                          : "votes"
                      }
                    >
                      {percentCompleted}%
                    </Text>
                  </div>
                </>
              );
            })}
            <div className="total-votes">
              Total Votes: {totalVotes} &#8226; Final Result
            </div>
          </>
        ) : slide.status === "created" ? (
          <Space
            direction="vertical"
            size="middle"
            style={{ marginTop: 10, width: "100%" }}
          >
            {/* Before poll started  */}
            {slide.data.options.map((opt, index) => (
              <Text
                className={
                  isMobile
                    ? "mobile-before-poll-started"
                    : "before-poll-started"
                }
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 5,
                }}
              >
                <Tag
                  style={{
                    borderRadius: "34px",
                    borderColor: theme["blue100"],
                    color: theme["blue200"],
                    background: theme["light-gray"],
                    fontSize: "1rem",
                  }}
                >
                  {indexToAlphabet(index)}
                </Tag>
                <span
                  style={{
                    display: "inline-block",
                    maxWidth: "100%",
                    overflowY: "overlay",
                    maxHeight: isMobile ? "unset" : "12vh",
                    marginBottom: "1vh",
                  }}
                >
                  {opt.description}
                </span>
              </Text>
            ))}
          </Space>
        ) : (
          <Radio.Group
            className="parent-after-poll-started"
            onChange={async (e) => {
              const res = await pollVote({
                id: id,
                data: {
                  slide_no: index,
                  option_no: e.target.value,
                  ...(!user?._id && { email: email }),
                },
              });

              if (res) {
                refetch();
              }
            }}
            // value={
            //   slide?.data?.votes?.length
            //     ? slide?.data?.votes?.find((e) => e?.user_id == user?.user_code)
            //         ?.option_id
            //     : null
            // }
          >
            <Space
              className={
                isMobile ? "mobile-after-poll-started" : "after-poll-started"
              }
              direction="vertical"
              style={{ marginTop: 10 }}
            >
              {/* after poll started */}
              {slide.data.options.map((opt, ind) => (
                <Radio
                  onClick={() => {
                    setSelectedPollIndex(ind);
                  }}
                  value={ind}
                  className={`option ${
                    selectedPollIndex === ind ? "selected" : ""
                  }`}
                >
                  <span
                    style={{
                      borderRadius: "34px",
                      borderColor: theme["blue100"],
                      color: theme["blue200"],
                      fontSize: "1rem",
                      marginLeft: "-1vw",
                      marginRight: "0.5vw",
                    }}
                  >
                    {indexToAlphabet(ind)}
                  </span>
                  {opt.description}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
      </div>
    );
  };

  const renderImageTypeSlides = (slide) => {
    return <ImageSlideView slide={slide} />;
  };

  const renderSchemaTypeSlides = (slide) => {
    return <PresentationSchema slide={slide} viewOnly={true} />;
  };
  const renderSimpleSlide = (slide) => {
    return <QuillEditorView explanationDetails={slide.data} />;
  };

  const renderSlide = (slide) => {
    switch (slide?.type) {
      case ("case", "description"):
        return renderCaseTypeSlide(slide);
      case "poll":
        return renderPollTypeSlide(slide);
      case "image":
        return renderImageTypeSlides(slide);
      case "schema":
        return renderSchemaTypeSlides(slide);
      case "slide":
        return renderSimpleSlide(slide);
    }
  };

  const hasSubSlides = (slide) => {
    return slide?.length > 1;
  };
  return (
    <>
      {canUserModifyPresentation ||
      isCompleted ||
      slideData?.find((e) => e.type == "poll")?.status == "started" ||
      slideData?.find((e) => e.type == "poll")?.status == "completed" ? (
        <div
          id={index}
          className="scrolling-div"
          style={{
            // marginBlock: 5,
            display: "flex",
            height: isMobile ? "unset" : "100%",
            minHeight: isMobile ? "unset" : "100vh",
            overflowY: "scroll",
            width: "100%",
            maxHeight: isMobile ? "unset" : "100vh",
            backgroundColor: backgroundColor ?? "white",
            marginBottom: "10px",
          }}
        >
          <Card
            style={{
              backgroundColor: backgroundColor ?? "white",
            }}
            className="slide-detail-card"
            headStyle={{ borderBottom: "none" }}
            bodyStyle={{
              backgroundColor: backgroundColor ?? "white",
              marginBottom: 30,
              height: "100%",
              width: "100%",
              padding: "20px 30px 20px 30px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    slideTitle && slideTitle != ""
                      ? "space-between"
                      : "flex-end",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                {slideTitle && slideTitle != "" && (
                  <Title
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      color: "black",
                      color: theme["gray900"],
                    }}
                    level={1}
                  >
                    {slideTitle}
                  </Title>
                )}
                <div className="action-btns">
                  {slideData?.[subSlide].type == "poll" &&
                    canUserModifyPresentation && (
                      <Button
                        icon="AiOutlineUnorderedList"
                        type="bordered"
                        onClick={async () => {
                          const res = await pollAction({
                            id: id,
                            data: {
                              status:
                                isCompleted ||
                                slideData?.[subSlide].status == "completed"
                                  ? null
                                  : slideData?.[subSlide].status == "created"
                                  ? "started"
                                  : "completed",
                              slide_no: index,
                            },
                          });
                          if (res) refetch();
                        }}
                        disabled={slideData?.[subSlide].status == "completed"}
                        loading={pollactionResponse?.isLoading}
                        style={{
                          color:
                            isCompleted ||
                            slideData?.[subSlide].status == "completed"
                              ? theme["gray700"]
                              : slideData?.[subSlide].status == "started"
                              ? theme["blue200"]
                              : "white",
                          background:
                            isCompleted ||
                            slideData?.[subSlide].status == "completed"
                              ? theme["gray500"]
                              : slideData?.[subSlide].status == "started"
                              ? theme["blue100"]
                              : theme["blue200"],
                          fontWeight: 700,
                          fontSize: "1rem",
                          borderRadius: "4px",
                          textTransform: "capitalize",
                          opacity: 1,
                        }}
                      >
                        {isCompleted ||
                        slideData?.[subSlide].status == "completed"
                          ? "Poll Ended"
                          : slideData?.[subSlide].status == "started"
                          ? "End Poll"
                          : "Start Poll"}
                      </Button>
                    )}
                  {(canUserModifyPresentation ||
                    isCompleted ||
                    slideData?.[subSlide].status != "started") &&
                    slideData?.length > 1 && (
                      <div className={isMobile ? "mobile-view" : "crousel"}>
                        <CircularArrow
                          ref={ref}
                          subSlide={subSlide}
                          setSubSlide={setSubSlide}
                          totalSlides={slideData.length}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
            {slideData && (
              <>
                <div
                  style={{
                    height: isMobile && "unset",
                    overFlowY: isMobile ? "unset" : "scroll",
                  }}
                >
                  {renderSlide(slideData?.[subSlide])}
                </div>
              </>
            )}
          </Card>
        </div>
      ) : null}
    </>
  );
};
export default SlideDetail;
