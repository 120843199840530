import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./index.module.less";
import Settings from "./Settings";
import Profile from "./Profile";
import { CiUser } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { useGetAccountDetailsQuery } from "./api";
import { useSelector } from "react-redux";
import { Card, Skeleton } from "components/common";
import { Spin } from "antd";
import ChangePassword from "./changePassword";
import { CgPassword } from "react-icons/cg";
import { RxActivityLog } from "react-icons/rx";
import UserActivity from "./userActivity";

const ACCOUNTS = () => {
  const user = useSelector((state) => state?.app?.user?.info);
  const userCode = user?.user_code;

  const [activeItem, setActiveItem] = useState("profile");
  const [profileData, setProfileData] = useState({});
  const [settingsData, setSettingsData] = useState({});
  const [email, setEmail] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(true);

  const { data, isFetching, isLoading, error, refetch } =
    useGetAccountDetailsQuery(
      {
        body: {
          userCode: userCode,
        },
      },
      { refetchOnMountOrArgChange: true }
    );

  const prepareProfileData = (data) => {
    const obj = {};
    obj.firstname = data.firstname;
    obj.lastname = data.lastname;
    obj.username = data.username;
    obj.phone_number = data.phone_number;
    obj.country = data.country;
    obj.profile_url = data.profile_url;
    obj.email = data.email;
    setEmail(data.email);
    setProfileData(obj);
    setIsDataLoaded(true);
  };

  const prepareSettingsData = (data) => {
    const obj = {};
    obj.is_payment_enabled = data.is_payment_enabled;
    obj.is_two_factor_auth_enabled = data.is_two_factor_auth_enabled;
    obj.is_email_enabled = data.is_email_enabled;
    obj.user_role = data.user_role;
    obj.activities_to_capture = data.activities_to_capture;

    setSettingsData(obj);
  };

  useEffect(() => {
    if (data?.data) {
      prepareProfileData(data.data);
      prepareSettingsData(data.data);
    }
  }, [data]);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div className={styles.app}>
      <div className={styles.sidebar}>
        <div
          className={`${styles.sidebarItem} ${
            activeItem === "profile" ? styles.active : ""
          }`}
          onClick={() => handleItemClick("profile")}
        >
          <span>
            {" "}
            <CiUser size={20} />
          </span>
          <span>Account</span>
        </div>
        <div
          className={`${styles.sidebarItem} ${
            activeItem === "activity" ? styles.active : ""
          }`}
          onClick={() => handleItemClick("activity")}
        >
          <span>
            <RxActivityLog size={20} />
          </span>
          <span>Activity</span>
        </div>
        <div
          className={`${styles.sidebarItem} ${
            activeItem === "settings" ? styles.active : ""
          }`}
          onClick={() => handleItemClick("settings")}
        >
          <span>
            {" "}
            <IoSettingsOutline size={20} />
          </span>
          <span>Settings</span>
        </div>

        <div
          className={`${styles.sidebarItem} ${
            activeItem === "password" ? styles.active : ""
          }`}
          onClick={() => handleItemClick("password")}
        >
          <span>
            <CgPassword size={20} />
          </span>
          <span>Change Password</span>
        </div>
      </div>
      <div className={styles.content}>
        {isDataLoaded ? (
          <>
            {activeItem === "profile" &&
              Object.keys(profileData).length > 0 && (
                <Card>
                  <Profile
                    profileData={profileData}
                    userCode={userCode}
                    email={email}
                    prepareProfileData={prepareProfileData}
                    setIsDataUpdated={setIsDataUpdated}
                  />
                </Card>
              )}

            {activeItem === "settings" &&
              Object.keys(settingsData).length > 0 && (
                <Card>
                  <Settings
                    settingsData={settingsData}
                    userCode={userCode}
                    prepareSettingsData={prepareSettingsData}
                    setIsDataUpdated={setIsDataUpdated}
                  />
                </Card>
              )}
            {activeItem === "password" && <ChangePassword />}
            {activeItem === "activity" && <UserActivity />}
          </>
        ) : (
          <div className={styles.spinner}>
            <Spin size="large" />
          </div>
        )}
        {isDataUpdated ? (
          <></>
        ) : (
          <Spin
            style={{
              position: "absolute",
              top: "50%",
              left: "60%",
            }}
            size="large"
          />
        )}
      </div>
    </div>
  );
};

export default ACCOUNTS;
