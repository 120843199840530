import { useCallback } from "react";
import DecisionNode from "./decisionNode";
import ProcessNode from "./processNode";
import SchemaSlide from "./schemaSlide";
import StartEndNode from "./startEndNode";
import CustomEdge from "./customEdge";
import TextNode from "./textNode";

const PresentationSchema = ({ slide, onChange, viewOnly }) => {
  const { nodes, edges } = slide?.data;
  const nodeTypes = {
    processNode: (props) => (
      <ProcessNode
        {...props}
        onChange={handleNodeUpdateLabel}
        onNodeResize={handleNodeResize}
        viewOnly={viewOnly}
      />
    ),
    decisionNode: (props) => (
      <DecisionNode
        {...props}
        onChange={handleNodeUpdateLabel}
        viewOnly={viewOnly}
      />
    ),
    startEnd: (props) => (
      <StartEndNode
        {...props}
        onChange={handleNodeUpdateLabel}
        viewOnly={viewOnly}
      />
    ),
    textNode: (props) => (
      <TextNode
        {...props}
        onChange={handleNodeUpdateLabel}
        onNodeResize={handleNodeResize}
        viewOnly={viewOnly}
      />
    ),
  };

  const edgeTypes = {
    custom: (props) => (
      <CustomEdge
        {...props}
        viewOnly={viewOnly}
        onUpdateLabel={handleEdgeUpdateLabel}
        id={`${props?.source}-${props?.target}`}
      />
    ),
  };

  const handleEdgeUpdateLabel = useCallback(
    (edgeId, newLabel, showInput = true) => {
      const edgeIndex = edges.findIndex((edge) => edge.id == edgeId);
      const updatedEdges = [
        ...edges.slice(0, edgeIndex),
        {
          ...edges[edgeIndex],
          data: {
            ...edges[edgeIndex]?.data,
            label: newLabel,
            showInput: showInput,
          },
        },
        ...edges.slice(edgeIndex + 1),
      ];
      onChange({ ...slide?.data, edges: updatedEdges });
    },
    [slide]
  );

  const handleNodeUpdateLabel = useCallback(
    (nodeId, newLabel) => {
      const nodeIndex = nodes.findIndex(
        (node) => node.id === nodeId?.toString()
      );
      const updatedNodes = [
        ...nodes.slice(0, nodeIndex),
        {
          ...nodes[nodeIndex],
          data: {
            ...nodes[nodeIndex].data,
            label: newLabel,
          },
        },
        ...nodes.slice(nodeIndex + 1),
      ];
      onChange({ ...slide?.data, nodes: updatedNodes });
    },
    [slide]
  );

  const handleNodeResize = useCallback(
    (nodeId, newDimensions) => {
      const nodeIndex = nodes.findIndex(
        (node) => node.id === nodeId?.toString()
      );
      const updatedNodes = [
        ...nodes.slice(0, nodeIndex),
        {
          ...nodes[nodeIndex],
          data: { ...nodes[nodeIndex].data, ...newDimensions },
        },
        ...nodes.slice(nodeIndex + 1),
      ];
      onChange({ ...slide?.data, nodes: updatedNodes });
    },
    [slide]
  );

  const handleNodeDelete = useCallback(
    (nodeId, type) => {
      let tempNodes = JSON.parse(JSON.stringify(nodes));
      let tempEdges = JSON.parse(JSON.stringify(edges));

      if (type == "node") {
        if (tempNodes?.length) {
          tempNodes = tempNodes?.filter((e) => e?.id != nodeId);
          if (tempEdges?.length) {
            tempEdges = tempEdges?.filter(
              (e) => e?.source != nodeId && e?.target != nodeId
            );
          }
        }
      } else if (type == "edge") {
        if (tempEdges?.length) {
          tempEdges = tempEdges?.filter((e) => e?.id != nodeId);
        }
      }
      onChange({ ...slide?.data, nodes: tempNodes, edges: tempEdges });
    },
    [slide]
  );

  return (
    <SchemaSlide
      isFromPresentation={true}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      slide={slide}
      onChange={onChange}
      onResize={handleNodeResize}
      onDelete={handleNodeDelete}
      viewOnly={viewOnly}
    />
  );
};

export default PresentationSchema;
