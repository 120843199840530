import TextEditor from "components/common/textEditor/textEditor";

const CaseFields = ({ key, value, onChange, placeholder }) => {
  return (
    <TextEditor
      key={key}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
export default CaseFields;
