import { convertQueryObjToString } from "utils";

const { default: parentApi } = require("store/parentApi");

const AppUsersApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAppUsers: build.query({
      query: (params) => ({
        url: `/user/list/all/users?${convertQueryObjToString(
          params?.queryObj
        )}`,
        method: "POST",
      }),
      providesTags: ["usersListing"],
    }),
    updateUserStatus: build.mutation({
      query: (obj) => ({
        url: "/user/update/user/account",
        method: "POST",
        body: {
          selected_user_code: obj?.userID,
          action: obj.activityStatus,
        },
      }),
      invalidatesTags: ["usersListing"],
    }),
    addFreeUser: build.mutation({
      query: (body) => ({
        url: "/user/register/free-users",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["usersListing"],
    }),
  }),
});

export const {
  useGetAllAppUsersQuery,
  useUpdateUserStatusMutation,
  useAddFreeUserMutation,
} = AppUsersApis;
