import PresentationSchema from "components/custom/schema/presentationSchema";
import CaseFields from "./caseField";
import ImageSlide from "./imageSlide";
import PollFields from "./pollFields";
import TextEditor from "components/common/textEditor/textEditor";

const Slide = ({
  subSlide,
  slideData,
  onChange,
  backgroundColor,
  mainSlide,
}) => {
  const renderCaseTypeSlide = (slide) => {
    return (
      <CaseFields
        key={`${slide?.id} - ${mainSlide}`}
        value={slide?.data}
        onChange={onChange}
        placeholder={slide?.placeholder}
      />
    );
  };

  const renderPollTypeSlide = (slide) => {
    return (
      <PollFields
        key={`${slide?.id} - ${mainSlide}`}
        slide={slide}
        onChange={onChange}
        subSlide={subSlide}
        backgroundColor={backgroundColor}
      />
    );
  };

  const renderImageTypeSlides = (slide) => {
    return (
      <ImageSlide
        key={`${slide?.id} - ${mainSlide}`}
        slide={slide}
        onChange={onChange}
      />
    );
  };

  const renderSchemaTypeSlides = (slide) => {
    return <PresentationSchema slide={slide} onChange={onChange} />;
  };
  const renderSimpleSlide = (slide) => {
    return (
      <TextEditor
        key={`${slide?.id} - ${mainSlide}`}
        value={slide?.data}
        onChange={onChange}
        placeholder={slide?.placeholder}
        editorHeight={67}
        inVh={true}
      />
    );
  };

  const renderSlide = (slide) => {
    switch (slide?.type) {
      case "case":
        return renderCaseTypeSlide(slide);
      case "poll":
        return renderPollTypeSlide(slide);
      case "image":
        return renderImageTypeSlides(slide);
      case "schema":
        return renderSchemaTypeSlides(slide);
      case "slide":
        return renderSimpleSlide(slide);
    }
  };

  return slideData ? renderSlide(slideData?.[subSlide]) : "";
};
export default Slide;
