import algoliasearch from "algoliasearch"

export default class Algolia {
  static #client
  static #locationsClient
  static #adsIndex
  static #locationIndex
  static #agents
  static #newProjects
  static #developers
  static #newestAdsIndex
  static #priceDescAdsIndex
  static #priceAscAdsIndex

  static #indexInsMaping = {
    popular: this.#adsIndex,
    date_desc: this.#newestAdsIndex,
    price_desc: this.#priceDescAdsIndex,
    price_asc: this.#priceAscAdsIndex,
  }

  static #indexNameMapping = {
    popular: "zameen-development-ads-en",
    date_desc: "zameen-development-ads-date-desc-en",
    price_desc: "zameen-development-ads-price-desc-en",
    price_asc: "zameen-development-ads-price-asc-en",
  }

  static getAdsIndex(sort = "popular") {
    if (!this.#indexInsMaping[sort]) {
      this.#indexInsMaping[sort] = this.getClientInstance().initIndex(this.#indexNameMapping[sort])
    }
    return this.#indexInsMaping[sort]
  }

  static getClientInstance() {
    if (!this.#client) {
      this.#client = algoliasearch(
        process.env.REACT_APP_ZAMEEN_ALGOLIA_APP_ID,
        process.env.REACT_APP_ZAMEEN_ALGOLIA_KEY
      )
    }
    return this.#client
  }

  static getLocationsInstance() {
    if (!this.#locationsClient) {
      this.#locationsClient = algoliasearch(
        process.env.REACT_APP_ZAMEEN_ALGOLIA_APP_ID,
        process.env.REACT_APP_ZAMEEN_ALGOLIA_KEY
      )
    }
    return this.#locationsClient
  }

  static getNewProjectsInstance() {
    if (!this.#newProjects) {
      this.#newProjects = algoliasearch(
        process.env.REACT_APP_ZAMEEN_ALGOLIA_APP_ID,
        process.env.REACT_APP_ZAMEEN_ALGOLIA_KEY
      )
    }
    return this.#newProjects
  }

  static getDevelopersInstance() {
    if (!this.#developers) {
      this.#developers = algoliasearch(
        process.env.REACT_APP_ZAMEEN_ALGOLIA_APP_ID,
        process.env.REACT_APP_ZAMEEN_ALGOLIA_KEY
      )
    }
    return this.#developers
  }
  static getPageKeyPart(pageKey) {
    return pageKey ? ` AND published_pages.title:${pageKey}` : ""
  }

  static getSubLocationsQuery = (locationId, pageKey, levelLimit = ["level > 3"]) => {
    return {
      filters: `city_id=${locationId} ${this.getPageKeyPart(pageKey)}`,
      numericFilters: levelLimit,
      hitsPerPage: 12,
    }
  }

  static getNewProjectsSuggestionsQuery = cityId => {
    return cityId ? { filters: `cityid=${cityId}` } : {}
  }

  static getDevelopersSuggestionsQuery = cityId => {
    return {
      filters: `cityid=${cityId}`,
    }
  }
  static getDevelopersObject = ids => {
    return {
      filters: ids
        .map(id => {
          return `developers_id=${id}`
        })
        .join(" OR "),
    }
  }
  static getNewProjectsObject = ids => {
    return {
      filters: ids
        .map(id => {
          return `dev_id=${id}`
        })
        .join(" OR "),
    }
  }
  static getPopularLocalities = (cityId, pageKey) => {
    return {
      filters: `city_id=${cityId} ${this.getPageKeyPart(pageKey)}`,
      numericFilters: ["level > 3"],
      hitsPerPage: 12,
    }
  }

  static getLocationsIndex() {
    if (!this.#locationIndex) {
      this.#locationIndex = this.getLocationsInstance().initIndex("locations_laravel")
    }
    return this.#locationIndex
  }
  static getNewProjectsIndex() {
    if (!this.#newProjects) {
      this.#newProjects = this.getNewProjectsInstance().initIndex("zn_developments")
    }
    return this.#newProjects
  }
  static getDevelopersIndex() {
    if (!this.#developers) {
      this.#developers = this.getDevelopersInstance().initIndex("zn_developers")
    }
    return this.#developers
  }
  static getAgents() {
    if (!this.#agents) {
      this.#agents = this.getAgentsInstance().initIndex("agents_laravel")
    }
    return this.#agents
  }

  static getAgentsQuery = cityId => {
    return {
      filters: `city_id=${cityId}`,
    }
  }

  static getAgentsInstance() {
    if (!this.#agents) {
      this.#agents = algoliasearch(
        process.env.REACT_APP_ZAMEEN_ALGOLIA_APP_ID,
        process.env.REACT_APP_ZAMEEN_ALGOLIA_KEY
      )
    }
    return this.#agents
  }

  static getCitiesQuery = pageKey => {
    const pageKeyPart = pageKey ? `published_pages.title:${pageKey}` : ""

    return {
      filters: `${pageKeyPart}`,
      numericFilters: ["level = 3"],
      hitsPerPage: 10,
    }
  }

  static getCityQuery = (cityId, pageKey) => {
    return {
      filters: `city_id=${cityId} ${this.getPageKeyPart(pageKey)}`,
      numericFilters: ["level = 3"],
      hitsPerPage: 1,
    }
  }

  static getCityQuery = (cityId, pageKey) => {
    return {
      filters: `city_id=${cityId} ${this.getPageKeyPart(pageKey)}`,
      numericFilters: ["level = 3"],
      hitsPerPage: 1,
    }
  }

  static getAllCities = () => {
    return {
      numericFilters: ["level = 3"],
      hitsPerPage: 500,
    }
  }
  static getCityObject = id => {
    return {
      numericFilters: [`location_id = ${id}`],
      hitsPerPage: 1,
    }
  }
}
