import { Modal } from "components/common";

const ClearAllConfirmationModal = ({
  setIsVisible,
  isVisible,
  onClear = () => {},
}) => {
  return (
    <Modal
      visible={isVisible}
      title={"Reset Schema"}
      onOk={() => {
        onClear();
        setIsVisible(false);
      }}
      onCancel={() => setIsVisible(false)}
      destroyOnClose
    >
      Are you sure you want to reset schema?
    </Modal>
  );
};

export default ClearAllConfirmationModal;
