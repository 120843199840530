import {
  Button,
  Card,
  DataTable,
  Group,
  Modal,
  Title,
  notification,
} from "components/common";
import { ActionButtons } from "components/custom";
import { SCRIPT_EDIT_ICON, TABLE_DELETE_ICON } from "components/svg";
import { SCRIPT_TEMPLATE_SETTINGS_FORM_PATH } from "constants/app-paths";
import { canUserModify } from "features/presentation/utils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import theme from "utils/themeVars";
import {
  useDeleteTemplateSettingMutation,
  useGetAllScriptsTemplateSettingsQuery,
} from "../api";

const ScriptTemplateSettings = () => {
  const user = useSelector((state) => state.app.user?.info);
  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();
  const {
    data,
    isFetching: dataFetching,
    isLoading: dataLoading,
    error: dataError,
    refetch,
  } = useGetAllScriptsTemplateSettingsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteTemplateSetting, { isLoading: deleteSettingLoading }] =
    useDeleteTemplateSettingMutation();
  const scriptTemplateSettingsColumns = [
    {
      title: "No.",
      dataIndex: "key",
      className: "tableStartSpace",
      width: 50,
    },
    {
      title: "Template Name",
      dataIndex: "name",
      width: 300,
    },
    {
      title: "Template Type",
      dataIndex: "type",
      width: 80,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      width: 80,
    },

    { title: "Actions", dataIndex: "actions", width: 100 },
  ];

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>Template Settings</Title>
        <Button
          text={"Add"}
          icon={"MdOutlineSettings"}
          type="bordered"
          style={{
            borderColor: theme["blue100"],
            color: theme["blue200"],
            background: theme["light-gray"],
          }}
          onClick={() => {
            navigate(SCRIPT_TEMPLATE_SETTINGS_FORM_PATH);
          }}
          size="small"
        />
      </div>
    );
  };

  const getActionsList = (e) => [
    {
      key: "edit_template_settings",
      icon: <SCRIPT_EDIT_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["blue200"]
        : theme["gray500"],
      content: "Edit Template Setting",
      title: "Edit Template Setting",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      onClick: () => {
        navigate(
          `${SCRIPT_TEMPLATE_SETTINGS_FORM_PATH}?templateId=${e?.base_template_id}`
        );
      },
    },
    {
      key: "delete_template_settings",
      icon: <TABLE_DELETE_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["red"]
        : theme["gray500"],
      content: "Delete Template Setting",
      title: "Delete Template Setting",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      onClick: () => {
        setSelectedItem(e);
      },
    },
  ];

  const getTableData = () => {
    if (data?.list?.length) {
      return data?.list?.map((e, i) => ({
        ...e,
        key: i + 1,
        name: <div>{capitalizeFirstLetter(e?.name)}</div>,
        type: <div>{capitalizeFirstLetter(e?.type)}</div>,
        created_date: (
          <div style={{ display: "flex", gap: "8px" }}>
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }).format(new Date(e?.created_at))}
          </div>
        ),
        actions: <ActionButtons list={getActionsList(e)} />,
      }));
    } else {
      return [];
    }
  };

  const deleteTemplate = async () => {
    const res = await deleteTemplateSetting({
      base_template_id: selectedItem?.base_template_id,
    });
    if (res) {
      setSelectedItem(null);
      if (res?.error) {
        notification.error({ message: res?.error?.message });
      } else {
        notification.success({
          message: "Template Setting Deleted Successfully!",
        });
      }
    }
  };

  return (
    <Group gap="16px">
      <Card>{renderPageHeader()}</Card>
      <DataTable
        pagination={data?.pagination}
        paginationOnBottom={true}
        onChangePage={(queryObj) => setSearchParams(queryObj)}
        columns={scriptTemplateSettingsColumns}
        data={getTableData()}
        loading={dataLoading}
        error={dataError}
        onErrorRetry={refetch}
        skeletonLoading={dataFetching}
        stickyOffset={0}
        noUrlPush={false}
      />
      <Modal
        visible={!!selectedItem}
        setVisible={setSelectedItem}
        title={"Confirmation"}
        onOk={deleteTemplate}
        onCancel={() => setSelectedItem(null)}
        okText={"Delete"}
      >
        Are you sure you want to delete this template settings?
      </Modal>
    </Group>
  );
};
export default ScriptTemplateSettings;
