import { List } from "antd";
import { renderQuillHtml } from "configs/utils";
import QuestionItemView from "./questionItemView";
import theme from "utils/themeVars";

const ViewQuestionModalBody = ({ scriptQuestions }) => {
  const renderItem = (item) => {
    return (
      <List.Item style={{ position: "relative", marginBottom: "1%" }}>
        <div
          style={{
            content: '""',
            position: "absolute",
            left: "0",
            top: "4%",
            bottom: "0",
            width: "50%",
            borderLeft: `2px solid ${theme["blue200"]}`,
          }}
        />
        <List.Item.Meta
          title={renderQuillHtml(item?.description, undefined, {
            margin: "-10px 0px -10px -10px",
          })}
          description={<QuestionItemView question={item} />}
        />
      </List.Item>
    );
  };
  return <List dataSource={scriptQuestions} renderItem={renderItem} />;
};

export default ViewQuestionModalBody;
