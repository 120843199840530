import {
  Button,
  Checkbox,
  Modal,
  SelectInput,
  TextInput,
  Title,
  Tooltip,
  notification,
} from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import { TABLE_DELETE_ICON } from "components/svg";
import { useAddQuestionMutation } from "features/script/api";
import { generateUUID } from "pubnub";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setQuestionsData } from "store/scriptSlice";
import theme from "utils/themeVars";

const AddQuestionModal = ({
  visible,
  setVisible,
  questionData,
  explanations = [],
  scriptData,
  allScripts,
  allTOpics,
}) => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (questionData) {
      setData(questionData);
    } else {
      setData({
        questionId: generateUUID(),
        description: "",
        options: ["", "", "", ""],
        explanation: [],
        correctAnswer: [],
        topics: [],
        scripts: scriptData?.scriptId ? [scriptData?.scriptId] : [],
      });
    }
  }, [questionData, scriptData]);

  const [addQuestion, _] = useAddQuestionMutation();

  const handleAddQuestion = async () => {
    if (!data?.explanation?.length) {
      notification.error({ message: "Please select an explanation first!" });
    } else if (!data?.description?.length) {
      notification.error({
        message: "Please add description for the question!",
      });
    } else if (!data?.correctAnswer?.length) {
      notification.error({ message: "Please select correct answer!" });
    } else {
      if (scriptData && scriptData?.scriptId) {
        const res = await addQuestion({
          question: [
            {
              ...data,
              correctAnswer: data?.correctAnswer,
            },
          ],
        });
        if (res?.error) {
          notification.error({ message: "Network Error" });
        } else {
          notification.success({ message: "Question Added Successfully!" });
          setVisible(false);
          setData(null);
        }
      } else {
        let tempQuestions = [];
        if (scriptData?.questions?.length) {
          tempQuestions = JSON.parse(JSON.stringify(scriptData?.questions));
        }
        tempQuestions.push(data);
        dispatch(setQuestionsData(tempQuestions));
        notification.success({ message: "Question Added Successfully!" });
        setVisible(false);
        setData(null);
      }
    }
  };

  const renderActions = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "baseline",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <SelectInput
            options={explanations}
            style={{ width: 180 }}
            placeholder="Select Explanation"
            getOptionValue={(e) => e?.title}
            getOptionLabel={(e) => e?.title}
            value={data?.explanation}
            mode="multiple"
            onChange={(value) => {
              setData((prev) => ({
                ...prev,
                explanation: value,
              }));
            }}
            getPopupContainer={false}
          />
          <SelectInput
            options={allScripts}
            style={{ width: 180 }}
            placeholder="Select Scripts"
            getOptionValue={(e) => e?._id}
            getOptionLabel={(e) => e?.page_name}
            value={data?.scripts}
            mode="multiple"
            onChange={(value, options) => {
              setData((prev) => ({
                ...prev,
                scripts: value,
              }));
            }}
            getPopupContainer={false}
          />
          <SelectInput
            placeholder="Add Topics"
            options={
              allTOpics?.data?.length
                ? allTOpics?.data?.map((e) => e?.PageTopics)
                : []
            }
            style={{ width: 180 }}
            getOptionValue={(e) => e}
            getOptionLabel={(e) => e}
            onChange={(val) => {
              setData((prev) => ({
                ...prev,
                topics: val,
              }));
            }}
            value={data?.topics}
            mode="multiple"
          />
        </div>
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <div style={{}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Title level={3}>Create Question:</Title>
          {renderActions()}
        </div>

        <TextEditor
          key={"Question"}
          placeholder={"Add question here"}
          value={data?.description}
          onChange={(value) => {
            setData((prev) => ({ ...prev, description: value }));
          }}
          editorHeight={200}
        />
      </div>
    );
  };

  const handleRemoveAnswer = (index) => {
    const tempData = JSON.parse(JSON.stringify(data));
    tempData.options = tempData.options.filter((e, i) => i != index);
    tempData.correctAnswer = tempData?.correctAnswer?.find((e) => e == index)
      ? []
      : tempData.correctAnswer;
    setData(tempData);
  };

  const answerItem = (item, index) => {
    const alphabetIndex = String.fromCharCode(65 + index);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px auto",
          gap: "10px",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Tooltip title="Mark as right option">
          <Checkbox
            key={index}
            onChange={() =>
              setData((prev) => ({
                ...prev,
                correctAnswer: [item],
              }))
            }
            checked={data?.correctAnswer?.find((e) => e == item)}
            style={{ backgroundColor: "white" }}
          ></Checkbox>
        </Tooltip>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: theme["blue50"],
            color: theme["blue200"],
          }}
        >
          {alphabetIndex}
        </div>
        <div style={{ width: "100%", paddingLeft: "10px" }}>
          <TextInput
            key={`answer-${index}`}
            value={item}
            placeholder={"Add Option"}
            onChange={(e) => {
              handleChangeAnswer(e?.target?.value, index);
            }}
          />
        </div>

        {!(index == data?.options?.length - 1 && data?.options?.length < 5) &&
          data?.options?.length > 2 && (
            <Button
              icon={<TABLE_DELETE_ICON />}
              iconProps={{
                size: "1.8rem",
                color: "#fc5327",
                backgroundColor: "white",
              }}
              style={{
                backgroundColor: "white",
              }}
              outlined
              onClick={() => {
                handleRemoveAnswer(index);
              }}
            />
          )}

        {index == data?.options?.length - 1 && data?.options?.length < 5 && (
          <Button
            icon="HiPlus"
            style={{
              background: "transparent",
              border: `1px solid ${theme["blue100"]}`,
              color: theme["primary-color"],
            }}
            iconProps={{ color: theme["primary-color"] }}
            onClick={handelAddAnswer}
          />
        )}
      </div>
    );
  };

  const handelAddAnswer = () => {
    setData((prev) => ({
      ...prev,
      options: [...prev?.options, ""],
    }));
  };

  const handleChangeAnswer = (answer, index) => {
    const tempData = JSON.parse(JSON.stringify(data));
    tempData.options = tempData.options.map((e, i) => {
      if (i == index) {
        return answer;
      } else {
        return e;
      }
    });
    setData(tempData);
  };

  const renderAdditionalInfo = () => {
    return (
      <div>
        <Title level={3}>Add Additional Info:</Title>
        <TextEditor
          key={"Question"}
          placeholder={"Add question here"}
          value={data?.additionalInfo}
          onChange={(value) => {
            setData((prev) => ({ ...prev, additionalInfo: value }));
          }}
          editorHeight={200}
        />
      </div>
    );
  };

  const renderAnswers = () => {
    return data?.options?.length ? (
      <div style={{ marginTop: "1%" }}>
        <Title level={3}>Create Answers:</Title>
        {data?.options?.map((item, index) => answerItem(item, index))}
      </div>
    ) : null;
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        setData(null);
      }}
      onOk={handleAddQuestion}
      title="Add Question"
      description="Add Question to Script and Link it with Explanation and Topic"
      width="1400px"
      okText="Add Question"
      destroyOnClose
    >
      <div
        style={{
          border: `1px solid ${theme["blue100"]}`,
          borderRadius: "10px",
          margin: "2%",
          padding: "2%",
        }}
      >
        {renderQuestion()}
        <Title style={{ marginTop: "1%" }} level={5}>
          Question Id: {data?.questionId}
        </Title>
        {renderAnswers()}
        {renderAdditionalInfo()}
      </div>
    </Modal>
  );
};

export default AddQuestionModal;
