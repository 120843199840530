import {
  createQueryParamsForFilterRequest,
  filterTagsTransform,
  getAppliedFilterListObject,
  modifyUrlParamsForExistingSearches,
} from "utils/filters";
import { useEffect, useMemo, useState } from "react";

import { useSearchParams } from "react-router-dom";
// import { useLazyGetFilterTagsQuery } from "features/shared/api"
// import { useLazyGetFilterTagsQuery } from "features/shared/api"

const useFilters = (
  filterList,
  ignoreFilterKeys = [],
  fn,
  dependencyArray = [],
  mapPreviousFilters
) => {
  const [filtersObject, setFiltersObject] = useState({});
  const [filterTags, setFilterTags] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParams = mapPreviousFilters
    ? modifyUrlParamsForExistingSearches(Object.fromEntries([...searchParams]))
    : Object.fromEntries([...searchParams]);

  // const [getFiltersTags, { {}, false, false }] = useLazyGetFilterTagsQuery()

  const requestParams = useMemo(() => {
    const rp = createQueryParamsForFilterRequest(urlParams, filterList);
    return rp;
  }, [JSON.stringify(urlParams), filterList.length]);

  const appliedFilterListParams = useMemo(() => {
    const afp = getAppliedFilterListObject(urlParams, filterList);
    return afp;
  }, [JSON.stringify(urlParams), filterList.length]);

  // useEffect(() => {
  //   false{} && Object.keys(requestParams)?.length && getFiltersTags(requestParams)
  // }, [false{}])

  useEffect(() => {
    if (
      (Object.keys(appliedFilterListParams)?.length ||
        filterTags?.list?.length) &&
      !false &&
      !false
    ) {
      setFilterTags(
        filterTagsTransform({}, { queryObject: urlParams, filterList })
      );
    }
  }, []);

  useEffect(() => {
    if (filterList) {
      setTagsAndFilters(urlParams, true);
    }
  }, [...dependencyArray, JSON.stringify(urlParams)]);

  const setTagsAndFilters = (
    queryObj = urlParams,
    isApplied = false,
    appliedParams = Object.fromEntries([...searchParams])
  ) => {
    let newQueryObj = { ...queryObj };
    setFiltersObject(newQueryObj);

    if (isApplied) {
      Object.keys(newQueryObj).forEach(
        (key) => !newQueryObj[key] && delete newQueryObj[key]
      );
      Object.keys(appliedParams).forEach((key) => {
        if (ignoreFilterKeys.includes(key)) {
          newQueryObj[key] = appliedParams[key];
        }
      });
      setSearchParams(newQueryObj);
    }
  };

  return {
    filtersObject,
    setFiltersObject,
    setTagsAndFilters,
    filterTags,
    isLoading: false,
  };
};

export default useFilters;
