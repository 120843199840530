import { getPaginationObject } from "configs/utils";
import { getUniqueId } from "../utils";

export const allLibrariesTransformer = (data) => {
  return {
    list: data?.data.length ? data.data : [],
    pagination: getPaginationObject(data.pagination),
  };
};

export const presentationTransformer = (data) => {
  let slides = data?.slides.map((slide) => {
    let newSlide = transformSlide(slide);
    if (slide?.extraInformation) {
      let b = slide?.extraInformation.map((ex) => transformSubSlide(ex));
      newSlide.data = [...newSlide.data, ...b];
    }
    return newSlide;
  });
  let g = { ...data };
  g.slides = [...slides];
  return {
    data: g,
  };
};

export const transformSlide = (slide) => {
  switch (slide.type) {
    case "case":
      let newString = "";
      slide.cases.forEach((element) => {
        if (element.title) {
          newString += `<h2><strong>${element.title}</strong></h2>`;
        }
        if (element.description) {
          newString += element.description;
        }
      });
      let t = {
        id: getUniqueId([]),
        backgroundColor: slide.backgroundColor ?? "white",
        title: slide.title,
        data: [
          {
            title: "",
            type: "slide",
            placeholder: "Start adding content to your slide...",
            data: newString,
          },
        ],
      };
      return t;
    case "description":
      let newString1 = "";
      if (slide.description.title) {
        newString1 += `<h2><strong>${slide.description.title}</strong></h2>`;
      }
      if (slide.description.description) {
        newString1 += slide.description.description;
      }

      let pp = {
        id: getUniqueId([]),
        backgroundColor: slide.backgroundColor ?? "white",
        title: slide.title,
        data: [
          {
            title: "",
            type: "slide",
            placeholder: "Start adding content to your slide...",
            data: newString1,
          },
        ],
      };
      return pp;
    case "poll":
      const poll = { ...slide.polls[0] };
      let r = {
        id: getUniqueId([]),
        title: slide.title,
        backgroundColor: slide.backgroundColor ?? "white",
        data: [
          {
            status: slide.status ?? "created",
            type: "poll",
            id: getUniqueId([]),
            data: {
              question: poll.question ?? "",
              options: poll.options.map((opt) => ({
                placeholder: "Add your option here",
                description: opt.value,
                isAnswer: opt.is_correct ?? false,
                votes: opt.votes ?? 0,
              })),
              votes: poll?.votes,
            },
          },
        ],
      };

      return r;
    case "image":
      let images = [];
      if (slide.image) {
        images = [
          {
            url: slide.image.url,
          },
        ];
      } else {
        images = slide.images;
      }
      let o = {
        id: getUniqueId([]),
        title: slide.title,
        backgroundColor: slide.backgroundColor ?? "white",
        data: [
          {
            id: getUniqueId([]),
            type: "image",
            data: {
              images: images,
            },
          },
        ],
      };
      return o;
    default:
      return slide;
  }
};

export const transformSubSlide = (slide) => {
  switch (slide.type) {
    case "case":
      let newString = "";
      slide.cases.forEach((element) => {
        if (element.title) {
          newString += `<h2><strong>${element.title}</strong></h2>`;
        }
        if (element.description) {
          newString += element.description;
        }
      });
      let t = {
        type: "slide",
        data: newString,
        id: getUniqueId([]),
      };
      return t;
    case "description":
      let newString1 = "";
      if (slide.description.title) {
        newString1 += `<h2><strong>${slide.description.title}</strong></h2>`;
      }
      if (slide.description.description) {
        newString1 += slide.description.description;
      }

      let pp = {
        type: "slide",
        data: newString1,
        id: getUniqueId([]),
      };
      return pp;
    case "poll":
      const poll = { ...slide.polls[0] };
      let r = {
        type: "poll",
        id: getUniqueId([]),
        data: {
          question: poll.question ?? "",
          options: poll.options.map((opt) => ({
            placeholder: "Add your option here",
            description: opt.value,
            isAnswer: false,
          })),
        },
      };

      return r;
    case "image":
      let images = [];
      if (slide.image) {
        images = [
          {
            url: slide.image.url,
          },
        ];
      } else {
        images = slide.images;
      }
      let o = {
        id: getUniqueId([]),
        type: "image",
        data: {
          images: images,
        },
      };
      return o;
    default:
      return slide;
  }
};

export const dataTransformer = (data) => {
  let newData = JSON.parse(JSON.stringify(data?.slides));
  newData = newData?.map((slide) => {
    let newSlide = transformDataSlide(slide);
    if (slide.data.length > 1) {
      let b = slide.data.slice(1).map((ex) => transformSubDataSlide(ex));
      newSlide.extraInformation = [...b];
    }
    return newSlide;
  });
  let g = { ...data };
  g.slides = [...newData];
  return g;
};

export const transformDataSlide = (slide) => {
  switch (slide.data[0].type) {
    case "slide":
      let t = {
        cases: [
          {
            title: slide.data[0].title,
            description: slide.data[0].data,
          },
        ],
        title: slide.title,
        type: "case",
        slide_id: getUniqueId([]),
        backgroundColor: slide.backgroundColor ?? "white",
        gradient: false,
      };
      return t;
    case "poll":
      let r = {
        polls: [
          {
            question: slide.data[0].data.question,
            options: slide.data[0].data.options.map((opt) => ({
              value: opt.description,
              votes: opt.votes,
              is_correct: opt.isAnswer,
            })),
          },
        ],
        title: slide.title,
        type: "poll",
        status: slide.data[0].status,
        slide_id: getUniqueId([]),
        backgroundColor: slide.backgroundColor,
        gradient: false,
      };

      return r;
    case "image":
      let o = {
        images: slide.data[0].data.images,
        title: slide.title,
        type: "image",
        slide_id: getUniqueId([]),
        backgroundColor: slide.backgroundColor,
        gradient: false,
      };
      return o;
    default:
      return slide;
  }
};

export const transformSubDataSlide = (slide) => {
  switch (slide.type) {
    case "slide":
      let t = {
        description: {
          title: null,
          description: slide.data,
        },
        type: "description",
        slide_id: getUniqueId([]),
      };
      return t;
    // case "poll":
    //   let r = {
    //     polls: [
    //       {
    //         question:slide.data[0].data.question,
    //         options:slide.data[0].data.options.map((opt)=>({
    //           value:opt.description,
    //           votes:opt.votes,
    //           is_correct:opt.isAnswer
    //         }))
    //       }
    //     ],
    //     title:slide.title,
    //     type:"poll",
    //     status:slide.data[0].status,
    //     slide_id: getUniqueId([]),
    //     backgroundColor:slide.backgroundColor,
    //     gradient:false,
    //   };

    //   return r;
    case "image":
      let o = {
        images: slide.data.images,
        title: slide.title,
        type: "image",
        slide_id: getUniqueId([]),
      };
      return o;
    default:
      return slide;
  }
};

export const validateSubmitData = (presentation) => {
  if (!presentation.name || presentation.name == "") {
    return "Presentation name is required!";
  }
  if (!presentation.speciality || presentation.speciality.length == 0) {
    return "Presentation speciality is required!";
  }
  if (!presentation.slides || presentation.slides.length == 0) {
    return "Presentation should have atleast one slide!";
  }

  presentation.slides.forEach((element) => {
    if (element.data.length == 0) {
      return "Each slide should have atleast one horizantal subslide!";
    }
  });

  return true;
};
