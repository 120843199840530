import {
  Button,
  Card,
  DataTable,
  Dropdown,
  Group,
  Icon,
  Modal,
  Tag,
  Title,
  notification,
} from "components/common";
import { ActionButtons, Filters } from "components/custom";
import {
  PRESENTATION_ADD_PATH,
  PRESENTATION_DETAIL_PATH,
  PRESENTATION_EDIT_PATH,
} from "constants/app-paths";
import { useEffect, useMemo, useRef, useState } from "react";
import { HiPresentationChartBar } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import theme from "utils/themeVars";
import {
  useDeletePresentationMutation,
  useGetAllPresentationsQuery,
  useResetPresentationMutation,
} from "../apis";
import { canUserModify } from "../utils";
import { FiMonitor } from "react-icons/fi";
import { Input, Pagination, Row } from "antd";
import {
  ADD_PRESENTATION_ICON,
  RESET_PRESENTATION_ICON,
  SCRIPT_EDIT_ICON,
  TABLE_DELETE_ICON,
} from "components/svg";
import { menuItemIcons } from "../../../constants/menuItems/menuItems-icons";


const libraryTableColumns = [
  {
    title: "No.",
    dataIndex: "key",
    className: "tableStartSpace",
    width: 80,
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 400,
  },
  {
    title: "Slides",
    dataIndex: "no_of_slides",
    width: 130,
  },
  { title: "Status", dataIndex: "status", width: 130 },
  { title: "Creation Date", dataIndex: "updated_at", width: 130 },
  { title: "Actions", dataIndex: "actions", width: 130 },
];

const PresentationTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);
  const [isMobile, setIsMobile] = useState(false);
  const [filterValue, setFilterValue] = useState(queryObj?.name || "");

  const { data, isFetching, isLoading, error, refetch } =
    useGetAllPresentationsQuery(queryObj, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    setIsMobile(window.innerWidth <= 700);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  const [page, setPage] = useState(1);
  const [pageSizeSave, setPageSize] = useState(10);

  useEffect(() => {
    setPage(queryObj.page ? parseInt(queryObj.page) : 1);
    setPageSize(queryObj.per_page ? queryObj.per_page : 10);
  }, [queryObj.page, queryObj.per_page]);

  const modelRef = useRef();
  const [action, setAction] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [deletePresentation, deletePresentationResponse] =
    useDeletePresentationMutation();
  const [resetPresentation, resetPresentationResponse] =
    useResetPresentationMutation();

  const navigate = useNavigate();
  const getActionsList = (e) => [
    {
      key: "reset_presentation",
      icon: <RESET_PRESENTATION_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["blue200"]
        : theme["gray500"],
      content: "Reset Presentation",
      title: "Reset Presentation",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      onClick: () => {
        setAction("Reset");
        setSelectedRow(e._id);
        modelRef.current.setVisible(true);
      },
    },
    {
      key: "edit_presentation",
      icon: <SCRIPT_EDIT_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["blue200"]
        : theme["gray500"],
      content: "Edit Presentation",
      title: "Edit Presentation",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      onClick: () => {
        navigate(PRESENTATION_EDIT_PATH.replace(":id", e._id));
      },
    },
    {
      key: "delete_presentation",
      icon: <TABLE_DELETE_ICON />,
      color: canUserModify(user, e?.editor_id)
        ? theme["red"]
        : theme["gray500"],
      content: "Delete Presentation",
      title: "Delete Presentation",
      disabled: canUserModify(user, e?.editor_id) ? false : true,
      onClick: () => {
        setAction("Delete");
        setSelectedRow(e._id);
        modelRef.current.setVisible(true);
      },
    },
  ];

  const user = useSelector((state) => state.app.user?.info);

  const tableData = () => {
    return data?.list?.length
      ? data.list.map((e, index) => ({
          ...e,
          key: index + 1,
          name: (
            <div
              style={{ color: theme["primary-color"] }}
              className="pointer"
              onClick={() =>
                navigate(PRESENTATION_DETAIL_PATH.replace(":id", e._id))
              }
            >
              {capitalizeFirstLetter(e?.name)}
            </div>
          ),
          status: (
            <Tag color="#60A7DB" style={{ borderRadius: "40px" }}>
              {e.is_completed ? "Completed" : "Not Completed"}
            </Tag>
          ),
          updated_at: (
            <div style={{ display: "flex", gap: "8px" }}>
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }).format(new Date(e.updated_at))}
            </div>
          ),
          actions: <ActionButtons list={getActionsList(e)} />,
        }))
      : [];
  };

  const breadCrumbs = [
    { key: "Home", href: "/dashboard" },
    { key: "Presentations" },
  ];

  const filterList = useMemo(() => {
    return [
      {
        label: "none",
        type: "input",
        key: "name",
        placeholder: "Search by Presentation Name",
      },
    ];
  }, []);

  const onHandleChange = (page, pageSize) => {
    setSearchParams({
      ...queryObj,
      page: parseInt(page),
      per_page: pageSize,
    });
  };

  const renderPresentationWeb = () => {
    return (
      <Group gap="16px" className={"listings-content-adjustments"}>
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" , width:"99.4%" }}>
            <Title level={2}>Presentations</Title>

            <Button
              type="bordered"
              icon={<FiMonitor color="rgb(75, 147, 255)" size={20}/>}
              onClick={() => {
                navigate(PRESENTATION_ADD_PATH);
              }}
              style={{
                color: theme["blue200"],
                background: theme["light-gray"],
                fontWeight: 800,
                fontSize: "1.2rem",
                padding: "12px, 12px, 13px, 12px",
                borderRadius: "4px",
                borderColor: theme["blue100"],
              }}
            >
              Add
            </Button>
          </div>

          <Filters
            list={filterList || []}
            loading={false}
            skeletonLoading={false}
            showClearAll={false}
          />
        </Card>
        <DataTable
          pagination={{
            totalCount: data?.pagination?.totalCount,
            pageOptions: [10, 25, 50],
          }}
          onChangePage={(queryObj) => setSearchParams(queryObj)}
          columns={libraryTableColumns}
          data={tableData()}
          loading={isLoading}
          error={error}
          onErrorRetry={refetch}
          skeletonLoading={isFetching}
          stickyOffset={0}
          noUrlPush={false}
        />
      </Group>
    );
  };

  const renderPresentationMob = () => {
    return (
      <>
        <Card>
          <Title level={2}>Presentations</Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Input
              label="Search by Name"
              placeholder="Search by Name"
              onChange={(e) => setFilterValue(e?.target?.value)}
              value={filterValue}
            ></Input>
            <Button
              onClick={() => {
                setSearchParams({ name: filterValue });
              }}
              icon={"FiSearch"}
              type="primary"
              size={"large"}
              className="pi-12"
              style={{ height: 48, boxShadow: "none" }}
              loading={isFetching}
            />
          </div>
        </Card>
        {data?.list?.length
          ? data?.list.map((item) => (
              <Card
                onClick={() => {
                  navigate(PRESENTATION_DETAIL_PATH.replace(":id", item._id));
                }}
                style={{ marginBottom: "20px" }}
                className="pointer"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>No of Slides: {item?.no_of_slides ?? "-"}</span>
                  <div>
                    <div style={{ display: "flex" }}>
                      <Tag color="#60A7DB" style={{ borderRadius: "40px" }}>
                        {item.is_completed ? "Completed" : "Not Completed"}
                      </Tag>
                      <Dropdown
                        options={[
                          // {
                          //   key: 1,
                          //   label: (
                          //     <div style={{ display: "flex", gap: "10px" }}>
                          //       <Icon
                          //         icon="FiEdit"
                          //         size="14px"
                          //         color={theme["primary-color"]}
                          //       />
                          //       <span>Edit</span>
                          //     </div>
                          //   ),
                          //   onClick: (e) => {
                          //     navigate(
                          //       PRESENTATION_EDIT_PATH.replace(":id", item._id)
                          //     );
                          //     e?.domEvent?.stopPropagation();
                          //   },
                          // },
                          {
                            key: 2,
                            label: (
                              <div style={{ display: "flex", gap: "10px" }}>
                                <Icon
                                  icon="MdRefresh"
                                  size="14px"
                                  color={theme["primary-color"]}
                                />
                                <span>Reset Polls</span>
                              </div>
                            ),
                            onClick: (e) => {
                              setAction("Reset");
                              setSelectedRow(item._id);
                              modelRef.current.setVisible(true);
                              e?.domEvent?.stopPropagation();
                            },
                          },
                          {
                            key: 3,
                            label: (
                              <div style={{ display: "flex", gap: "10px" }}>
                                <Icon
                                  icon="RiDeleteBin5Line"
                                  size="14px"
                                  color={theme["primary-color"]}
                                />
                                <span>Delete</span>
                              </div>
                            ),
                            onClick: (e) => {
                              setAction("Delete");
                              setSelectedRow(item._id);
                              modelRef.current.setVisible(true);
                              e?.domEvent?.stopPropagation();
                            },
                          },
                        ]}
                        suffixIcon=""
                        trigger={"click"}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Button
                          size="small"
                          style={{ background: "transparent" }}
                          icon={"BsThreeDotsVertical"}
                        />
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <Title level={3}>{capitalizeFirstLetter(item?.name)}</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                  className={"gray-800"}
                >
                  <div>
                    <Icon icon="BiCalendar" size="14px" />
                  </div>
                  <div>{`Date of Upload: ${new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(item.updated_at))}`}</div>
                </div>
              </Card>
            ))
          : null}
        <Row justify="end" style={{ padding: "10px 16px 16px" }}>
          <Pagination
            onChange={onHandleChange}
            pageSizeOptions={[10, 25, 50]}
            pageSize={pageSizeSave}
            defaultCurrent={page}
            current={page}
            total={data?.pagination?.totalCount}
            showSizeChanger
          />
        </Row>
      </>
    );
  };

  return (
    <>
      {isMobile ? renderPresentationMob() : renderPresentationWeb()}
      <Modal
        ref={modelRef}
        onOk={() => {
          if (!action) {
            return;
          }
          if (action == "Delete") {
            deletePresentation(selectedRow)
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Presentation has been removed!",
                });
              })
              .catch((e) => {
                notification.error({
                  message: e?.message,
                });
              });
          } else if (action == "Reset") {
            resetPresentation(selectedRow)
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Presentation has been reset!",
                });
              })
              .catch((e) => {
                notification.error({
                  message: e?.message,
                });
              });
          }
          modelRef.current.setVisible(false);
          setAction(null);
          setSelectedRow(null);
          refetch();
        }}
        onCancel={() => modelRef.current.setVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
        title={`${action} Presentation`}
      >
        Are you sure you want to {action} this presentation?
      </Modal>
    </>
  );
};

export default PresentationTable;
