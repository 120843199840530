import { DateFormat, Icon, Skeleton, Text, Tooltip } from "../../common"

import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Space } from "antd"

const IconTextDate = props => {
  const {
    icon,
    iconProps,
    title,
    gap,
    textType,
    loading,
    loadingProps,
    message,
    date,
    dateClassName = "gray-800",
    suffixIcon,
    linkInfo,
    from,
  } = props

  const renderComponent = () => (
    <Space size={gap} style={{ flexDirection: iconProps.placement === "end" ? "row-reverse" : "" }}>
      {icon &&
        (loading ? (
          <Skeleton type="avatar" size={loadingProps.avatarSize || "small"} />
        ) : message ? (
          <div style={{ cursor: "pointer" }}>
            <Tooltip placement="top" title={message}>
              <Icon icon={icon} {...iconProps} />
            </Tooltip>
          </div>
        ) : (
          <Icon
            icon={icon}
            size={iconProps.size}
            color={iconProps.color}
            hasBackground={iconProps.hasBackground}
            {...iconProps}
          />
        ))}
      {loading ? (
        <Skeleton type="button" size={loadingProps.rectSize || "small"} />
      ) : (
        <div>
          <Space>
            {linkInfo ? (
              <Link to={linkInfo} state={{ hasPath: true, from }} className="text-link">
                {title}
              </Link>
            ) : (
              <Text type={textType}>{title}</Text>
            )}
            {suffixIcon}
          </Space>
          <DateFormat date={date} className={dateClassName} />
        </div>
      )}
    </Space>
  )

  return renderComponent()
}

IconTextDate.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textColor: PropTypes.string,
  loading: PropTypes.bool,
  loadingProps: PropTypes.object,
  lead: PropTypes.bool,
}

IconTextDate.defaultProps = {
  icon: null,
  iconProps: { size: "1em" },
  loadingProps: { avatarSize: "small", rectSize: "small" },
  title: "title",
}

export default IconTextDate
