import React, { useState } from "react";

const ColorBoard = ({ onSelectColor }) => {
  // Array of colors
  const colors = [
    "#000000",
    "#2b2b2b",
    "#434343",
    "#383838",
    "#5c5b5b",
    "#a6a6a6",
    "#c2bebe",
    "#edebeb",
    "#dbd9d9",
    "#ffffff",
    "#e8b7b0",
    "#f4cccc",
    "#fbe6cb",
    "#fef0d0",
    "#d8ead4",
    "#d1e0e3",
    "#c5dbfa",
    "#d1e2f6",
    "#dad2ea",
    "#e8d0db",
    "#db806c",
    "#e79b9d",
    "#f7ca9c",
    "#fce798",
    "#b6d5a9",
    "#a6c0f8",
    "#a1c4e9",
    "#b6a6d5",
    "#d4a7be",
    "#ffabd5"
  ];

  const colorRows = {
    firstRow: colors.slice(0, 10),
    secondRow: colors.slice(10, 20),
    thirdRow: colors.slice(20, 30),
  };

  const [selectedCircle, setSelectedCircle] = useState(null);

  const handleColorClick = (color) => {
    onSelectColor(color);
    setSelectedCircle(color);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {Object.keys(colorRows).map((rowKey, rowIndex) => (
        <div
          key={rowIndex}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          {colorRows[rowKey].map((color, colorIndex) => (
            <div
              key={colorIndex}
              style={{
                backgroundColor: color,
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                margin: "3px",
                cursor: "pointer",
                border: selectedCircle === color ? "2px solid black" : "none",
              }}
              onClick={() => handleColorClick(color)}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ColorBoard;
