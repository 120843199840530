import { Modal } from "components/common";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getScriptPayload } from "./getScriptPayload";
import ScriptDetailRightPanel from "../scriptDetails/scriptDetailRightPanel";

const PreviewModal = ({
  visible,
  setIsVisible,
  onConfirm,
  onCancel,
  onOk,
  editMode = false,
  previewMode = false,
  loading,
  ...rest
}) => {
  const scriptData = useSelector((state) => state?.scripts?.data);
  const user = useSelector((state) => state?.app?.user?.info);

  const getData = useCallback(() => {
    return getScriptPayload(scriptData, user);
  }, [scriptData, user]);


  return (
    <Modal
      visible={visible}
      onOk={() => {
        onConfirm();
      }}
      loading={loading}
      onCancel={onCancel}
      title={editMode ? "Update Script" : "Add Script"}
      okText={editMode ? "Update" : "Submit"}
      width={1200}
      {...rest}
    >
      <ScriptDetailRightPanel
        data={getData()}
        templateVersions={[]}
        updateSelectedTemplate={() => {}}
        isMobile={false}
        queryObj={{}}
        editMode={editMode}
        previewMode={previewMode}
        showBack={false}
      />
    </Modal>
  );
};

export default PreviewModal;
