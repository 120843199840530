import { Avatar, Dropdown } from "antd";
import { Button, Divider, Icon, Text, Title } from "components/common";
import theme from "utils/themeVars";
import styles from "./userMenu.module.less";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/appSlice";
import parentApi from "store/parentApi";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "constants/app-paths";

const UserMenu = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user?.info);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("presentation_url");
    navigate(LOGIN_PATH);
    dispatch(logout());
    dispatch(parentApi.util.resetApiState());
  };
  const items = [
    {
      label: "Logout",
      key: "3",
      icon: <Icon icon="FiLogOut" />,
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dropdown
          menu={{ items }}
          dropdownRender={(menu) => (
            <div className={styles.userMenuOuter}>
              <div className={styles.userMenuHeader}>
                <div className={styles.userAvatar}>
                  <Avatar size={54} icon={<Icon icon={"FiUser"} size={30} />} />
                </div>
                <div className={styles.userInfo}>
                  <Title level={5} style={{ marginBottom: "10px" }}>
                    {user?.firstname} {user?.lastname}
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Icon icon={"HiOutlineMail"} size={17} />
                    <Text>{user?.email}</Text>
                  </div>

                  <div className={styles.userDesignation}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <Icon icon={"MdSmartphone"} size={17} />
                      <Text>+{user?.phone_number}</Text>
                    </div>
                  </div>
                </div>
              </div>
              <Divider style={{ marginBlock: "1rem" }} />
              <div className="fs16">{menu}</div>
            </div>
          )}
          className="d-grid"
        >
          <div>
            <Button
              type="light"
              color={theme["primary-color"]}
              shape="circle"
              style={{ padding: 0, width: 30, height: 30 }}
            >
              {user?.profile_image ? (
                <Avatar size={34} src={user?.profile_image} />
              ) : (
                <Icon size={20} icon="FiUser" />
              )}
            </Button>
          </div>
        </Dropdown>
      </div>
    </>
  );
};

export default UserMenu;
