import { Button, Card, Icon, Dropdown, ColorBoard } from "components/common";
import DeleteConfirmationModal from "./deleteConfirmationModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import ClearAllConfirmationModal from "./clearAllModalConfirmation";
import { Badge, Menu } from "antd";
import ColorPicker from "components/common/colorPicker/colorPicker";
import styles from "./index.module.less";

const ActionHeader = ({
  onAddNode,
  onClearSchema,
  nodes,
  edges,
  onDelete,
  onChangeColor,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [clearAllModalVisible, setClearAllModalVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [colorBoard, setColorBoard] = useState("");

  useEffect(() => {
    if (nodes?.length) {
      const findNode = nodes?.find((e) => e?.selected);
      setSelectedNode(findNode);
    } else {
      setSelectedNode(null);
    }
    if (edges?.length) {
      const findEdge = edges?.find((e) => e?.selected);
      setSelectedEdge(findEdge);
    } else {
      setSelectedEdge(null);
    }
  }, [nodes, edges]);

  const actions = useMemo(
    () => [
      {
        id: 1,
        icon: "MdOutlineRectangle",
        onClick: () => {
          onAddNode("processNode", "", {
            width: 200,
            height: 70,
          });
        },
      },
      {
        id: 2,
        icon: "BsDiamond",
        onClick: () => {
          onAddNode("decisionNode", "");
        },
      },
      {
        id: 3,
        icon: "MdOutlineCircle",
        onClick: () => {
          onAddNode("startEnd", "");
        },
      },
      {
        id: 4,
        icon: "BiText",
        onClick: () => {
          onAddNode("textNode", "", {
            width: 200,
            height: 70,
          });
        },
      },
      {
        id: 5,
        icon: "MdDeleteForever",
        onClick: () => setDeleteModalVisible(true),
        disabled: !selectedNode && !selectedEdge,
      },
      {
        id: 6,
        icon: "VscClearAll",
        onClick: () => setClearAllModalVisible(true),
        disabled: !nodes?.length && !edges?.length,
      },
      { id: 7, type: "dropdown", disabled: !selectedNode && !selectedEdge },
    ],
    [selectedEdge, selectedNode, nodes, edges]
  );

  const colorOptions = [
    { key: "1", label: "White", value: "white" },
    // { label: "Black", value: "black" },
    { key: "2", label: "Blue", value: "#E3EDFF" },
    { key: "3", label: "Purple", value: "#FAEDFC" },
    { key: "4", label: "Orange", value: "#FFF2F2" },
    { key: "5", label: "Gray", value: "#F0F0F0" },
    { key: "6", label: "Yellow", value: "#F5F7E4" },
    { key: "7", label: "Green", value: "#E4F7E9" },
  ];

  const colors = (
    <Menu>
      {colorOptions.map((item) => (
        <Menu.Item>
          <div
            style={{ display: "flex", gap: "5px" }}
            onClick={() => {
              onChangeColor(
                selectedNode
                  ? selectedNode?.id
                  : selectedEdge
                  ? selectedEdge?.id
                  : null,
                item?.value
              );
            }}
          >
            <Icon />
            <Badge key={item?.key} color={item?.value} /> {item?.label}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  const handleColorSelect = (selectedColor) => {
    setColorBoard(selectedColor);
    setSelectedColor(selectedColor);
  };
  const renderActions = () => {
    return actions?.map((e) => {
      return e?.type == "dropdown" ? (
        <Dropdown
          visible={visible}
          onVisibleChange={(visible) => setVisible(visible)}
          className={`${styles["custom-dropdown"]} hide-dropdown-arrow`}
          overlay={
            <Menu>
              <Menu.Item>
                <ColorBoard onSelectColor={handleColorSelect} />
              </Menu.Item>
            </Menu>
          }
          dropdownRender={(menu) => (
            <div className="dropdown-content">
              {menu}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingTop: "2%",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    onChangeColor(
                      selectedNode
                        ? selectedNode?.id
                        : selectedEdge
                        ? selectedEdge?.id
                        : null,
                      selectedColor
                    );

                    setSelectedColor(null);
                    setVisible((prev) => !prev);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          trigger="click"
          placement="bottomLeft"
          arrow={false}
          suffixIcon=""
        >
          <Badge
            key="purple"
            color="purple"
            onClick={() => setVisible((prev) => !prev)}
          />{" "}
        </Dropdown>
      ) : (
        <Button
          type="grey-light-no-border"
          icon={e?.icon}
          text={e?.text ?? ""}
          size="small"
          iconProps={{ color: "#1565DC" }}
          onClick={e?.onClick}
          disabled={e?.disabled}
        />
      );
    });
  };

  const handleDelete = useCallback(() => {
    onDelete(
      selectedNode ? selectedNode?.id : selectedEdge ? selectedEdge?.id : null,
      selectedNode ? "node" : selectedEdge ? "edge" : null
    );
  }, [selectedNode, selectedEdge]);

  return (
    <>
      <DeleteConfirmationModal
        isVisible={deleteModalVisible}
        setIsVisible={setDeleteModalVisible}
        onDelete={handleDelete}
        nodes={nodes}
        edges={edges}
        title={
          selectedNode ? "Delete Node" : selectedEdge ? "Delete Edge" : "Delete"
        }
        selectedType={selectedNode ? "node" : selectedEdge ? "edge" : "default"}
      />
      <ClearAllConfirmationModal
        isVisible={clearAllModalVisible}
        setIsVisible={setClearAllModalVisible}
        onClear={onClearSchema}
      />
      <Card
        style={{
          position: "absolute",
          zIndex: 5,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "10px",
          backgroundColor: "#F1F7FD",
          border: "1px solid #dbeafa",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",
            height: "8px",
          }}
        >
          <div style={{ color: "#1565DC", fontWeight: "bold" }}>Add</div>
          {renderActions()}
        </div>
      </Card>
    </>
  );
};

export default ActionHeader;
