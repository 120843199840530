import { useState } from "react";
import { Handle, Position } from "reactflow";
import NodeLabelModel from "./nodeLabelModal";
import { renderQuillHtml } from "configs/utils";

function StartEndNode({
  data,
  id,
  onChange,
  selected,
  size = 120,
  onDelete = () => {},
}) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <NodeLabelModel
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onChange={onChange}
        label={data?.label}
        id={id}
        onDelete={onDelete}
      />

      <Handle
        type="target"
        position={Position.Top}
        isConnectable={true}
        id="start"
        style={{
          background: "#fff",
          width: "9px",
          height: "9px",
          borderRadius: "50%", // Makes it a circle
          border: "1px solid #000", // Border color and width
          zIndex: "997",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={true}
        style={{
          background: "#fff",
          width: "9px",
          height: "9px",
          borderRadius: "50%", // Makes it a circle
          border: "1px solid #000", // Border color and width
          zIndex: "997",
        }}
        id="right"
      />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={true}
        style={{
          background: "#fff",
          width: "9px",
          height: "9px",
          borderRadius: "50%", // Makes it a circle
          border: "1px solid #000", // Border color and width
          zIndex: "997",
        }}
        id="left"
      />

      <div
        style={{
          position: "relative",
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: "50%",
          pointerEvents: "all",
          color: selected ? "#1565DC" : "#000",
          borderColor: selected ? "#1565DC" : "#000",
          backgroundColor: data?.color || "#fff",
        }}
        className="pointer"
        onDoubleClick={() => setIsVisible(true)}
      >
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="50%"
            cy="50%"
            r={`${size / 2 - 2}`}
            fill="none"
            stroke={selected ? "#1565DC" : "#000"}
            strokeWidth={selected ? "2" : "1"}
          />
        </svg>
        <div
          style={{
            position: "absolute",
            fontWeight: "bold",
            color: selected ? "#1565DC" : "#000",
            padding: "10px",
            overflow: "auto", // Add this line to enable scrolling
            bottom: "20px",
            left: "18px",
            maxWidth: `${86}px`, // Set a maximum width for the box
            maxHeight: `${77}px`, // Set a maximum height for the box
          }}
        >
          {renderQuillHtml(data?.label)}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={true}
        style={{
          background: "#fff",
          width: "9px",
          height: "9px",
          borderRadius: "50%", // Makes it a circle
          border: "1px solid #000", // Border color and width
        }}
        id="end"
      />
    </>
  );
}

export default StartEndNode;
