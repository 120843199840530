import React, { useEffect, useState } from "react"
import { convertToHMSString, getOBJValueByKey } from "utils"

import { Number } from "components/common"

export const useTableData = (dataRows = [], dataMapping, TableComponents, shouldTransformData) => {
  const [state, setState] = useState({ data: [], columns: [] })

  useEffect(() => {
    shouldTransformData && setTableData()
  }, [dataRows, dataRows?.length, dataMapping])

  const mapRowByColumns = (column, row, newRow) => {
    if (column.children) {
      column.children.forEach(childColumn => {
        mapRowByColumns(childColumn, row, newRow)
      })
    } else if (column.component === "String") {
      newRow[column.dataIndex] = getOBJValueByKey(row, column.dataKey)
    } else if (column.component === "Time") {
      newRow[column.dataIndex] = convertToHMSString(getOBJValueByKey(row, column.dataKey))
    } else if (column.component === "Number") {
      newRow[column.dataIndex] = <Number value={getOBJValueByKey(row, column.dataKey)} />
    } else if (!!TableComponents?.[column?.component]) {
      const Component = TableComponents[column.component]
      newRow[column.dataIndex] = <Component {...getOBJValueByKey(row, column.dataKey, row)} subKey={column.dataIndex} />
    } else {
      newRow[column.dataIndex] = row[column.dataIndex]
    }
  }

  const getRowData = item => {
    const obj = { ...item, key: item.key || item.id }
    dataMapping.forEach(column => {
      mapRowByColumns(column, item, obj)
    })
    if (!!item?.children) {
      obj.children = item.children.map(e => getRowData(e))
    }
    return obj
  }

  const setTableData = () => {
    if (dataRows && dataRows.length) {
      const tableData = []
      dataRows.forEach(item => {
        tableData.push(getRowData(item))
      })

      setState({
        columns: dataMapping.map(e => ({ ...e, dataKey: e.key })),
        data: tableData,
      })
    } else {
      setState(prevState => ({ data: [], columns: [] }))
    }
  }

  return shouldTransformData ? [state.data, state.columns] : [dataRows, dataMapping]
}
