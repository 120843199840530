import { Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
  getStraightPath,
} from "reactflow";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
  markerEnd = "arrowclosed",
  onUpdateLabel,
  selected,
  viewOnly,
  ...rest
}) => {
  const dx = Math.abs(targetX - sourceX);
  const dy = Math.abs(targetY - sourceY);

  const alignmentThreshold = 5;

  const isHorizontalAlignment =
    dx <= alignmentThreshold && dy > alignmentThreshold;
  const isVerticalAlignment =
    dy <= alignmentThreshold && dx > alignmentThreshold;

  const [edgePath, labelX, labelY] =
    isHorizontalAlignment || isVerticalAlignment
      ? getStraightPath({
          sourceX,
          sourceY,
          targetX,
          targetY,
        })
      : getSmoothStepPath({ sourceX, sourceY, targetX, targetY });

  const [inputValue, setInputValue] = useState(data?.label);

  useEffect(() => {
    setInputValue(data?.label);
  }, [data?.label]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = useCallback(() => {
    onUpdateLabel(id, inputValue, !!inputValue);
  }, [inputValue]);

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          stroke: selected ? "#1565DC" : "#000",
          strokeWidth: 2,
        }}
        markerEnd={markerEnd}
      />
      {data?.showInput && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              padding: 10,
              borderRadius: 5,
              fontSize: 12,
              fontWeight: 500,
              pointerEvents: !viewOnly ? "all" : "none",
            }}
            // className="nodrag nopan"
          >
            <Input.TextArea
              key={id}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              style={{ width: inputValue?.length > 10 ? "75%" : "50%" }}
            />
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default CustomEdge;
