import React, { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import styles from "./index.module.less";
import {
  Button,
  Group,
  Image,
  ImageUpload,
  PhoneInput,
  SelectInput,
  TextInput,
  notification,
} from "components/common";
import {
  useGetAllCountriesQuery,
  useUpdateAccountDetailsMutation,
} from "./api";
import { Form, Row } from "antd";
import { schemaRules } from "features/shared/utils";
import * as yup from "yup";
import DraggerView from "components/common/draggerView/draggerView";

const Profile = ({
  profileData,
  onUpdate,
  email,
  userCode,
  prepareProfileData,
  setIsDataUpdated,
}) => {
  const [form] = Form.useForm();
  const profileUrl = Form.useWatch("profile_url", form);
  const country = Form.useWatch("country", form);

  const { data: countries, isLoading: countriesLoading } =
    useGetAllCountriesQuery();

  console.log("===data", countries);

  const [data, setData] = useState({
    ...profileData,
  });

  const [imageUploading, setImageUploading] = useState(false);

  useEffect(() => {
    profileData && setData(profileData);
  }, [profileData]);

  const [updateAccountDetails, { isLoading }] =
    useUpdateAccountDetailsMutation();

  const handleUpdate = async (values) => {
    const res = await updateAccountDetails({
      body: values,
    });
    if (res) {
      if (res?.error) {
        notification.error({ message: res?.error?.message });
      } else {
        notification.success({ message: "Profile Updated Successfully!" });
      }
    }
  };

  const getProfileValidationSchema = () =>
    yup.object().shape({
      firstname: yup.string().required("First Name is required!"),
      lastname: yup.string().required("Last Name is required!"),
      username: yup.string().required("User Name is required!"),
      phone_number: yup.string().required("Phone Number is required!"),
      country: yup.string().required("City is required!"),
    });

  const rules = schemaRules(getProfileValidationSchema());

  return (
    <>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid lightgrey",
          paddingBottom: "1vh",
        }}
      >
        <div className={styles.profileHeader}>
          <div className={styles.profileInfo}>
            <div className={styles.profilePictureContainer}>
              <img
                className={styles.profilePicture}
                src={
                  profileUrl ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQG91Ivd0CD1UA-3GKi8OPGqO9BZvrfsclJFujvxLWQU6z0UR5xNec89_mVGFysedGeUo0&usqp=CAU"
                }
                alt="Profile"
              />
            </div>
          </div>
        </div>
        <div className={styles.userInfo}>
          <div className={styles.userName}>{data.username}</div>
          <div className={styles.userEmail}>{email}</div>
        </div>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Form
          name="profile"
          initialValues={profileData}
          form={form}
          scrollToFirstError
          onFinish={handleUpdate}
          preserve={true}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          shouldUpdate
          disabled={isLoading}
          layout="vertical"
        >
          <Group gap="0" template="repeat(2,1fr)" className="pi-20 ">
            <Form.Item
              name="firstname"
              label="First Name"
              validateTrigger={"onBlur"}
              required
              rules={rules}
            >
              <TextInput placeholder="Enter First Name" />
            </Form.Item>
            <Form.Item
              name="lastname"
              label="Last Name"
              validateTrigger={["onChange", "onBlur"]}
              required
              rules={rules}
            >
              <TextInput placeholder="Enter Last Name" />
            </Form.Item>
          </Group>

          <Group gap="0" template="repeat(2,1fr)" className="pi-20 ">
            <Form.Item
              name="email"
              label="Email Address"
              validateTrigger={["onChange", "onBlur"]}
            >
              <TextInput
                disabled
                placeholder="Enter Email Name"
                onChange={(e) => {}}
              />
            </Form.Item>
            <Form.Item
              name="username"
              label="User Name"
              required
              rules={rules}
              validateTrigger={["onChange", "onBlur"]}
            >
              <TextInput placeholder="Enter User Name" />
            </Form.Item>
          </Group>

          <Group gap="0" template="repeat(2,1fr)" className="pi-20 ">
            <Form.Item
              name="country"
              label="Country"
              required
              rules={rules}
              validateTrigger={["onChange", "onBlur"]}
            >
              <SelectInput
                placeholder="Select Country"
                options={countries}
                getOptionValue={(e) => e.country}
                getOptionLabel={(e) => e.country}
                inputLoading={countriesLoading}
                value={country}
                onChange={(value) => {
                  form.setFieldValue("country", value);
                }}
              />
            </Form.Item>
            <Form.Item
              label={"Phone Number"}
              required
              rules={rules}
              hasFeedback
              validateTrigger={["onChange", "onBlur"]}
              name="phone_number"
            >
              <PhoneInput defaultCountry="US" onChange={() => {}} required />
            </Form.Item>
          </Group>

          <Group gap="0" template="repeat(2,1fr)" className="pi-20 ">
            <Form.Item label={"Profile Picture"} name="profile_url">
              <ImageUpload
                onUpload={(file, uploadedFile) => {
                  form.setFieldValue("profile_url", file?.location);
                }}
                setLoading={(value) => {
                  setImageUploading(value);
                }}
              >
                <DraggerView />
              </ImageUpload>
            </Form.Item>

            <Image src={profileUrl} width={"100px"} height={"100px"} />
          </Group>

          <Form.Item shouldUpdate className="submit" wrapperCol={{ span: 22 }}>
            <Row style={{ gap: 8, justifyContent: "center" }}>
              <Button
                type="primary"
                text={"Update"}
                loading={isLoading}
                disabled={isLoading || imageUploading}
                onClick={form.submit}
              />
            </Row>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Profile;
