import React, {  useState } from "react";
import ReactHtmlParser from "react-html-parser";

const QuillEditorView = ({ explanationDetails }) => {
  const [allHyperLinks, setAllHyperLinks] = useState([]);
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        color: "black",
      }}
    >
      {ReactHtmlParser(explanationDetails)}
    </div>
  );
};

export default QuillEditorView;
