import CollapseCard from "components/common/collapseCard/collapseCard";
import { useSelector } from "react-redux";
import theme from "utils/themeVars";
import ExplanationHeader from "../addScriptForm/explanationHeader";
import ExplanationPanel from "../addScriptForm/explanationPanel";
import ExplanationActions from "./explanationActions";

const ExplanationCardItem = ({
  itemIndex,
  activeKeys,
  handleChange,
  handleExpandCollapse,
  editMode,
  setModalsVisibility,
  onEditSave,
  isPodCast = false,
  data,
  explanations = [],
}) => {
  const itemData = explanations?.find((e, i) => i == itemIndex);

  const handleTitleChange = (val, index) => {
    const tempExplanations = JSON.parse(JSON.stringify(explanations));
    if (tempExplanations[index]) {
      tempExplanations[index] = {
        ...tempExplanations[index],
        title: val,
      };
    }
    handleChange("explanations", tempExplanations);
  };

  const handleExplanationChange = (val, index) => {
    const tempExplanations = JSON.parse(JSON.stringify(explanations));
    tempExplanations[index] = {
      ...tempExplanations[index],
      explanation: val,
    };
    handleChange("explanations", tempExplanations);
  };

  const onDeleteImage = (item, i) => {
    const tempExplanations = JSON.parse(JSON.stringify(explanations));
    tempExplanations[itemIndex] = {
      ...tempExplanations[itemIndex],
      media: tempExplanations?.[itemIndex]?.media?.filter(
        (e, itIndex) => itIndex != i
      ),
    };
    handleChange("explanations", tempExplanations);
  };

  return (
    <>
      <div
        style={{
          border: `1px solid ${theme["blue100"]}`,
          borderRadius: "10px",
          margin: "2%",
          padding: "2% 1% 2% 1%",
        }}
        key={`${itemIndex + 1}-explanation-collapse-card`}
        id={`${itemIndex + 1}-explanation-collapse-card`}
      >
        <CollapseCard
          headerKey={itemIndex?.toString()}
          activeKey={activeKeys?.find((e) => e == itemIndex?.toString())}
          handleExpandCollapse={handleExpandCollapse}
          header={
            <ExplanationHeader
              value={isPodCast ? "Podcast" : itemData?.title}
              onChange={(e) => {
                handleTitleChange(e?.target?.value, itemIndex);
              }}
              inputKey={`${itemData?.title}-${itemIndex}-header`}
              isMobile={false}
              explanationActionList={[]}
              isPodCast={isPodCast}
            />
          }
          panel={
            <ExplanationPanel
              isPodCast={isPodCast}
              value={itemData?.explanation}
              explanationKey={`${itemData?.explanation}-${itemIndex}-panel`}
              onChange={(e) => {
                handleExplanationChange(e, itemIndex);
              }}
              media={itemData?.media}
              onDeleteImage={onDeleteImage}
              onEditImage={(it) =>
                setModalsVisibility({ ...it, explanationIndex: itemIndex })
              }
              onEditVideo={(it) => {
                setModalsVisibility({ ...it, explanationIndex: itemIndex });
              }}
              isMobile={false}
              renderExplanationActions={
                <ExplanationActions
                  setModalsVisibility={setModalsVisibility}
                  index={itemIndex}
                  editMode={editMode}
                  onEditSave={onEditSave}
                  isPodCast={isPodCast}
                  data={data}
                  handleChange={handleChange}
                />
              }
            />
          }
          activeKeys={activeKeys}
        />
      </div>
    </>
  );
};
export default ExplanationCardItem;
