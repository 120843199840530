import { Title } from "components/common";
import { renderQuillHtml } from "configs/utils";
import { useQuestionAnswerMutation } from "features/script/api";
import { useDispatch } from "react-redux";
import { setQuizData } from "store/quizSlice";
import QuestionAnswerDetails from "./questionAnswerDetails";
import QuestionOptions from "./questionOptions";
import QuizQuestionActions from "./quizQuestionActions";
import UserActions from "./userActions";

const QuestionItem = ({
  question,
  setSelectedQuestion,
  quizDetails,
  selectedQuestion,
  showQuizActions = true,
  scriptId,
  resultPage,
}) => {
  const dispatch = useDispatch();

  const [getQuestionAnswer, _] = useQuestionAnswerMutation();

  const onUserAction = (questionDetails) => {
    let tempQuestionsList = JSON.parse(
      JSON.stringify(quizDetails?.questionsList)
    );
    tempQuestionsList[selectedQuestion] = {
      ...tempQuestionsList[selectedQuestion],
      questionDetails: questionDetails,
    };
    dispatch(setQuizData({ questionsList: tempQuestionsList }));
  };

  const renderTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "-12px",
        }}
      >
        <UserActions
          question={question}
          scriptId={scriptId}
          onUserAction={onUserAction}
        />
      </div>
    );
  };

  const onQuestionSubmit = async () => {
    const response = await getQuestionAnswer({
      questionId: question?.questionId,
    });

    if (response) {
      if (response?.error) {
      } else if (response?.data) {
        let tempQuestionsList = JSON.parse(
          JSON.stringify(quizDetails?.questionsList)
        );
        tempQuestionsList[selectedQuestion].answer = response?.data;
        dispatch(setQuizData({ questionsList: tempQuestionsList }));
      }
    }
  };

  const onSkipQuestion = () => {
    const tempQuestionsList = JSON.parse(
      JSON.stringify(quizDetails?.questionsList)
    );
    tempQuestionsList[selectedQuestion].skipped = true;
    dispatch(setQuizData({ questionsList: tempQuestionsList }));
    setSelectedQuestion((prev) => prev + 1);
  };

  const onOptionSelection = (item) => {
    const tempQuestionsList = JSON.parse(
      JSON.stringify(quizDetails?.questionsList)
    );
    tempQuestionsList[selectedQuestion].optionSelected = item;
    dispatch(setQuizData({ questionsList: tempQuestionsList }));
  };

  return (
    <div style={{ margin: "1%" }}>
      {renderTitle()}
      <div
        style={{
          flex: 1,
          overflow: "auto",
          maxHeight: "70vh", // Adjust this value as needed
          marginTop: "16px",
        }}
      >
        <Title level={4}>{renderQuillHtml(question?.description)}</Title>

        <QuestionOptions
          options={question?.options}
          answer={question?.answer}
          isAnswered={!!question?.answer}
          optionSelected={question?.optionSelected}
          onOptionSelection={onOptionSelection}
          resultPage={resultPage}
        />
        {resultPage && (
          <Title level={4}>Question Id: {question?.questionId}</Title>
        )}
        {!!question?.answer && (
          <QuestionAnswerDetails
            answer={question?.answer}
            explanation={question?.explanation}
            additionalInfo={question?.additionalInfo}
            isCorrectSelected={question?.answer?.includes(
              question?.optionSelected
            )}
          />
        )}
      </div>
      {showQuizActions && (
        <QuizQuestionActions
          onSkipQuestion={onSkipQuestion}
          onQuestionSubmit={onQuestionSubmit}
          disableSubmit={!question?.optionSelected || !!question?.answer}
          disableNext={!question?.answer && !question?.skipped}
          showNext={quizDetails?.questionsList?.length - 1 > selectedQuestion}
          onNext={() => setSelectedQuestion((prev) => prev + 1)}
          questionId={question?.questionId}
          disableSkip={!!question?.answer}
        />
      )}
    </div>
  );
};

export default QuestionItem;
