import {
  Button,
  Card,
  DataTable,
  Group,
  Icon,
  Modal,
  Tag,
  TextInput,
  Title,
  notification,
} from "components/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import theme from "utils/themeVars";

import { Radio, message } from "antd";
import { ActionButtons, Filters } from "components/custom";
import { SCRIPT_EDIT_ICON, TABLE_DELETE_ICON } from "components/svg";
import { regex } from "constants/regex";
import {
  useAddFreeUserMutation,
  useGetAllAppUsersQuery,
  useUpdateUserStatusMutation,
} from "../apis";
import PrimaryButton from "components/common/button/primary-button";
import { FaRegUser } from "react-icons/fa";
import moment from "moment";

const AppUsers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const element = document.querySelector(".ant-table-header");
  if (element) {
    element.style.overflow = "unset";
  }

  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);

  const { data, isFetching, isLoading, error, refetch } =
    useGetAllAppUsersQuery({ queryObj }, { refetchOnMountOrArgChange: true });
  const [loading, setLoading] = useState(false);
  const [freeUserModal, setFreeUserModal] = useState(false);
  const [freeUserData, setFreeUserData] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const [updateUserStatus, {}] = useUpdateUserStatusMutation();
  const [addFreeUser, {}] = useAddFreeUserMutation();

  const updateStatus = async () => {
    setLoading(true);
    let res = await updateUserStatus({
      activityStatus: editData?.value,
      userID: selectedRow?.item?.user_code,
    });
    if (res) {
      setLoading(false);
      if (res?.error) {
        notification.error({ message: res?.error?.message });
      } else {
        notification.success({ message: "Updated Successfully!" });
        setEditData(null);
        setSelectedRow(null);
      }
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    let res = await updateUserStatus({
      activityStatus: "DELETE",
      userID: selectedRow?.item?.user_code,
    });
    if (res) {
      setLoading(false);
      if (res?.error) {
        notification.error({ message: res?.error?.message });
      } else {
        notification.success({ message: "Updated Successfully!" });
        setEditData(null);
        setSelectedRow(null);
      }
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [editData, setEditData] = useState(null);
  const libraryTableColumns = useMemo(
    () => [
      {
        title: "No.",
        dataIndex: "key",
        className: "tableStartSpace",
        width: 70,
      },
      {
        title: "User Name",
        dataIndex: "username",
        // width: 150,
      },
      {
        title: "First Name",
        dataIndex: "firstname",
        // width: 170,
      },
      {
        title: "Last Name",
        dataIndex: "lastname",
        // width: 170,
      },
      {
        title: "Email",
        dataIndex: "email",
        width: 170,
      },
      {
        title: "Cell No.",
        dataIndex: "cellNumber",
        width: 140,
      },
      {
        title: "Country",
        dataIndex: "country",
        width: 90,
      },
      {
        title: "Created Date",
        dataIndex: "created_date",
        width: 130,
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      // {
      //   title: "Subscription",
      //   dataIndex: "has_active_subscription",
      // },
      // {
      //   title: "Expiry",
      //   dataIndex: "package_expiry_date",
      // },

      {
        title: "Action",
        dataIndex: "actions",
        width: 90,
        // fixed: "right",
      },
    ],
    []
  );

  const navigate = useNavigate();

  const getActionsList = (e) => [
    {
      key: "edit_user_status",
      icon: <SCRIPT_EDIT_ICON />,
      color: theme["blue200"],
      content: "Edit User Status",
      title: "Edit User Status",
      onClick: () => {
        setSelectedRow({ item: e, type: "edit" });
        setEditData({
          value: e?.is_enabled_free_user
            ? "FREE_USER"
            : e?.is_active
            ? "ACTIVE"
            : "INACTIVE",
        });
      },
    },
    {
      key: "delete_user",
      icon: <TABLE_DELETE_ICON />,
      color: theme["red"],
      content: "Delete user",
      title: "Delete User",
      onClick: () => {
        setSelectedRow({ item: e, type: "delete" });
      },
    },
  ];
  const convertDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const tableData = useCallback(() => {
    return data?.data?.data?.length
      ? data.data.data.map((e, index) => ({
          ...e,
          key: index + 1,
          created_date: convertDate(e?.created_at),
          cellNumber: "+" + e?.phone_number,
          has_active_subscription: !!e?.has_active_subscription ? "Yes" : "No",
          status: (
            <Tag
              color={
                e.is_enabled_free_user
                  ? theme["warning-color"]
                  : e?.is_active
                  ? theme["primary-color"]
                  : theme["error-color"]
              }
              style={{ borderRadius: "40px" }}
            >
              {e.is_enabled_free_user
                ? "Free User"
                : e?.is_active
                ? "Active"
                : "InActive"}
            </Tag>
          ),
          actions: <ActionButtons list={getActionsList(e)} />,
        }))
      : [];
  }, [data]);

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>Users</Title>
        <PrimaryButton onClick={() => setFreeUserModal(true)}>
          <FaRegUser />
          Register Free User
        </PrimaryButton>
      </div>
    );
  };

  const filterList = useMemo(
    () => [
      {
        label: "none",
        type: "input",
        key: "username",
        placeholder: "Search By User Name",
      },
      {
        label: "none",
        type: "input",
        key: "firstname",
        placeholder: "Search By First Name",
      },
      {
        label: "none",
        type: "input",
        key: "lastname",
        placeholder: "Search By Last Name",
      },
      {
        label: "none",
        type: "input",
        key: "email",
        placeholder: "Search By Email",
      },
      // {
      //   label: "none",
      //   type: "input",
      //   key: "number",
      //   placeholder: "Search By Phone Number",
      // },
    ],
    []
  );

  const renderDeleteViewModal = () => {
    return "Are you sure you want to delete this user?";
  };

  const renderEditViewModal = () => {
    return (
      <Radio.Group
        onChange={(e) => setEditData({ value: e?.target?.value })}
        value={editData?.value}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {[
            { lable: "Active", value: "ACTIVE" },
            { lable: "InActive", value: "INACTIVE" },
            { lable: "Free User", value: "FREE_USER" },
          ].map((e) => (
            <Radio value={e?.value}>{e?.lable}</Radio>
          ))}
        </div>
      </Radio.Group>
    );
  };

  const validateEmail = () => {
    return regex.emailRegex.test(freeUserData?.email);
  };
  const handleAddFreeUser = async () => {
    if (!freeUserData?.email) {
      notification.error({ message: "Please Enter Email First!" });
    } else {
      if (!validateEmail()) {
        setEmailError("Invalid Email");
      } else {
        const res = await addFreeUser({
          email: freeUserData?.email,
          is_enabled: true,
        });
        if (res?.error) {
          notification.error({ message: res?.error?.message });
        } else {
          notification.success({ message: "Added User Successfully!" });
          setFreeUserModal(false);
          setFreeUserData(null);
          setEmailError(null);
        }
      }
    }
  };
  const renderPresentationWeb = () => {
    return (
      <Group gap="16px" className={"listings-content-adjustments"}>
        <Filters
          list={filterList || []}
          loading={false}
          skeletonLoading={false}
          isSingleFilter={false}
          isDrawerView={false}
        />
        <Card>{renderPageHeader()}</Card>
        <DataTable
          pagination={{
            totalCount: data?.data?.count || 10,
            pageOptions: [10, 25, 50],
          }}
          onChangePage={(queryObj) => setSearchParams(queryObj)}
          columns={libraryTableColumns}
          data={tableData()}
          loading={isLoading}
          error={error}
          onErrorRetry={refetch}
          skeletonLoading={isFetching}
        />
        <Modal
          visible={!!selectedRow}
          setVisible={setSelectedRow}
          title={
            selectedRow?.type == "edit"
              ? "Change Status of User Account"
              : "Delete User"
          }
          okText={selectedRow?.type == "edit" ? "Update" : "Delete"}
          onOk={() => {
            selectedRow?.type == "edit" ? updateStatus() : deleteUser();
          }}
          onCancel={() => {
            setSelectedRow(null);
            setEditData(null);
          }}
          width={450}
          loading={loading}
        >
          {selectedRow?.type == "edit"
            ? renderEditViewModal()
            : renderDeleteViewModal()}
        </Modal>
        <Modal
          visible={freeUserModal}
          setVisible={setFreeUserModal}
          title={"Add a Free User"}
          okText={"Add"}
          onOk={() => {
            handleAddFreeUser();
          }}
          onCancel={() => {
            setFreeUserModal(false);
            setFreeUserData(null);
            setEmailError(null);
          }}
          loading={loading}
        >
          <TextInput
            placeholder={"Enter Email"}
            value={freeUserData?.email}
            handleChange={(e) => {
              setFreeUserData((prev) => ({ ...prev, email: e?.target?.value }));
            }}
            handleBlur={() => {
              !validateEmail()
                ? setEmailError("Invalid Email")
                : setEmailError(null);
            }}
            errorMsg={emailError}
          />
        </Modal>
      </Group>
    );
  };

  return <>{renderPresentationWeb()}</>;
};

export default AppUsers;
