import { Result, Row } from "antd"

import Button from "../button/button"
import Empty from "../empty/empty"
import Icon from "../icon/icon"
import PropTypes from "prop-types"

const ComponentState = props => {
  const {
    type,
    children,
    onClick,
    btnLoading,
    btnText,
    status,
    title,
    description,
    btnIcon = "SlReload",
    image,
    ...rest
  } = props

  const getIcon = () => {
    switch (type) {
      default:
        return "IoIosCloseCircleOutline"
    }
  }

  const getStatus = () => {
    if (["error", "warning", "success", "info", "500", "404"].find(e => e == status)) {
      return status
    } else {
      return "error"
    }
  }

  return type === "empty" ? (
    <Row align="middle" justify="center" className="text-center" style={{ height: "100%" }}>
      <Empty title={title} description={description} image={image} {...rest} />
    </Row>
  ) : (
    <Result
      status={"error"}
      title={title}
      subTitle={description}
      extra={[
        <Button
          key="retry"
          type="link"
          size="large"
          onClick={onClick}
          loading={btnLoading}
          text={btnText}
          icon={btnIcon}
        />,
      ]}
      icon={<Icon icon={props.icon || getIcon()} />}
      {...rest}
    />
  )
}

ComponentState.propTypes = {
  type: PropTypes.string,
  btnLoading: PropTypes.bool,
  children: PropTypes.func,
  onClick: PropTypes.func,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  btnText: PropTypes.string,
  title: PropTypes.string,
}

ComponentState.defaultProps = {
  btnText: "Retry",
  title: "Something went wrong!",
}

export default ComponentState
