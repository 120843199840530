import { Button } from "components/common";
import { useState, useEffect } from "react";
import { ADD_FILE, ADD_IMAGE, ADD_VIDEO, DELETE_ICON } from "components/svg";
import { useDispatch, useSelector } from "react-redux";
import { setBasicScriptData } from "store/scriptSlice";
import { MdOutlinePodcasts } from "react-icons/md";
import theme from "utils/themeVars";

const ExplanationActions = ({
  setModalsVisibility,
  index,
  editMode = false,
  onEditSave,
  isPodCast = false,
  handleChange,
  data: scriptData,
}) => {
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 700);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  const handleDeleteExplanation = () => {
    handleChange(
      "explanations",
      scriptData?.explanations?.filter((e, i) => i != index)
    );
  };

  const handleSaveDataOnEdit = () => {
    onEditSave(["section_2"], index, scriptData);
  };
  let explanationActionList = [
    {
      key: "action1",
      text: isMobile ? "File" : "Add File",
      icon: isMobile ? <></> : <ADD_FILE />,
      onClick: () => setModalsVisibility({ type: "image", index: index }),
    },
    {
      key: "action2",
      text: isMobile ? "Image" : "Link Image",
      icon: isMobile ? <></> : <ADD_IMAGE />,
      onClick: () =>
        setModalsVisibility({ type: "licenseImage", index: index }),
    },
    {
      key: "action3",
      text: isMobile ? "Video" : "Link Video",
      icon: isMobile ? <></> : <ADD_VIDEO />,
      onClick: () =>
        setModalsVisibility({ type: "licenseVideo", index: index }),
    },
    {
      key: "action5",
      text: isMobile ? "Delete" : "Delete",
      icon: isMobile ? <></> : <DELETE_ICON />,
      color: "#E13838",
      borderColor: "#E13838",
      fontWeight: "700",
      onClick: handleDeleteExplanation,
    },

    ...(editMode
      ? [
          {
            key: "action4",
            text: "Cancel",
            icon: "",
            onClick: () => {},
          },
          {
            key: "action4",
            text: "Save",
            type: "primary",
            style: {},
            icon: "",
            loading: false,
            onClick: handleSaveDataOnEdit,
          },
        ]
      : []),
  ];
  if (isPodCast) {
    explanationActionList = [
      // {
      //   key: "action6",
      //   text: "Add Podcast",
      //   icon: <MdOutlinePodcasts />,
      //   fontWeight: "700",
      //   onClick: () =>
      //     setModalsVisibility({ type: "licensePodcast", index: index }),
      // },
      ...(editMode
        ? [
            // {
            //   key: "action4",
            //   text: "Cancel",
            //   icon: "",
            //   onClick: () => {},
            // },
            {
              key: "action4",
              text: "Save",
              type: "primary",
              style: {},
              icon: "",
              loading: false,
              onClick: handleSaveDataOnEdit,
            },
          ]
        : []),
    ];
  }
  return (
    <>
      {explanationActionList?.length
        ? explanationActionList?.map((e) => (
            <Button
              text={e?.text}
              type={e?.type ? e?.type : "bordered"}
              style={
                e?.style
                  ? e?.style
                  : {
                      borderColor: e?.borderColor
                        ? e?.borderColor
                        : theme["blue100"],
                      color: e?.color ? e.color : theme["blue200"],
                      background: theme["light-gray"],
                      fontWeight: e?.fontWeight ? e?.fontWeight : "unset",
                    }
              }
              loading={e?.loading}
              icon={e?.icon}
              iconProps={{ size: "16px" }}
              size="small"
              onClick={e?.onClick}
            />
          ))
        : null}
    </>
  );
};

export default ExplanationActions;
