import {
  Button,
  Modal,
  notification,
  SelectInput,
  TextInput,
} from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import { useUpdateQuestionStatsMutation } from "features/script/api";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQuizData } from "store/quizSlice";
import theme from "utils/themeVars";

const UserActions = ({ question, scriptId, onUserAction }) => {
  const user = useSelector((state) => state.app.user.info);
  const dispatch = useDispatch();
  const [handleUpdateStats, _] = useUpdateQuestionStatsMutation();
  const [showModal, setShowModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    title: "",
    description: "",
    userId: user?.user_code,
  });

  const onUpdateStats = async (options, key) => {
    const res = await handleUpdateStats({
      questionId: question?.questionId,
      scriptId: scriptId,
      options: options,
      action: key,
    });
    if (res) {
      if (res?.error) {
      } else {
        if (key == "feedback") {
          setShowModal(false);
          setFeedbackData(null);
          notification.success({ message: "Feedback added successfully!" });
        }
        onUserAction(options);
      }
    }
  };

  const onSave = () => {
    let isSaved = [];
    if (question?.questionDetails?.isSaved?.length) {
      isSaved = question?.questionDetails?.isSaved;
    }
    if (isSaved?.length && isSaved?.includes(user?.user_code)) {
      isSaved = isSaved.filter((e) => e != user?.user_code);
    } else {
      isSaved.push(user?.user_code);
    }
    onUpdateStats(
      {
        ...question?.questionDetails,
        isSaved: isSaved,
      },
      "saved"
    );
  };

  const onLike = () => {
    let liked = [];
    if (question?.questionDetails?.liked?.length) {
      liked = question?.questionDetails?.liked;
    }
    liked.push(user?.user_code);

    let disliked = [];
    if (question?.questionDetails?.disLiked?.length) {
      disliked = question?.questionDetails?.disLiked;
    }
    if (disliked?.length && disliked?.includes(user?.user_code)) {
      disliked = disliked.filter((e) => e != user?.user_code);
    }
    onUpdateStats(
      {
        ...question?.questionDetails,
        liked: liked,
        disLiked: disliked,
      },
      "liked"
    );
  };

  const onDisliked = async () => {
    let disliked = [];
    if (question?.questionDetails?.disLiked?.length) {
      disliked = question?.questionDetails?.disLiked;
    }
    disliked.push(user?.user_code);

    let liked = [];
    if (question?.questionDetails?.liked?.length) {
      liked = question?.questionDetails?.liked;
    }
    if (liked?.length && liked?.includes(user?.user_code)) {
      liked = liked.filter((e) => e != user?.user_code);
    }

    onUpdateStats(
      {
        ...question?.questionDetails,
        disLiked: disliked,
        liked: liked,
      },
      "disliked"
    );
  };

  const onAddFeedback = async () => {
    let feedback = [];
    if (question?.questionDetails?.feedback?.length) {
      feedback = question?.questionDetails?.feedback;
    }
    feedback = feedback?.length ? [...feedback, feedbackData] : [feedbackData];
    onUpdateStats(
      {
        ...question?.questionDetails,
        feedback: feedback,
      },
      "feedback"
    );
  };

  const renderFeedbackModal = () => {
    return (
      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          setFeedbackData({
            title: "",
            description: "",
          });
        }}
        title="Feedback"
        onOk={onAddFeedback}
        destroyOnClose
      >
        <div>
          <TextInput
            placeholder={"Add Title"}
            value={feedbackData?.title}
            onChange={(e) => {
              setFeedbackData((prev) => ({ ...prev, title: e?.target?.value }));
            }}
            style={{ marginBottom: "15px" }}
          />
          <TextEditor
            style={{ textAlign: "left" }}
            key={"DescriptionFeedback"}
            value={feedbackData?.description}
            onChange={(e) => {
              setFeedbackData((prev) => ({ ...prev, description: e }));
            }}
            placeholder={"Add description"}
            editorHeight={200}
          />
        </div>
      </Modal>
    );
  };

  const renderBookmark = () => {
    const isSaved = question?.questionDetails?.isSaved?.includes(
      user?.user_code
    );
    return (
      <Button
        type="bordered"
        icon={isSaved ? "FaBookmark" : "LuBookmark"}
        style={{
          border: `1px solid ${isSaved ? theme["primary-color"] : "gray"}`,
          color: isSaved ? theme["primary-color"] : "gray",
          backgroundColor: isSaved ? theme["blue50"] : "",
          height: "36px",
        }}
        iconProps={{ size: 16 }}
        onClick={onSave}
      />
    );
  };
  const renderLike = () => {
    const isLiked = question?.questionDetails?.liked?.includes(user?.user_code);
    return (
      <Button
        type="bordered"
        icon={isLiked ? "AiFillLike" : "AiOutlineLike"}
        text={question?.questionDetails?.liked?.length}
        size="small"
        style={{
          border: `1px solid ${isLiked ? "#4ca64c" : "gray"}`,
          color: isLiked ? "#4ca64c" : "gray",
          backgroundColor: isLiked ? "#e5f2e5" : "",
        }}
        onClick={onLike}
      />
    );
  };
  const renderDisLike = () => {
    const isDisLiked = question?.questionDetails?.disLiked?.includes(
      user?.user_code
    );
    return (
      <Button
        type="bordered"
        icon={isDisLiked ? "AiFillDislike" : "AiOutlineDislike"}
        text={question?.questionDetails?.disLiked?.length}
        size="small"
        style={{
          border: `1px solid ${isDisLiked ? theme["error-color"] : "gray"}`,
          color: isDisLiked ? theme["error-color"] : "gray",
          backgroundColor: isDisLiked ? theme["error-color-light"] : "",
        }}
        onClick={onDisliked}
      />
    );
  };
  const renderFeedback = () => {
    return (
      <Button
        type="bordered"
        text={"Feedback"}
        size="small"
        style={{
          border: `1px solid gray`,
          color: theme["gray900"],
        }}
        onClick={() => setShowModal(true)}
      />
    );
  };
  return (
    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
      {renderBookmark()}
      {renderLike()}
      {renderDisLike()}
      {renderFeedback()}
      {renderFeedbackModal()}
    </div>
  );
};

export default UserActions;
