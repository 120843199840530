import { Badge, Input, Menu, Space } from "antd";
import {
  Button,
  Card,
  Dropdown,
  Icon,
  Title,
  notification,
  Modal,
  ColorBoard,
} from "components/common";
import PrimaryButton from "components/common/button/primary-button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsPlus } from "react-icons/bs";
import theme from "utils/themeVars";
import {
  caseSubSlide,
  getUniqueId,
  imageMainSlide,
  imageSubSlide,
  pollMainSlide,
  pollSubSlide,
  simpleMainSlide,
  schemaMainSlide,
  schemaSubSlide,
} from "../utils";
import CircularArrow from "./arrowSlider";
import styles from "./index.module.less";
import styles2 from "./presentationPageStyle.module.less";
import PresentationSlides from "./presentationSlides";
import Slide from "./slide";
import PresentationDetails from "./presentationDetails";
import { useDispatch } from "react-redux";
import { setModalStatus } from "store/modalReducer";
import { ADD_SUB_SLIDE_ICON } from "components/svg";
// import ColorPicker from "components/common/colorPicker/colorPicker";

const PresentationPage = ({
  slides,
  setSlides,
  onSubmitClick,
  basicInfo,
  setBasicInfo,
}) => {
  const dispatch = useDispatch();

  const sliderRef = useRef(null);
  const [mainSlide, setMainSlide] = useState(0);
  const [visible, setVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState(false);
  const [colorBoard , setColorBoard] = useState("");
  const [subSlide, setSubSlide] = useState(0);
  const modalRef = useRef(null);
  const deleteRef = useRef(null);

  function setModalState(isModalOpen) {
    dispatch(setModalStatus({ isModalOpen }));
  }

  const hasSubSlides = useCallback(
    (slide) => {
      return slide?.length > 1;
    },
    [slides?.[mainSlide]]
  );

  const renderAddMedia = () => {
    return (
      <Button
        style={{ background: "transparent" }}
        loading={false}
        size="small"
        icon="MdAdd"
        iconProps={{ color: theme["primary-color"] }}
      >
        Add Media
      </Button>
    );
  };

  const renderAddDescription = () => {
    return (
      <Button
        style={{ background: "transparent" }}
        loading={false}
        size="small"
        icon="MdAdd"
        iconProps={{ color: theme["primary-color"] }}
      >
        Add Slide
      </Button>
    );
  };

  const renderAddPoll = () => {
    return (
      <Button
        style={{ background: "transparent" }}
        loading={false}
        size="small"
        icon="MdAdd"
        iconProps={{ color: theme["primary-color"] }}
      >
        Add Poll
      </Button>
    );
  };

  const renderAddSchema = () => {
    return (
      <Button
        style={{ background: "transparent" }}
        loading={false}
        size="small"
        icon="MdAdd"
        iconProps={{ color: theme["primary-color"] }}
      >
        Add Schema
      </Button>
    );
  };
  const items = [
    {
      label: <>{renderAddDescription()}</>,
      key: "0",
      onClick: () => {
        let tempSlides = [...slides];
        tempSlides[mainSlide] = {
          ...tempSlides[mainSlide],
          data: [
            ...tempSlides[mainSlide].data,
            {
              ...caseSubSlide,
              id: getUniqueId(tempSlides[mainSlide].data.map((s) => s.id)),
            },
          ],
        };
        setSubSlide(tempSlides[mainSlide]?.data.length - 1);
        setSlides(tempSlides);
      },
    },
    {
      label: <>{renderAddMedia()}</>,
      key: "1",
      onClick: () => {
        let tempSlides = [...slides];
        tempSlides[mainSlide] = {
          ...tempSlides[mainSlide],
          data: [
            ...tempSlides[mainSlide].data,
            {
              ...imageSubSlide,
              id: getUniqueId(tempSlides[mainSlide].data.map((s) => s.id)),
            },
          ],
        };
        setSlides(tempSlides);
        setSubSlide(tempSlides[mainSlide]?.data.length - 1);
      },
    },
    {
      label: <>{renderAddSchema()}</>,
      key: "2",
      onClick: () => {
        let tempSlides = [...slides];
        tempSlides[mainSlide] = {
          ...tempSlides[mainSlide],
          data: [
            ...tempSlides[mainSlide].data,
            {
              ...schemaSubSlide,
              id: getUniqueId(tempSlides[mainSlide].data.map((s) => s.id)),
            },
          ],
        };
        setSlides(tempSlides);
        setSubSlide(tempSlides[mainSlide]?.data.length - 1);
      },
    },
    // {
    //   label: <>{renderAddPoll()}</>,
    //   key: "3",
    //   onClick: () => {
    //     let tempSlides = [...slides];
    //     tempSlides[mainSlide] = {
    //       ...tempSlides[mainSlide],
    //       data: [
    //         ...tempSlides[mainSlide].data,
    //         {
    //           ...pollSubSlide,
    //           id: getUniqueId(tempSlides[mainSlide].data.map((s) => s.id)),
    //         },
    //       ],
    //     };
    //     setSubSlide(tempSlides[mainSlide]?.data.length - 1);
    //     setSlides(tempSlides);
    //   },
    // },
  ];

  const changeSlideBackgroundColor = (color) => {
    const tempSlide = [...slides];
    tempSlide[mainSlide] = { ...tempSlide[mainSlide], backgroundColor: color };
    setSlides(tempSlide);
  };

  const renderAddDropDown = () => {
    return (
      <Dropdown
        menu={{
          items,
        }}
        trigger="click"
        arrow={false}
        suffixIcon=""
      >
        <PrimaryButton icon={<ADD_SUB_SLIDE_ICON />}>
          Add
          <Icon size="1.3rem" icon="IoMdArrowDropdown" />
        </PrimaryButton>
      </Dropdown>
    );
  };

  const handleColorSelect = (selectedColor) => {
    setColorBoard(selectedColor);
  };

  const renderBackgroundColorDropdown = () => {
    return (
      <Dropdown
        visible={visible}
        onVisibleChange={(visible) => setVisible(visible)}
        className={`${styles["custom-dropdown"]} hide-dropdown-arrow`}
        overlay={
          <Menu>
            <Menu.Item>
              <ColorBoard onSelectColor={handleColorSelect}/>
            </Menu.Item>
          </Menu>
        }
        dropdownRender={(menu) => (
          <div className="dropdown-content">
            {menu}

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "2%",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  changeSlideBackgroundColor(colorBoard);
                  setSelectedColor(null);
                  setVisible((prev) => !prev);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        )}
        trigger="click"
        placement="bottomLeft"
        arrow={false}
        suffixIcon=""
      >
        <Button
          type="grey-light-bordered"
          size="small"
          outlined
          onClick={() => setVisible((prev) => !prev)}
        >
          <Badge key="purple" color="purple" /> Background Color
        </Button>
      </Dropdown>
    );
  };

  const renderSubmitButton = () =>{
    return (
    <div >
    <Button
      type="bordered"
      style={{
        borderWidth: "1px",
        borderColor: theme["blue200"],
        color: "white",
        background: theme["blue200"],
        // position: "absolute",
        // zIndex: 10,
      }}
      onClick={() => {
        onSubmitClick();
      }}
    >
      Submit
    </Button>
  </div>
    )
  }

  const renderExtraInfo = () => {
    return (
      <>
        {slides && slides?.length > 0 && slides?.[mainSlide] ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "15px",
                alignItems: "center",
              }}
            >
              {hasSubSlides(slides?.[mainSlide]?.data) ? (
                <CircularArrow
                  ref={sliderRef}
                  subSlide={subSlide}
                  setSubSlide={setSubSlide}
                  totalSlides={slides?.[mainSlide]?.data.length}
                />
              ) : (
                ""
              )}
              {renderBackgroundColorDropdown()}
              {renderAddDropDown()}
              {renderSubmitButton()}
            </div>
            {hasSubSlides(slides?.[mainSlide]?.data) && (
              <Button
                type="link"
                danger
                onClick={() => {
                  deleteRef.current.setVisible(true);
                  setModalState(true);
                }}
                variant="skeleton"
                text="Delete Subslide"
                style={{
                  height: 25,
                  textDecoration: "underline",
                  padding: 0,
                  marginLeft: "15px",
                }}
              />
            )}
          </>
        ) : null}
      </>
    );
  };

  const addVerticalSlide = (type) => {
    let slide = null;
    if (type == "poll") {
      slide = { ...pollMainSlide };
    } else if (type == "image") {
      slide = { ...imageMainSlide };
    } else if (type == "slide") {
      slide = { ...simpleMainSlide };
    } else if (type == "schema") {
      slide = { ...schemaMainSlide };
    } else {
      notification.error({
        message: "You're trying to add an invalid slide type",
      });
      return;
    }

    slide.id = getUniqueId(slides.map((sli) => sli.id));
    slide.data[0].id = getUniqueId(slides.map((sli) => sli.id));

    setMainSlide(slides?.length);
    setSubSlide(0);
    setSlides((prev) => [...prev, slide]);
  };

  const options = useMemo(() => {
    let options = [
      {
        key: 1,
        label: "Poll",
        onClick: () => addVerticalSlide("poll"),
      },
      {
        key: 2,
        label: "Image",
        onClick: () => addVerticalSlide("image"),
      },
      {
        key: 3,
        label: "Slide",
        onClick: () => addVerticalSlide("slide"),
      },
      {
        key: 4,
        label: "Schema",
        onClick: () => addVerticalSlide("schema"),
      },
    ];
    return options;
  }, [slides]);

  const getCurrentSlideTitle = useCallback(() => {
    return slides?.[mainSlide]?.title;
  }, [slides, mainSlide, subSlide]);

  const [inputValue, setInputValue] = useState(getCurrentSlideTitle() ?? "");
  const [inputEditable, setInputEditable] = useState(false);

  useEffect(() => {
    setInputValue(getCurrentSlideTitle() ?? "");
  }, [mainSlide]);

  const inputStyle = {
    maxWidth: inputEditable ? "68%" : "fit-content",
    width: inputEditable ? "68%" : "fit-content", // Adjust the factor as needed
    // transition: "width 0.2s", // Add a transition for smooth width changes
  };

  const renderDropDown = () => {
    return (
      <Dropdown
        options={options}
        placement="bottomRight"
        suffixIcon=""
        trigger={"click"}
      >
        <PrimaryButton icon={<BsPlus size={30} />}>Add Slide</PrimaryButton>
      </Dropdown>
    );
  };

  const onChangeSlide = (newSlide) => {
    const tempSlides = JSON.parse(JSON.stringify(slides));
    tempSlides[mainSlide].data[subSlide] = {
      ...tempSlides[mainSlide].data[subSlide],
      data: newSlide ? newSlide : "",
    };
    setSlides(tempSlides);
  };

  const getCurrentSlideData = useMemo(() => {
    return slides?.[mainSlide]?.data;
  }, [mainSlide, subSlide, slides]);

  const renderActiveSlide = useMemo(
    () => (
      <Slide
        subSlide={subSlide ?? 0}
        slideData={getCurrentSlideData}
        onChange={onChangeSlide}
        backgroundColor={slides?.[mainSlide]?.backgroundColor ?? "white"}
        mainSlide={mainSlide}
      />
    ),
    [slides, subSlide, mainSlide]
  );
  const renderPreviews = useMemo(
    () => (
      <PresentationSlides
        slides={slides}
        setSlides={setSlides}
        mainSlide={mainSlide}
        setMainSlide={setMainSlide}
        setSubSlide={setSubSlide}
        basicInfo={basicInfo}
        setBasicInfo={setBasicInfo}
      />
    ),
    [mainSlide, slides, basicInfo]
  );
  return (
    <div className={styles.pageContainer}>
      <div className={styles.addLeftSection}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: window.location.href.includes("edit") ? "92%" : "95%",
            overflow: "auto",
          }}
        >
          <Card
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              border: "none",
            }}
            headStyle={{ borderBottom: "none" }}
            title={
              <Space
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Title
                  level={4}
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "110px",
                    color: "initial",
                  }}
                >
                  {basicInfo.name && basicInfo.name !== ""
                    ? basicInfo.name
                    : "Title"}
                </Title>
                <Icon
                  icon="AiOutlineEdit"
                  size="1.4rem"
                  color={theme["primary-color"]}
                  className="pointer"
                  onClick={() => {
                    modalRef.current.setVisible(true);
                    setModalState(true);
                  }}
                />
              </Space>
            }
            bodyStyle={{ paddingTop: 0 }}
          >
            {renderPreviews}
          </Card>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
            height: window.location.href.includes("edit") ? "6%" : "2%",
            marginRight: "3.3%",
          }}
        >
          {renderDropDown()}
        </div>
      </div>
      <div
        className={styles.addRightSection}
        // style={{left:"19vw" , width:"80vw"}}
      >
        <Card
          headStyle={{ borderBottom: "none" }}
          style={{
            ...(slides?.[mainSlide]?.backgroundColor && {
              backgroundColor: slides?.[mainSlide]?.backgroundColor ?? "white",
            }),
          }}
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                maxHeight: "10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                {slides && slides.length > 0 && mainSlide >= 0 && (
                  <>
                    {inputEditable ? (
                      <Input
                        style={inputStyle}
                        value={slides?.[mainSlide]?.title}
                        onChange={(e) => {
                          const tempSlides = [...slides];
                          tempSlides[mainSlide].title = e?.target?.value;
                          setSlides(tempSlides);
                          setInputValue(e.target.value);
                        }}
                        placeholder="Slide Title"
                        bordered={inputEditable}
                        maxLength={80}
                        contentEditable={!inputEditable}
                      />
                    ) : (
                      <Title
                        style={{
                          marginBottom: 0,
                          color:
                            slides?.[mainSlide]?.title &&
                            slides?.[mainSlide]?.title != ""
                              ? "black"
                              : theme["gray600"],
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        level={4}
                      >
                        {slides?.[mainSlide]?.title &&
                        slides?.[mainSlide]?.title != ""
                          ? slides?.[mainSlide]?.title
                          : "Slide Title"}
                      </Title>
                    )}
                    <Icon
                      icon={inputEditable ? "MdDone" : "AiOutlineEdit"}
                      size="1.4rem"
                      color={theme["primary-color"]}
                      className="pointer"
                      onClick={() => {
                        const tempSlides = [...slides];
                        tempSlides[mainSlide] = {
                          ...tempSlides[mainSlide],
                          titile: inputValue,
                        };
                        setSlides([...tempSlides]);
                        setInputEditable((prev) => (prev = !prev));
                      }}
                    />
                  </>
                )}
              </div>

              {renderExtraInfo()}
            </div>
          }
          bodyStyle={{
            height: "90%",
            overflowY:
              slides[mainSlide]?.data?.[subSlide]?.type == "poll"
                ? "scroll"
                : "hidden",
          }}
          className={styles.slideCard}
        >
          {renderActiveSlide}
        </Card>
      </div>
      <Modal
        ref={modalRef}
        onOk={() => {
          modalRef.current.setVisible(false);
          setModalState(false);
        }}
        onCancel={() => {
          modalRef.current.setVisible(false);
          setModalState(false);
        }}
        okText="Update"
        cancelText="Cancel"
        title="Edit Presentation Details"
      >
        <PresentationDetails
          basicInfo={basicInfo}
          setBasicInfo={setBasicInfo}
        />
      </Modal>
      <Modal
        ref={deleteRef}
        onOk={() => {
          const tempSlides = JSON.parse(JSON.stringify(slides));
          tempSlides[mainSlide].data = tempSlides[mainSlide].data.filter(
            (sli, index) => index != subSlide
          );
          setSlides(tempSlides);
          setSubSlide(0);
          deleteRef.current.setVisible(false);
          setModalState(false);
        }}
        onCancel={() => {
          modalRef.current.setVisible(false);
          setModalState(false);
        }}
        okText="Confirm"
        cancelText="Cancel"
        title={`Remove Slide`}
      >
        Are you sure you want to delete this subslide?
      </Modal>
    </div>
  );
};
export default PresentationPage;
