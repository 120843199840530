import { Space, Typography } from "antd"

import { Icon } from "components/common"

const { Paragraph } = Typography

const TitleDescription = props => {
  const { title, description, descriptionIcon, color, gapSpace, onClick, titleClassName } = props
  return (
    <>
      <Paragraph className={"mbe-0 " + titleClassName} onClick={onClick}>
        {title}
      </Paragraph>
      <Space align="start" size={gapSpace ? gapSpace : 24}>
        <Paragraph className="mbe-0" style={{ color: color ? color : "#9d9d9d" }}>
          {descriptionIcon && description && <Icon icon={descriptionIcon} size="14px" style={{ translate: "0 1px" }} />}
          {description}
        </Paragraph>
      </Space>
    </>
  )
}

export default TitleDescription
