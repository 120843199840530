import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import Alert from "../alert/alert";
import { Modal as AntdModal } from "antd";
import Button from "../button/button";
import Icon from "../icon/icon";
import cx from "clsx";
import styles from "./index.module.less";

const Modal = forwardRef((props, ref) => {
  const {
    onClose,
    onCancel,
    className,
    onOk,
    title,
    subTitle = "",
    type,
    color,
    footer,
    width,
    children,
    okText,
    cancelText,
    loading,
    onOkProps,
    onCancelProps,
    hideCancelButton,
    disableOkButton,
    onModalVisible = () => {},
    footerAlert,
    destroyOnClose,
    forceRender,
    description,
    ...rest
  } = props;

  const [visible, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      onOk,
      onCancel,
      visible,
      setVisible(value) {
        setShow(value);
      },
    }),
    []
  );

  useEffect(() => {
    visible && onModalVisible(visible);
  }, [visible]);

  return (
    <AntdModal
      title={
        title && (
          <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            {title}
            {subTitle && (
              <span className={cx("gray-800", styles.subTitle)}>
                {subTitle}
              </span>
            )}
            {description && (
              <span
                style={{
                  fontWeight: "normal",
                  color: "grey",
                  fontSize: "14px",
                }}
              >
                {description}
              </span>
            )}
          </div>
        )
      }
      forceRender={forceRender}
      destroyOnClose={destroyOnClose}
      open={visible}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      onOk={onOk}
      type={color ? type : false}
      width={width}
      className={className}
      centered
      closeIcon={<Icon icon="ImCross" size=".8em" />}
      footer={
        footer || footer === null
          ? footer
          : [
              footerAlert?.message && (
                <Alert
                  className="mbe-16"
                  style={{ marginInline: -32, marginBlockStart: -20 }}
                  banner
                  type="error"
                  showIcon
                  {...footerAlert}
                />
              ),
              !!onCancel && !hideCancelButton && (
                <Button
                  size="large"
                  type="default"
                  key="back"
                  className={styles.cancel}
                  onClick={onCancel}
                  text={cancelText}
                  {...onCancelProps}
                />
              ),
              !!onOk && (
                <Button
                  size="large"
                  type={type}
                  key="submit"
                  onClick={onOk}
                  loading={loading}
                  disabled={loading || disableOkButton}
                  text={okText}
                  {...onOkProps}
                />
              ),
            ]
      }
      {...rest}
    >
      {children}
    </AntdModal>
  );
});

Modal.defaultProps = {
  width: 700,
  type: "primary",
  okText: "Confirm",
  cancelText: "Cancel",
};

export default Modal;
