import { getPaginationObject } from "configs/utils";

export const allLibrariesTransformer = (data) => {
  return {
    list: data?.data?.length ? data?.data : [],
    pagination: getPaginationObject({ ...data.pagination, count: data?.count }),
  };
};

export const scriptById = (data) => {
  return data;
};

export const getLastThreeTemplates = (data) => {
  //sorting data in descending order on basis of version
  // returning 3 most recently updated templates
  return data;
};
