import { createSlice } from "@reduxjs/toolkit";

const scriptsSlice = createSlice({
  name: "scripts",
  initialState: {
    data: {
      podCast: [],
    },
  },
  reducers: {
    setScriptsData(state, { payload }) {
      state.data = payload;
    },
    setTemplateDetails(state, { payload }) {
      state.data = { ...state?.data, ...payload };
    },
    setBasicScriptData(state, { payload }) {
      state.data = { ...state?.data, ...payload };
    },
    setScriptPresentationData(state, { payload }) {
      state.data = { ...state?.data, presentationSection: payload };
    },
    setScriptPodCastData(state, { payload }) {
      if (state.data) {
        state.data.podCast = payload;
      }
    },
    setQuestionsData(state, { payload }) {
      if (state.data) {
        state.data.questions = payload;
      }
    },
  },
});

export const {
  setScriptsData,
  setScriptPresentationData,
  setTemplateDetails,
  setBasicScriptData,
  setScriptPodCastData,
  setQuestionsData,
} = scriptsSlice.actions;
export default scriptsSlice;
