import { Alert, Form, Input, Layout } from "antd";
import {
  Button,
  Card,
  Checkbox,
  Group,
  PhoneInput,
  SelectInput,
  Text,
  Title,
  notification,
} from "components/common";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { LogoIllnessRx } from "components/svg";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setAppToken } from "store/appSlice";
import { AUTH_TOKEN_COOKIE_KEY } from "../../constants";
import {
  useForgotPasswordMutation,
  useLazyGetSyncElasticSearchQuery,
  useLoginMutation,
  useResendEmailMutation,
  useSignUpMutation,
  useSignUpPresentationMutation,
} from "./api";
import styles from "./index.module.less";
import { useGetAllCountriesQuery } from "features/dashboard/components/ACCOUNTS/api";

const SignIn = () => {
  const [login, { isLoading, isError, error }] = useLoginMutation();
  const { data: countries, isLoading: countriesLoading } =
    useGetAllCountriesQuery();
  const [
    syncData,
    {
      isLoading: isSyncingData,
      isError: isErrorSyncingData,
      error: errorSyncingData,
    },
  ] = useLazyGetSyncElasticSearchQuery();
  const [forgotPass, {}] = useForgotPasswordMutation();
  const [signUp, {}] = useSignUpMutation();
  const [signUpPresentation, {}] = useSignUpPresentationMutation();
  const [resentEmailPresentation, {}] = useResendEmailMutation();
  const [resendEmail, {}] = useResendEmailMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);
  const { redirectedPath = "" } = queryObj || {};

  const [selectedFlavour, setSelectedFlavour] = useState(
    window.location.pathname.includes("verified")
      ? "verificationSuccessful"
      : "login"
  );
  const [email, setEmail] = useState(null);
  const [isMobile, setIsMobile] = useState(true);

  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const country = Form.useWatch("country", form);

  useEffect(() => {
    const presentation_id = searchParams.get("pres_id");
    if (presentation_id) {
      localStorage.setItem(
        "presentation_url",
        `/presentation/${presentation_id}`
      );
    }

    setIsMobile(window.innerWidth <= 700);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    // add eventListener for window resize

    window.addEventListener("resize", handleResize);

    // initial check on component mount
    handleResize();

    // remove the event listener when the component unmounts

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmitLogin = async (values) => {
    const response = await login(values).unwrap();
    if (response.data) {
      Cookies.set(AUTH_TOKEN_COOKIE_KEY, response.data);
      dispatch(setAppToken({ token: response.data }));
      // syncData();
    }
  };

  const handleForgotPassword = async (values) => {
    const response = await forgotPass(values).unwrap();
    if (response.status_code == 200) {
      notification.success({
        message: "A verification email has been sent to you",
      });
    }
  };

  const handleSignUp = async (values) => {
    let response = null;
    if (localStorage.getItem("presentation_url")) {
      const presentationLink =
        window.location.origin + localStorage.getItem("presentation_url");
      const presentationId = localStorage
        .getItem("presentation_url")
        .substring(
          localStorage.getItem("presentation_url").lastIndexOf("/") + 1
        );
      response = await signUpPresentation({
        ...values,
        presentation_id: presentationId,
        presentation_link: presentationLink,
      }).unwrap();
    } else {
      response = await signUp(values).unwrap();
    }

    if (response.status_code == 200) {
      notification.success({
        message: "A verification email has been sent to you",
      });
      setSelectedFlavour("EmailSent");
    }
  };

  const handleResendEmail = async () => {
    const response = await resendEmail({ email: email }).unwrap();
    if (response.status_code == 200) {
      notification.success({
        message: "Verification email is resent to you",
      });
    }
  };

  const renderForgotPassword = () => (
    <>
      <Title align="middle" level="4">
        Forgot Password?
      </Title>
      <Text style={{ display: "flex", textAlign: "center" }}>
        Please enter your email address below and we will send you a link to
        reset your password
      </Text>
      <Form
        name="login"
        form={form}
        onFinish={handleForgotPassword}
        layout="vertical"
      >
        <Form.Item
          name="email"
          rules={[
            { message: "Please input email", required: true },
            { message: "Please enter valid email", type: "email" },
          ]}
          initialValue=""
          label="Email Address"
          validateTrigger={"onBlur"}
        >
          <Input placeholder="e.g. jane.doe@example.com" />
        </Form.Item>
        {isError && (
          <Alert
            style={{ textAlign: "center" }}
            message={error.status + ": " + error?.data?.errors}
            type="error"
          />
        )}
        <div style={{ marginTop: "10px" }}>
          <Button
            htmlType="submit"
            type="primary"
            block
            size="large"
            loading={isLoading}
            disabled={isLoading}
            text="Submit"
          />
        </div>
      </Form>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div>
          <Text
            style={{
              display: "flex",
              textAlign: "center",
              whiteSpace: isMobile ? "nowrap" : "unset",
            }}
          >
            Already know your account password ?
          </Text>
        </div>
        <div>
          <Button
            type="link"
            text={"Login Now"}
            onClick={() => setSelectedFlavour("login")}
          />
        </div>
      </div>
    </>
  );
  const renderVerificationSuccessful = () => (
    <>
      <Title align="middle" level="4">
        Congratulations!
      </Title>
      <Text style={{ display: "flex", textAlign: "center" }}>
        Your email has been successfully verified. Please click the button below
        to log into your account.
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div>
          <Button
            htmlType="submit"
            type="primary"
            block
            size="large"
            loading={isLoading}
            disabled={isLoading}
            text={"Login"}
            onClick={() => setSelectedFlavour("login")}
          />
        </div>
      </div>
    </>
  );

  const renderSingUp = () => (
    <>
      <Title align="middle" level="4">
        Create your Account
      </Title>
      <Form
        name="login"
        form={form}
        getInitialValues={{
          firstname: null,
          lastname: null,
          email: null,
          phone_number: null,
          country: "Pakistan",
          username: null,
          password: null,
          confirmpassword: null,
        }}
        onFinish={handleSignUp}
        layout="vertical"
      >
        <Group
          gap="30px"
          template={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
          className="mbe-32"
        >
          <Form.Item
            name="firstname"
            rules={[{ message: "Please enter first name", required: true }]}
            initialValue=""
            label="First Name"
            validateTrigger={"onBlur"}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[{ message: "Please enter last name", required: true }]}
            initialValue=""
            label="Last Name"
            validateTrigger={"onBlur"}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { message: "Please input email", required: true },
              { message: "Please enter valid email", type: "email" },
            ]}
            initialValue=""
            label="Email Address"
            validateTrigger={"onBlur"}
          >
            <Input
              placeholder="e.g. jane.doe@example.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={"Phone Number"}
            required
            hasFeedback
            validateTrigger={["onChange", "onBlur"]}
            name="phone_number"
            // rules={rules}
          >
            <PhoneInput
              defaultCountry="US"
              onChange={(value) => form.setFieldValue({ mobile: value })}
              required
            />
          </Form.Item>
          <Form.Item name="country" label="Country">
            <SelectInput
              placeholder="Select Country"
              options={countries}
              inputLoading={countriesLoading}
              value={country}
              getOptionLabel={(e) => e?.country}
              getOptionValue={(e) => e?.country}
              onChange={(value) => {
                form.setFieldValue("country", value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              { message: "Please enter user name", required: true },
              // { message: "Please enter valid email", type: "email" },
            ]}
            initialValue=""
            label="User Name"
            validateTrigger={"onBlur"}
          >
            <Input placeholder="User Name" />
          </Form.Item>
          <Form.Item
            name="password"
            initialValue=""
            label="Password"
            rules={[{ message: "Please enter password", required: true }]}
          >
            <Input.Password placeholder="Your secure password" />
          </Form.Item>
          <Form.Item
            initialValue=""
            label="Confirm password"
            rules={[{ message: "Please enter password", required: true }]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
        </Group>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text style={{ display: "flex", textAlign: "center" }}>
            By Logging in, you agree to illnessRx’s Terms of Service and Privacy
            Policy
          </Text>
        </div>

        {isError && (
          <Alert
            style={{ textAlign: "center" }}
            message={error.status + ": " + error?.data?.errors}
            type="error"
          />
        )}
        <div style={{ marginTop: "10px" }}>
          <Button
            htmlType="submit"
            type="primary"
            block
            size="large"
            loading={isLoading}
            disabled={isLoading}
            text="Sign Up"
          />
        </div>
      </Form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>Already have an account?</div>
        <div>
          <Button
            type="link"
            text={"Login Now"}
            onClick={() => setSelectedFlavour("login")}
          />
        </div>
      </div>
    </>
  );

  const renderLogin = () => (
    <>
      <Title align="middle" level="4">
        Log in to your Account
      </Title>
      <Form
        name="login"
        form={form}
        initialValues={{
          email: null,
          password: null,
          keep_me_logged_in: false,
        }}
        onFinish={handleSubmitLogin}
        layout="vertical"
      >
        <Form.Item
          name="email"
          rules={[
            { message: "Please input email", required: true },
            { message: "Please enter valid email", type: "email" },
          ]}
          initialValue=""
          label="Email Address"
          validateTrigger={"onBlur"}
        >
          <Input placeholder="e.g. jane.doe@example.com" />
        </Form.Item>
        <Form.Item
          name="password"
          initialValue=""
          label="Password"
          rules={[{ message: "Please enter password", required: true }]}
        >
          <Input.Password placeholder="Your secure password" />
        </Form.Item>
        {isError && (
          <Alert
            style={{ textAlign: "center" }}
            message={error.status + ": " + error?.data?.errors}
            type="error"
          />
        )}
        <Form.Item noStyle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Form.Item noStyle name="keep_me_logged_in">
              <Checkbox onChange={() => {}}>Stay Logged In</Checkbox>
            </Form.Item>

            <div>
              <Button
                type="link"
                text={"Forgot Password?"}
                onClick={() => setSelectedFlavour("forgot_Password")}
              />
            </div>
          </div>
        </Form.Item>
        <div style={{ marginTop: "10px" }}>
          <Button
            htmlType="submit"
            type="primary"
            block
            size="large"
            loading={isLoading}
            disabled={isLoading}
            text="Sign In"
          />
        </div>
      </Form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <Text style={{ display: "flex", textAlign: "center" }}>
            Don't have an account?
          </Text>
        </div>
        <div>
          <Button
            type="link"
            text={"Sign Up"}
            onClick={() => setSelectedFlavour("sign-up")}
          />
        </div>
      </div>
    </>
  );

  const renderEmailSentScreen = () => {
    return (
      <>
        <Title align="middle" level="4">
          Almost Done
        </Title>
        <Text style={{ display: "flex", textAlign: "center" }}>
          You have successfully registered with illnessRx.com. Please click on
          the verification link sent to your email address to finish setting up
          your account. If you have already verified your email address, please
          click below button to login.
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <div>
            <Button
              type="primary"
              text={"Login"}
              style={{ width: "250px" }}
              onClick={() => setSelectedFlavour("login")}
            />
          </div>
          <div>
            <Text style={{ display: "flex", textAlign: "center" }}>
              Didn't receive email?
            </Text>
          </div>
          <div>
            <Button
              type="link"
              text={"Resend Email"}
              onClick={() => handleResendEmail()}
            />
          </div>
        </div>
      </>
    );
  };

  const authFlavours = [
    { key: "login", label: "Login", render: renderLogin() },
    {
      key: "forgot_Password",
      label: "Forgot Password",
      render: renderForgotPassword(),
    },
    { key: "sign-up", label: "Sign up", render: renderSingUp() },
    { key: "EmailSent", label: "Almost Done", render: renderEmailSentScreen() },
    {
      key: "verificationSuccessful",
      label: "Almost Done",
      render: renderVerificationSuccessful(),
    },
  ];

  return (
    <Layout.Content className={styles.authLayout}>
      <Group
        gap="32px"
        style={{
          placeContent: isMobile ? "unset" : "center",
          minHeight: "100vh",
        }}
      >
        {!isMobile && (
          <div className="pb-32">
            <div className={styles.logo}>
              <LogoIllnessRx width={350} height="auto" />
            </div>
            <Title level={1}></Title>
          </div>
        )}
        <Card
          style={{
            width: !isMobile
              ? selectedFlavour === "sign-up"
                ? "55vw"
                : "35vw"
              : "unset",
          }}
        >
          <Group
            style={{ padding: "40px" }}
            template="initial"
            justify="space-around"
            align=""
          >
            {authFlavours?.find((e) => e.key == selectedFlavour)?.render}
          </Group>
        </Card>
      </Group>
    </Layout.Content>
  );
};

export default SignIn;
