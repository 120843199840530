import { Modal, TextInput, notification } from "components/common";
import { generateUUID } from "pubnub";
import { useEffect, useState } from "react";

const PodCastModal = ({ visible, setVisible, data, onConfirm }) => {
  const [podcastUrl, setPodcastUrl] = useState(data);

  useEffect(() => {
    setPodcastUrl(data);
  }, [data]);

  const handleOk = () => {
    if (!!podcastUrl) {
      const tempPodcast = {
        type: "licensePodcast",
        ImageURL: podcastUrl,
        id: generateUUID(),
      };

      onConfirm(tempPodcast);
      setVisible(false);
    } else {
      notification.error({ message: "Please Enter Podcast Url" });
    }
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title={"Add Podcast"}
      onOk={handleOk}
      onCancel={() => {
        setPodcastUrl(null);
        setVisible(false);
      }}
    >
      <TextInput
        name="podcast"
        maxLength={500}
        placeholder={`Enter Podcast URL`}
        value={podcastUrl}
        handleChange={(e) => setPodcastUrl(e?.target?.value)}
      />
    </Modal>
  );
};

export default PodCastModal;
