import { Card } from "antd";
import { Divider, Icon, Title } from "components/common";
import { useGetQuizDetailsQuery } from "features/script/api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setQuizResultData } from "store/quizSlice";
import theme from "utils/themeVars";
import QuestionItem from "../takeQuiz/questionItem";

const QuizResult = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);
  const quizDetails = useSelector((state) => state?.quiz?.data?.result);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quizId } = queryObj;
  const { data } = useGetQuizDetailsQuery(
    { quizId },
    { refetchOnMountOrArgChange: true, skip: !quizId }
  );

  useEffect(() => {
    if (data?.length) {
      dispatch(setQuizResultData(data?.[0]));
    }
  }, [data]);

  return (
    <div style={{ minHeight: "100vh", backgroundColor: theme["light-gray"] }}>
      <Card>
        <div style={{ margin: "1%" }}>
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <Icon
              icon="IoMdArrowBack"
              size={24}
              onClick={() => {
                navigate("/user/quiz");
              }}
              color={theme["primary-color"]}
              className="pointer"
            />
            <Title style={{ marginBottom: "0px" }} level={1}>
              Result
            </Title>
          </div>
          {quizDetails?.questionsList?.map((question, i) => (
            <div style={{ marginBottom: "30px" }}>
              <QuestionItem
                question={question}
                selectedQuestion={i}
                quizDetails={quizDetails}
                showQuizActions={false}
                resultPage={true}
              />
              {i != quizDetails?.questionsList?.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default QuizResult;
