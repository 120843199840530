import { Input } from "antd";
import { Button, Dropdown } from "components/common";
import { Title } from "components/common";

const ExplanationHeader = ({
  value,
  onChange,
  inputKey,
  isMobile,
  explanationActionList,
  isPodCast = false,
}) => {
  return (
    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
      {isPodCast ? (
        <Title>Podcasts</Title>
      ) : (
        <Input
          placeholder="Enter Explanation Title"
          style={{ pointerEvents: "all", width: isMobile ? "80%" : "95%" }}
          value={value}
          onChange={onChange}
        />
      )}

      {isMobile && (
        <Dropdown
          onClick={(event) => {
            event.stopPropagation();
          }}
          options={explanationActionList}
          suffixIcon=""
          trigger={"click"}
        >
          <Button
            size="small"
            style={{ background: "transparent" }}
            icon={"BsThreeDotsVertical"}
          />
        </Dropdown>
      )}
    </div>
  );
};

export default ExplanationHeader;
