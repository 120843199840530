import { Select, Skeleton, Typography } from "antd";
import { useCallback, useState } from "react";

import HoverText from "../hoverable-text/hoverable-text";
import Icon from "../icon/icon";
import PropTypes from "prop-types";
import Spinner from "../spinner/spinner";

function SelectInput(props) {
  const {
    label,
    labelProps,
    labelClass,
    placeholder,
    labelIcon,
    skeletonLoading,
    options,
    getOptionLabel = (e) => e,
    getOptionValue = (e) => e,
    suffixIcon,
    prefixIcon,
    openIcon,
    sortSelectedList = true,
    allowClear = false,
    showSearch,
    serverProps,
    horizontal,
    className,
    onChange,
    errorMsg,
    valueAsObj,
    inputLoading,
    selectGrow,
    defaultValue,
    value,
    getPopupContainer = true,
    filterOption = (value, option) => {
      return option?.label
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase());
    },
    ...rest
  } = props;
  const [suffix, setSuffix] = useState(suffixIcon);
  const [prefix, setPrefix] = useState(prefixIcon);

  const getOptions = useCallback(() => {
    if (options && options?.length) {
      let tempOptions = options?.map((item) => ({
        ...item,
        value: getOptionValue(item),
        label: getOptionLabel(item),
      }));
      if (sortSelectedList) {
        if (typeof value == "string" && !!value) {
          const selectedItems = tempOptions.filter(
            (item) => value == item?.value
          );
          const remainingItems = tempOptions.filter(
            (item) => value != item?.value
          );
          return [...selectedItems, ...remainingItems];
        } else if (typeof value == "object" && !!value?.length) {
          const selectedItems = tempOptions.filter((option) =>
            value.includes(option?.value)
          );
          const remainingItems = tempOptions.filter(
            (option) => !value.includes(option?.value)
          );
          return [...selectedItems, ...remainingItems];
        } else return tempOptions;
      } else {
        return tempOptions;
      }
    }
    return [];
  }, [options, value, getOptionValue]);

  const filterOptions = (searchValue) => {};

  const renderSelect = () => {
    return (
      <>
        <Select
          className={className}
          defaultValue={defaultValue}
          showSearch={showSearch}
          maxTagCount={0}
          maxTagPlaceholder={(items) => {
            return (
              <HoverText
                message={`+${items.length}`}
                data={items.map((e) => e?.label)}
              />
            );
          }}
          suffixIcon={
            inputLoading ? (
              <Spinner type="plain" />
            ) : (
              !!suffix && (
                <Icon
                  icon={"AiFillCaretDown"}
                  className="ant-select-suffix"
                  color="#9D9D9D"
                  size={"16px"}
                />
              )
            )
          }
          placeholder={
            <>
              {prefixIcon && <Icon icon={prefixIcon} />}
              {placeholder && placeholder}
            </>
          }
          onDropdownVisibleChange={() => {
            setSuffix(suffix == suffixIcon ? openIcon : suffixIcon);
            setPrefix(prefix == prefixIcon ? openIcon : suffixIcon);
          }}
          options={getOptions()}
          onChange={(value, option) => {
            onChange(value, option);
          }}
          filterOption={filterOption}
          status={!!errorMsg ? "error" : undefined}
          dropdownStyle={{ "--select-dropdown-accent-color": rest.accentColor }}
          {...(getPopupContainer
            ? { getPopupContainer: (triggerNode) => triggerNode.parentElement }
            : {})}
          showArrow
          value={value}
          {...rest}
        />
        {!!errorMsg && (
          <Typography.Text type="danger">
            <small>{errorMsg}</small>
          </Typography.Text>
        )}
      </>
    );
  };

  const renderLabel = () => {
    return skeletonLoading ? (
      <Skeleton.Button active size="small" style={{ height: 22 }} />
    ) : (
      <label
        className={labelClass}
        style={{
          display: "block",
          visibility: label === "none" ? "hidden" : "unset",
        }}
      >
        {label}
      </label>
    );
  };

  return (
    <>
      {skeletonLoading ? (
        <Skeleton.Input active style={{ borderRadius: 6, width: "100%" }} />
      ) : (
        <div>
          {renderLabel()}
          {renderSelect()}
        </div>
      )}
    </>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.object,
  placeholder: PropTypes.string,
  labelIcon: PropTypes.string,
  name: PropTypes.string,
  skeletonLoading: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  suffixIcon: PropTypes.string,
  prefixIcon: PropTypes.string,
  openIcon: PropTypes.string,
  showSearch: PropTypes.bool,
  serverProps: PropTypes.object,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
};

SelectInput.defaultProps = {
  labelIcon: null,
  skeletonLoading: false,
  options: [],
  getOptionLabel: (item) => item.label || item.name || item.title,
  getOptionValue: (item) => item.value || item.id,
  suffixIcon: "MdKeyboardArrowDown",
  openIcon: "MdKeyboardArrowUp",
  showSearch: true,
  serverProps: null,
  horizontal: false,
};

export default SelectInput;
