import { createSlice } from "@reduxjs/toolkit"

const modalReducer = createSlice({
  name: "modalReducer",
  initialState: {isModalOpen : false},
  reducers: {
    setModalStatus(state, { payload }) {
      state.isModalOpen = payload?.isModalOpen
    },
  },
})

// export const { setModalStatus } = appSlice.actions
export const { setModalStatus } = modalReducer.actions;
export default modalReducer
