const sectionsPrivsKeys = {
  ModerationQueue: {
    show_listing: "can_view_queues",
    assign_users: "can_assign_users",
    index: "can_view_index", //this
    destroy: "can_delete_queue",
    listings: "can_view", //this
    fetch_listing: "can_fetch_", //this
    approve: "can_approve_", //this
    create: "can_create_queue",
    read: "can_read_queue", //this
    update: "can_update_queue",
  },
  BadWord: {
    index: "can_view_badword", //this
    destroy: "can_delete_badword",
    create: "can_create_badword",
    read: "can_view_badword_details",
    update: "can_update_badword",
  },
  User: {
    current: "can_view_current_user", //this
    index: "can_view_user", //this
    read: "can_view_user_details",
    update: "can_update_user",
  },
  Target: {
    index: "can_view_target", //this
    create: "can_create_target",
    read: "can_view_target_details",
    update: "can_update_target",
  },
  Admin: {
    index: "can_view_admin",
    create: "can_create_admin",
    read: "can_view_admin_details",
    update: "can_update_admin",
  },
  Team: {
    index: "can_view_team",
    create: "can_create_teams",
    read: "can_view_team_details",
    update: "can_update_team",
  },
  Role: {
    index: "can_view_role",
    create: "can_create_role",
    read: "can_view_role_details",
    update: "can_update_role",
    destroy: "can_delete_role",
  },
  Permission: {
    index: "can_view_permissions",
  },
  Designation: {
    index: "can_view_designations",
  },
  Location: {
    cities_listings_count: "can__view_cities_count", //this
    sub_locations: "can_view_sublocations", //this
    index: "can_view_location",
    create: "can_create_location",
    read: "can_view_location_details",
    update: "can_update_location",
    destroy: "can_delete_location",
  },
  Agency: {
    approve: "can_approve_agency",
    reject: "can_reject_agency",
    suspend: "can_suspend_agency",
    mark_as_admin: "can_mark_admin",
    users: "can_view_user",
    index: "can_view_agency",
    read: "can_view_agency_details",
    update: "can_update_agency",
  },
  Keyword: {
    index: "can_view_keywords",
  },
}

const getSectionPriv = (sectionKey, privs) => {
  let sectionPermissions = {}
  Object.keys(sectionsPrivsKeys[sectionKey]).forEach(e => {
    sectionPermissions[sectionsPrivsKeys[sectionKey][e]] = privs?.manage ? true : privs[e]
  })
  return sectionPermissions
}

const getUserPreviliges = privs => {
  if (privs) {
    const privileges = {}
    Object.keys(privs).forEach(e => {
      privileges[e.toLocaleLowerCase()] = getSectionPriv(e, privs[e])
    })
    return privileges
  }
}

export const userDetailsTransformer = user => {
  return {
    ...user,
    priviliges: user?.permissions,
  }
}
