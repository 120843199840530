import { createSlice } from "@reduxjs/toolkit";

const templateSettingSlice = createSlice({
  name: "templateSetting",
  initialState: {
    data: {},
  },
  reducers: {
    setTemplateSettingsData(state, { payload }) {
      state.data = { ...state.data, ...payload };
    },
  },
});

export const { setTemplateSettingsData } = templateSettingSlice.actions;
export default templateSettingSlice;
