import "./App.less";

import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ErrorBoundary } from "components/custom";
import Routes from "routes";
import { useInitializeApp } from "configs/useInitializeApp";
import { useSelector } from "react-redux";

const App = () => {
  const configs = useSelector((state) => state.app.configs);
  useInitializeApp();

  return (
    <ConfigProvider direction={configs.direction}>
      <BrowserRouter>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
