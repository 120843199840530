import cx from "clsx"
import * as LocalIcons from "../../svg"
import * as ReactIcons from "../../icons"
import AntIcon from "@ant-design/icons"
import PropTypes from "prop-types"
import React from "react"
import styles from "./index.module.less"
import Tooltip from "../tooltip/tooltip"

function Icon(props) {
  const { icon, size, className, hasBackground, tooltipText, iconContainerStyle, ...rest } = props
  const Component = ReactIcons[icon] || LocalIcons[icon]

  const getIcon = () =>
    !!tooltipText ? (
      <Tooltip placement="top" title={tooltipText}>
        <AntIcon
          className={cx(styles.icon, className)}
          component={() => (Component ? <Component size={size} {...rest} /> : null)}
        />
      </Tooltip>
    ) : (
      <AntIcon
        className={cx(styles.icon, className)}
        component={() => (Component ? <Component size={size} {...rest} /> : null)}
      />
    )

  return hasBackground ? (
    <div className={styles.iconStyled} style={{ ...iconContainerStyle }}>
      {getIcon()}
    </div>
  ) : (
    getIcon()
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Icon.defaultProps = {
  size: "1.2em",
}

export default Icon
