import { useState } from "react";
import { Handle, NodeResizer, Position } from "reactflow";
import NodeLabelModel from "./nodeLabelModal";
import { renderQuillHtml } from "configs/utils";

function ProcessNode({
  data,
  id,
  onChange,
  selected,
  onNodeResize,
  viewOnly,
  ...rest
}) {
  const [isVisible, setIsVisible] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: data?.width,
    height: data?.height,
  });

  const onResize = (event, dim) => {
    onNodeResize(id, { width: dim?.width, height: dim?.height });
  };

  return (
    <>
      <NodeLabelModel
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onChange={onChange}
        label={data?.label}
        id={id}
      />
      {selected && (
        <NodeResizer
          id={id}
          minWidth={200}
          minHeight={70}
          onResize={onResize}
        />
      )}
      <div
        style={{
          position: "relative",
          width: dimensions?.width,
          height: dimensions?.height,
          border: selected ? "2px solid #1565DC" : "1px solid #000",
          borderRadius: 5,
          pointerEvents: "all",
          backgroundColor: data?.color || "#fff",
        }}
        className="pointer"
        onDoubleClick={() => setIsVisible(true)}
      >
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            borderRadius: "50%", // Makes it a circle
            border: "1px solid #000", // Border color and width
            // marginTop: "-4%",
          }}
          id={"test1"}
        />
        <Handle
          type="target"
          position={Position.Left}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            borderRadius: "50%", // Makes it a circle
            border: "1px solid #000", // Border color and width
            // marginLeft: "-4%",
          }}
          id={"test2"}
        />

        <Handle
          type="source"
          position={Position.Right}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            border: "1px solid #000",
            // marginRight: "-4%",
          }}
          id={"test3"}
        />

        <div
          style={{
            position: "absolute",
            fontWeight: "bold",
            color: selected ? "#1565DC" : "#000",
            padding: "10px",
            overflow: "auto", // Add this line to enable scrolling
            maxWidth: dimensions?.width - 10, // Set a maximum width for the box
            maxHeight: dimensions?.height - 10, // Set a maximum height for the box
            // lineHeight: "1px",
          }}
        >
          {renderQuillHtml(data?.label)}
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: "#fff",
            width: "9px",
            height: "9px",
            marginTop: "58%",
            borderRadius: "50%", // Makes it a circle
            border: "1px solid #000", // Border color and width
            // marginBottom: "-4%",
          }}
          id={"test4"}
        />
      </div>
    </>
  );
}

export default ProcessNode;
