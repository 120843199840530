import { combineReducers, configureStore } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import { authApi } from "features/auth/api";
import parentApi from "./parentApi";
import reportsReducer from "./reportsSlice";
import modalReducer from "./modalReducer";
import thunk from "redux-thunk";
import scriptsReducer from "./scriptSlice";
import scriptsDetailReducer from "./scriptDetailSlice";
import templateSettingSlice from "./templateSettingsSlice";
import quizSlice from "./quizSlice";

const reducers = combineReducers({
  [appReducer.name]: appReducer.reducer,
  [parentApi.reducerPath]: parentApi.reducer,
  [reportsReducer.name]: reportsReducer.reducer,
  appState: appReducer,
  [authApi.reducerPath]: authApi.reducer,
  [modalReducer.name]: modalReducer.reducer,
  [scriptsReducer.name]: scriptsReducer.reducer,
  [scriptsDetailReducer.name]: scriptsDetailReducer.reducer,
  [templateSettingSlice.name]: templateSettingSlice.reducer,
  [quizSlice.name]: quizSlice.reducer,
});

export default configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      parentApi.middleware,
      parentApi.middleware,
      thunk
    ),
});
