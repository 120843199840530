import { Form } from "antd";
import { Modal, TextInput, Title } from "components/common";
import ImageSlider from "components/common/slider/slider";
import TextEditor from "components/common/textEditor/textEditor";
import { schemaRules } from "features/shared/utils";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "utils";
import * as yup from "yup";

const AddImageVideoModal = ({
  isVisible,
  setIsVisible,
  type,
  data,
  onConfirm,
  renderFooter = true,
  detailPage = false,
  ...rest
}) => {
  const isPodCast = type === "licensePodcast";
  const [form] = Form.useForm();

  const getValidationSchema = () =>
    yup.object().shape({
      siteURL: yup.string().required("Site Url is required").nullable(),
      ImageURL: yup.string().required("Url is required").nullable(),
    });

  const rules = schemaRules(getValidationSchema());

  const getInitialValues = (data) => {
    return {
      imageTitle: data?.imageTitle || data?.videoTitle || "",
      siteURL: data?.siteURL || data?.siteURL || "",
      ImageURL: data?.ImageURL || data?.VideoIframe || "",
    };
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(getInitialValues(data));
    }
  }, [data]);

  const onSubmit = (values) => {
    onConfirm({ ...data, ...values, type: type });
    setIsVisible(null);
  };

  const renderFormView = () => {
    return (
      <Form
        name="basic"
        initialValues={getInitialValues()}
        form={form}
        scrollToFirstError
        onFinish={onSubmit}
        preserve={true}
        labelCol={{ span: 6 }}
        shouldUpdate
        disabled={!renderFooter}
      >
        <div style={{ display: "flex", gap: "1vh", flexDirection: "column" }}>
          {!isPodCast && (
            <>
              <Form.Item name="imageTitle">
                <TextInput
                  name="imageTitle"
                  maxLength={100}
                  placeholder={`Enter Linked ${
                    type?.includes("image") || type?.includes("Image")
                      ? "Image"
                      : "Video"
                  } Title`}
                  handleChange={(e) =>
                    form.setFieldsValue({ imageTitle: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item name="siteURL" rules={rules}>
                <TextInput
                  name="siteURL"
                  maxLength={100}
                  placeholder={`Enter Site Url`}
                  handleChange={(e) =>
                    form.setFieldsValue({ siteURL: e.target.value })
                  }
                />
              </Form.Item>
            </>
          )}

          <Form.Item name="ImageURL" rules={rules}>
            <TextInput
              name="ImageURL"
              maxLength={500}
              placeholder={`Enter ${
                type?.includes("image") || type?.includes("Image")
                  ? "Image"
                  : type?.includes("video") || type?.includes("Video")
                  ? "Video"
                  : type?.includes("podcast") || type?.includes("Podcast")
                  ? "Podcast"
                  : ""
              } URL`}
              handleChange={(e) =>
                form.setFieldsValue({ ImageURL: e.target.value })
              }
            />
          </Form.Item>
        </div>
      </Form>
    );
  };

  const renderDetailView = () => {
    return (
      <div>
        <Title level={3} style={{ display: "flex", justifyContent: "center" }}>
          {data?.imageTitle}
        </Title>
        {data?.ImageURL && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ImageSlider
              data={[{ ...data, url: data?.ImageURL }]}
              itemToShow={1}
              itemHeight={150}
              itemWidth={150}
              showActions={false}
            />
          </div>
        )}
        {data?.siteURL && (
          <div
            style={{
              paddingTop: "5%",
              display: "flex",
              gap: "5px",
            }}
          >
            <Title level={4}>Source Url: </Title>
            <a href={data?.siteURL}>{data?.siteURL}</a>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      visible={isVisible}
      title={
        detailPage
          ? null
          : !renderFooter
          ? `Licensed ${
              type?.includes("image") || type?.includes("Image")
                ? "Image"
                : type?.includes("video") || type?.includes("Video")
                ? "Video"
                : type?.includes("podcast") || type?.includes("Podcast")
                ? "Podcast"
                : ""
            }`
          : type === "image" || type === "licenseImage"
          ? `${data?.edit ? "Update" : "Add"} License Image`
          : type === "video" || type === "licenseVideo"
          ? `${data?.edit ? "Update" : "Add"} License Video`
          : "Add License Podcast"
      }
      onOk={() => form.submit()}
      width={800}
      onCancel={() => {
        setIsVisible(null);
      }}
      okText={!data?.edit ? "Add" : "Update"}
      footer={!renderFooter && null}
      {...rest}
    >
      {!detailPage ? renderFormView() : renderDetailView()}
    </Modal>
  );
};

export default AddImageVideoModal;
