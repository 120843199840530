import { Card, Skeleton, Title } from "components/common";
import styles from "../index.module.less";

const ScriptSettingLeftPanel = ({
  skeletonLoading = false,
  explanations = ["test", "test", "test", "test", "test"],
}) => {
  const scrollToExplanation = (key) => {
    const element = document.getElementById(key);
    const yOffset = -50;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    if (element) {
      element.scrollIntoView({
        top: y,
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  const renderExplanation = (title, index) => {
    return (
      <div
        className={`pointer ${styles.explanationBox}`}
        onClick={() =>
          scrollToExplanation(`${index}-explanation-collapse-card`)
        }
      >
        {title}
      </div>
    );
  };
  return (
    <Card className={styles.leftSection}>
      <Title level={4} style={{ opacity: "0.5" }}>
        Explanations
      </Title>
      {skeletonLoading
        ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
            <div className={`pointer ${styles.explanationBox}`}>
              <Skeleton type={"button"} width={"10vw"} />
            </div>
          ))
        : explanations?.length &&
          explanations?.map((e, i) => renderExplanation(e?.title, i))}
    </Card>
  );
};

export default ScriptSettingLeftPanel;
