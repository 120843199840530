import { Icon, Number, Spinner, Text } from "components/common"
import { Input, Skeleton, Typography } from "antd"
import { useCallback, useEffect, useState } from "react"

import PropTypes from "prop-types"

function TextInput(props) {
  const {
    flexDirection,
    label,
    labelProps,
    labelClass,
    placeholder,
    prefixIcon,
    suffixIcon,
    suffixText,
    iconColor,
    labelIcon,
    name,
    skeletonLoading,
    value,
    handleChange,
    handleBlur,
    horizontal,
    lineCount,
    errorMsg,
    loading,
    muted,
    useInternalState,
    type,
    limit,
    className,
    maxLength,
    renderDescription,
    renderButton = () => {},
    allowInput,
    renderInputCharacters,
    labelInputParent,
    ...rest
  } = props
  const [fieldValue, setFieldValue] = useState(value)
  useEffect(
    () => {
      setFieldValue(value)
    },
    useInternalState ? [value] : []
  )
  const hide = {
    visibility:'hidden'
    // Add more styles as needed
  };

  const renderLabel = () => {
    return skeletonLoading ? (
      <Skeleton.Button active size="small" style={{ height: 22 }} />
    ) : (
      <label style={{
        visibility: label === 'none'  ? 'hidden' : 'unset'
      }} className={labelClass}>{label}</label>
    )
  }

  const renderInput = showAsTextArea => {
    const Component = showAsTextArea ? Input.TextArea : Input
    return skeletonLoading ? (
      <Skeleton.Input active style={{ borderRadius: 6, width: "100%" }} />
    ) : (
      <>
        <Component
          {...(lineCount && { rows: lineCount })}
          prefix={
            prefixIcon &&
            (typeof prefixIcon === "string" ? <Icon icon={prefixIcon} size="1.2em" color={iconColor} /> : prefixIcon)
          }
          suffix={
            loading ? (
              <Spinner type="plain" size="small" />
            ) : suffixText ? (
              <Text type="secondaryLight">{suffixText}</Text>
            ) : suffixIcon ? (
              <Icon icon={suffixIcon} size="1.2em" color={iconColor} />
            ) : (
              <span />
            )
          }
          name={name}
          maxLength={maxLength}
          value={useInternalState ? fieldValue : value}
          onChange={e => {
            const val = e.target.value
            const shouldChange = allowInput(val)
            if (useInternalState) {
              shouldChange && setFieldValue(val)
            } else {
              shouldChange && handleChange && handleChange(e)
            }
          }}
          onBlur={handleBlur}
          placeholder={placeholder}
          status={errorMsg ? "error" : undefined}
          key={name}
          type={type}
          className={className}
          {...rest}
        />
        {renderDescription(value)}
        {renderButton(value)}
        {!!renderInputCharacters && (
          <Typography.Text>
            <small>
              {value?.length || 0}/{maxLength}
            </small>
          </Typography.Text>
        )}
        {!!errorMsg && (
          <Typography.Text type="danger">
            <small>{errorMsg}</small>
          </Typography.Text>
        )}
      </>
    )
  }
  return (
    <>
    <div className={labelInputParent}>
      {renderLabel()}
      {renderInput(lineCount)}
      </div>
    </>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.object,
  placeholder: PropTypes.string,
  prefixIcon: PropTypes.string,
  suffixIcon: PropTypes.string,
  suffixText: PropTypes.string,
  labelIcon: PropTypes.string,
  name: PropTypes.string,
  skeletonLoading: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  horizontal: PropTypes.bool,
  errorMsg: PropTypes.string,
  lineCount: PropTypes.number,
  loading: PropTypes.bool,
  muted: PropTypes.bool,
  iconColor: PropTypes.string,
}

TextInput.defaultProps = {
  labelIcon: null,
  skeletonLoading: false,
  type: "text",
  renderDescription: () => null,
  allowInput: () => true,
}

export default TextInput
