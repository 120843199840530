import {
  Card,
  DataTable,
  Dropdown,
  Group,
  Icon,
  SelectInput,
  Tag,
  Title,
  Tooltip,
  notification,
} from "components/common";
import { TEMPLATE_DETAIL_PATH } from "constants/app-paths";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import theme from "utils/themeVars";

import PrimaryButton from "components/common/button/primary-button";
import { ScriptLogo } from "components/svg";
import {
  useGetAllScriptsQuery,
  useGetScriptTypesListQuery,
  useGetSpecialitiesQuery,
  useMarkScriptAsFavoriteMutation,
} from "features/script/api";

const FavoriteListing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  // Get the element by its class name
  const element = document.querySelector(".ant-table-header");

  // Check if the element exists before modifying its style
  if (element) {
    // Change its CSS properties
    element.style.overflow = "unset"; // Example of changing the overflow property
  }

  const [searchParams, setSearchParams] = useSearchParams();
  let queryObj = Object.fromEntries([...searchParams]);
  const user = useSelector((state) => state.app.user?.info);

  const { data, isFetching, isLoading, error, refetch } = useGetAllScriptsQuery(
    {
      body: {
        favoriteBy: user?._id,
        ...queryObj,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: options, isLoading: optionsLoading } =
    useGetScriptTypesListQuery();

  const { data: specialties } = useGetSpecialitiesQuery();

  const handleSpecialityChange = (value) => {
    if (value) {
      setSearchParams((prev) => ({
        ...prev,
        speciality: value,
      }));
    }
  };

  const getColumnFilterSpecialties = () => {
    if (specialties?.data?.length) {
      let selected = specialties?.data?.filter((e) =>
        queryObj?.speciality?.includes(e?.topic)
      );
      let unSelected = specialties?.data?.filter(
        (e) => !queryObj?.speciality?.includes(e?.topic)
      );
      return [...selected, ...unSelected];
    }
    return [];
  };

  const libraryTableColumns = [
    {
      title: "No.",
      dataIndex: "key",
      className: "tableStartSpace",
      width: 50,
    },
    {
      title: "Page Name",
      dataIndex: "name",

      width: 300,
    },
    {
      title: "Page ID",
      dataIndex: "pageId",
      width: 80,
    },
    {
      // title: (
      //   <SelectInput
      //     showSearch={false}
      //     placeholder="Speciality"
      //     options={specialties?.data}
      //     onChange={handleSpecialityChange}
      //     value={queryObj?.speciality}
      //     getOptionLabel={(e) => e?.topic}
      //     getOptionValue={(e) => e?.topic}
      //     allowClear
      //     style={{ width: 200 }}
      //   />
      // ),
      title: "Specialty",
      filterIcon: () => (
        <Icon
          icon={
            !!queryObj?.speciality ? "BsFilterCircleFill" : "BsFilterCircle"
          }
          color={!!queryObj?.speciality ? theme["primary-color"] : ""}
          size={20}
        />
      ),
      filterDropdown: ({ confirm }) => (
        <div style={{ maxHeight: 200, overflowY: "auto" }}>
          {specialties?.data?.length ? (
            getColumnFilterSpecialties().map((e) => (
              <div
                key={e?.topic}
                onClick={() => {
                  handleSpecialityChange(e?.topic);
                  confirm();
                }}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  color: queryObj?.speciality?.includes(e?.topic)
                    ? theme["primary-color"]
                    : "black",
                  backgroundColor: queryObj?.speciality?.includes(e?.topic)
                    ? theme["primary-light"]
                    : "white",
                }}
              >
                {e?.topic}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      ),
      dataIndex: "speciality",
      width: 200,
    },
  ];

  const [markAsFavorite, {}] = useMarkScriptAsFavoriteMutation();

  const navigate = useNavigate();

  const getItems = useCallback(() => {
    return options?.length
      ? options?.map((e) => ({
          label: e?.name,
          key: e?._id,
          onClick: () => {
            navigate(`/scripts/add?template_id=${e?.base_template_id}`);
          },
        }))
      : [];
  }, [options]);

  const markFavorite = async (script) => {
    const res = await markAsFavorite({
      id: script?.editor_template_id,
      userId: user?._id,
    });
    if (res) {
      if (res?.error) {
        notification.error({ message: res?.error });
      } else {
        notification.success({ message: "Updated Successfully" });
      }
    }
  };

  const isFavoriteOfUser = (favorites) => {
    if (favorites?.length) {
      const index = favorites.findIndex((e) => e == user?._id);
      return index != -1;
    } else {
      return false;
    }
  };

  const tableData = () => {
    return data?.list?.length
      ? data.list.map((e, index) => ({
          key: index + 1,
          page_name: e?.page_name,
          page_id: e?.page_id,
          name: (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div>
                <Icon
                  icon={
                    isFavoriteOfUser(e?.favorites)
                      ? "AiFillStar"
                      : "AiOutlineStar"
                  }
                  color="#F5B301"
                  size="22px"
                  style={{ cursor: "pointer" }}
                  tooltipText={
                    isFavoriteOfUser(e?.favorites)
                      ? "Click to remove from  Favorites"
                      : "Click to add to Favorites"
                  }
                  onClick={() => {
                    markFavorite(e);
                  }}
                />
              </div>
              <div
                style={{ color: theme["primary-color"] }}
                className="pointer"
                onClick={() =>
                  navigate(
                    `${TEMPLATE_DETAIL_PATH.replace(
                      ":name",
                      e?.page_name
                    )}?editorId=${e?.editor_template_id}&version=${e?.version}`
                  )
                }
              >
                {e?.page_name}
              </div>
            </div>
          ),
          speciality: (
            <>
              {e?.speciality?.length > 3 ? (
                <>
                  {e.speciality.map((item, index) => {
                    return index < 3 ? (
                      <Tag key={index} style={{ marginBottom: "0.5vh" }}>
                        {item}
                      </Tag>
                    ) : null;
                  })}
                  <Tooltip
                    placement="top"
                    title={e.speciality.map((item, index) => {
                      return index > 2 ? <div>{item}</div> : null;
                    })}
                  >
                    <Tag key={"more"} style={{ marginBottom: "0.5vh" }}>
                      {`+${e.speciality?.length - 3}`}
                    </Tag>
                  </Tooltip>
                </>
              ) : (
                <>
                  {e.speciality.map((item, index) => (
                    <Tag key={index} style={{ marginBottom: "0.5vh" }}>
                      {item}
                    </Tag>
                  ))}
                </>
              )}
            </>
          ),
          pageId: <div>{e?.page_id}</div>,
        }))
      : [];
  };

  const renderPageHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5% 0 0% 0",
        }}
      >
        <Title level={1}>My Favorites</Title>
        <Dropdown
          options={getItems()}
          trigger="click"
          arrow={false}
          suffixIcon=""
          style={{
            color: theme["blue200"],
            background: theme["light-gray"],
            fontWeight: 800,
            fontSize: "1.2rem",
            padding: "12px, 12px, 13px, 12px",
            borderRadius: "4px",
            borderColor: theme["blue100"],
          }}
        >
          <PrimaryButton>
            <ScriptLogo color="1565DC" />
            Add
          </PrimaryButton>
        </Dropdown>
      </div>
    );
  };

  const renderPresentationWeb = () => {
    return (
      <Group gap="16px" className={"listings-content-adjustments"}>
        <Card>{renderPageHeader()}</Card>
        <DataTable
          pagination={{
            totalCount: data?.pagination?.totalCount,
            pageOptions: [10, 25, 50],
          }}
          paginationOnBottom={true}
          onChangePage={(queryObj) => setSearchParams(queryObj)}
          columns={libraryTableColumns}
          data={tableData()}
          loading={isLoading}
          error={error}
          onErrorRetry={refetch}
          skeletonLoading={isFetching}
          stickyOffset={0}
          noUrlPush={false}
        />
      </Group>
    );
  };

  return <>{renderPresentationWeb()}</>;
};

export default FavoriteListing;
