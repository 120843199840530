import { Spinner } from "components/common";
import { USER_STORAGE_KEY } from "constants";
import {
  LOGIN_PATH,
  PRESENTATION_DETAIL_PATH,
  PRESENTATION_DETAIL_VIEW_PUBLIC_PATH,
  PRESENTATION_PATH,
  SCRIPT,
  SCRIPT_PATH,
  SCRIPT_QUIZ_PATH,
  SCRIPT_QUIZ_RESULT_PATH,
  USER_VERIFIED_PATH,
} from "constants/app-paths";
import getAppRoutes from "constants/app-routes";
import SignIn from "features/auth/sign-in";
import PresentationDetails from "features/presentation/components/presentationDetailPage";
import PublicPresentation from "features/auth/publicPresentationViewCall";
import AppLayout from "layout";
import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router";
import { setAppUser, setPopoverLinks } from "store/appSlice";
import parentApi from "store/parentApi";
import { setStorageItem } from "utils/localStorage";
import { useSearchParams } from "react-router-dom";
import TakeQuiz from "features/quiz/takeQuiz";
import QuizResult from "features/quiz/result";

const PFRoutes = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.app.auth.token);
  const [isMobile, setIsMobile] = useState(false);
  const { data: user } = parentApi.useGetCurrentUserDetailQuery(undefined, {
    skip: !token,
  });
  const { data: popoverLinks } = parentApi.useGetUserPopoverLinksQuery(
    undefined,
    {
      skip: !token,
    }
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);

  const { redirectedPath = "" } = queryObj || {};

  useEffect(() => {
    if (popoverLinks?.length) {
      dispatch(setPopoverLinks(popoverLinks));
    }
  }, [popoverLinks]);

  useEffect(() => {
    if (user) {
      setStorageItem(USER_STORAGE_KEY, user);
      dispatch(setAppUser({ info: user }));
    }
  }, [user]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 700);
  }, []);

  const renderDefault = () => {
    if (redirectedPath) {
      return redirectedPath;
    } else {
      return SCRIPT;
    }
  };
  //TODO
  const presentationUrl = null && localStorage.getItem("presentation_url");
  return (
    <Suspense fallback={<Spinner size="large" fullPageSpinner />}>
      <Routes>
        <Route path="/" element={<Navigate to={renderDefault()} />} />
        <Route
          path={USER_VERIFIED_PATH}
          element={
            !!token ? (
              <Navigate
                to={
                  presentationUrl && presentationUrl != ""
                    ? presentationUrl
                    : SCRIPT
                }
              />
            ) : (
              <SignIn isLoggedIn={!!token} />
            )
          }
          name="sign-in"
        />
        <Route
          path={LOGIN_PATH}
          element={
            !!token ? (
              <Navigate to={renderDefault()} />
            ) : (
              <SignIn isLoggedIn={!!token} />
            )
          }
          name="sign-in"
        />
        <Route
          path={PRESENTATION_DETAIL_VIEW_PUBLIC_PATH}
          element={<PublicPresentation />}
          name="sign-in"
        />
        <Route
          path={SCRIPT_QUIZ_PATH}
          element={!!token ? <TakeQuiz /> : <SignIn isLoggedIn={!!token} />}
          name="quiz"
        />
        <Route
          path={SCRIPT_QUIZ_RESULT_PATH}
          element={!!token ? <QuizResult /> : <SignIn isLoggedIn={!!token} />}
          name="QuizResult"
        />
        <Route
          path={LOGIN_PATH}
          element={
            !!token ? (
              <Navigate
                to={
                  presentationUrl && presentationUrl != ""
                    ? presentationUrl
                    : SCRIPT
                }
              />
            ) : (
              <SignIn isLoggedIn={!!token} />
            )
          }
          name="sign-in"
        />
        <Route
          path={PRESENTATION_DETAIL_PATH}
          element={
            !!token && isMobile ? (
              <PresentationDetails />
            ) : !!token ? (
              <AppLayout>
                <PresentationDetails />
              </AppLayout>
            ) : (
              <PresentationDetails />
            )
          }
          name="presentation-details"
        />

        <Route
          path="/"
          element={
            !!token ? (
              <AppLayout>
                <Outlet />
              </AppLayout>
            ) : (
              <Navigate to={LOGIN_PATH} />
            )
          }
        >
          {getAppRoutes()?.map((e) => (
            <Route path={e.path} element={e.element} name={e.name} />
          ))}
        </Route>
      </Routes>
    </Suspense>
  );
};
export default PFRoutes;
