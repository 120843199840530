import { Button, Checkbox, Icon, TextInput, Tooltip } from "components/common";
import PrimaryButton from "components/common/button/primary-button";
import TextEditor from "components/common/textEditor/textEditor";
import { BsPlus } from "react-icons/bs";
import { MdAddBox } from "react-icons/md";
import styles from "./index.module.less";
import { TABLE_DELETE_ICON } from "components/svg";

const PollFields = ({ key, slide, onChange, backgroundColor }) => {
  const { question, options = [] } = slide?.data;

  const onChangeQuestion = (value) => {
    onChange({ ...slide?.data, question: value });
  };

  const onChangeOption = (index, value) => {
    const tempOptions = [...options];
    tempOptions[index] = {
      ...tempOptions[index],
      description: value,
    };
    onChange({ ...slide?.data, options: tempOptions });
  };

  const onAddOption = () => {
    const tempOptions = [...options];
    tempOptions.push({
      placeholder: "Add your option here",
      data: null,
      isAnswer: false,
    });
    onChange({ ...slide?.data, options: tempOptions });
  };

  const onRemoveOption = (index) => {
    const tempOptions = [...options];
    tempOptions.splice(index, 1);
    onChange({ ...slide?.data, options: tempOptions });
  };

  const onChangeCheckbox = (value, index) => {
    const tempOptions = [...options];
    tempOptions[index] = { ...tempOptions[index], isAnswer: value };
    onChange({ ...slide?.data, options: tempOptions });
  };

  return (
    <>
      <div key={key} style={{ display: "flex", flexDirection: "column" }}>
        <TextEditor
          key={key}
          value={question}
          onChange={(value) => onChangeQuestion(value)}
          editorHeight={200}
        />

        <div
          style={{
            marginTop: "20px",
            backgroundColor: backgroundColor ?? "white",
          }}
        >
          {options.map((option, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "10px auto",
                  gap: "10px",
                  alignItems: "center",
                  backgroundColor: backgroundColor ?? "white",
                  width: "100%",
                }}
              >
                <Tooltip title="Mark as right option">
                  <Checkbox
                    key={index}
                    onChange={(e) => onChangeCheckbox(e.target.checked, index)}
                    checked={option?.isAnswer}
                    style={{ backgroundColor: backgroundColor ?? "white" }}
                  ></Checkbox>
                </Tooltip>
                <div style={{ width: "100%" }}>
                  <TextInput
                    value={option?.description}
                    placeholder={option?.placeholder}
                    onChange={(e) => onChangeOption(index, e?.target?.value)}
                    className={styles.pollOpt}
                    // style={{
                    //   backgroundColor: backgroundColor ?? "white",
                    //   "&input": {
                    //     backgroundColor: "transparent",
                    //   },
                    // }}
                  />
                </div>
                {index === options.length - 1 && (
                  <div
                    onClick={onAddOption}
                    style={{
                      background: "#60A7DB",
                      color: "white",
                      width: "2.5vw",
                      height: "4vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      fontSize: "3vh",
                      cursor: "pointer",
                    }}
                  >
                    +
                  </div>
                )}
                {/* <Icon
                icon="RiDeleteBin5Line"
                  style={{ color: "red", fontSize: "1.4rem" }}
                  onClick={() => onRemoveOption(index)}
                  className="pointer"
                />  */}
                <Button
                  icon={<TABLE_DELETE_ICON />}
                  iconProps={{
                    size: "1.8rem",
                    color: "#fc5327",
                    backgroundColor: slide?.backgroundColor ?? "white",
                  }}
                  style={{
                    backgroundColor: slide?.backgroundColor ?? "white",
                  }}
                  size="small"
                  outlined
                  onClick={() => onRemoveOption(index)}
                />
              </div>
            );
          })}
        </div>
      </div>
      {options?.length <= 10 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <PrimaryButton icon={<BsPlus size={30} />} onClick={onAddOption}>
            Add Option
          </PrimaryButton> */}
        </div>
      )}
    </>
  );
};

export default PollFields;
