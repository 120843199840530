import { Modal } from "components/common";
import PresentationSchema from "components/custom/schema/presentationSchema";
import { useEffect, useState } from "react";

const SchemaModal = ({
  visible,
  setIsVisible,
  onConfirm,
  schemaData,
  onCancel,
  onOk,
  viewOnly = false,
  ...rest
}) => {
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    setSchema(schemaData);
  }, [schemaData]);

  return (
    <Modal
      visible={visible}
      onOk={() => {
        onConfirm(schema);
        setIsVisible(null);
      }}
      onCancel={onCancel}
      title={viewOnly ? "Schema" : "Add Schema"}
      okText="Add"
      width={1500}
      footer={!!viewOnly && null}
      {...rest}
    >
      <div style={{ height: "800px" }}>
        {schema && (
          <PresentationSchema
            slide={schema}
            onChange={(e) => {
              setSchema((prev) => ({
                ...prev,
                data: {
                  ...prev?.data,
                  nodes: e?.nodes,
                  edges: e?.edges,
                },
              }));
            }}
            viewOnly={viewOnly}
          />
        )}
      </div>
    </Modal>
  );
};

export default SchemaModal;
