import { Image } from "antd";
import { Button, Card, ImageUpload, Title, Tooltip } from "components/common";
import { TitleDescriptionInput } from "components/custom/titleDescriptionInput/titleDescriptionInput";
import { ADD_MEDIA } from "components/svg";
import CircularArrow from "features/presentation/components/arrowSlider";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScriptPresentationData } from "store/scriptSlice";
import theme from "utils/themeVars";

const ScriptPresentationSection = ({
  titleDescriptions = [],
  media = [],
  editMode,
  onEditSave,
  updateLoading,
  isMobile,
  updatePresentationData,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [mainSlide, setMainSlide] = useState(0);
  const scriptData = useSelector((state) => state?.scripts?.data);

  const handleImageUpload = (image) => {
    updatePresentationData(titleDescriptions, [
      ...media,
      { id: "", url: image?.location, ...image, type: "image" },
    ]);
    setMainSlide(() => media?.length + 1);
    setLoading(false);
  };

  const handleAddOption = () => {
    updatePresentationData(
      [
        ...titleDescriptions,
        {
          key: (titleDescriptions?.length + 1).toString(),
          placeholder: "Enter Title and Description",
        },
      ],
      media
    );
  };

  const handleRemoveOption = (index) => {
    let tempTitleDescriptions = [...titleDescriptions];
    tempTitleDescriptions = tempTitleDescriptions?.filter((e, i) => i != index);
    updatePresentationData(tempTitleDescriptions, media);
  };

  const handleTitleDescriptionhange = (value, index, key) => {
    const tempTitleDescriptions = JSON.parse(JSON.stringify(titleDescriptions));
    tempTitleDescriptions[index] = {
      ...tempTitleDescriptions[index],
      [key]: value,
    };
    updatePresentationData(tempTitleDescriptions, media);
  };

  const handleRemoveImage = () => {
    let tempMedia = [...media];
    let tempTitleDescriptions = [...titleDescriptions];

    tempMedia = tempMedia?.filter((e, i) => i != mainSlide - 1);
    updatePresentationData(tempTitleDescriptions, tempMedia);
    setMainSlide((prev) => prev - 1);
  };

  const renderPresentationHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1%",
          alignItems: "end",
        }}
        id={`0-explanation-collapse-card`} //Do not change this id as it represent presentation section to scroll
      >
        <Title level={2}>Presentation</Title>
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          {media?.length ? (
            <CircularArrow
              totalSlides={media?.length + 1}
              subSlide={mainSlide}
              setSubSlide={setMainSlide}
            />
          ) : (
            <></>
          )}
          <ImageUpload onUpload={handleImageUpload} setLoading={setLoading}>
            <Button
              type="bordered"
              style={{
                borderColor: theme["blue100"],
                color: theme["blue200"],
                background: theme["light-gray"],
              }}
              icon={<ADD_MEDIA />}
              iconProps={{ size: "14px", textColor: theme["blue200"] }}
              size="small"
              text="Add Media"
              onClick={() => {}}
              loading={loading}
            />
          </ImageUpload>
        </div>
      </div>
    );
  };

  const renderTitleDescriptions = () => {
    return (
      <>
        {titleDescriptions?.length
          ? titleDescriptions?.map((e, i) => (
              <TitleDescriptionInput
                key={e?.key}
                onRemove={() => handleRemoveOption(i)}
                title={e?.title}
                description={e?.description}
                onChangeTitle={(e) => {
                  handleTitleDescriptionhange(e?.target?.value, i, "title");
                }}
                onChangeDescription={(value) => {
                  handleTitleDescriptionhange(value, i, "description");
                }}
              />
            ))
          : null}
      </>
    );
  };

  const renderMedia = () => {
    return (
      <Image
        width={"73vw"}
        height={"73vh"}
        style={{ padding: "2% 5% 2% 5%", border: "1px solid gray" }}
        src={media?.[mainSlide - 1]?.url}
        key={`presentation-Image-${media?.[mainSlide - 1]?.url}`}
        preview={{
          mask: (
            <div style={{ display: "flex", gap: "10px" }}>
              <Tooltip title="Preview">
                <Button icon={"IoInformationCircleOutline"} />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  icon={"MdDeleteForever"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImage();
                    // onDelete(item, index);
                  }}
                />
              </Tooltip>
            </div>
          ),
        }}
      />
    );
  };

  return (
    <Card style={{ marginBottom: "10px" }}>
      {renderPresentationHeader()}
      <div
        style={{
          padding: "1%",
          ...(!isMobile && { overflow: "auto", maxHeight: "1400px" }),
        }}
      >
        {mainSlide == 0 && renderTitleDescriptions()}
        {mainSlide != 0 && renderMedia()}
      </div>
      {!!editMode && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <Button
            text="Cancel"
            bordered
            style={{ borderColor: theme["primary-color"] }}
            onClick={() => {}}
          />
          <Button
            text="Save"
            loading={updateLoading}
            type="primary"
            style={{ borderColor: theme["primary-color"] }}
            onClick={() => {
              onEditSave(["section_1", "media"], undefined, scriptData);
            }}
          />
        </div>
      )}
      {mainSlide == 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <Button
            type="bordered"
            style={{
              borderColor: theme["blue100"],
              color: theme["blue200"],
              background: theme["light-gray"],
            }}
            icon="BsPlus"
            iconProps={{ size: "24px" }}
            size="small"
            text="Add More"
            onClick={handleAddOption}
          />
        </div>
      )}
    </Card>
  );
};

export default ScriptPresentationSection;
