import { convertQueryObjToString } from "utils";
import {
  allLibrariesTransformer,
  dataTransformer,
  presentationTransformer,
} from "../transformers";

const { default: parentApi } = require("store/parentApi");

const presentationApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getAllPresentations: build.query({
      query: (filters) => {
        return {
          url: `/presentation?${convertQueryObjToString(filters)}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return allLibrariesTransformer(response?.data);
      },
      providesTags: ["allPresentations"],
    }),
    getPresentation: build.query({
      query: (id) => {
        return {
          url: `/presentation/${id}`,
          method: "GET",
          headers: {
            isPublic: true,
          },
        };
      },

      transformResponse: (response) =>
        presentationTransformer(response?.data.data),
      providesTags: ["presentationDetails"],
    }),
    getPresentationWithInterval: build.query({
      query: (id) => {
        return {
          url: `/presentation/${id}`,
          method: "GET",
          headers: {
            isPublic: true,
          },
        };
      },

      transformResponse: (response) =>
        presentationTransformer(response?.data.data),
      providesTags: ["presentationDetails"],
    }),
    addPresentation: build.mutation({
      query: ({ data }) => {
        const presentationData = dataTransformer(data);
        return {
          url: `/presentation`,
          method: "POST",
          body: presentationData,
        };
      },
      transformResponse: (response) => response?.data.data,
      providesTags: ["presentationAdd"],
      invalidatesTags: ["allPresentations"],
    }),
    updatePresentation: build.mutation({
      query: ({ id, data }) => {
        const presentationData = dataTransformer(data);
        return {
          url: `/presentation/${id}`,
          method: "PUT",
          body: presentationData,
        };
      },
      transformResponse: (response) => response?.data.data,
      providesTags: ["presentationEdit"],
      invalidatesTags: ["allPresentations"],
    }),
    getAllLinks: build.query({
      query: (id) => {
        return {
          url: `/presentation/${id}`,
          method: "GET",
        };
      },
      transformResponse: (response) =>
        presentationTransformer(response?.data.data),
      providesTags: ["presentationDetails"],
    }),
    resetPresentation: build.mutation({
      query: (id) => {
        return {
          url: `/presentation/${id}/reset`,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data.data,
      providesTags: ["presentationReset"],
      invalidatesTags: ["allPresentations", "presentationDetails"],
    }),
    pollAction: build.mutation({
      query: ({ id, data }) => {
        return {
          url: `/presentation/${id}/action`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response?.data.data,
      providesTags: ["presentationAction"],
    }),
    pollVote: build.mutation({
      query: ({ id, data }) => {
        return {
          url: `/presentation/${id}/vote`,
          method: "POST",
          body: data,
          headers: {
            isPublic: true,
          },
        };
      },
      transformResponse: (response) => response?.data.data,
      providesTags: ["presentationVote"],
    }),
    completePresentation: build.mutation({
      query: (id) => {
        return {
          url: `/presentation/${id}/complete`,
          method: "POST",
        };
      },

      transformResponse: (response) => response?.data.data,
      providesTags: ["presentationCompelete"],
      invalidatesTags: ["presentationDetails"],
    }),
    deletePresentation: build.mutation({
      query: (id) => {
        return {
          url: `/presentation/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: (response) => response?.data.data,
      providesTags: ["presentationDelete"],
      invalidatesTags: ["allPresentations"],
    }),
    updateAllPollStatus: build.mutation({
      query: (body) => {
        return {
          url: `/presentation/update-all-polls/${body?.presentationId}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["presentationDetails"],
    }),
  }),
});

export const {
  useGetAllPresentationsQuery,
  useGetPresentationQuery,
  useGetPresentationWithIntervalQuery,
  useCompletePresentationMutation,
  useDeletePresentationMutation,
  useGetAllLinksQuery,
  usePollActionMutation,
  usePollVoteMutation,
  useResetPresentationMutation,
  useUpdatePresentationMutation,
  useAddPresentationMutation,
  useUpdateAllPollStatusMutation,
} = presentationApis;
