import theme from "utils/themeVars";
import { Button, Title } from "components/common";
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";
import RenderSlide from "./renderSlide";

export const ItemTypes = {
  CARD: "card",
};

const SlidePreview = ({
  id,
  slide,
  mainSlide,
  index,
  onClick,
  moveSlide,
  deleteSlide,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveSlide(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div id={`main-slide${index}`} data-handler-id={handlerId} ref={ref}>
      <RenderSlide
        handlerId={handlerId}
        mainSlide={mainSlide}
        slide={slide}
        onClick={onClick}
        index={index}
        ref={ref}
        opacity={opacity}
        deleteSlide={(id, index) => deleteSlide(id, index)}
        isEditable={true}
      />
    </div>
  );
};

export default SlidePreview;
