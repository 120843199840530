import React from 'react';

function BackButtonSvg() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.72701 9.07819L9.34901 13.7002C9.42447 13.7761 9.51422 13.8364 9.61307 13.8775C9.71192 13.9187 9.81794 13.9399 9.92501 13.9399C10.0321 13.9399 10.1381 13.9187 10.2369 13.8775C10.3358 13.8364 10.4255 13.7761 10.501 13.7002L11.269 12.9322C11.345 12.8567 11.4052 12.767 11.4464 12.6681C11.4875 12.5693 11.5087 12.4633 11.5087 12.3562C11.5087 12.2491 11.4875 12.1431 11.4464 12.0443C11.4052 11.9454 11.345 11.8557 11.269 11.7802L7.99601 8.50019L11.272 5.22419C11.348 5.14872 11.4082 5.05898 11.4494 4.96012C11.4905 4.86127 11.5117 4.75526 11.5117 4.64819C11.5117 4.54112 11.4905 4.43511 11.4494 4.33625C11.4082 4.2374 11.348 4.14766 11.272 4.07219L10.504 3.30119C10.4285 3.22524 10.3388 3.16496 10.2399 3.12383C10.1411 3.0827 10.0351 3.06152 9.92801 3.06152C9.82094 3.06152 9.71492 3.0827 9.61607 3.12383C9.51722 3.16496 9.42747 3.22524 9.35201 3.30119L4.73101 7.92319C4.65472 7.99878 4.59417 8.08873 4.55285 8.18785C4.51153 8.28697 4.49025 8.3933 4.49025 8.50069C4.49025 8.60808 4.51153 8.7144 4.55285 8.81353C4.59417 8.91265 4.65472 9.0026 4.73101 9.07819H4.72701Z"
        fill="#1565DC"
      />
    </svg>
  );
}

export default BackButtonSvg;