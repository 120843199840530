import { createSlice } from "@reduxjs/toolkit"

const reportsSlice = createSlice({
  name: "reports",
  initialState: {},
  reducers: {
    setSelectedColumns(state, { payload }) {
      state.selectedColumns = payload
    },
  },
})

export const { setSelectedColumns } = reportsSlice.actions
export default reportsSlice
