import "react-phone-number-input/style.css"
import { default as PhoneInputReact } from "react-phone-number-input"
import { Row } from "antd"
import styles from "./phoneInput.module.less"

const PhoneInput = props => {
  const {
    label,
    className = "",
    errorMsg,
    horizontal,
    labelClassName,
    loading,
    errorType,
    errorClassName,
    placeholder,
    id,
    value,
    setValue,
    textRight,
    textAreaLabelClassName,
    containerClassName,
    left,
    right,
    onChange,
    inputContainerClass,
    defaultCountry,
    disabled,
    component,
    ...rest
  } = props

  //   const country = useSelector(state => state.BasicConfigurationData?.tenant?.country)

  const renderLabel = () =>
    label ? (
      <div className="ant-col ant-form-item-label">
        <label className="ant-form-item-required">{label}</label>
      </div>
    ) : null

  const renderInput = () => (
    <PhoneInputReact
      value={value}
      onChange={onChange}
      defaultCountry={defaultCountry}
      placeholder={placeholder}
      international
      countries={["PK"]}
      disabled={disabled}
      // countrySelectComponent={props => {
      //   return (
      //     <Select
      //       {...props}
      //     />
      //   );
      // }}
      {...rest}
    />
  )
  const renderText = () => {}

  return (
    <>
      <div className={`${styles.phoneInputOuter} ${className}`} id={id ? id : ""}>
        <Row align="middle" justify="space-between">
          {renderLabel()}
          {!!component && component}
        </Row>
        {renderInput()}
        {!!errorMsg && <p>{errorMsg}</p>}
      </div>
    </>
  )
}
export default PhoneInput
