import { useState } from "react";
import { NodeResizer } from "reactflow";
import NodeLabelModel from "./nodeLabelModal";
import { renderQuillHtml } from "configs/utils";

function TextNode({
  data,
  id,
  onChange,
  selected,
  onNodeResize,
  onDelete,
  ...rest
}) {
  const [isVisible, setIsVisible] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: data?.width,
    height: data?.height,
  });

  const onResize = (event, dim) => {
    onNodeResize(id, { width: dim?.width, height: dim?.height });
  };

  return (
    <>
      <NodeLabelModel
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onChange={onChange}
        label={data?.label}
        id={id}
        onDelete={onDelete}
      />
      {selected && (
        <NodeResizer
          id={id}
          minWidth={200}
          minHeight={70}
          onResize={onResize}
        />
      )}
      <div
        style={{
          position: "relative",
          width: dimensions?.width,
          height: dimensions?.height,
          border: selected ? "2px solid #1565DC" : "1px solid black",
          borderRadius: 5,
          backgroundColor: "#fff",
          pointerEvents: "all",
        }}
        className="pointer"
        onDoubleClick={() => setIsVisible(true)}
      >
        <div
          style={{
            position: "absolute",
            fontWeight: "bold",
            color: selected ? "#1565DC" : "#000",
            padding: "10px",
            overflow: "auto", // Add this line to enable scrolling
            maxWidth: dimensions?.width - 10, // Set a maximum width for the box
            maxHeight: dimensions?.height - 10, // Set a maximum height for the box
          }}
        >
          {renderQuillHtml(data?.label)}
        </div>
      </div>
    </>
  );
}

export default TextNode;
