import { Form } from "antd";
import { SelectInput, TextInput, Title } from "components/common";
import {
  useGetSpecialitiesQuery,
  useGetTopicsQuery,
} from "features/script/api";

const PresentationDetails = ({ basicInfo, setBasicInfo }) => {
  const [form] = Form.useForm();
  const { data: specialities } = useGetSpecialitiesQuery();
  const { data: topics } = useGetTopicsQuery();

  return (
    <>
      <Form
        form={form}
        name="presentation"
        initialValues={{
          name: basicInfo.name,
          specialty: basicInfo.specialities[0],
          topics: basicInfo.topics,
        }}
        scrollToFirstError
        //   onFinish={onSubmit}
        preserve={true}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        labelAlign={"left"}
        shouldUpdate
      >
        <Form.Item name={"name"} required label={"Name"}>
          <TextInput
            placeholder={"Enter Name"}
            onChange={(e) => {
              setBasicInfo((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item name={"specialty"} required label={"Specialty"}>
          <SelectInput
            placeholder={"Select Specialty"}
            options={
              specialities && specialities.data
                ? specialities.data.map((spec) => ({
                    label: spec.topic,
                    value: spec.topic,
                    id: spec._id,
                  }))
                : []
            }
            onChange={(e) => {
              setBasicInfo((prev) => ({
                ...prev,
                specialities: [...prev.specialities, e],
              }));
            }}
          />
        </Form.Item>
        <Form.Item name={"topics"} required label={"Topics"}>
          <SelectInput
            placeholder={"Select Topics"}
            allowClear={false}
            showSearch
            mode="multiple"
            getPopupContainer={false}
            loading={false}
            options={
              topics && topics.data
                ? topics.data.map((topic) => ({
                    label: topic.topic,
                    value: topic.topic,
                    id: topic._id,
                  }))
                : []
            }
            onChange={(e) => {
              setBasicInfo((prev) => ({
                ...prev,
                topics: e,
              }));
            }}
          />
        </Form.Item>
      </Form>
    </>
  );
};
export default PresentationDetails;
