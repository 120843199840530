import React, { useState, useEffect, useCallback } from "react";
import Modal from "../modal/modal";
import Switch from "../switch/switch";
import TextInput from "../textInput/textInput";
import { Input } from "antd";
import parentApi from "store/parentApi";
import notification from "../notification/notification";
import { useSearchParams } from "react-router-dom";

const PopoverModal = ({
  visible,
  setVisible,
  text,
  link,
  setText,
  quillRef,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryObj = Object.fromEntries([...searchParams]);

  const [popup, setPopup] = useState(false);

  const [modalData, setModalData] = useState();
  const [submitLink, {}] = parentApi.usePostLinkMutation();
  const [updateLink, {}] = parentApi.useUpdateLinkMutation();
  const { data: linkDetails } = parentApi.useGetPopoverLinkDetailQuery(text, {
    skip: !text,
    refetchOnMountOrArgChange: true,
  });

  const UpdateModalDataForText = () => {
    //ToDo TemplateId
    const findWordPageSpecific = linkDetails?.find(
      (e) => e?.Title == text && e?.template_id == queryObj?.editorId
    );
    const findWordPublic = linkDetails?.find(
      (e) => e?.Title == text && !e?.isCurrentPageSpecific
    );

    if (findWordPageSpecific) {
      setPopup(true);
      setModalData(findWordPageSpecific);
    } else if (findWordPublic) {
      setPopup(true);
      setModalData(findWordPublic);
    } else {
      setModalData({ Title: text, Link: link });
    }
  };

  useEffect(() => {
    linkDetails && UpdateModalDataForText();
  }, [text, linkDetails]);

  const convertQuillTextToLink = (link) => {
    quillRef.current.getEditor().focus();
    const quill = quillRef.current.getEditor();
    var range = quill.getSelection();
    if (range && range.length != 0) {
      quill.format("link", link);
      quill.format("color", "#4ba4a1");
      notification.success("PopUp Link added Successfully!");
    }
  };

  const onSaveLink = async () => {
    if (!popup) {
      convertQuillTextToLink(modalData?.Link);
    } else {
      const res = (await modalData?._id)
        ? updateLink(modalData)
        : submitLink(modalData);
      if (res) {
        if (res?.error) {
          notification.error(res?.error);
        } else {
          convertQuillTextToLink(modalData?.Link);
        }
      }
    }
    setModalData(null);
    setPopup(false);
    setText(null);
    setVisible(false);
  };

  const getButtonTitle = useCallback(
    () => (modalData?._id || link ? "Update" : "Add"),
    [modalData]
  );

  return (
    <Modal
      visible={visible}
      title={
        popup
          ? `${
              modalData?._id ? "Update" : "Add"
            } Extra Information to your hyperlink`
          : `${modalData?._id ? "Update" : "Add"} Hyper Link`
      }
      onOk={onSaveLink}
      onCancel={() => {
        setPopup(false);
        setText(null);
        setModalData(null);
        setVisible(false);
      }}
      okText={getButtonTitle()}
    >
      <>
        {!modalData?._id && (
          <Switch
            label="Add Pop-up"
            value={popup}
            onChange={(e) => setPopup((prev) => !prev)}
          />
        )}

        <TextInput
          placeholder="Enter Title"
          value={modalData?.Title}
          onChange={(e) =>
            setModalData((prev) => ({ ...prev, Title: e?.target?.value }))
          }
          style={{ marginTop: "5%" }}
          disabled={true}
        />

        {popup && (
          <Input.TextArea
            rows={4}
            placeholder="Enter Description"
            value={modalData?.Description}
            onChange={(e) => {
              setModalData((prev) => ({
                ...prev,
                Description: e?.target?.value,
              }));
            }}
            style={{ marginTop: "5%" }}
          />
        )}

        <TextInput
          placeholder="Add HyperLink"
          value={modalData?.Link}
          onChange={(e) =>
            setModalData((prev) => ({ ...prev, Link: e?.target?.value }))
          }
          style={{ marginTop: "5%" }}
        />
      </>
    </Modal>
  );
};

export default PopoverModal;
